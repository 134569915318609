
<div>
  Назначена заявка
  <router-link
    :to="{ name: 'pest-request-view', params: { id: notification.itemId } }"
    class=""
  >
    #{{
      notification.requestNumber ? notification.requestNumber : "Без номера"
    }}
  </router-link>
</div>
