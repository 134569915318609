<template>
  <div class="pest-penalty-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link
              :to="{ name: 'pest-penalty-list' }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <div>
            <h1>
              {{ penalty.title }}
            </h1>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <pest-penalty-info
          :penalty="penalty"
          @update-prop="updateProp"
        ></pest-penalty-info>
      </div>
    </div>
  </div>
</template>

<script>
import PenaltyInfo from "./PenaltyInfo";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "PenaltyView",
  components: {
    "pest-penalty-info": PenaltyInfo,
  },
  data() {
    return {
      tabIndex: 0,
      penalty: {
        id: "new",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      this.penalty.phone = "";
      let penaltiesUrl = "/pest/penalties/" + this.id;
      this.loading = true;
      this.$http
        .get(penaltiesUrl)
        .then((response) => {
          this.loading = false;
          this.penalty = response.data;
          this.penalty.managerName = this.penalty.manager.username;
          this.header = this.penalty.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/penalties/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.penalty)
        .then((response) => {
          this.penalty = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-penalty-info", this.penalty);
          this.$bvToast.toast("Данные сохранены", {
            title: "Тип отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>
