<template>
  <div class="version">
    <div>Front version: {{ frontVersion }}</div>
    <div>
      Backend version:
      <span v-if="!backendLoading">{{ backendVersion }}</span>
      <span v-else>Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SystemVersion",
  components: {},
  data() {
    return {
      frontVersion: APP_VERSION,
      backendVersion: "",
      backendLoading: true,
    };
  },
  watch: {},
  mounted: function () {
    this.fetchData();
  },
  methods: {
    fetchData: function () {
      this.backendLoading = true;
      let settingsUrl = "app/site/status";
      this.$http
        .get(settingsUrl)
        .then((response) => {
          this.backendLoading = false;
          this.backendVersion = response.data.version;
        })
        .catch((error) => {
          this.backendLoading = false;
          this.backendVersion = "Error";
          // this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.version {
  padding-left: 5px;
}
</style>
