<template>
  <b-overlay :show="loading" rounded="sm">
    <assignee-stats-short
      v-if="tab == 'archiveEmployeeList'"
      ref="stats"
      :search="search"
      :date-from="search.dateFrom"
      :date-to="search.dateTo"
    />

    <div class="ibox mb-4">
      <div v-if="canAddClient()" class="ibox-content">
        <div class="search">
          <div class="d-flex flex-wrap align-items-center">
            <div v-if="canAddClient()" class="mr-2">
              <b-form-group label="Город">
                <b-form-select
                  v-model="search.townId"
                  :options="townList"
                  tabindex="1"
                ></b-form-select>
              </b-form-group>
            </div>
            <div v-if="canAddClient()" class="mr-2">
              <b-form-group label="Номер">
                <b-form-input
                  v-model="search.externalId"
                  tabindex="1"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Дата от">
                <datepicker
                  v-model="search.dateFrom"
                  input-class="form-control"
                  :editable="true"
                  lang="ru"
                  :format="dateFormat"
                  type="date"
                  value-type="format"
                  @change="fetchData"
                ></datepicker>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Дата до">
                <datepicker
                  v-model="search.dateTo"
                  input-class="form-control"
                  :editable="true"
                  lang="ru"
                  :format="dateFormat"
                  type="date"
                  value-type="format"
                  @change="fetchData"
                ></datepicker>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Статус">
                <b-form-select
                  v-model="search.status"
                  :options="statusList"
                  tabindex="1"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Исполнитель">
                <user-autocomplete
                  v-model="search.assigneeId"
                  :default-value="getAssigneeName(search.assignee)"
                  @selected="selectAssignee"
                />
              </b-form-group>
            </div>

            <div class="align-self-center mt-2 mr-2">
              <b-button class="btn btn-info ml-1" @click="fetchData">
                <font-awesome-icon icon="search" />&nbsp;Искать
              </b-button>
            </div>

            <div v-if="!loading" class="mt-2 mr-2">
              Найдено: {{ pagination.total }}
            </div>
          </div>
        </div>

        <div class="d-none d-md-block d-lg-block">
          <b-table
            v-if="requests.length"
            responsive
            striped
            hover
            condensed
            :items="requests"
            :fields="fields"
            :busy="loading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle table-spinner"></b-spinner>
                <strong> Данные подгружаются</strong>
              </div>
            </template>

            <template #cell(time)="data">
              {{ getRequestDatetime(data.item) }}
              <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class="">-->
              <!--          </router-link>-->
              <br />
            </template>

            <template #cell(title)="data">
              <router-link
                :to="{
                  name: 'pest-request-view',
                  params: { id: data.item.id },
                }"
                class=""
              >
                {{ getRequestTitle(data.item) }}
              </router-link>
              <br />
            </template>

            <template #cell(status)="data">
              {{ getRequestStatus(data.item) }}
            </template>

            <template #cell(sum)="data">
              {{
                servicesSum(data.item) ? servicesSum(data.item) : data.item.sum
              }}
              <span v-if="data.item.earned"> / {{ data.item.earned }} </span>
              <span v-if="data.item.hasRecord">
                <font-awesome-icon
                  v-b-tooltip.hover
                  icon="star"
                  title="Рекорд"
                />
              </span>
            </template>

            <template #cell(client)="data">
              {{ getRequestClientName(data.item) }}
              <!--          <router-link :to="{ name: 'crm-client-view', params: { id: data.item.clientId } }" class=""-->
              <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class=""-->
              <!--                       v-if="data.item.clientId">-->
              <!--            {{ getRequestClientName(data.item) }}-->
              <!--          </router-link>-->
              <!--          <div v-else>-->
              <!--            {{ getRequestClientName(data.item) }}-->
              <!--          </div>-->
            </template>

            <template #cell(assignee)="data">
              <router-link
                v-if="data.item.assigneeId"
                :to="{
                  name: 'users-user-view',
                  params: { id: data.item.assigneeId },
                }"
                class=""
              >
                {{ getRequestAssigneeName(data.item) }}
              </router-link>
              <div v-else>
                {{ getRequestAssigneeName(data.item) }}
              </div>
            </template>

            <template #cell(timeCreated)="data">
              {{ getRequestCreateTime(data.item) }}
            </template>

            <template #cell(deadline)="data">
              {{ getRequestDeadline(data.item) }}
            </template>

            <template #cell(town)="data">
              {{ getRequestTownTitle(data.item) }}
            </template>

            <template #cell(timeUpdated)="data">
              {{ getRequestUpdateTime(data.item) }}
            </template>

            <template #cell(externalId)="data">
              <router-link
                :to="{
                  name: 'pest-request-view',
                  params: { id: data.item.id },
                }"
                class=""
              >
                {{ getRequestNumber(data.item) }}
              </router-link>
            </template>

            <template #cell(address)="data">
              {{ data.item.address }}
            </template>

            <template #cell(addressCoords)="data">
              {{ data.item.addressCoords }}
            </template>

            <template #cell(actions)="data">
              <b-button
                class="btn btn-xs btn-primary ml-1"
                @click="openRequest(data.item)"
              >
                <font-awesome-icon icon="eye" />&nbsp;
              </b-button>
              <!--              <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class="">-->
              <!--              </router-link>-->
            </template>
          </b-table>
        </div>

        <b-pagination-nav
          v-if="pagination.count > 1"
          :link-gen="linkGen"
          :value="pagination.page"
          :number-of-pages="pagination.count"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>

      <div v-else-if="showFilter" class="ibox-content pb-2">
        <div class="search">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-2">
              <b-form-group label="Дата от">
                <datepicker
                  v-model="search.dateFrom"
                  input-class="form-control"
                  :editable="true"
                  lang="ru"
                  :format="dateFormat"
                  type="date"
                  value-type="format"
                  @change="fetchData"
                ></datepicker>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Дата до">
                <datepicker
                  v-model="search.dateTo"
                  input-class="form-control"
                  :editable="true"
                  lang="ru"
                  :format="dateFormat"
                  type="date"
                  value-type="format"
                  @change="fetchData"
                ></datepicker>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Статус">
                <b-form-select
                  v-model="search.status"
                  :options="statusList"
                  tabindex="1"
                  @change="fetchData"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="mr-2">
              <b-form-group label="Адрес">
                <b-form-input
                  v-model="search.searchAddress"
                  @change="fetchData"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="align-self-center mt-2 mr-2">
              <button class="btn btn-info mr-2" @click.prevent="fetchData">
                <font-awesome-icon icon="search" />&nbsp;Искать
              </button>
            </div>
          </div>
        </div>

        <div class="d-none d-md-block d-lg-block">
          <b-table
            v-if="requests.length"
            responsive
            striped
            hover
            condensed
            :items="requests"
            :fields="fields"
            :busy="loading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle table-spinner"></b-spinner>
                <strong> Данные подгружаются</strong>
              </div>
            </template>

            <template #cell(externalId)="data">
              <router-link
                :to="{
                  name: 'pest-request-view',
                  params: { id: data.item.id },
                }"
                class=""
              >
                {{ getRequestNumber(data.item) }}
              </router-link>
            </template>

            <template #cell(time)="data">
              {{ getRequestDatetime(data.item) }}
              <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class="">-->
              <!--          </router-link>-->
              <br />
            </template>

            <template #cell(title)="data">
              <router-link
                :to="{
                  name: 'pest-request-view',
                  params: { id: data.item.id },
                }"
                class=""
              >
                {{ getRequestTitle(data.item) }}
              </router-link>
              <br />
            </template>

            <template #cell(status)="data">
              {{ getRequestStatus(data.item) }}
            </template>

            <template #cell(sum)="data">
              {{
                servicesSum(data.item) ? servicesSum(data.item) : data.item.sum
              }}
              <span v-if="data.item.earned"> / {{ data.item.earned }} </span>
              <span v-if="data.item.hasRecord">
                <font-awesome-icon
                  v-b-tooltip.hover
                  icon="star"
                  title="Рекорд"
                />
              </span>
            </template>

            <template #cell(client)="data">
              {{ getRequestClientName(data.item) }}
              <!--          <router-link :to="{ name: 'client-view', params: { id: data.item.clientId } }" class=""-->
              <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class=""-->
              <!--                       v-if="data.item.clientId">-->
              <!--            {{ getRequestClientName(data.item) }}-->
              <!--          </router-link>-->
              <!--          <div v-else>-->
              <!--            {{ getRequestClientName(data.item) }}-->
              <!--          </div>-->
            </template>

            <template #cell(assignee)="data">
              <router-link
                v-if="data.item.assigneeId"
                :to="{
                  name: 'users-user-view',
                  params: { id: data.item.assigneeId },
                }"
                class=""
              >
                {{ getRequestAssigneeName(data.item) }}
              </router-link>
              <div v-else>
                {{ getRequestAssigneeName(data.item) }}
              </div>
            </template>

            <template #cell(timeCreated)="data">
              {{ getRequestCreateTime(data.item) }}
            </template>

            <template #cell(deadline)="data">
              {{ getRequestDeadline(data.item) }}
            </template>

            <template #cell(town)="data">
              {{ getRequestTownTitle(data.item) }}
            </template>

            <template #cell(timeUpdated)="data">
              {{ getRequestUpdateTime(data.item) }}
            </template>

            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'pest-request-view',
                  params: { id: data.item.id },
                }"
                class=""
              >
                <b-button class="btn btn-xs btn-primary ml-1">
                  <font-awesome-icon icon="eye" />&nbsp;
                </b-button>
              </router-link>
            </template>
          </b-table>

          <b-pagination-nav
            v-if="pagination.count > 1"
            :link-gen="linkGen"
            :value="pagination.page"
            :number-of-pages="pagination.count"
            limit="10"
            use-router
            class="mt-2"
          >
          </b-pagination-nav>
        </div>
      </div>
    </div>

    <div class="d-md-none d-lg-none">
      <!--                    :blocked="blocked &&rIndex>0"-->
      <request-card
        v-for="(request, rIndex) in requests"
        :key="rIndex + '-r'"
        :for-employee="!canAddClient()"
        :blocked="blocked"
        :request="request"
      ></request-card>

      <b-pagination-nav
        v-if="pagination.count > 1"
        :link-gen="linkGen"
        :value="pagination.page"
        :number-of-pages="pagination.count"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </b-overlay>
</template>

<script>
import {
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestNumber,
  getRequestDatetime,
  getRequestDeadline,
  getRequestManagerName,
  getRequestStatus,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  servicesSum,
  STATUS_DECLINE,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests";
import RequestCard from "@/modules/pest/views/requests/card/RequestCard.vue";
import DatePicker from "vue2-datepicker";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete";
import { getAssigneeName } from "@/modules/crm/common";
import AssigneeStatsShort from "@/modules/pest/views/stats/AssigneeStatsShort.vue";

import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import clone from "just-clone";

const ARCHIVE_REQUEST_LIST_FILTER = "arсhive-request-list-filter";

export default {
  name: "RequestList",
  components: {
    AssigneeStatsShort,
    UserAutocomplete,
    "request-card": RequestCard,
    Datepicker: DatePicker,
  },
  props: {
    blocked: {},
    tab: {},
  },
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      requests: [],
      search: {
        assignee: {},
        assigneeId: null,
        title: "",
        dateFrom: "",
        dateTo: "",
        managerId: "",
        townId: 1,
        externalId: "",
        status: null,
        searchAddress: "",
      },
      towns: {
        1: "Москва",
        2: "Санкт-Петербург",
      },
      managersList: [],
      statusList: [
        { value: null, text: "Все" },
        // {value: STATUS_NEW, text: 'Новая'},
        // {value: STATUS_PAID, text: 'Оплачена'},
        // {value: STATUS_DISPATCHED, text: 'Распределена'},
        // {value: STATUS_PROGRESS, text: 'В процессе'},
        { value: STATUS_DONE, text: "Закрыта" },
        { value: STATUS_DECLINE, text: "Отклонена" },
      ],
      townList: [],

      telegramChatId: null,
      phone: null,
      telegramUrl: "https://t.me/DezCrmBot?start=",
      loading: true,
      loadingStats: false,
      archived: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      totalSum: 0,
      profitSum: 0,
      sumToPay: 0,
      finesSum: 0,
      average: 0,
      count: 0,
      fields: [
        // {
        //   key: 'title',
        //   label: 'Название',
        // },
        {
          key: "actions",
          label: "",
          class: "actions-cell",
        },
        {
          key: "externalId",
          label: "Номер",
        },
        {
          key: "time",
          label: "Дата/Время",
        },
        {
          key: "status",
          label: "Статус",
        },
        {
          key: "client",
          label: "Клиент",
        },
        {
          key: "sum",
          label: "Сумма / Заработано",
        },
        {
          key: "assignee",
          label: "Исполнитель",
        },
        {
          key: "town",
          label: "Город",
        },
        // {
        //   key: 'address',
        //   label: 'Адрес',
        // },
        // {
        //   key: 'addressCoords',
        //   label: 'Координаты',
        // },
      ],
    };
  },
  computed: {
    showFilter() {
      return this.tab !== "currentEmployeeList";
    },
    showTelegramSingupWindow() {
      return !this.canAddClient() && !this.loadingStats && !this.telegramChatId;
    },
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
    getAverageSumClass() {
      if (this.average < 4000) {
        return "text-danger";
      }
      if (this.average < 5000) {
        return "text-warning";
      }
      return "text-success";
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
    if (!this.canAddClient()) {
      this.fetchSum();
    }
  },
  created() {
    // this.search.dateFrom = dayjs().format('DD.MM.YYYY');
    // this.search.dateTo = dayjs().format('DD.MM.YYYY');
    // this.search.dateTo = dayjs().add(1, 'd').format('DD.MM.YYYY');
    // this.search.dateFrom = dayjs().subtract(7, 'd').format('DD.MM.YYYY');
    this.fetchTowns();
    if (this.tab === "archiveEmployeeList") this.restoreFilterData();
    // this.fetchStatuses();
  },
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    getAssigneeName,
    getRequestNumber,
    servicesSum,
    getRequestTitle,
    getRequestDatetime,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getRequestTownTitle,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
    saveFilterData() {
      const search = clone(this.search);
      this.setProp({ key: ARCHIVE_REQUEST_LIST_FILTER, value: search });
    },
    restoreFilterData() {
      this.search = this.getProp()(ARCHIVE_REQUEST_LIST_FILTER) || this.search;
    },
    fetchData: function () {
      this.loading = true;
      if (this.tab == "archiveEmployeeList") {
        this.$refs["stats"].fetchData();
      }
      let $this = this;
      let requestsUrl = this.archived
        ? "/pest/requests/archived"
        : "/pest/requests";
      let params = {
        page: this.page,
        searchNames: this.search.title,
        townId: this.search.townId,
        status: this.search.status,
        dateFrom: this.search.dateFrom ? this.search.dateFrom : "",
        dateTo: this.search.dateTo ? this.search.dateTo : "",
        assigneeId: this.search.assigneeId,
        externalId: this.search.externalId,
        searchAddress: this.search.searchAddress,
      };
      if (this.tab === "archiveEmployeeList") {
        params.userArchived = true;
        this.saveFilterData();
      }
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.requests = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
          this.$emit("loaded", {
            total: this.pagination.total,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchSum: function () {
      this.loadingStats = true;
      let requestsUrl = "/pest/requests/my-info";
      let params = {};
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;

          this.totalSum = response.data.totalSum;
          this.profitSum = response.data.profitSum;
          this.sumToPay = response.data.sumToPay;
          this.finesSum = response.data.finesSum;
          this.average = response.data.average;
          this.count = response.data.count;
          this.telegramChatId = response.data.telegramChatId;
          this.phone = response.data.phone;
          this.telegramUrl += response.data.phone;
        })
        .catch((error) => {
          this.loadingStats = false;

          this.processRequestError(error);
        });
    },
    linkGen(pageNum) {
      return {
        name:
          this.tab === "archiveEmployeeList"
            ? "pest-employee-archive-request-list"
            : "pest-request-list",
        params: { page: pageNum },
      };
    },
    showArchivedRequests() {
      this.archived = true;
      this.fetchData();
    },
    showActiveRequests() {
      this.archived = false;
      this.fetchData();
    },
    resetFilter() {
      this.search = {
        assignee: {},
        assigneeId: null,
        title: "",
        dateFrom: "",
        dateTo: "",
        managerId: "",
        townId: 1,
        externalId: "",
        status: null,
      };
      this.fetchData();
    },

    canAddClient() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },
    fetchTowns: function () {
      this.townList = [];
      this.townList.push({ value: "", text: "Все" });
      let townUrl = "/pest/towns";
      let params = {};
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.townList.push({ value: item.id, text: item.title });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    selectAssignee(data) {
      if (data && data.value) {
        this.search.assigneeId = data.value.id;
        this.search.assignee = data.value;
      } else {
        this.search.assigneeId = null;
        this.search.assignee = {};
      }
      this.fetchData();
    },
    openRequest(request) {
      this.showUpdatePopup(request);
    },
    showUpdatePopup(info) {
      let $this = this;
      this.$eventHub.$emit("modal-lead-popup", {
        request: info,
        success: function (request) {
          Object.assign(info, request);
        },
        cancel: function () {},
      });
    },
    // fetchStatuses: function () {
    //   this.statusList = [];
    //   this.statusList.push({value: '', text: 'Все'});
    //   let statusUrl = '/pest/statuses';
    //   let params = {};
    //   this.$http.get(statusUrl, {
    //     params: params,
    //   }).then(response => {
    //     for (let item of response.data) {
    //       this.statusList.push({value: item.id, text: item.title});
    //     }
    //   }).catch(error => {
    //     this.processRequestError(error);
    //   })
    // },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.request-organization {
  font-size: smaller;
  color: gray;
}

.actions-cell {
  max-width: 50px;
}

.text-success {
  color: #28a745 !important;
}
</style>
