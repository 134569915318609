
<b-form
  id="pest-region-form"
  ref="region"
  :model="region"
  class="pest-region-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="region.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <!--      <b-form-group label="Город" prop="townId">-->
    <!--        <autocomplete :search="searchTown"-->
    <!--                      class=""-->
    <!--                      :default-value="townTitle"-->
    <!--                      placeholder="Поиск по городам"-->
    <!--                      aria-label="Поиск по городам"-->
    <!--                      :get-result-value="getResultTownAutocompleteValue"-->
    <!--                      ref="searchTown"-->
    <!--                      @submit="handleTownSubmit"-->
    <!--        ></autocomplete>-->
    <!--      </b-form-group>-->

    <div class="row">
      <div class="col-md-5">
        <b-form-group label="Цвет" prop="color">
          <b-form-input v-model="region.color" type="color"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="region.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="region.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteRegion"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
