<template>
  <a
    v-b-tooltip.hover="tooltip"
    class="title-link"
    :title="tooltip"
    @click.prevent="changeSort"
  >
    <span class="title-link-text"
      ><slot>{{ title }}</slot></span
    >&nbsp;
    <span class="title-link-icon">
      <font-awesome-icon v-if="value === param" class="icon" icon="sort-up" />
      <font-awesome-icon
        v-else-if="value === '-' + param"
        class="icon"
        icon="sort-down"
      />
      <font-awesome-icon v-else class="icon inactive" icon="sort" />
    </span>
  </a>
</template>

<script>
export default {
  name: "SortColumnTitle",
  props: ["title", "tooltip", "param", "value"],
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    changeSort() {
      let sort = this.param;
      if (this.value === this.param) {
        sort = "-" + this.param;
      }
      this.$emit("input", sort);
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss" scoped>
.inactive {
  color: lightgrey;
}

.icon {
  margin-left: 5px;
}

.title-link {
  color: inherit;
  white-space: nowrap;
}

.title-link-text {
  white-space: normal;
}
</style>
