
<div class="ibox">
  <div class="ibox-content">
    <h1>Смс сообщения</h1>
    <div class="mb-2"></div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Время</th>
          <th>Текст</th>
          <th>Статус</th>
          <th>Получатель</th>
          <th>Отправитель</th>
          <th>Шлюз</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(message, mIndex) in messages" :key="mIndex">
          <td>{{ getMessageTime(message) }}</td>
          <td>{{ message.text }}</td>
          <td>{{ getMessageStatusTitle(message) }}</td>
          <td>{{ message.phone }}</td>
          <td>{{ getMessageUserName(message) }}</td>
          <td>Манго</td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
