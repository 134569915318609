import dayjs from "dayjs";
import { getUserName } from "@/modules/crm/common";

export const getMessageCreateDatetime = function (message) {
  return dayjs.unix(message.createTime).format("DD.MM.YYYY HH:mm");
};

export const getMessageUserName = function (message) {
  return getUserName(message.user);
};

export const REQUEST_CHANGED_ASSIGNEE = "change_assignee";
export const FINE_APPROVED = "fine_approved";
export const REQUEST_NEW_RECORD = "new_record";
export const MESSAGE = "message";

export const notificationTitles = {
  [REQUEST_CHANGED_ASSIGNEE]: "Смена мастера",
  [FINE_APPROVED]: "Назначен штраф",
  [REQUEST_NEW_RECORD]: "Новый рекорд",
  [MESSAGE]: "Сообщение",
};
