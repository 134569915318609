
<div>
  Назначен
  <router-link
    v-if="canAddFine"
    :to="{ name: 'pest-fine-update', params: { id: notification.itemId } }"
    class=""
  >
    штраф №{{ notification.itemId }}
  </router-link>
  <span v-else>штраф №{{ notification.itemId }}</span>
  на сумму: <strong>{{ sum }}р</strong> <br />
  <strong>Комментарий:</strong> {{ notification.fine.comment }} <br />
  {{ notification.fine.review }}
</div>
