<template>
  <b-form
    id="direct-campaign-form"
    ref="campaign"
    :model="campaign"
    class="direct-campaign-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="campaign.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Аккаунт" prop="title">
        <account-autocomplete
          v-model="campaign.accountId"
          :default-value="getCampaignAccountName(campaign)"
          @selected="selectAccount"
        ></account-autocomplete>
      </b-form-group>

      <b-form-group label="Тип вредителя (один)" prop="title">
        <pest-autocomplete
          v-model="campaign.pestId"
          :default-value="getCampaignPestName(campaign)"
          @selected="selectPest"
        ></pest-autocomplete>
      </b-form-group>

      <b-form-group label="Тип вредителя(несколько)" prop="title">
        <multiply-autocomplete
          ref="pestAutocomplete"
          :get-data="fetchPestData"
          :get-title="getPestTitle"
          @selected="selectMultiplePest"
        ></multiply-autocomplete>
        <div v-if="campaign.pestIds.length">
          Выбранные: {{ getCampaignPestName(campaign) }}<br />
          <a @click.prevent="clearPests">Очистить</a>
        </div>
      </b-form-group>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="campaign.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="campaign.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteCampaign"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import PestAutocomplete from "@/modules/pest/views/pests/PestAutocomplete";
import {
  getCampaignAccountName,
  getCampaignPestName,
} from "@/modules/direct/decorators/campaigns";
import AccountAutocomplete from "@/modules/direct/views/accounts/AccountAutocomplete";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete";
import { getPestTitle } from "@/modules/pest/decorators/pests";

export default {
  name: "DirectCampaignForm",
  directives: { mask: VueMaskDirective },
  components: { MultiplyAutocomplete, AccountAutocomplete, PestAutocomplete },
  props: ["campaign"],
  data() {
    return {
      phoneMask: false,
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.campaign.id;
    },
    name() {
      let name = this.campaign.name ? this.campaign.name : "Новая кампания";
      return name ? name : "Кампания";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    getPestTitle,
    getCampaignAccountName,
    getCampaignPestName,
    update: function () {
      let updateUrl;
      updateUrl = "/direct/campaigns/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.campaign)
        .then((response) => {
          this.campaign = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-direct-campaign-info", this.campaign);
          // this.$router.push({name: 'direct-campaign-view', params: {id: this.campaign.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Кампания отредактирована",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/direct/campaigns";
      this.loading = true;
      this.$http
        .post(updateUrl, this.campaign)
        .then((response) => {
          this.campaign = response.data;
          this.$eventHub.$emit("update-direct-campaign-info", this.campaign);
          this.$router.push({
            name: "direct-campaign-update",
            params: { id: this.campaign.id },
          });
          this.$bvToast.toast("Кампания добавлен", {
            title: "Кампания добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить кампанию",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("direct-campaign-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    selectPest(data) {
      if (data && data.value) {
        this.campaign.pest = data.value;
        this.campaign.pestId = data.value.id;
      } else {
        this.campaign.pest = {};
        this.campaign.pestId = null;
      }
      this.$forceUpdate();
    },
    selectAccount(data) {
      if (data && data.value) {
        this.campaign.account = data.value;
        this.campaign.accountId = data.value.id;
      } else {
        this.campaign.account = {};
        this.campaign.accountId = null;
      }
      this.$forceUpdate();
    },
    deleteCampaign() {
      if (confirm("Вы действительно хотите удалить кампанию?")) {
        let deleteUrl = "/direct/campaigns/" + this.campaign.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "direct-campaign-list" });
            this.$bvToast.toast("Кампания удалена", {
              title: "Кампания удалена",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить кампанию",
                variant: "danger",
              });
            }
          });
      }
    },

    fetchPestData(search, resolve) {
      let pestUrl = "/pest/pests";
      let params = {
        search: search,
        service: false,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = [{ id: -1, title: "Не назначен" }, ...response.data];
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    clearPests() {
      this.campaign.pestIds = [];
    },
    selectMultiplePest(info) {
      this.campaign.pestIds = [];
      if (info) {
        for (let pest of info) {
          this.campaign.pestIds.push(pest.id);
        }
      }
      this.fetchData();
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
