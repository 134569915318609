
<div class="ibox">
  <div class="ibox-content">
    <div class="">
      <h1>Контакты</h1>
      <div class="mb-2">
        <router-link
          :to="{ name: 'crm-client-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          Добавить контакт
        </router-link>

        <!--        <b-button @click="showArchivedClients" class="btn btn-info ml-1" v-if="!archived">-->
        <!--          <font-awesome-icon icon="box"/>-->
        <!--          Показать архив-->
        <!--        </b-button>-->
      </div>
    </div>

    <div class="search">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <b-form-group label="Имя">
            <b-form-input v-model="search.name" tabindex="1"></b-form-input>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Телефон">
            <b-form-input v-model="search.phone" tabindex="3"></b-form-input>
          </b-form-group>
        </div>

        <div v-if="canManageClients" class="mr-2">
          <b-form-group label="Менеджер">
            <b-form-select
              v-model="search.managerId"
              :options="managersList"
              placeholder="Менеджер"
              tabindex="5"
              class="managerFilter"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>
        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.total }}
        </div>
      </div>
    </div>

    <b-table
      v-if="clients.length"
      responsive
      striped
      hover
      condensed
      :items="clients"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(icon)="data">
        <router-link
          :to="{ name: 'crm-client-view', params: { id: data.item.id } }"
          class=""
        >
          <font-awesome-icon
            v-if="data.item.type == c_TYPE_ORGANIZATION"
            icon="building"
          />
          <font-awesome-icon v-else icon="user" />
        </router-link>
      </template>

      <template #cell(name)="data">
        <router-link
          :to="{ name: 'crm-client-view', params: { id: data.item.id } }"
          class=""
        >
          {{ getClientName(data.item) }}
          <div class="client-organization">
            {{ getClientOrganization(data.item) }}
          </div>
        </router-link>
      </template>

      <template #cell(phone)="data">
        {{ getClientFirstPhone(data.item) }}
      </template>
      <template #cell(email)="data">
        {{ getClientFirstEmail(data.item) }}
      </template>
      <template #cell(manager)="data">
        {{ getUserName(data.item.manager) }}
      </template>
      <template #cell(numerationType)="data">
        {{ getNumerationType(data.item) }}
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
