
<div>
  Сумма: {{ log.sum }}<br />
  Мастер: {{ getUserName(log.employee) }}<br />

  <a href="#" @click.prevent="showIds = !showIds"> Идентификаторы:</a>
  <div v-if="showIds">
    finesIds: {{ log.finesIds }}<br />
    requestIds: {{ log.requestIds }}<br />
  </div>
</div>
