
<div class="ibox">
  <div class="ibox-content">
    <h1>Оборудование</h1>
    <div class="mb-2">
      <router-link
        :to="{ name: 'pest-equipment-create' }"
        class="btn btn-primary"
      >
        <font-awesome-icon icon="plus" />
        Добавить оборудование
      </router-link>
    </div>

    <b-table
      v-if="equipments.length"
      responsive
      striped
      hover
      condensed
      :items="equipments"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(price)="data">
        <span v-if="data.item.paymentType === EQUIPMENT_PAYMENT_PERCENT">
          {{ data.item.percent }}%
        </span>
        <span v-else>{{ data.item.price }}р</span>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{
            name: 'pest-equipment-update',
            params: { id: data.item.id },
          }"
          class=""
        >
          {{ data.item.title ? data.item.title : "[Без названия]" }}
        </router-link>
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
