<template>
  <div
    v-click-outside="cancelSearch"
    class="autocomplete-wrapper"
    @keydown.esc="cancelSearch"
  >
    <div class="input-group">
      <b-form-input
        v-model="search"
        autocomplete="off"
        :state="state"
        @change="startSearch"
        @keyup="startSearch"
        @focus="startSearch"
      ></b-form-input>
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          type="button"
          @click="selectItem(false)"
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
    </div>
    <div v-if="items && items.length">
      <ul v-if="showResults" class="autocomplete-search-list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="autocomplete-search-item"
          @click.prevent="selectItem(item)"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FacilityAutocomplete",
  components: {},
  props: [
    "model",
    "label",
    "title",
    "placeholder",
    "required",
    "defaultValue",
    "type",
    "state",
  ],

  data() {
    return {
      loading: false,
      search: "",
      lastSearch: "",
      showResults: false,
      selected: false,
      items: [],
    };
  },
  computed: {
    propModel: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit("update:model", value);
      },
    },
  },

  watch: {
    defaultValue: {
      immediate: true,
      handler(value) {
        this.search = value;
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    selectItem(item) {
      this.showResults = false;
      this.search = item.title;
      this.propModel = item.id;
      this.$emit("selected", { value: item });
    },
    startSearch() {
      if (this.search === this.defaultValue) {
        this.search = "";
      }
      this.showResults = true;
      this.fetchData();
    },
    cancelSearch() {
      if (!this.search || this.search.trim() === "") {
        this.search = this.defaultValue;
      }
      this.showResults = false;
    },

    fetchData() {
      this.loading = true;
      let facilitiesUrl = "/pest/facilities";
      let params = {
        search: this.search,
        type: this.type,
      };
      this.$http
        .get(facilitiesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.items = response.data;
          this.$forceUpdate();
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-wrapper {
  position: relative;
}

ul.autocomplete-search-list {
  position: absolute;
  top: 35px;
  z-index: 10000;

  background: #ffffff;
  width: 100%;

  list-style: none;
  border: 1px solid lightgray;
  padding: 0;

  max-height: 300px;
  overflow-y: scroll;
}

.autocomplete-search-item:hover {
  cursor: pointer;
  background: #1ab394;
  color: white;
}

.autocomplete-search-item {
  width: 100%;
  margin: 0;
  padding: 10px 20px;
}
</style>
