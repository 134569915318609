export const SOCKET_ONOPEN = "✅ Socket connected!";
export const SOCKET_ONCLOSE = "❌ Socket disconnected!";
export const SOCKET_ONERROR = "❌ Socket Error!!!";
export const SOCKET_ONMESSAGE = "Websocket message received";
export const SOCKET_RECONNECT = "Websocket reconnected";
export const SOCKET_RECONNECT_ERROR =
  "Websocket is having issues reconnecting..";

export const mutations = {
  SOCKET_ONOPEN,
  SOCKET_ONCLOSE,
  SOCKET_ONERROR,
  SOCKET_ONMESSAGE,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ERROR,
};
