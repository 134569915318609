<template>
  <div class="div">
    <inline-field
      label="Название"
      type="input"
      attr="title"
      :value="product.title"
      @update-prop="updateProp"
    ></inline-field>
    <inline-field
      label="Описание"
      type="input"
      attr="comment"
      :value="product.comment"
      @update-prop="updateProp"
    ></inline-field>
  </div>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import InlineField from "@/components/shared/InlineField";

export default {
  name: "PestProductForm",
  directives: { mask: VueMaskDirective },
  components: {
    "inline-field": InlineField,
  },
  props: ["product"],
  data() {
    return {};
  },
  computed: {
    id() {
      return this.product.id;
    },
  },
  watch: {},
  created: function () {},
  methods: {
    updateProp(e) {
      this.product[e.attr] = e.value;
      this.$emit("update-prop", e);
    },
  },
};
</script>

<style lang="scss"></style>
