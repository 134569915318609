<template>
  <div class="feed-element">
    <!--    <a href="#" class="float-left">-->
    <!--      <img alt="image" class="rounded-circle" src="img/a1.jpg">-->
    <!--    </a>-->
    <div class="media-body ">
      <small class="float-right text-navy">{{ diffDate }} </small>
      <strong>{{ userName }}</strong> <small class="text-muted">{{ commentDate }}</small>
      <div class="">
        {{ comment.message }}

        <div v-if="isCall">
          <audio :src="comment.info.fileUrl" controls></audio>
        </div>
      </div>
    </div>
  </div>

</template>


<script>


import {getUserName} from "@/modules/crm/common";
import dayjs from "dayjs";

export default {
  name: "CommentList",
  components: {},
  data() {
    return {
      now: dayjs(),
    }
  },
  props: ['comment'],
  created() {
    let self = this;
    setInterval(function () {
      self.now = dayjs();
    }, 60000)
  },
  computed: {
    isCall() {
      return ('call' in this.comment.info) && (this.comment.info.type === 'call')
    },
    diffDate() {
      let time = dayjs.unix(this.comment.time);
      let now = this.now;
      let diffDays = now.diff(time, 'days');
      let diffHours = now.diff(time, 'hours');
      let diffMinutes = now.diff(time, 'minutes');
      let diffSeconds = now.diff(time, 'seconds');

      if (diffDays) {
        return diffDays + 'д назад';
      }
      if (diffHours) {
        return diffHours + 'ч назад';
      }
      if (diffMinutes) {
        return diffMinutes + 'м назад';
      }
      return 'только что';
    },
    commentDate() {
      return dayjs.unix(this.comment.time).format('DD.MM.YYYY HH:MM:SS');
    },
    userName() {
      return getUserName(this.comment.user);
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.feed-element:last-child {
  border-bottom: 0;
}
</style>
