
<div class="board-buttons">
  <div v-if="requestUpdating">
    <button
      :disabled="requestSaving"
      class="btn btn-outline-info mr-2"
      @click.prevent="closeRequestCard"
    >
      <font-awesome-icon icon="arrow-left" />
      Отмена
    </button>

    <b-button
      v-if="!requestSaving"
      :disabled="requestSaving"
      variant="primary"
      @click.prevent="saveRequest"
    >
      <font-awesome-icon icon="check" />
      Сохранить
    </b-button>
    <b-button
      v-else
      :disabled="requestSaving"
      variant="primary"
      @click.prevent
    >
      <font-awesome-icon icon="rotate" spin />
      Сохраняется
    </b-button>
  </div>
  <div v-else-if="isContract && !requestOpened">
    <b-button
      variant="primary"
      :disabled="requestOpening"
      @click.prevent="addNewRequest"
    >
      <font-awesome-icon icon="plus" />
      Добавить заявку
    </b-button>
  </div>
</div>
