
<b-form :model="comment" id="comment-form" ref="comment" class="comment-form " @submit="createOrUpdate"
        novalidate>

  <div class="d-flex">
    <b-form-textarea v-model="comment.message" row="3" tabindex="2">
    </b-form-textarea>
    <div class="ml-2">
      <b-button variant="success" @click="create">
        <font-awesome-icon icon="check"/>
        Написать
      </b-button>
    </div>
  </div>

</b-form>
