
<b-modal
  id="modal-task"
  ref="modal"
  title="Добавить задачу"
  cancel-title="Отмена"
  ok-title="Сохранить"
  title-tag="h3"
  @ok="handleTaskModalOk"
>
  <task-form ref="taskForm" :task="task" :own-window="false"></task-form>
</b-modal>
