
<b-overlay :show="loading" rounded="sm">
  <assignee-stats-short
    v-if="tab == 'archiveEmployeeList'"
    ref="stats"
    :search="search"
    :date-from="search.dateFrom"
    :date-to="search.dateTo"
  />

  <div class="ibox mb-4">
    <div v-if="canAddClient()" class="ibox-content">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div v-if="canAddClient()" class="mr-2">
            <b-form-group label="Город">
              <b-form-select
                v-model="search.townId"
                :options="townList"
                tabindex="1"
              ></b-form-select>
            </b-form-group>
          </div>
          <div v-if="canAddClient()" class="mr-2">
            <b-form-group label="Номер">
              <b-form-input
                v-model="search.externalId"
                tabindex="1"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Дата от">
              <datepicker
                v-model="search.dateFrom"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Дата до">
              <datepicker
                v-model="search.dateTo"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Статус">
              <b-form-select
                v-model="search.status"
                :options="statusList"
                tabindex="1"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Исполнитель">
              <user-autocomplete
                v-model="search.assigneeId"
                :default-value="getAssigneeName(search.assignee)"
                @selected="selectAssignee"
              />
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            Найдено: {{ pagination.total }}
          </div>
        </div>
      </div>

      <div class="d-none d-md-block d-lg-block">
        <b-table
          v-if="requests.length"
          responsive
          striped
          hover
          condensed
          :items="requests"
          :fields="fields"
          :busy="loading"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle table-spinner"></b-spinner>
              <strong> Данные подгружаются</strong>
            </div>
          </template>

          <template #cell(time)="data">
            {{ getRequestDatetime(data.item) }}
            <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class="">-->
            <!--          </router-link>-->
            <br />
          </template>

          <template #cell(title)="data">
            <router-link
              :to="{
                name: 'pest-request-view',
                params: { id: data.item.id },
              }"
              class=""
            >
              {{ getRequestTitle(data.item) }}
            </router-link>
            <br />
          </template>

          <template #cell(status)="data">
            {{ getRequestStatus(data.item) }}
          </template>

          <template #cell(sum)="data">
            {{
              servicesSum(data.item) ? servicesSum(data.item) : data.item.sum
            }}
            <span v-if="data.item.earned"> / {{ data.item.earned }} </span>
            <span v-if="data.item.hasRecord">
              <font-awesome-icon
                v-b-tooltip.hover
                icon="star"
                title="Рекорд"
              />
            </span>
          </template>

          <template #cell(client)="data">
            {{ getRequestClientName(data.item) }}
            <!--          <router-link :to="{ name: 'crm-client-view', params: { id: data.item.clientId } }" class=""-->
            <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class=""-->
            <!--                       v-if="data.item.clientId">-->
            <!--            {{ getRequestClientName(data.item) }}-->
            <!--          </router-link>-->
            <!--          <div v-else>-->
            <!--            {{ getRequestClientName(data.item) }}-->
            <!--          </div>-->
          </template>

          <template #cell(assignee)="data">
            <router-link
              v-if="data.item.assigneeId"
              :to="{
                name: 'users-user-view',
                params: { id: data.item.assigneeId },
              }"
              class=""
            >
              {{ getRequestAssigneeName(data.item) }}
            </router-link>
            <div v-else>
              {{ getRequestAssigneeName(data.item) }}
            </div>
          </template>

          <template #cell(timeCreated)="data">
            {{ getRequestCreateTime(data.item) }}
          </template>

          <template #cell(deadline)="data">
            {{ getRequestDeadline(data.item) }}
          </template>

          <template #cell(town)="data">
            {{ getRequestTownTitle(data.item) }}
          </template>

          <template #cell(timeUpdated)="data">
            {{ getRequestUpdateTime(data.item) }}
          </template>

          <template #cell(externalId)="data">
            <router-link
              :to="{
                name: 'pest-request-view',
                params: { id: data.item.id },
              }"
              class=""
            >
              {{ getRequestNumber(data.item) }}
            </router-link>
          </template>

          <template #cell(address)="data">
            {{ data.item.address }}
          </template>

          <template #cell(addressCoords)="data">
            {{ data.item.addressCoords }}
          </template>

          <template #cell(actions)="data">
            <b-button
              class="btn btn-xs btn-primary ml-1"
              @click="openRequest(data.item)"
            >
              <font-awesome-icon icon="eye" />&nbsp;
            </b-button>
            <!--              <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class="">-->
            <!--              </router-link>-->
          </template>
        </b-table>
      </div>

      <b-pagination-nav
        v-if="pagination.count > 1"
        :link-gen="linkGen"
        :value="pagination.page"
        :number-of-pages="pagination.count"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>

    <div v-else-if="showFilter" class="ibox-content pb-2">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mr-2">
            <b-form-group label="Дата от">
              <datepicker
                v-model="search.dateFrom"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Дата до">
              <datepicker
                v-model="search.dateTo"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Статус">
              <b-form-select
                v-model="search.status"
                :options="statusList"
                tabindex="1"
                @change="fetchData"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Адрес">
              <b-form-input
                v-model="search.searchAddress"
                @change="fetchData"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <button class="btn btn-info mr-2" @click.prevent="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </button>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block d-lg-block">
        <b-table
          v-if="requests.length"
          responsive
          striped
          hover
          condensed
          :items="requests"
          :fields="fields"
          :busy="loading"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle table-spinner"></b-spinner>
              <strong> Данные подгружаются</strong>
            </div>
          </template>

          <template #cell(externalId)="data">
            <router-link
              :to="{
                name: 'pest-request-view',
                params: { id: data.item.id },
              }"
              class=""
            >
              {{ getRequestNumber(data.item) }}
            </router-link>
          </template>

          <template #cell(time)="data">
            {{ getRequestDatetime(data.item) }}
            <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class="">-->
            <!--          </router-link>-->
            <br />
          </template>

          <template #cell(title)="data">
            <router-link
              :to="{
                name: 'pest-request-view',
                params: { id: data.item.id },
              }"
              class=""
            >
              {{ getRequestTitle(data.item) }}
            </router-link>
            <br />
          </template>

          <template #cell(status)="data">
            {{ getRequestStatus(data.item) }}
          </template>

          <template #cell(sum)="data">
            {{
              servicesSum(data.item) ? servicesSum(data.item) : data.item.sum
            }}
            <span v-if="data.item.earned"> / {{ data.item.earned }} </span>
            <span v-if="data.item.hasRecord">
              <font-awesome-icon
                v-b-tooltip.hover
                icon="star"
                title="Рекорд"
              />
            </span>
          </template>

          <template #cell(client)="data">
            {{ getRequestClientName(data.item) }}
            <!--          <router-link :to="{ name: 'client-view', params: { id: data.item.clientId } }" class=""-->
            <!--          <router-link :to="{ name: 'pest-request-view', params: { id: data.item.id } }" class=""-->
            <!--                       v-if="data.item.clientId">-->
            <!--            {{ getRequestClientName(data.item) }}-->
            <!--          </router-link>-->
            <!--          <div v-else>-->
            <!--            {{ getRequestClientName(data.item) }}-->
            <!--          </div>-->
          </template>

          <template #cell(assignee)="data">
            <router-link
              v-if="data.item.assigneeId"
              :to="{
                name: 'users-user-view',
                params: { id: data.item.assigneeId },
              }"
              class=""
            >
              {{ getRequestAssigneeName(data.item) }}
            </router-link>
            <div v-else>
              {{ getRequestAssigneeName(data.item) }}
            </div>
          </template>

          <template #cell(timeCreated)="data">
            {{ getRequestCreateTime(data.item) }}
          </template>

          <template #cell(deadline)="data">
            {{ getRequestDeadline(data.item) }}
          </template>

          <template #cell(town)="data">
            {{ getRequestTownTitle(data.item) }}
          </template>

          <template #cell(timeUpdated)="data">
            {{ getRequestUpdateTime(data.item) }}
          </template>

          <template #cell(actions)="data">
            <router-link
              :to="{
                name: 'pest-request-view',
                params: { id: data.item.id },
              }"
              class=""
            >
              <b-button class="btn btn-xs btn-primary ml-1">
                <font-awesome-icon icon="eye" />&nbsp;
              </b-button>
            </router-link>
          </template>
        </b-table>

        <b-pagination-nav
          v-if="pagination.count > 1"
          :link-gen="linkGen"
          :value="pagination.page"
          :number-of-pages="pagination.count"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>
    </div>
  </div>

  <div class="d-md-none d-lg-none">
    <!--                    :blocked="blocked &&rIndex>0"-->
    <request-card
      v-for="(request, rIndex) in requests"
      :key="rIndex + '-r'"
      :for-employee="!canAddClient()"
      :blocked="blocked"
      :request="request"
    ></request-card>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</b-overlay>
