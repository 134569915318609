
<div class="ibox">
  <div class="ibox-title">
    <div class="search">
      <div class="d-flex align-items-center" style="overflow-x: auto">
        <div class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getTownClass(MSK)"
              @click.prevent="selectTown(MSK)"
            >
              Москва
            </button>
            <button
              class="btn"
              :class="getTownClass(SPB)"
              @click.prevent="selectTown(SPB)"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
        </div>

        <div class="mr-2">
          <datepicker
            v-model="search.date"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
            @change="fetchData"
          ></datepicker>
        </div>

        <div class="align-self-center mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>
        <!--@click="fetchManagers"-->
        <button
          v-if="!assigneeLoading"
          class="mr-2 btn btn-success btn-sm disabled"
        >
          <font-awesome-icon icon="check" />
          Менеджеры загружены
        </button>
        <button v-else class="mr-2 btn btn-success btn-sm disabled">
          <font-awesome-icon icon="rotate" spin />
          Менеджеры загружаются
        </button>
        <!--@click="fetchRequests"-->
        <button
          v-if="!requestsLoading"
          class="mr-2 btn btn-success btn-sm disabled"
        >
          <font-awesome-icon icon="check" />
          Заявки загружены
        </button>
        <button v-else class="mr-2 btn btn-success btn-sm disabled">
          <font-awesome-icon icon="rotate" spin />
          Заявки загружаются
        </button>

        <div v-if="false">
          <button
            v-if="!assigneeLoading && !requestsLoading"
            class="mr-2 btn btn-success btn-sm"
            @click.prevent="addRequestMarkers"
          >
            <font-awesome-icon icon="check" />
            Разместить точки
          </button>
          <button
            v-else
            class="mr-2 btn btn-success btn-sm disabled"
            disabled
          >
            <font-awesome-icon icon="rotate" spin />
            подождите
          </button>
        </div>

        <button
          v-if="!assigneeLoading && !requestsLoading"
          class="mr-2 btn btn-success btn-sm"
          @click.prevent="addRequestPaths"
        >
          <font-awesome-icon icon="check" />
          Разместить маршруты
        </button>
        <button v-else class="mr-2 btn btn-success btn-sm disabled" disabled>
          <font-awesome-icon icon="rotate" spin />
          подождите
        </button>
      </div>
    </div>
  </div>

  <div class="ibox-content gray-bg pl-0 pr-0 d-flex">
    <div class="cards pr-2">
      <h2>заявки</h2>
      <b-form-select
        v-model="selectedAssignee"
        :options="assigneesOptions"
        @change="toggleMapItems"
      ></b-form-select>
      <request-map-card
        v-for="(request, rIndex) in filteredRequests"
        :key="rIndex"
        :request="request"
      />
    </div>

    <div id="map" style=""></div>
  </div>
</div>
