<template>
  <datepicker
    ref="datepicker"
    v-model="date"
    :editable="true"
    :input-class="inputClass"
    :lang="lang"
    :format="dateFormat"
    :formatter="dayjsFormat"
    :placeholder="placeholder"
    prefix-class="xmx"
    type="datetime"
    @change="onChange"
  >
  </datepicker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

export default {
  name: "DateTimePicker",
  components: {
    Datepicker: DatePicker,
  },
  data() {
    return {
      date: this.value,
      dayjsFormat: {
        // переопределяем, поскольку локализация даты в dayjs реализована с падежами
        stringify: (date, format) => {
          return date ? dayjs(date).format(format) : "";
        },
        parse: (value, format) => {
          return value ? dayjs(value, format).toDate() : null;
        },
      },
    };
  },
  props: {
    value: {
      default: new Date(),
    },
    dateFormat: {
      type: String,
      default: "DD MMM YYYY HH:mm",
    },
    valueFormat: {
      type: String,
      default: "YYYY-MM-DD HH:mm:00",
    },
    placeholder: {
      type: String,
      default: "Select date",
    },
    inputClass: {
      type: String,
      default: "form-control",
    },
    lang: {
      type: String,
      default: "ru",
    },
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {
    onChange(date, type) {
      this.$emit("input", this.date);
      this.$emit("change", this.date);
      if (type === "minute") {
        this.$refs.datepicker.closePopup();
      }
    },
    selectToday() {
      this.date = dayjs().format(this.valueFormat);
      this.onChange();
    },
    selectYesterday() {
      this.date = dayjs().subtract(1, "day").format(this.valueFormat);
      this.onChange();
    },
    selectTomorrow() {
      this.date = dayjs().add(1, "day").format(this.valueFormat);
      this.onChange();
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
}

ul li {
  list-style: none;
  padding: 0;
}

ul li a {
  display: block;
  padding: 5px;
  color: inherit !important;
}

ul li:hover {
  background: #f5f4f4;
  border-radius: 5px;
  color: $inspinia-primary;
}
</style>
