<template>
  <div class="pest-fine-page">
    <div class="row">
      <div class="col-md-6">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h2>
              <router-link
                :to="{ name: 'pest-fine-list', id: fine.id }"
                class="h4 text-muted"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>
              Штраф от {{ getFineTime(fine) }} – {{ getFineStatusTitle(fine) }}
            </h2>
          </div>
          <div class="ibox-content">
            <pest-fine-form :fine="fine"></pest-fine-form>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h2>Проверка</h2>
          </div>
          <div class="ibox-content">
            <pest-fine-review-form
              :fine="fine"
              @update-pest-fine-info="updateFine"
            ></pest-fine-review-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FineForm from "./FineForm";
import {
  getFineStatusTitle,
  getFineTime,
} from "@/modules/pest/decorators/fines";
import PestFineReviewForm from "@/modules/pest/views/fines/FineReviewForm";

export default {
  name: "Fine",
  components: {
    PestFineReviewForm,
    "pest-fine-form": FineForm,
  },
  data() {
    return {
      fine: {
        id: "new",
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getFineTime,
    getFineStatusTitle,
    checkUrl() {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      let finesUrl = "/pest/fines/" + this.id;
      this.loading = true;
      this.$http
        .get(finesUrl)
        .then((response) => {
          this.loading = false;
          this.fine = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateFine(fine) {
      console.log(fine);
      this.fine = fine;
    },
  },
};
</script>

<style lang="scss" scoped></style>
