
<div class="request-page">
  <div class="ibox d-none d-md-block">
    <div class="ibox-title">
      <b-row>
        <b-col>
          <h1>
            <a href="#" class="ml-1 mr-1 h4" @click.prevent="goBack">
              <font-awesome-icon icon="arrow-left" />
            </a>
            {{ requestName }}
            <span>
              <small class="request-status">
                {{ requestStatus }}
              </small>
            </span>

            <small v-if="!loading && canUpdateRequest()">
              <a
                href=""
                class="h6 ml-2"
                @click.prevent="toggleUpdate(!updating)"
              >
                <font-awesome-icon v-if="updating" icon="eye" />
                <font-awesome-icon v-else icon="edit" />
              </a>
              <a href="" class="h6 ml-2" @click.prevent="deleteRequest">
                <font-awesome-icon icon="trash" />
              </a>
            </small>
          </h1>
        </b-col>
      </b-row>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="ibox">
        <div class="ibox-title d-flex pr-4">
          <div class="">
            <h5>Информация о заявке</h5>
          </div>
          <div class="ml-auto">
            <router-link
              v-if="false"
              :to="{ name: 'pest-request-list' }"
              @click.prevent="goBack"
            >
              Закрыть
            </router-link>
            <a href="#" @click.prevent="goBack"> Закрыть </a>
          </div>
        </div>

        <div class="ibox-content">
          <request-info
            v-if="!loading && !updating"
            :request="request"
          ></request-info>
          <request-form
            v-if="!loading && updating"
            :request-model="request"
            :data-loaded="!loading"
            @update-request-info="afterUpdate"
            @cancel-updating="toggleUpdate(false)"
          ></request-form>
        </div>
      </div>

      <!-- Выезды по цепочке -->
      <requests-sequence-history
        v-if="sequence && sequence.length > 1"
        :request:="request"
        :sequence="sequence"
      />
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6">
      <div v-if="showResultsBlock" class="ibox">
        <div class="ibox-title">
          <h5>Результат</h5>
        </div>

        <div class="ibox-content">
          <!--              <h4>Результат</h4>-->
          <request-result-info
            v-if="!updatingResult"
            :request="request"
          ></request-result-info>

          <request-result-form
            v-if="!loading && updatingResult"
            ref="request-result-form"
            :request-model="request"
            :data-loaded="!loading"
            @update-request-info="afterRequestUpdate"
            @cancel-updating="toggleUpdateResult(false)"
            @open-resource-popup-form="openResourcePopupForm"
          ></request-result-form>
          <request-correct-form
            v-if="!loading && correctingResult && canCorrect"
            :request-model="request"
            :data-loaded="!loading"
            @request-corrected="afterRequestCorrected"
            @cancel-updating="toggleCorrectResult(false)"
          ></request-correct-form>

          <div class="d-flex">
            <div v-if="!correctingResult" class="md-2 mr-2">
              <a href="" @click.prevent="toggleUpdateResult(!updatingResult)">
                <button v-if="false" class="btn btn-primary btn-sm">
                  <font-awesome-icon icon="eye" />
                  Отмена
                </button>
                <button
                  v-if="!updatingResult && canSetResults"
                  class="btn btn-success btn-sm"
                >
                  <font-awesome-icon icon="edit" />
                  Внести результаты
                </button>
              </a>
            </div>

            <div
              v-if="!updatingResult && requestClosed && canCorrect"
              class="md-2"
            >
              <a
                href=""
                @click.prevent="toggleCorrectResult(!correctingResult)"
              >
                <button v-if="false" class="btn btn-info btn-sm">
                  <font-awesome-icon icon="eye" />
                  Отмена
                </button>
                <button v-if="!correctingResult" class="btn btn-info btn-sm">
                  <font-awesome-icon icon="edit" />
                  Скорректировать результаты
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-5">
      <div v-if="!updating" class="ibox mb-2">
        <div class="ibox-title" :class="blockHeadClass('comments')">
          <h4 class="" @click.prevent="toggleBlock('comments')">
            Комментарии
            <small>
              <font-awesome-icon v-if="blockShown('comments')" icon="minus" />
              <font-awesome-icon v-else icon="plus" />
            </small>
          </h4>
        </div>
        <div class="ibox-content pb-1" :class="blockClass('comments')">
          <request-comments
            v-if="!loading"
            :type="COMMENT_TYPE_REQUEST"
            :model="request"
          ></request-comments>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div v-if="!updating && canSeeRequestHistory" class="ibox mb-2">
        <div
          class="ibox-title d-flex align-items-center"
          :class="blockHeadClass('logs')"
        >
          <div>
            <h4 class="" @click.prevent="toggleBlock('logs')">
              История изменений
              <small>
                <font-awesome-icon v-if="blockShown('logs')" icon="minus" />
                <font-awesome-icon v-else icon="plus" />
              </small>
            </h4>
          </div>
          <div class="ml-auto">
            <a href="" @click.prevent="reloadHistory">
              <font-awesome-icon icon="rotate" />
            </a>
          </div>
        </div>
        <div class="ibox-content pb-1" :class="blockClass('logs')">
          <request-logs
            v-if="!loading"
            ref="request-logs"
            :item-id="request.id"
          ></request-logs>
        </div>
      </div>
    </div>
  </div>
  <b-modal
    id="modal-resource-form"
    ref="modal-resource-form"
    title="Добавить новый препарат"
    cancel-title="Отмена"
    ok-title="Сохранить"
    title-tag="h3"
    :ok-disabled="checkResourceTitle"
    @ok="handleResourceModal"
  >
    <resource-form
      :modal="true"
      :resource="resource"
      :from-results="true"
    ></resource-form>
  </b-modal>
</div>
