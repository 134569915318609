
<div>
  Новый рекорд <br />
  Сумма услуг: <strong>{{ notification.serviceSum }}</strong
  ><br />
  Новый рекорд: <strong>{{ notification.newSum }}</strong
  ><br />
  Предыдущий рекорд: <strong>{{ notification.recordCurSum }}</strong
  ><br />

  <span v-if="notification.newSum1">
    <strong>Рекорд был сброшен </strong><br
  /></span>
  <!--      <span v-if="notification.newSum2"> <strong >Рекорд округлен </strong><br></span>-->
  Сумма, которую надо превысить:
  <strong>{{ notification.compareRecordSum }}</strong
  ><br />
  Минимальная планка для рекорда:
  <strong>{{ notification.recordSumMin }}</strong
  ><br />
  Максимальная планка для рекорда:
  <strong>{{ notification.recordSumMax }}</strong
  ><br />
  <!--      Интервал повышения: <strong>{{ notification.recordSumInc }}</strong> <br>-->
</div>
