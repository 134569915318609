
<div class="ibox">
  <div class="ibox-content">
    <document-tabs tab="documents"></document-tabs>

    <h1>Документы</h1>
    <h4 v-if="breadcrumbs">
      <router-link class="folder-breadcrumb" :to="{ name: 'document-list' }">
        <font-awesome-icon icon="home" :fixed-width="true" />
      </router-link>
      <router-link
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="folder-breadcrumb"
        :to="{ name: 'document-list', params: { folderId: breadcrumb.id } }"
      >
        {{ getFolderTitle(breadcrumb) }}
      </router-link>
    </h4>
    <div class="mb-2">
      <b-button v-b-modal.modal-folder variant="primary">
        <font-awesome-icon icon="file" :fixed-width="true" />
        Добавить папку
      </b-button>

      <b-button v-b-modal.modal-document variant="info" class="ml-2">
        <font-awesome-icon icon="folder" :fixed-width="true" />
        Добавить файл
      </b-button>
    </div>

    <b-modal
      id="modal-folder"
      ref="modal"
      title="Добавить папку"
      cancel-title="Отмена"
      ok-title="Сохранить"
      title-tag="h3"
      @ok="handleFolderModalOk"
    >
      <folder-form-body
        ref="folderForm"
        :model="folderModel"
        :own-window="false"
      ></folder-form-body>
    </b-modal>

    <b-modal
      id="modal-document"
      ref="modal"
      title="Добавить файл"
      cancel-title="Отмена"
      ok-title="Сохранить"
      title-tag="h3"
      @ok="handleDocumentModalOk"
    >
      <document-form-body
        ref="documentForm"
        :folder-id="folderId"
        :own-window="false"
      ></document-form-body>
    </b-modal>

    <b-table
      v-if="items.length"
      responsive
      striped
      hover
      condensed
      :items="items"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(icon)="data">
        <font-awesome-icon v-if="data.item.type === 'folder'" icon="folder" />
        <font-awesome-icon v-else icon="file" />
      </template>
      <template #cell(title)="data">
        <router-link
          v-if="data.item.type === 'folder'"
          :to="{ name: 'document-list', params: { folderId: data.item.id } }"
        >
          {{ getFolderTitle(data.item.info) }}
        </router-link>

        <a v-else :href="getDocumentUrl(data.item.info)" target="_blank">
          {{ getDocumentTitle(data.item.info) }}
        </a>
      </template>

      <template #cell(type)="data">
        <span v-if="data.item.type !== 'folder'">
          {{ getDocumentTypeTitle(data.item.info) }}
        </span>
      </template>
      <template #cell(comment)="data">
        <span v-if="data.item.type === 'folder'"> </span>
        {{ data.item.info.comment }}
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          v-if="data.item.type === 'folder'"
          :id="'dropdown' + data.item.id"
          text=""
          variant="outline-primary"
          size="sm"
          class="m-md-2"
        >
          <b-dropdown-item @click.prevent="updateFolder(data.item.info)"
            >Редактировать</b-dropdown-item
          >
          <b-dropdown-item
            v-if="!data.item.hasChildren"
            @click.prevent="deleteFolder(data.item.info)"
            >Удалить
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          v-else
          :id="'dropdown' + data.item.id"
          text=""
          variant="outline-primary"
          size="sm"
          class="m-md-2"
        >
          <b-dropdown-item @click.prevent="deleteDocument(data.item.info)"
            >Удалить</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
