<template>
  <div class="status-page">
    <h1>{{ header }}</h1>

    <div class="row">
      <div class="col-md-5 col-sm-12">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h2>Параметры</h2>
          </div>

          <div class="ibox-content">
            <status-form
              :status-model="status"
              :data-loaded="!loading"
              :return-url="{ name: 'status-list' }"
              @update-status-info="afterStatusUpdate"
            ></status-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  emptyStatus,
  getStatusName,
} from "@/modules/crm/decorators/statusDecorator";
import StatusForm from "@/modules/crm/views/status/StatusForm";

export default {
  name: "Status",
  components: {
    "status-form": StatusForm,
  },
  data() {
    return {
      updating: false,
      status: emptyStatus,
      loading: false,
    };
  },
  computed: {
    isNew() {
      return this.id === "new";
    },
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
    header() {
      if (this.isNew) {
        return "Новый статус";
      }
      return getStatusName(this.status);
    },

    queryVariables() {
      return {
        id: this.id,
      };
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
      this.fetchData();
    },
    fetchData: function () {
      if (this.isNew) {
        this.type = emptyStatus;
        return;
      }

      let statusesUrl = "/crm/statuses/" + this.id;
      this.loading = true;
      this.$http
        .get(statusesUrl)
        .then((response) => {
          this.loading = false;
          this.status = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    afterStatusUpdate(status) {
      this.status = status;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
