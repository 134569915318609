
<a
  v-b-tooltip.hover
  class=""
  :class="{
    'text-success': canAssigneeViewAppartment,
    'text-danger': !canAssigneeViewAppartment,
  }"
  :title="iconTitle"
  @click.prevent="toggleToAssignee(!canAssigneeViewAppartment)"
>
  <font-awesome-icon v-if="opening" icon="rotate" spin />
  <font-awesome-icon v-else-if="canAssigneeViewAppartment" icon="eye" />
  <font-awesome-icon v-else icon="eye-slash" />
</a>
