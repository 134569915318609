
<div class="direct-account-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link
              :to="{ name: 'direct-account-list', id: account.id }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Изменение аккаунта
          </h1>
        </div>
        <div class="ibox-content">
          <direct-account-form :account="account"></direct-account-form>
        </div>
      </div>
    </div>
  </div>
</div>
