
<div class="ibox">
  <div class="ibox-title">
    <div class="search">
      <h2 class="d-none d-md-block">Мое расписание</h2>
      <div class="d-flex">
        <div class="">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>
        <div class="">
          <b-button class="btn btn-info ml-2" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Обновить
          </b-button>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox-content pl-0 pr-0">
    <div v-if="!loading" class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Статус</th>
            <th>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(date, ddIndex) in dates" :key="'dd' + ddIndex">
            <th>
              {{ formattedDateHeader(date) }}
            </th>
            <td :class="getStatusClass(schedule[date])"></td>
            <td>
              {{ getStatusComment(schedule[date]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="p-2">Данные загружаются</div>
  </div>
</div>
