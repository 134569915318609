export const getSourceTitle = function (source) {
  if (!source || !source.title) {
    return "[Не назначен]";
  }
  return source.title;
};

export const TYPE_ROISTAT = 0;
export const TYPE_MANUAL = 1;
export const CPO_SOURCE_ROISTAT = "roistat";
export const CPO_SOURCE_NONE = 0;
export const sourceTypeTitles = {
  [TYPE_ROISTAT]: "Ройстат",
  [TYPE_MANUAL]: "Ручной источник",
};
export const sourceTypeOptions = [
  { value: TYPE_ROISTAT, text: "Ройстат" },
  { value: TYPE_MANUAL, text: "Ручной источник" },
];
export const getSourceTypeTitle = function (source) {
  return sourceTypeTitles[source.type];
};

export const TARGET_LEAD = 0;
export const TARGET_IN_CONTRACT = 1;
export const sourceTargetTitles = {
  [TARGET_LEAD]: "Лиды",
  [TARGET_IN_CONTRACT]: "Заявки",
};
export const sourceTargetOptions = [
  { value: TARGET_LEAD, text: "Лиды" },
  { value: TARGET_IN_CONTRACT, text: "Заявки" },
];
export const getSourceTargetTitle = function (source) {
  return sourceTargetTitles[source.target];
};
