
<div class="ibox">
  <div class="ibox-content">
    <h1 class="d-none d-md-block">Перечень штрафов</h1>

    <b-table
      v-if="penalties.length"
      responsive
      striped
      hover
      condensed
      :items="penalties"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        {{ data.item.title ? data.item.title : "[Без названия]" }}
      </template>

      <template #cell(comment)="data">
        <span v-if="data.item.sum"> {{ data.item.sum }} <br /> </span>
        <span v-if="data.item.dismissal">
          <font-awesome-icon icon="exclamation-triangle" />
          Увольнение
        </span>
        {{ data.item.comment }}
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
