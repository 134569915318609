
<div>
  <div v-if="isSuccessMessageVisible" class="panel panel-primary mb-4">
    <div class="panel-heading">
      <font-awesome-icon icon="check" />
      Подтверждение
    </div>
    <div class="panel-body">
      Касса на сумму
      <strong>{{ successInfo.totalSum }}</strong> руб за период с
      <strong>{{ successInfo.dateFrom }}</strong> по
      <strong>{{ successInfo.dateTo }}</strong>
      успешно сдана

      <button
        class="btn btn-primary mt-2"
        @click="isSuccessMessageVisible = false"
      >
        Закрыть
      </button>
    </div>
  </div>

  <div v-if="!isSuccessMessageVisible" class="pest-deposit-page">
    <div class="panel panel-warning mb-4">
      <div class="panel-heading">
        <!--          <font-awesome-icon icon="warning"/>-->
        Инструкция
      </div>
      <div class="panel-body">
        <ul>
          <li>Выберите ниже смены, за которые хотите сдать кассу.</li>
          <li>Сверьте сумму в конверте со значением в форме.</li>
          <li>Подпишите конверт (период, за который сдается касса и ФИО)</li>
          <li>
            Подтвердите сдачу кассы нажатием на кнопку "сдать кассу"
            <br />(потребуется разрешение определения вашего местоположения)
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!isSuccessMessageVisible">
      <pest-deposit-form
        :deposit="deposit"
        @success="showSuccessMessage"
      ></pest-deposit-form>
    </div>
  </div>
</div>
