
<div class="d-flex relative">
  <div v-if="canDeleteContractContacts" class="deleteContact primary-star">
    <a
      v-b-tooltip.hover
      href="#"
      class="primary-star"
      title="Удалить контакт"
      @click.prevent="deleteContact"
    >
      <font-awesome-icon v-if="!deleting" icon="fa-trash" />
      <font-awesome-icon v-else icon="rotate" spin />
    </a>
  </div>
  <div>
    <h4>ФИО</h4>
    <p>{{ clientFullName }}</p>
    <div v-if="client.phones && client.phones.length">
      <div
        v-for="(phone, phoneIndex) in client.phones"
        :key="'p' + phoneIndex"
      >
        <h4>
          Телефон <span v-if="phoneIndex > 0">{{ phoneIndex + 1 }}</span>
        </h4>
        <p>{{ phone.phone ? phone.phone : " – " }}</p>
      </div>
    </div>
    <div v-else>
      <h4>Телефон</h4>
      <p>–</p>
    </div>
    <h4>Адрес</h4>
    <p>{{ client.address ? client.address : " – " }}</p>
    <h4>Организация</h4>
    <p>{{ client.firm ? client.firm : " – " }}</p>
    <h4>Должность</h4>
    <p>{{ client.post ? client.post : " – " }}</p>
    <h4>Ответственный</h4>
    <p>{{ client.responsiblePerson }}</p>
  </div>
  <div class="ml-auto d-flex h-100">
    <div class="add-phone">
      <a
        v-b-tooltip.hover
        href="#"
        class="primary-star"
        title="Добавить телефон"
        @click.prevent="addPhone"
      >
        <font-awesome-icon icon="fa-phone" />
      </a>
    </div>
    <div class="ml-2 primary-star">
      <a
        v-if="!client.primary"
        v-b-tooltip.hover
        href="#"
        title="Добавить контакт"
        @click.prevent="makePrimary"
      >
        <font-awesome-icon icon="fa-regular fa-star" />
      </a>
      <a
        v-else
        v-b-tooltip.hover
        href="#"
        title="Основной контакт"
        @click.prevent
      >
        <font-awesome-icon icon="star" />
      </a>
    </div>
  </div>
</div>
