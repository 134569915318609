
<div class="ibox">
  <div class="ibox-title search-ibox-wrapper" :class="{ editing: editing }">
    <div class="search">
      <div class="d-flex align-items-center" style="overflow-x: auto">
        <div class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getTownClass(MSK)"
              @click.prevent="selectTown(MSK)"
            >
              Москва
            </button>
            <button
              class="btn"
              :class="getTownClass(SPB)"
              @click.prevent="selectTown(SPB)"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
        </div>

        <div class="mr-2">
          <date-picker v-model="search.date" @change="fetchData" />
        </div>

        <div v-if="!loading" class="mr-2">
          Найдено:
          <span v-if="!filter.search"> {{ pagination.total }} </span>
          <span v-else> {{ filteredCount }} из {{ pagination.total }}</span>
        </div>

        <div v-if="canChangeAssignee" class="align-self-center ml-auto">
          <button
            v-if="editing"
            class="btn btn-danger ml-1"
            @click="toggleEdit"
          >
            <font-awesome-icon
              icon="exclamation-triangle"
            />&nbsp;Редактирование включено
          </button>
          <button
            v-if="!editing"
            class="btn btn-outline-info ml-1"
            @click="toggleEdit"
          >
            <font-awesome-icon icon="check" />&nbsp; Редактирование отключено
          </button>
        </div>

        <div class="ml-auto">Фильтр:</div>

        <div class="ml-2">
          <b-form-input
            v-model="filter.search"
            placeholder="Имя, телефон, адрес, id"
            tabindex="2"
            @keyup="filterRequests"
          >
          </b-form-input>
        </div>
      </div>
      <dispatching-stats-by-pest :requests="requests" />
    </div>
  </div>

  <div class="ibox-content gray-bg pl-0 pr-0 mb-0 pb-0 requests-box">
    <div class="cont">
      <vuescroll :style="{ width: '100%', height: '100%' }">
        <div class="d-flex align-items-stretch">
          <div
            v-for="(requestsColumn, r2Index) in allRequests"
            :key="r2Index + 'col'"
            class="ibox ml-1 mr-1 mb-0"
            :class="assigneeColClass(requestsColumn.assignee)"
          >
            <div
              class="ibox-title pl-2 pr-2 pt-1 pb-1 assigneeHeader"
              :class="assigneeHeaderClass(requestsColumn.assignee)"
            >
              <!--                 :style="{'border-bottom': '3px solid ' + (requests.assignee.color?requests.assignee.color:'')}"-->
              <div class="man-col-header-wrapper">
                <div class="man-col-header h6 pb-0 pt-1">
                  <div v-if="requestsColumn.assignee.id === STATUS_DECLINE">
                    <div class="user-header-name">
                      Отклоненные&nbsp;<small
                        >({{ requestsColumn.items.length }})</small
                      >
                    </div>
                  </div>
                  <div v-else-if="!requestsColumn.assignee.id">
                    <div class="user-header-name">
                      Нераспределенные&nbsp;<small
                        >({{ requestsColumn.items.length }})</small
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div class="user-header-name d-flex">
                      <div>
                        {{
                          getAssigneeName(requestsColumn.assignee)
                        }}&nbsp;<small
                          >({{ requestsColumn.items.length }})</small
                        >
                      </div>

                      <button
                        v-if="requestsColumn.assignee.blocked === BLOCKED"
                        v-b-tooltip.hover.right
                        class="btn btn-xs btn-danger ml-auto"
                        title="Заблокирован"
                        @click.prevent="
                          unblockAssignee(requestsColumn.assignee)
                        "
                      >
                        <font-awesome-icon
                          v-if="unblocking === requestsColumn.assignee.id"
                          icon="rotate"
                          spin
                        />
                        <font-awesome-icon v-else icon="lock" fixed-width />
                      </button>
                      <button
                        v-else-if="
                          requestsColumn.assignee.blocked === UNBLOCKED
                        "
                        v-b-tooltip.hover.right
                        class="btn btn-xs btn-danger ml-auto"
                        title="Разблокирован"
                        @click.prevent
                      >
                        <font-awesome-icon icon="lock-open" fixed-width />
                      </button>
                      <button
                        v-else-if="
                          requestsColumn.assignee.initialPledgeRequired
                        "
                        v-b-tooltip.hover.right
                        class="btn btn-xs btn-info ml-auto"
                        title="Новый"
                        @click.prevent
                      >
                        <font-awesome-icon icon="money-bill" fixed-width />
                      </button>

                      <button
                        v-else-if="requestsColumn.assignee.isHalfPledgeUsed"
                        v-b-tooltip.hover.right
                        class="btn btn-xs btn-warning ml-auto btn-no-hover cursor-default"
                        title="Использовано 50% депозита"
                        @click.prevent
                      >
                        <font-awesome-icon icon="dollar-sign" fixed-width />
                      </button>
                      <button
                        v-else
                        v-b-tooltip.hover.right
                        class="btn btn-xs btn-primary ml-auto btn-no-hover cursor-default"
                        title="Нет проблем"
                        @click.prevent
                      >
                        <font-awesome-icon icon="dollar-sign" fixed-width />
                      </button>
                    </div>
                    <div
                      v-if="requestsColumn.assignee.timeSlots"
                      class="user-time-slot-wrapper mt-1"
                    >
                      <div
                        v-for="(timeSlot, tIndex) in requestsColumn.assignee
                          .timeSlots"
                        :key="'t' + tIndex"
                        :class="{
                          'user-time-slot': true,
                          half: timeSlot.minutes === '30',
                          busy: isTimeSlotBusy(
                            requestsColumn.items,
                            timeSlot.hours,
                            timeSlot.minutes
                          ),
                          'user-busy': isTimeSlotUserBusy(
                            requestsColumn,
                            timeSlot.hours,
                            timeSlot.minutes
                          ),
                        }"
                        @click.prevent="
                          toggleTimeSlot(requestsColumn.assignee, timeSlot)
                        "
                      >
                        {{ timeSlot.hours }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <vuescroll class="foo" :style="{ width: '100%', height: '72vh' }">
              <div class="ibox-content pl-2 pr-2">
                <draggable
                  class="list-group"
                  :list="requestsColumn.items"
                  group="people"
                  :disabled="!editing"
                  handle=".handle"
                  @add="updateAssignee(requestsColumn.assignee)"
                >
                  <request-card
                    v-for="(request, rIndex) in requestsColumn.items"
                    :key="rIndex + '-r'"
                    :request="request"
                    :dispatching-board="true"
                  ></request-card>
                </draggable>
              </div>
            </vuescroll>
          </div>
        </div>
      </vuescroll>
    </div>
  </div>
  <request-time-popup></request-time-popup>
  <request-assignee-popup
    :town-id="search.townId"
    :assignees="assigneeList"
    :requests="requests"
  ></request-assignee-popup>
</div>
