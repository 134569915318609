
<div class="div">
  <h4>Исполнитель</h4>
  <p>{{ getUserName(task.assignee) }}</p>
  <h4>Дедлайн</h4>
  <p>{{ getTaskDeadline(task) }}</p>
  <h4>Поставлено</h4>
  <p>{{ getTaskCreateTime(task) }}</p>
  <inline-field
    label="Описание"
    type="input"
    attr="comment"
    :value="task.comment"
    @update-prop="updateProp"
  ></inline-field>
  <inline-field
    label="Результат"
    type="input"
    attr="result"
    :value="task.result"
    @update-prop="updateProp"
  ></inline-field>

  <div class="row">
    <div class="col-12">
      <hr />
      <h4>Клиент</h4>
      <client-link :task="task"></client-link>
      <hr />
    </div>
  </div>
</div>
