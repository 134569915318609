export const SCHEDULE_ACTIVE_NONE = 0;
export const SCHEDULE_ACTIVE_UNAVAILABLE = -1;
export const SCHEDULE_ACTIVE_ACTIVE = 1;
export const SCHEDULE_ACTIVE_DUTY_1 = 2;
export const SCHEDULE_ACTIVE_DUTY_2 = 3;
export const SCHEDULE_ACTIVE_DAY_OFF = -3;

let nextStatuses = {
  [SCHEDULE_ACTIVE_NONE]: SCHEDULE_ACTIVE_ACTIVE,
  [SCHEDULE_ACTIVE_ACTIVE]: SCHEDULE_ACTIVE_DUTY_1,
  [SCHEDULE_ACTIVE_DUTY_1]: SCHEDULE_ACTIVE_DUTY_2,
  [SCHEDULE_ACTIVE_DUTY_2]: SCHEDULE_ACTIVE_DAY_OFF,
  [SCHEDULE_ACTIVE_DAY_OFF]: SCHEDULE_ACTIVE_NONE,
};

export const guessNextActivityStatus = function (status) {
  if (!status && status !== SCHEDULE_ACTIVE_NONE) {
    return SCHEDULE_ACTIVE_ACTIVE;
  }
  if (status in nextStatuses) {
    return nextStatuses[status];
  }
  return SCHEDULE_ACTIVE_ACTIVE;
};

export const timeHours = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1,
  2, 3, 4,
];

export const timeOptions = [
  { value: 5, text: "5:00" },
  { value: 6, text: "6:00" },
  { value: 7, text: "7:00" },
  { value: 8, text: "8:00" },
  { value: 9, text: "9:00" },
  { value: 10, text: "10:00" },
  { value: 11, text: "11:00" },
  { value: 12, text: "12:00" },
  { value: 13, text: "13:00" },
  { value: 14, text: "14:00" },
  { value: 15, text: "15:00" },
  { value: 16, text: "16:00" },
  { value: 17, text: "17:00" },
  { value: 18, text: "18:00" },
  { value: 19, text: "19:00" },
  { value: 20, text: "20:00" },
  { value: 21, text: "21:00" },
  { value: 22, text: "22:00" },
  { value: 23, text: "23:00" },
  { value: 24, text: "00:00" },
  { value: 1, text: "1:00" },
  { value: 2, text: "2:00" },
  { value: 3, text: "3:00" },
  { value: 4, text: "4:00" },
];
export const durationOptions = [
  { value: 30, text: "0:30" },
  { value: 60, text: "1:00" },
  { value: 90, text: "1:30" },
  { value: 120, text: "2:00" },
  { value: 150, text: "2:30" },
  { value: 180, text: "3:00" },
  { value: 210, text: "3:30" },
];
