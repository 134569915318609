
<div>
  <table class="table table-striped res-table">
    <tbody>
      <tr>
        <td>
          <font-awesome-icon icon="tag" fixed-width />
          Статус
        </td>
        <td>
          {{ getRequestStatus(request) }}
        </td>
      </tr>
      <tr>
        <td>
          <font-awesome-icon icon="user-cog" fixed-width />
          Исполнитель
        </td>
        <td>
          <span v-if="request.assigneeId">
            {{ getRequestAssigneeName(request) }}
            <router-link
              v-if="false"
              :to="{
                name: 'users-user-view',
                params: { id: request.assigneeId },
              }"
              class=""
            >
              <font-awesome-icon
                icon="external-link-square-alt"
                fixed-width
              />
            </router-link>
          </span>
          <span v-else>[Не назначен]</span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="clock" fixed-width />
          Длительность
        </td>
        <td>
          {{ getRequestDatetime(request) }}
          <span v-if="request.duration">
            – {{ getRequestDurationFormatted(request) }}
          </span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="car" fixed-width />
          Транспорт
          <font-awesome-icon icon="arrow-right" fixed-width />
        </td>
        <td>
          <span v-if="request.tripSum || request.tripDistance">
            {{ request.tripSum }}р – {{ request.tripDistance }}км
          </span>
          <span v-else>Не задано</span>
        </td>
      </tr>

      <tr v-if="request.tripBackSum || request.tripBackDistance">
        <td>
          <font-awesome-icon icon="car" fixed-width />
          Транспорт
          <font-awesome-icon icon="arrow-left" fixed-width />
        </td>
        <td>
          <span v-if="request.tripBackSum || request.tripBackDistance">
            {{ request.tripBackSum }}р – {{ request.tripBackDistance }}км
          </span>
          <span v-else>Не задано</span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Сумма
        </td>
        <td>
          <b>{{ request.sum }}</b>
        </td>
      </tr>
      <tr v-if="hasAnyResources(request)">
        <td>
          <font-awesome-icon icon="vial" fixed-width />
          Материалы
        </td>
        <td>
          <ul class="mb-0 pl-3">
            <li
              v-for="(resource, rIndex) in request.resources"
              :key="'r' + rIndex"
            >
              {{
                resource.resource
                  ? resource.resource.title
                  : "[Без материала]"
              }}
              <span v-if="resource.count">
                – раб.р.-{{ resource.solutionVolume }}л, доз.-{{
                  resource.dosage
                }}мл/л, расход - {{ resource.count }}мл</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="cogs" fixed-width />
          Услуги
        </td>
        <td>
          <ul class="mb-0 pl-3">
            <li
              v-for="(service, rIndex) in request.services"
              :key="'r' + rIndex"
            >
              {{ service.title }} – {{ service.price }}
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Стоимость услуг
        </td>
        <td>{{ servicesSum(request) }}р</td>
      </tr>
      <tr v-if="hasAnyProducts(request)">
        <td>
          <font-awesome-icon icon="box" fixed-width />
          Товары
        </td>
        <td>
          <ul class="mb-0 pl-3">
            <li
              v-for="(product, rIndex) in request.products"
              :key="'r' + rIndex"
            >
              {{ product.title }} – {{ product.price }}
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="hasAnyProducts(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Стоимость товаров
        </td>
        <td>{{ productSum(request) }}р ({{ productNetCost(request) }}р)</td>
      </tr>
      <tr v-if="calcEquipmentSum(request) > 0">
        <td>
          <font-awesome-icon icon="tools" fixed-width />
          Оборудование
        </td>
        <td>
          <strong> {{ calcEquipmentSum(request) }}р</strong>
        </td>
      </tr>

      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Заработано
        </td>
        <td>
          <strong> {{ request.earned }}р</strong>
          <span v-if="request.earnPercent">
            ({{ request.earnPercent }} %)
          </span>
          <span v-if="request.hasRecord">
            <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
          </span>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          ... +
          <font-awesome-icon icon="car" fixed-width />
          -
          <font-awesome-icon icon="tools" fixed-width />
        </td>
        <td>
          <strong> {{ request.profitSum }}р</strong>
        </td>
      </tr>

      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="money-bill" fixed-width />
          К выплате
        </td>
        <td>
          <strong> {{ request.paymentSum }}р</strong>
        </td>
      </tr>

      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="money-bill" fixed-width />
          Выплачено
        </td>
        <td>
          <span v-if="request.isPaid"> Да </span>
          <span v-else> Нет </span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="comment" fixed-width />
          Повтор
        </td>
        <td>
          <span v-if="request.repeatAppointed">
            {{ request.repeatDate }}
          </span>
          <span v-else> Не назначен </span>
        </td>
      </tr>

      <tr v-if="request.assigneeComment">
        <td>
          <font-awesome-icon icon="comment" fixed-width />
          Комментарий
        </td>
        <td>
          <span>{{ request.assigneeComment }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <ul class="files-block mb-2">
    <li v-for="(file, fIndex) in request.resultFiles" :key="'i' + fIndex">
      <div class="d-flex">
        <a
          v-b-tooltip.hover
          class="ml-2"
          :href="file.url"
          :title="file.name"
          target="_blank"
        >
          {{ file.name + "." + file.extension }}
        </a>
        <div class="ml-2 mr-2">{{ getDate(file.createTime) }}</div>
        <div v-if="canDeleteResultFiles" class="ml-auto mr-2">
          <a
            v-if="!file.deleting"
            href="#"
            @click.prevent="deleteResultFile(fIndex)"
          >
            <font-awesome-icon icon="trash" />
          </a>
          <font-awesome-icon v-else icon="rotate" spin />
        </div>
      </div>
    </li>
  </ul>
</div>
