
<div class="sms-message-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link
              :to="{ name: 'sms-message-list', id: message.id }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Изменение сообщение
          </h1>
        </div>
        <div class="ibox-content">
          <sms-message-form :message="message"></sms-message-form>
        </div>
      </div>
    </div>
  </div>
</div>
