
<div class="request-page">
  <div class="ibox mb-1">
    <div class="ibox-title">
      <h1>
        <router-link
          :to="{ name: 'request-view', id: request.id }"
          class="h4 text-muted"
        >
          <font-awesome-icon icon="arrow-left" />
        </router-link>
        Изменение заявки
      </h1>
    </div>
    <div class="ibox-content">
      <request-form
        :request-model="request"
        :data-loaded="!loading"
      ></request-form>
    </div>
  </div>
</div>
