
<b-form
  id="pest-shop-resource-form"
  ref="shop-resource"
  :model="shopResource"
  class="pest-shop-resource-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div>
    <b-form-group label="Название" prop="title">
      <b-form-input
        v-model="shopResource.title"
        placeholder="Название"
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Артикул" prop="title">
      <b-form-input
        v-model="shopResource.article"
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Единица измерения" prop="unit">
      <b-form-input
        v-model="shopResource.unit"
        placeholder="шт."
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Связанный материал в CRM" prop="unit">
      <universal-autocomplete
        :get-title="(p) => p.title"
        :get-data="getResources"
        :default-value="shopResource.resource && shopResource.resource.title"
        @selected="selectResource"
      ></universal-autocomplete>

      <button
        v-if="shopResource.resource"
        class="btn btn-outline-danger btn-xs mt-2"
        @click.prevent="selectResource({ value: { id: null } })"
      >
        отвязать
      </button>
    </b-form-group>

    <b-form-group label="Описание" prop="title">
      <b-form-textarea
        v-model="shopResource.comment"
        placeholder="Описание"
        tabindex="1"
      ></b-form-textarea>
    </b-form-group>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="shopResource.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteShopResource"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
