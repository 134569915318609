
<div class="feed-element">
  <!--    <a href="#" class="float-left">-->
  <!--      <img alt="image" class="rounded-circle" src="img/a1.jpg">-->
  <!--    </a>-->
  <div class="media-body ">
    <small class="float-right text-navy">{{ diffDate }} </small>
    <strong>{{ userName }}</strong> <small class="text-muted">{{ commentDate }}</small>
    <div class="">
      {{ comment.message }}

      <div v-if="isCall">
        <audio :src="comment.info.fileUrl" controls></audio>
      </div>
    </div>
  </div>
</div>

