
<div class="pest-fine-page">
  <div class="row">
    <div class="col-md-12" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>Новый штраф</h1>
        </div>
        <div class="ibox-content">
          <pest-fine-form :fine="fine"></pest-fine-form>
        </div>
      </div>
    </div>
  </div>
</div>
