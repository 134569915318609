<template>
  <div>
    Вам назначена заявка
    <router-link
      :to="{ name: 'pest-request-view', params: { id: notification.itemId } }"
      class=""
    >
      #{{ notification.itemId ? notification.itemId : "Без номера" }}
    </router-link>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "NotificationUserChangedAssignee",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getUserName,
  },
};
</script>

<style lang="scss" scoped></style>
