
<div
  class="p-2 mb-2"
  :data-counter="counter"
  :class="{
    closed: isClosed(request),
    highlighted: request.highlighted,
    'counter-c': counter,
    'request-card': !forEmployee,
    'notification-soon': isCallNotificationSoon(request),
    'employee-request-card': forEmployee,
    'mr-2': contractBoard,
  }"
  :style="{
    border:
      '3px  ' +
      getRequestBorderType(request) +
      ' ' +
      getRequestColor(request),
  }"
  @dblclick="openRequest"
>
  <request-card-employee-fields
    v-if="forEmployee"
    :blocked="blocked"
    :counter="counter"
    :request="request"
    @open-request="openRequest"
  />

  <request-card-dispatching-fields
    v-else-if="!shortVersion"
    :lead-board="leadBoard"
    :blocked="blocked"
    :counter="counter"
    :request="request"
    :dispatching-board="dispatchingBoard"
    :hide-handle="contractBoard"
    @open-request="openRequest"
  />

  <request-card-kanban-fields
    v-else
    :lead-board="leadBoard"
    :blocked="blocked"
    :counter="counter"
    :request="request"
    :status="status"
    :hide-handle="contractBoard"
    :show-duplicate="contractBoard"
    @open-request="openRequest"
    @duplicate-request="duplicateRequest"
  />
</div>
