/**
 * parses key => value array and create array for form select
 * @param obj
 * @returns {*[]}
 */
export const objectSelectValues = function (obj, toInt) {
  let titles = [];
  for (const [key, value] of Object.entries(obj)) {
    let k = toInt ? parseInt(key) : key;
    titles.push({ value: k, text: value });
  }
  return titles;
};
