import PenaltyCreate from "@/modules/pest/views/penalties/PenaltyCreate";
import PenaltyList from "@/modules/pest/views/penalties/PenaltyList";
import PenaltyUpdate from "@/modules/pest/views/penalties/PenaltyUpdate";
import PenaltyView from "@/modules/pest/views/penalties/PenaltyView";
import PenaltyWorkerList from "@/modules/pest/views/penalties/PenaltyWorkerList.vue";

export default [
  {
    path: "/penalties/list:page?",
    name: "pest-penalty-list",
    components: {
      default: PenaltyList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/user-penalties/list:page?",
    name: "pest-penalties-list",
    components: {
      default: PenaltyWorkerList,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "Перечень штрафов",
    },
  },

  {
    path: "/penalties/new/:userId?",
    name: "pest-penalty-create",
    components: {
      default: PenaltyCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/penalties/:id/update",
    name: "pest-penalty-update",
    components: {
      default: PenaltyUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/penalties/:id",
    name: "pest-penalty-view",
    components: {
      default: PenaltyView,
    },
    meta: { requiresAuth: true },
  },
];
