<template>
  <b-form
    id="pest-facility-form"
    ref="facility"
    :model="facility"
    class="pest-facility-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="facility.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Тип" prop="title">
            <b-form-select
              v-model="facility.type"
              :options="facilityTypeOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="facility.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Сортировка" prop="title">
            <b-form-input
              v-model="facility.order"
              placeholder="порядок сортировки"
              tabindex="2"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="facility.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteFacility"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { facilityTypeOptions } from "@/modules/pest/decorators/facilities.js";

export default {
  name: "PestFacilityForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["facility"],
  data() {
    return {
      facilityTypeOptions: [
        { value: null, text: "Не выбрано" },
        ...facilityTypeOptions,
      ],
      phoneMask: false,
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.facility.id;
    },
    name() {
      let name = this.facility.name ? this.facility.name : "Новый тип";
      return name ? name : "Тип";
    },
    managerName() {
      return this.facility.managerId && this.facility.manager
        ? getUserName(this.client.manager)
        : "";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/facilities/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.facility)
        .then((response) => {
          this.facility = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-facility-info", this.facility);
          // this.$router.push({name: 'pest-facility-view', params: {id: this.facility.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Тип отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/facilities";
      this.loading = true;
      this.$http
        .post(updateUrl, this.facility)
        .then((response) => {
          this.facility = response.data;
          this.$eventHub.$emit("update-pest-facility-info", this.facility);
          this.$router.push({
            name: "pest-facility-update",
            params: { id: this.facility.id },
          });
          this.$bvToast.toast("Тип добавлен", {
            title: "Тип добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить тип",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-facility-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteFacility() {
      if (confirm("Вы действительно хотите удалить тип?")) {
        let deleteUrl = "/pest/facilities/" + this.facility.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-facility-list" });
            this.$bvToast.toast("Тип удален", {
              title: "Тип удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить тип",
                variant: "danger",
              });
            }
          });
      }
    },

    getManagerName(user) {
      return getUserName(user);
    },
    handleManagerSubmit(result) {
      this.facility.managerId = result.id;
    },
    getResultManagerAutocompleteValue(user) {
      return this.getManagerName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
