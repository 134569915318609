<template>
  <div>
    <comment-list :comments="comments" class="comment-list"></comment-list>
    <comment-form
      :model="model"
      :type="type"
      v-on:comment-added="addComment"
    ></comment-form>
  </div>
</template>

<script>
import CommentList from "@/modules/law/views/comment/CommentList";
import CommentForm from "@/modules/law/views/comment/CommentForm";
import { COMMENT_TYPE_ISSUE } from "@/modules/law/common";
import { isWorker } from "@/modules/users/access/common.js";

export default {
  name: "CommentForm",
  components: {
    "comment-list": CommentList,
    "comment-form": CommentForm,
  },
  props: ["type", "model"],
  data() {
    return {
      comments: [],
    };
  },
  computed: {
    isWorker() {
      return isWorker(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created() {
    this.checkUrl();
    this.$eventHub.$on("issue-comment-added", this.onCommentAdded);
  },
  beforeDestroy() {
    this.$eventHub.$off("issue-comment-added");
  },
  methods: {
    checkUrl: function () {
      this.fetchData();
    },
    onCommentAdded: function (comment) {
      this.comments.push(comment);
    },
    isCall(comment) {
      return "call" in comment.info && comment.info.type === "call";
    },
    processList(comments) {
      let $this = this;
      if (!comments) {
        return;
      }
      if (this.isWorker) {
        this.comments = comments.filter((c) => !$this.isCall(c));
      } else {
        this.comments = comments;
      }
    },
    fetchData: function () {
      let itemsUrl = "/crm/comments";
      let params = {
        type: this.type ? this.type : COMMENT_TYPE_ISSUE,

        itemId: this.model.id,
      };
      this.loading = true;
      this.$http
        .get(itemsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.processList(response.data);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    addComment(c) {
      this.comments.push(c);
    },
  },
};
</script>

<style scoped>
.comment-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
