
<b-form
  ref="settings"
  :model="settings"
  class="settings-form"
  @submit="createOrUpdate"
>
  <h1>Настройки импорта</h1>

  <div class="row">
    <div class="col-md-4">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h5>Параметры сервера</h5>
        </div>
        <div class="ibox-content">
          <b-form-group label="Сервер">
            <b-form-select
              v-model="settings.emailSelectServer"
              :options="servers"
            ></b-form-select>
          </b-form-group>
          <b-form-group v-if="ownServer" label="Адрес сервера">
            <b-form-input v-model="settings.emailServer"></b-form-input>
          </b-form-group>
          <b-form-group label="Логин">
            <b-form-input v-model="settings.emailLogin"></b-form-input>
          </b-form-group>
          <b-form-group label="Пароль">
            <b-form-input
              v-model="settings.emailPassword"
              type="password"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="ownServer" label="Ssl">
            <b-form-input v-model="settings.emailSsl"></b-form-input>
          </b-form-group>
          <b-form-group v-if="ownServer" label="Тип соединения">
            <b-form-select
              v-model="settings.emailConnection"
              :options="connections"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Искать только в непрочитанных письмах">
            <b-form-checkbox
              v-model="settings.searchOnlyUnseenEmail"
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group label="Отмечать письма как прочитанные">
            <b-form-checkbox
              v-model="settings.markEmailAsSeen"
            ></b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h5>Параметры поиска</h5>
        </div>
        <div class="ibox-content">
          <div v-for="(filter, index) in settings.filters" :key="index">
            <div class="row">
              <div class="col-md-3">
                <b-form-group label="Email отправителя">
                  <b-form-input v-model="filter.emailFrom"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Заголовок содержит">
                  <b-form-input v-model="filter.emailSubject"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-1 align-self-center">
                <button
                  v-if="index === 0"
                  class="btn btn-primary"
                  @click="addFilter"
                >
                  <font-awesome-icon icon="plus" />
                </button>
                <button
                  v-if="index !== 0"
                  class="btn btn-danger"
                  @click="removeFilter(index)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <b-button variant="primary" @click="createOrUpdate"
        >{{ submitButton }}
      </b-button>
    </div>
  </div>
</b-form>
