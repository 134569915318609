import {
  TYPE_ADMIN,
  TYPE_FINE_MANAGER,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_SHADOW,
  TYPE_WORKER,
} from "@/modules/users/decorators/users";

//======= common =========================

export function isGuest(user) {
  return user.isGuest;
}

export function isWorker(user) {
  return !user.isGuest && [TYPE_WORKER].includes(user.profile.type);
}

//======= settings =========================
export function canSeeSettings(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}

//======= reports =========================

export function canSeeManagerReport(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_LOGIST,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

//======= requests-list =========================

export function canSeeRequestList(user) {
  return !user.isGuest;
}

export function canSeeRequestTableList(user) {
  return !user.isGuest && [TYPE_SHADOW, TYPE_ADMIN].includes(user.profile.type);
}

//======= clients =========================

export function canSeeClientList(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_MANAGER].includes(user.profile.type)
  );
}

export function canSeeKanban(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,
      TYPE_LOGIST,
    ].includes(user.profile.type)
  );
}

export function canDeleteContractContacts(user) {
  return !user.isGuest && [TYPE_SHADOW, TYPE_ADMIN].includes(user.profile.type);
}

export function canSeeAllKanbans(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST, TYPE_FINE_MANAGER].includes(
      user.profile.type
    )
  );
}
//======= requests-board =========================

export function canChangeAssignee(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}

export function canChangeManager(user) {
  return !user.isGuest && [TYPE_SHADOW, TYPE_ADMIN].includes(user.profile.type);
}
//======= requests =========================

export function canDeclineRequests(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}
export function canSaveResultWithoutDocuments(user) {
  return !user.isGuest && ![TYPE_WORKER].includes(user.profile.type);
}
export function canCorrectRequests(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}

export function canSetRequestResults(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_WORKER].includes(user.profile.type)
  );
}

export function canSeeRequestHistory(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_LOGIST,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

export function canSeeRequestAppartment(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_LOGIST,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

export function canViewPhone(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST, TYPE_MANAGER].includes(
      user.profile.type
    )
  );
}

//======= leads =========================
//======= stats =========================
//======= schedule =========================

export function canSeeSchedule(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_MANAGER,
      TYPE_LOGIST,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

export function canEditSchedule(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}

export function canEditDaySchedule(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST, TYPE_MANAGER].includes(
      user.profile.type
    )
  );
}

export function canSeeOwnSchedule(user) {
  return !user.isGuest && user.profile.type === TYPE_WORKER;
}

export function canSeeOwnStats(user) {
  return !user.isGuest && user.profile.type === TYPE_WORKER;
}

export function canDeleteResultFiles(user) {
  return !user.isGuest && [TYPE_ADMIN, TYPE_SHADOW].includes(user.profile.type);
}

//======= payments =========================
export function canSeePayments(user) {
  return !user.isGuest && [TYPE_ADMIN, TYPE_SHADOW].includes(user.profile.type);
}

export function canSeeDeposits(user) {
  return !user.isGuest && [TYPE_ADMIN, TYPE_SHADOW].includes(user.profile.type);
}

export function canSeeReports(user) {
  return !user.isGuest && [TYPE_ADMIN, TYPE_SHADOW].includes(user.profile.type);
}

export function canUpdatePayments(user) {
  return !user.isGuest && [TYPE_ADMIN, TYPE_SHADOW].includes(user.profile.type);
}

//======= notifications =========================
export function canSeeOwnNotifications(user) {
  return !user.isGuest && user.profile.type === TYPE_WORKER;
}

export function canSeeAllNotifications(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_FINE_MANAGER, TYPE_MANAGER].includes(
      user.profile.type
    )
  );
}

//======= fines =========================
export function canSeeFines(user) {
  return !user.isGuest;
}

export function canAddFines(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_MANAGER,
      TYPE_LOGIST,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

export function canReviewFines(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_FINE_MANAGER].includes(user.profile.type)
  );
}

//======= users =========================

export function showHeaderUserMenu(user) {
  return !user.isGuest && [TYPE_FINE_MANAGER].includes(user.profile.type);
}

export function canSeeUserList(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_MANAGER,
      TYPE_LOGIST,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

export function canSeeUserCard(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}

export function canUpdateUser(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST].includes(user.profile.type)
  );
}

export function canUpdateUsers(user) {
  return (
    !user.isGuest &&
    [
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_MANAGER,
      TYPE_LOGIST,
      TYPE_FINE_MANAGER,
    ].includes(user.profile.type)
  );
}

//======= maps =========================

export function canSeeRequestMap(user) {
  return (
    !user.isGuest &&
    [TYPE_SHADOW, TYPE_ADMIN, TYPE_MANAGER, TYPE_LOGIST].includes(
      user.profile.type
    )
  );
}

//======= other =========================
