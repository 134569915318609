
<b-form
  id="pest-place-form"
  ref="place"
  :model="place"
  class="pest-place-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="place.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="Город" prop="townId">
      <b-button-group>
        <button
          class="btn"
          :class="getTownClass(MSK)"
          @click.prevent="selectTown(MSK)"
        >
          Москва
        </button>
        <button
          class="btn"
          :class="getTownClass(SPB)"
          @click.prevent="selectTown(SPB)"
        >
          Санкт-Петербург
        </button>
      </b-button-group>
    </b-form-group>

    <b-form-group label="Поиск по адресу" prop="color">
      <dadata-autocomplete
        v-model="searchAddress"
        @selected="addressSelected"
      ></dadata-autocomplete>
    </b-form-group>

    <b-form-group label="Сохраненный адрес" prop="color">
      <b-form-input v-model="place.address"></b-form-input>
    </b-form-group>

    <b-form-group label="Координаты" prop="color">
      <b-form-input v-model="place.coords"></b-form-input>
    </b-form-group>

    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
    <!--          <b-form-group label="Описание" prop="title">-->
    <!--            <b-form-textarea v-model="place.comment" placeholder="Описание"-->
    <!--                             tabindex="1"></b-form-textarea>-->
    <!--          </b-form-group>-->
    <!--        </div>-->
    <!--      </div>-->

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="place.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deletePlace"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
