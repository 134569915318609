<template>
  <div
    :data-active="active"
    draggable
    @click.prevent="clicked"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.prevent="onDrop"
  >
    <!-- share state with the scoped slot -->
    <slot :drop-zone-active="active"></slot>
  </div>
</template>

<script>
export default {
  name: "DropZone",
  components: {},
  props: {},
  data() {
    return {
      active: false,
      inActiveTimeout: null,
      events: ["dragenter", "dragover", "dragleave", "drop"],
    };
  },
  computed: {},
  watch: {},
  created: function () {},

  // mounted(){
  // 	this.events.forEach((eventName) => {
  // 		document.body.addEventListener(eventName)
  // 	})
  // },
  // beforeDestroy(){
  // 	this.events.forEach((eventName) => {
  // 		document.body.removeEventListener(eventName)
  // 	})
  // },

  methods: {
    setActive() {
      this.active = true;
      clearTimeout(this.inActiveTimeout);
    },
    setInactive() {
      let $this = this;
      this.inActiveTimeout = setTimeout(() => {
        $this.active = false;
      }, 50);
      // this.active = false;
    },
    clicked() {
      this.$emit("click");
    },
    onDrop(e) {
      this.setInactive();
      this.$emit("files-dropped", [...e.dataTransfer.files]);
    },
  },
};
</script>

<style lang="scss" scoped></style>
