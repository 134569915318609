<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Задачи</h1>

      <div>
        <b-button
          :variant="completed ? 'outline-info' : 'info'"
          class="btn"
          @click="showActiveTasks"
        >
          В работе
        </b-button>

        <b-button
          :variant="!completed ? 'outline-info' : 'info'"
          class="btn ml-1"
          @click="showCompletedTasks"
        >
          Завершенные
        </b-button>

        <router-link :to="{ name: 'task-create' }" class="btn btn-primary ml-2">
          <font-awesome-icon icon="plus" />
          Добавить задачу
        </router-link>
      </div>

      <b-table
        v-if="tasks.length"
        responsive
        striped
        hover
        condensed
        :items="tasks"
        :fields="fields"
        :busy="loading"
        :tbody-tr-class="taskRowClass"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> Данные подгружаются</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{ name: 'task-view', params: { id: data.item.id } }"
            class=""
          >
            {{ data.item.title }}
          </router-link>
        </template>

        <template #cell(type)="data">
          {{ getTaskTypeTitle(data.item.type) }}
        </template>
        <template #cell(assignee)="data">
          {{ getUserName(data.item.author) }}
        </template>

        <template #cell(author)="data">
          {{ getUserName(data.item.assignee) }}
        </template>

        <template #cell(deadline)="data">
          {{ getTaskDeadline(data.item) }}
        </template>

        <template #cell(completeTime)="data">
          {{ getTaskCompleteTime(data.item) }}
        </template>

        <template #cell(complete)="data">
          <b-button
            v-if="!data.item.completed"
            class="btn-xs"
            variant="primary"
            @click="completeTask(data.item)"
          >
            <span v-if="!data.item.loading">
              <font-awesome-icon icon="check" /> Завершить
            </span>
            <font-awesome-icon v-else icon="rotate" spin />
          </b-button>
          <b-button
            v-else
            class="btn-xs"
            variant="info"
            @click="restoreTask(data.item)"
          >
            <span v-if="!data.item.loading">
              <font-awesome-icon icon="box" /> Открыть
            </span>
            <font-awesome-icon v-else icon="rotate" spin />
          </b-button>
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getUserName } from "../../common";
import {
  getClientFirstEmail,
  getClientFirstPhone,
  getClientName,
  getClientOrganization,
  getNumerationTypeTitle,
  TYPE_EMPLOYEE,
  TYPE_ORGANIZATION,
} from "@/modules/crm/decorators/client";
import { getTaskTypeTitle } from "@/modules/crm/decorators/taskType";
import {
  getTaskCompleteTime,
  getTaskDeadline,
} from "@/modules/crm/decorators/task";

export default {
  name: "TaskList",
  components: {},
  data() {
    return {
      c_TYPE_ORGANIZATION: TYPE_ORGANIZATION,
      c_TYPE_PERSON: TYPE_EMPLOYEE,
      tasks: [{}],
      search: {
        name: "",
        phone: "",
        managerId: "",
      },
      managersList: [],
      loading: true,
      completed: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Задачи",
      isGroupListVisible: false,
      sampleGroupIndex: -1,
      sampleGroup: "",
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
    fields() {
      let fields = [
        {
          key: "title",
          label: "Задача",
          sortable: false,
        },
        {
          key: "type",
          label: "Тип",
          sortable: false,
        },
        {
          key: "assignee",
          label: "Исполнитель",
          sortable: false,
        },
        {
          key: "author",
          label: "Автор",
          sortable: false,
        },
        {
          key: "deadline",
          label: "Дедлайн",
          sortable: false,
        },
        {
          key: "completeTime",
          label: "Завершено",
          sortable: false,
          visible: this.completed,
        },
        {
          key: "complete",
          label: "",
          sortable: false,
          // visible: this.completed
        },
      ];
      return fields.filter(
        (col) => col.visible === undefined || col.visible === true
      );
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {
    this.fetchManagers();
  },
  methods: {
    getClientName,
    getClientOrganization,
    getUserName,
    getClientFirstPhone,
    getClientFirstEmail,
    getTaskDeadline,
    getTaskCompleteTime,
    getNumerationTypeTitle,
    getTaskTypeTitle,
    fetchManagers: function () {
      this.managersList = [];
      this.managersList.push({ value: "", text: "Все" });
      let usersUrl = "/users/admin/";
      let params = {
        page: this.page,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.managersList.push({
              value: item.id,
              text: this.getUserName(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchData: function () {
      this.loading = true;

      let tasksUrl = "/crm/tasks";
      let params = {
        page: this.page,
        completed: this.completed ? 1 : 0,
      };
      this.$http
        .get(tasksUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.tasks = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "task-list",
        params: { page: pageNum },
      };
    },
    taskRowClass(task, type) {
      if (!task) {
        return "";
      }
      if (task.completed) {
        return "table-success";
      }
      if (task.deadline && new Date(task.deadline) < new Date()) {
        return "table-danger";
      }
    },
    showCompletedTasks() {
      this.completed = true;
      this.fetchData();
    },
    showActiveTasks() {
      this.completed = false;
      this.fetchData();
    },
    completeTask(task) {
      task.loading = true;
      let completeUrl = "/crm/tasks/complete/" + task.id;
      this.$http
        .post(completeUrl, {})
        .then((response) => {
          task.completed = true;
          task.loading = false;
          this.$bvToast.toast("Задача закрыта", {
            title: "Задача отмечена как завершенная",
            variant: "success",
          });
        })
        .catch((error) => {
          task.loading = false;
        });
    },
    restoreTask(task) {
      task.loading = true;
      let restoreUrl = "/crm/tasks/restore/" + task.id;
      this.$http
        .post(restoreUrl, {})
        .then((response) => {
          task.completed = 0;
          task.loading = false;
          this.$bvToast.toast("Задача открыта", {
            title: "Задача открыта заново",
            variant: "success",
          });
        })
        .catch((error) => {
          task.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.task-organization {
  font-size: smaller;
  color: gray;
}
</style>
