import MoneyResultList from "@/modules/direct/views/results/MoneyResultList";

export default [
  {
    path: "/results/page:page?",
    name: "direct-money-result-list",
    components: {
      default: MoneyResultList,
    },
    meta: { requiresAuth: true },
  },
];
