<template>
  <div>
    <div v-if="updating">
      <div class="d-flex">
        <b-form-group :label="label" prop="type">
          <div class="d-flex">
            <b-form-input
              v-model="val"
              :placeholder="label"
              :tabindex="tabindex"
            >
            </b-form-input>

            <b-button class="ml-2" variant="primary" @click="endUpdate(true)">
              <font-awesome-icon fixed-width icon="check" />
            </b-button>

            <b-button class="ml-2" variant="default" @click="endUpdate(false)">
              <font-awesome-icon fixed-width icon="times" />
            </b-button>
          </div>
        </b-form-group>
      </div>
    </div>
    <div v-else>
      <div class="d-flex" @mouseover="hover = true" @mouseleave="hover = false">
        <div>
          <h4>{{ label }}</h4>
          <p v-if="val">{{ val }}</p>
          <a v-else href="#" class="text-muted" @click.prevent="startUpdate">
            <font-awesome-icon icon="plus" />
            Добавить
          </a>
        </div>
        <div class="ml-2">
          <b-button
            v-if="val && hover"
            class=""
            variant="default"
            @click="startUpdate"
          >
            <font-awesome-icon icon="edit" />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InlineField",
  components: {},
  props: ["attr", "value", "type", "label", "tabindex"],
  data() {
    return {
      updating: false,
      hover: false,
      val: "",
    };
  },
  computed: {},
  watch: {
    attr() {
      this.saveLocalVal();
    },
    value() {
      this.saveLocalVal();
    },
  },
  updated() {},
  methods: {
    saveLocalVal() {
      this.val = this.value;
    },
    startUpdate() {
      this.oldVal = this.val;
      this.updating = true;
    },
    endUpdate(save) {
      if (save) {
        this.$emit("update-prop", { attr: this.attr, value: this.val });
      } else {
        this.val = this.oldVal;
      }
      this.updating = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
