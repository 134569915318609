export const getFacilityTitle = function (facility) {
  if (!facility || !facility.title) {
    return "[Не назначен]";
  }
  return facility.title;
};

export const CAT_ORG = "2";
export const CAT_IND = "3";

export const facilityTypeTitles = {
  [CAT_ORG]: "Организация",
  [CAT_IND]: "Частное лицо",
};
export const facilityTypeOptions = [
  { value: CAT_ORG, text: "Организация" },
  { value: CAT_IND, text: "Частное лицо" },
];
export const getFacilityTypeTitle = function (facility) {
  if (!facility.type) {
    return "[Не назначен]";
  }
  return facilityTypeTitles[facility.type];
};
