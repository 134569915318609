<template xmlns:comment="http://www.w3.org/1999/html">
  <div class="feed-activity-list">
    <div v-if="comments.length">
      <comment :comment="comment" v-for="(comment, index) in comments" :key="index"></comment>
    </div>
    <div v-else>
      <p>
        Еще нет комментариев
      </p>
    </div>

  </div>
</template>

<script>

import Comment from "@/modules/law/views/comment/Comment";


export default {
  name: "CommentList",
  components: {
    'comment': Comment,

  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60
      }
    }
  },
  props: ['comments'],
  computed: {},
  watch: {},
  created: function () {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
