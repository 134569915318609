
<div class="client-header">
  <b-row>
    <b-col>
      <h1>
        <router-link v-if="returnUrl" :to="returnUrl" class="ml-1 mr-1 h4">
          <font-awesome-icon icon="arrow-left" />
        </router-link>
        {{ clientName }}
        <small v-if="showButtons">
          <router-link
            :to="{ name: 'crm-client-update', params: { id: client.id } }"
            class="ml-1 mr-1 h6"
          >
            <font-awesome-icon icon="edit" />
          </router-link>

          <a href="" class="h6 ml-2" @click.prevent="deleteClient">
            <font-awesome-icon icon="trash" />
          </a>
        </small>
      </h1>
    </b-col>
  </b-row>

  <!--    <b-row class="mb-2">-->
  <!--      <b-col>-->
  <!--        <router-link :to="{ name: 'crm-client-view',params:{id:id,} }"-->
  <!--                     class="btn"-->
  <!--                     :class="{'btn-info': tab === 'info', 'btn-default': tab !== 'info'}"-->
  <!--        >-->
  <!--          Информация и задачи-->
  <!--        </router-link>-->

  <!--        <router-link :to="{ name: 'document-list',params:{id:id,} }"-->
  <!--                     class="btn ml-1"-->
  <!--                     :class="{'btn-info': tab === 'docs', 'btn-default': tab !== 'docs'}"-->
  <!--                     v-if="id !== 'new' ">-->
  <!--          Документы-->
  <!--        </router-link>-->
  <!--      </b-col>-->
  <!--    </b-row>-->
</div>
