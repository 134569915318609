//
import RecordList from "@/modules/data/views/records/RecordList";
import RecordView from "@/modules/data/views/records/RecordView";
import RecordCreate from "@/modules/data/views/records/RecordCreate";
import RecordUpdate from "@/modules/data/views/records/RecordUpdate";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/records/index:index/page:page?",
    name: "data-record-list",
    meta: { requiresAuth: true },
    components: {
      default: RecordList,
    },
  },

  {
    path: "/records/index:index/new/:organizationId?",
    name: "data-record-create",
    meta: { requiresAuth: true },
    components: {
      default: RecordCreate,
      // 'record-header': RecordHeader,
    },
  },
  {
    path: "/records/index:index/:id/update",
    name: "data-record-update",
    meta: { requiresAuth: true },
    components: {
      default: RecordUpdate,
      // 'record-header': RecordHeader,
    },
  },
  {
    path: "/records/index:index/:id",
    name: "data-record-view",
    meta: { requiresAuth: true },
    components: {
      default: RecordView,
      // 'record-header': RecordHeader,
    },
  },
];
