
<div>
  <div class="ibox">
    <div class="ibox-content">
      <div class="d-flex align-items-center">
        <h1>История платежей</h1>
        <router-link
          v-if="canAddPayment()"
          :to="{ name: 'pest-payment-create' }"
          class="btn btn-primary ml-2"
        >
          <font-awesome-icon icon="plus" />
          Добавить платеж
        </router-link>

        <div class="ml-auto">
          <router-link
            :to="{ name: 'pest-settlements' }"
            class="btn btn-outline-info ml-2"
          >
            <font-awesome-icon icon="list" />
            Взаиморасчеты
          </router-link>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <b-form-group label="Город" prop="townId">
          <div class="mr-2">
            <b-button-group>
              <button
                class="btn"
                :class="getTownClass(MSK)"
                @click.prevent="selectTown(MSK)"
              >
                Москва
              </button>
              <button
                class="btn"
                :class="getTownClass(SPB)"
                @click.prevent="selectTown(SPB)"
              >
                Санкт-Петербург
              </button>
            </b-button-group>
          </div>
        </b-form-group>

        <b-form-group label="Сотрудник" prop="employeeId">
          <user-autocomplete
            v-model="search.employeeId"
            :default-value="getEmployeeName(search.employee)"
            @selected="selectManager"
          />
        </b-form-group>
      </div>

      <b-table
        v-if="payments.length"
        responsive
        striped
        hover
        condensed
        :items="payments"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> Данные подгружаются</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <router-link
            :to="{
              name: 'pest-payment-update',
              params: { id: data.item.id },
            }"
            class=""
          >
            <b-button class="btn btn-xs btn-primary ml-1">
              <font-awesome-icon icon="eye" />&nbsp;
            </b-button>
          </router-link>
        </template>

        <template #cell(time)="data">
          {{ getPaymentTime(data.item) }}
        </template>

        <template #cell(sum)="data">
          {{ data.item.sum }}
        </template>
        <template #cell(employee)="data">
          {{ getUserName(data.item.employee) }}
        </template>
        <template #cell(manager)="data">
          {{ getUserName(data.item.manager) }}
        </template>

        <template #cell(color)="data">
          <span class="p-1" :style="{ background: data.item.color }"
            >{{ data.item.color }}
          </span>
        </template>
      </b-table>

      <b-pagination-nav
        v-if="pagination.count > 1"
        :link-gen="linkGen"
        :value="pagination.page"
        :number-of-pages="pagination.count"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </div>
</div>
