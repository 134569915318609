
<div>
  <div class="ibox">
    <div class="ibox-content">
      <div class="d-flex align-items-center">
        <h1>Взаиморасчеты</h1>

        <router-link
          v-if="canAddPayment()"
          :to="{ name: 'pest-payment-create' }"
          class="btn btn-primary ml-2"
        >
          <font-awesome-icon icon="plus" />
          Добавить платеж
        </router-link>

        <div class="ml-auto">
          <router-link
            :to="{ name: 'pest-payment-list' }"
            class="btn btn-outline-info ml-2"
          >
            <font-awesome-icon icon="list" />
            История платежей
          </router-link>
        </div>
      </div>

      <div>
        <div class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getTownClass(MSK)"
              @click.prevent="selectTown(MSK)"
            >
              Москва
            </button>
            <button
              class="btn"
              :class="getTownClass(SPB)"
              @click.prevent="selectTown(SPB)"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
        </div>
      </div>

      <div>
        <table
          v-if="debtors.length"
          class="debtors-table table table-striped"
        >
          <thead>
            <tr>
              <th>Пользователь</th>
              <th>Сумма к оплате</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(info, index) in debtorsFromTown" :key="index">
              <td>{{ getUserName(info.user) }}</td>
              <td>{{ info.sum }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'pest-payment-create',
                    params: { userId: info.user.id },
                  }"
                  class="btn btn-primary btn-sm"
                >
                  <font-awesome-icon icon="plus" />
                  Добавить платеж
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
