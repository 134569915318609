
<div>
  <table class="table table-striped request-info">
    <tbody>
      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Номер
        </td>
        <td>
          {{ request.externalId }}
        </td>
      </tr>
      <tr>
        <td><font-awesome-icon icon="paperclip" fixed-width /> Проект</td>
        <td>
          {{ getRequestProjectTitle(request) }}
        </td>
      </tr>
      <tr>
        <td>
          <font-awesome-icon
            v-if="request.isOrganization"
            icon="building"
            fixed-width
          />
          <font-awesome-icon v-else icon="user" fixed-width />
          Клиент
        </td>
        <td>
          <span v-if="request.clientId">
            {{ getRequestClientName(request) }}
            <router-link
              v-if="canOpenToAssignee"
              :to="{
                name: 'crm-client-view',
                params: { id: request.clientId },
              }"
              class=""
            >
              <font-awesome-icon
                icon="external-link-square-alt"
                fixed-width
              />
            </router-link>
          </span>
          <span v-else>[Не назначен]</span>
        </td>
      </tr>
      <tr v-if="request.isOrganization && request.organizationTitle">
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Организация
        </td>
        <td>
          {{ request.organizationTitle }}
        </td>
      </tr>

      <tr v-if="request.isOrganization && request.organizationTitle">
        <td>
          <font-awesome-icon icon="money-bill" fixed-width />
          Тип оплаты
        </td>
        <td>
          {{ getPaymentTypeTitle(request) }}
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="clock" fixed-width />
          Время
        </td>
        <td>
          {{ getRequestDatetime(request) }} –
          {{ getRequestDuration(request) }}
        </td>
      </tr>

      <tr v-if="request.town">
        <td>
          <font-awesome-icon icon="map-marker" fixed-width />
          Город
        </td>
        <td>
          {{ request.town.title }}
        </td>
      </tr>
      <tr v-if="request.subway">
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Метро
        </td>
        <td>
          {{ request.subway }}
        </td>
      </tr>
      <tr>
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Адрес
        </td>
        <td>
          {{ request.address }}
        </td>
      </tr>
      <tr>
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Квартира
        </td>

        <td>
          <div class="d-flex">
            <div class="">
              <span v-if="canIViewAppartment">
                <span v-if="request.appartment">{{
                  request.appartment
                }}</span>
                <span v-else>[Квартира не назначена]</span>
              </span>
              <span v-else> Вы пока не можете увидеть номер квартиры </span>
            </div>
            <div class="ml-auto">
              <div v-if="!canIViewAppartment">
                <a
                  v-if="!requestingAddress"
                  class="btn btn-primary btn-sm"
                  @click.prevent="showAddress()"
                >
                  <font-awesome-icon icon="map" />
                  Показать мне
                </a>
                <a v-else class="btn btn-primary btn-sm" @click.prevent>
                  <font-awesome-icon spin icon="rotate" />
                  Идет запрос
                </a>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="canOpenToAssignee">
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Видимость квартиры
        </td>

        <td>
          <div class="d-flex">
            <div class="">
              <span v-if="canAssigneeViewAppartment">
                <font-awesome-icon icon="check" fixed-width />
                Номер отображается исполнителю
              </span>
              <span v-else>
                <font-awesome-icon icon="times" fixed-width />
                Номер скрыт от исполнителя
              </span>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="phone" fixed-width />
          Телефон
        </td>
        <td>
          <div class="d-flex">
            <div v-if="canViewPhone" class="">{{ request.phone }}</div>
            <div v-else class="">У вас нет доступа к телефону</div>
          </div>
        </td>
      </tr>

      <tr v-if="request.contactPhone">
        <td>
          <font-awesome-icon icon="phone-volume" fixed-width />
          Номер для связи
        </td>
        <td>
          <div class="d-flex">
            <div v-if="canViewPhone" class="">{{ request.contactPhone }}</div>
            <div v-else class="">У вас нет доступа к телефону</div>
          </div>
        </td>
      </tr>
      <tr v-if="request.contactPhone2">
        <td>
          <font-awesome-icon icon="phone-volume" fixed-width />
          Номер для связи
        </td>
        <td>
          <div class="d-flex">
            <div v-if="canViewPhone" class="">
              {{ request.contactPhone2 }}
            </div>
            <div v-else class="">У вас нет доступа к телефону</div>
          </div>
        </td>
      </tr>
      <tr v-if="request.contactPhone3">
        <td>
          <font-awesome-icon icon="phone-volume" fixed-width />
          Номер для связи
        </td>
        <td>
          <div class="d-flex">
            <div v-if="canViewPhone" class="">
              {{ request.contactPhone3 }}
            </div>
            <div v-else class="">У вас нет доступа к телефону</div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Сумма заявки
        </td>
        <td>
          {{ request.sum }}
        </td>
      </tr>

      <tr v-if="request.warning">
        <td>
          <font-awesome-icon icon="exclamation-triangle" fixed-width />
          Особое внимание
        </td>
        <td>
          <span>{{ request.warning }}</span>
        </td>
      </tr>
      <tr v-if="request.pest">
        <td>
          <font-awesome-icon icon="bug" fixed-width />
          Вредитель
        </td>
        <td :style="'color: ' + getRequestColor(request)">
          <span>{{ getPestTitle(request.pest) }}</span>
        </td>
      </tr>
      <tr v-if="request.facility">
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Тип объекта
        </td>
        <td>
          <span>{{ getFacilityTitle(request.facility) }}</span>
        </td>
      </tr>

      <tr v-if="request.fromExchange">
        <td>
          <font-awesome-icon icon="hand-holding-usd" fixed-width />
          Дополнительно
        </td>
        <td>
          <span>Из биржи (нет рекорда) </span>
        </td>
      </tr>

      <tr v-if="request.comment">
        <td>
          <font-awesome-icon icon="comment" fixed-width />
          Комментарий
        </td>
        <td>
          <span>{{ request.comment }}</span>
        </td>
      </tr>
      <!--          <tr>-->
      <!--            <td>-->
      <!--              Дата создания-->
      <!--            </td>-->
      <!--            <td>-->
      <!--              {{ getRequestCreateTime(request) }}-->
      <!--            </td>-->
      <!--          </tr>-->
    </tbody>
  </table>
</div>
