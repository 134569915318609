
<div>
  <div class="ibox">
    <div class="ibox-title d-flex align-items-center">
      <h3>Отчет по менеджерам</h3>
      <div class="ml-2">
        <date-range-picker
          v-model="dateRange"
          @change="fetchData"
        ></date-range-picker>
      </div>
      <div class="ml-2">Проект:</div>
      <div class="ml-2">
        <b-form-select
          v-model="projectId"
          :options="projectsList"
          @change="fetchData"
        />
      </div>
    </div>
  </div>

  <div class="ibox-content">
    <b-overlay :show="loadingData" rounded="sm">
      <div :class="{ 'd-none': hasData }">Отчет еще не построен</div>
      <div class="table-responsive" :class="{ 'd-none': !hasData }">
        <managers-data-table
          ref="reportTable"
          :has-data="hasData"
          :source-data="data"
        />
      </div>
    </b-overlay>
  </div>
</div>
