
<div class="pest-price-page">
  <div class="row">
    <div class="col-md-8" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title d-flex align-items-center pr-4">
          <div class="ml-1 mr-2">
            <router-link
              :to="{ name: 'pest-price-list' }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <h1>Изменить цены</h1>

          <b-button
            variant="primary"
            class="ml-2"
            @click.prevent="createOrUpdate"
          >
            <font-awesome-icon icon="check" />
            Сохранить
          </b-button>
        </div>
        <div class="ibox-content">
          <pest-price-form
            ref="price-form"
            :price-model="price"
          ></pest-price-form>
        </div>
      </div>
    </div>
  </div>
</div>
