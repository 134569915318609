
<div class="documentType-page">
  <div class="row">
    <div class="col-md-6 " style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link :to="{ name: 'document-type-list'}" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left"/>
            </router-link>
            Изменение типа документа
          </h1>
        </div>
        <div class="ibox-content ">
          <documentType-form :documentType="documentType"
                             :return-url="{ name: 'document-type-list'}"></documentType-form>
        </div>
      </div>
    </div>
  </div>

</div>

