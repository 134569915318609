
<b-form
  id="task-form"
  ref="task"
  :model="task"
  class="task-form"
  novalidate
  @submit.prevent="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Тип" prop="type">
          <b-form-radio-group
            id="task-type"
            v-model="task.typeId"
            name="task-type"
            :options="taskTypesList"
            tabindex="-1"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="task.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Исполнитель" prop="assigneeId">
          <autocomplete
            ref="searchAssignee"
            :search="searchAssignee"
            class=""
            :default-value="assigneeName"
            placeholder="Поиск по сотрудникам"
            aria-label="Поиск по сотрудникам"
            :get-result-value="getResultAssigneeAutocompleteValue"
            @submit="handleAssigneeSubmit"
          ></autocomplete>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group label="Крайний срок" prop="deadline">
          <datepicker
            v-model="task.deadline"
            v-mask="'##.##.####'"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
            tabindex="6"
            required
          >
          </datepicker>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="task.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <hr />
        <h4>Привязки</h4>
        <client-link
          :task="task"
          :show-buttons="true"
          @update-client-link="updateClientLink"
        ></client-link>
        <hr />
      </div>
    </div>

    <div v-if="ownWindow" class="row mt-2">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>
      </div>
    </div>
  </div>
</b-form>
