
<b-modal
  id="modal-search-contract-popup"
  ref="modal"
  title="Поиск контракта"
  size="lg"
  class="contract-popup"
  cancel-title="Отмена"
  ok-title="Сохранить"
  @hide="handleHide"
  @shown="onShown"
  @ok.prevent="handleModalOk"
>
  <template #modal-title> <h3>Поиск контракта или лида</h3></template>
  <template #default>
    <div class="d-flex">
      <div class="mr-2">
        <b-form-group label="Адрес" prop="address">
          <b-form-input v-model="address" @change="fetchData"></b-form-input>
        </b-form-group>
      </div>
      <div v-if="false" class="mr-2">
        <b-form-group label="Имя" prop="name">
          <b-form-input v-model="name" @change="fetchData"></b-form-input>
        </b-form-group>
      </div>
      <div class="mr-2">
        <b-form-group label="Телефон" prop="phone">
          <b-form-input v-model="phone" @change="fetchData"></b-form-input>
        </b-form-group>
      </div>
      <div class="mr-2">
        <b-form-group label="Источник" prop="source">
          <source-autocomplete
            :default-value="source"
            @selected="selectSource"
          ></source-autocomplete>
        </b-form-group>
      </div>
      <div class="mr-2">
        <b-form-group label="Номер" prop="id">
          <b-form-input v-model="id" @change="fetchData"></b-form-input>
        </b-form-group>
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <table class="table table-condensed table-striped table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Тип</th>
            <th>Имя</th>
            <th>Телефон</th>
            <th>Источник</th>
          </tr>
        </thead>
        <tbody v-if="requests.length">
          <tr
            v-for="(request, rIndex) in requests"
            :key="'r' + rIndex"
            :class="{ 'table-info': request.id === selectedId }"
            @click.prevent="selectContract(request)"
          >
            <td>
              {{
                request.contractId
                  ? request.contractId
                  : getRequestNumber(request)
              }}
            </td>
            <td>
              {{ request.contractId ? "Контракт" : "Лид" }}
            </td>
            <td>
              <div
                v-if="request.communications && request.communications.length"
              >
                <ul
                  v-for="(link, lIndex) in request.communications"
                  :key="'li' + rIndex + '+' + lIndex"
                  class="pl-0"
                >
                  {{
                    link.clientName
                  }}
                </ul>
              </div>
              <div v-else>
                {{ getRequestClientName(request) }}
              </div>
              <div>
                {{ getRequestAddressFormatted(request) }}
              </div>
            </td>
            <td>
              <div
                v-if="request.communications && request.communications.length"
              >
                <ul
                  v-for="(link, lIndex) in request.communications"
                  :key="'li' + rIndex + '+' + lIndex"
                  class="pl-0"
                >
                  <li
                    v-for="(aPhone, phIndex) in link.phones"
                    :key="'li' + rIndex + '+' + lIndex + '+' + phIndex"
                  >
                    {{ aPhone }}
                  </li>
                </ul>
              </div>
              <div v-else>
                {{ request.phone }}
              </div>
            </td>
            <td>{{ getRequestSourceName(request) }}</td>
          </tr>
        </tbody>
        <tbody v-if="!requests.length">
          <tr>
            <td colspan="5">Данных нет</td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
  </template>

  <template #modal-footer>
    <div class="mt-2">
      <!--        <button class="btn btn-outline-info " @click.prevent="fetchData">-->
      <!--          <font-awesome-icon fixedWidth icon="arrow-left"/>-->
      <!--          search-->
      <!--        </button>-->
      <button class="btn btn-outline-info mr-2" @click.prevent="handleHide">
        Отмена
      </button>
      <button class="btn btn-outline-primary" @click.prevent="handleModalOk">
        Прикрепить
      </button>
    </div>
  </template>
</b-modal>
