
<div v-if="hasData && data && data.results" class="table-responsive">
  <table class="table table-bordered table-condensed">
    <thead>
      <tr>
        <th class="titleCol">Тип вредителя / Источник / Исполнитель</th>
        <th v-for="(param, pIndex) in CPOParams" :key="'t' + pIndex">
          {{ param.title }}
        </th>
      </tr>
    </thead>
    <template v-for="(pestInfo, pestInd) in data.results">
      <tbody :key="'pestInd' + pestInd" class="pestBlock">
        <c-p-o-table-row
          :pest-info="pestInfo"
          :class="{
            sourceOpened: !!pestInfo.toggled,
          }"
          :cell-style="gradientStyle"
        >
          <template #title>
            <td @click.prevent="togglePest(pestInfo)">
              <div class="d-flex">
                <div>
                  {{ pestInfo.pestTitle }}
                </div>
                <div class="ml-auto">
                  <font-awesome-icon
                    v-if="!pestInfo.toggled"
                    icon="arrow-down"
                  />
                  <font-awesome-icon v-else icon="arrow-up" />
                </div>
              </div>
            </td>
          </template>
        </c-p-o-table-row>
      </tbody>

      <tbody
        v-if="pests && pestInfo.toggled"
        :key="'toggled-pestInd' + pestInd"
        class="sourceBlock"
      >
        <template v-for="(sourceInfo, sourceInd) in pestInfo.sources">
          <c-p-o-table-row
            v-if="sourceInfo.count || showEmpty"
            :key="'sourceInd' + pestInd + '-' + sourceInd"
            row-class="sourceBlock"
            :pest-info="pestInfo"
            :source-info="sourceInfo"
          >
            <template #title>
              <td @click.prevent="togglePest(sourceInfo)">
                <div class="d-flex">
                  <div>{{ getSourceTitle(sourceInfo) }}</div>
                  <div v-if="sourceInfo.count" class="ml-auto">
                    <font-awesome-icon
                      v-if="!sourceInfo.toggled"
                      icon="arrow-down"
                    />
                    <font-awesome-icon v-else icon="arrow-up" />
                  </div>
                </div>
              </td>
            </template>
          </c-p-o-table-row>

          <template
            v-for="(assigneeInfo, assigneeInd) in sourceInfo.assignees"
          >
            <c-p-o-table-row
              v-if="sourceInfo.toggled"
              :key="
                'assigneeInd' + pestInd + '-' + sourceInd + '-' + assigneeInd
              "
              row-class="assigneeBlock"
              :pest-info="pestInfo"
              :source-info="sourceInfo"
              :assignee-info="assigneeInfo"
            >
              <template #title>
                <td>
                  <div class="d-flex">
                    {{ getUserName(assigneeInfo.assignee) }}
                  </div>
                </td>
              </template>
            </c-p-o-table-row>
          </template>
        </template>
      </tbody>
    </template>
    <tbody>
      <c-p-o-table-total-row :data="data" />
    </tbody>
  </table>
</div>
