import FineList from "@/modules/pest/views/fines/FineList";
import FineCreate from "@/modules/pest/views/fines/FineCreate";
import FineUpdate from "@/modules/pest/views/fines/FineUpdate";
import FineView from "@/modules/pest/views/fines/FineView";

export default [
  {
    path: "/fines/list:page?",
    name: "pest-fine-list",
    components: {
      default: FineList,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "Мои штрафы",
    },
  },

  {
    path: "/fines/new/:userId?",
    name: "pest-fine-create",
    components: {
      default: FineCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/fines/:id/update",
    name: "pest-fine-update",
    components: {
      default: FineUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/fines/:id",
    name: "pest-fine-view",
    components: {
      default: FineView,
    },
    meta: { requiresAuth: true },
  },
];
