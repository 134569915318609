<template>
  <div>
    <drop-zone
      v-slot="{ dropZoneActive }"
      class="drop-area"
      @click="openFileInput"
      @files-dropped="addFiles"
    >
      <div>
        <div v-if="dropZoneActive">Перенесите файлы сюда для загрузки</div>
        <div v-else>
          Перенесите файл или <strong><em>нажмите сюда</em></strong
          >, чтобы загрузить файл
        </div>
      </div>
    </drop-zone>

    <input
      id="file-input"
      ref="file"
      class="d-none"
      multiple
      type="file"
      @input="inputChanged"
    />
    <progress
      v-if="uploadPercentage > 0"
      max="100"
      :value.prop="uploadPercentage"
    ></progress>
  </div>
</template>

<script>
import DropZone from "@/components/shared/DropZone";

export default {
  name: "FileUploadField",
  components: { DropZone },
  props: ["attr", "value", "type", "label", "tabindex", "uploadUrl"],
  data() {
    return {
      updating: false,
      hover: false,
      val: "",
      file: null,
      prevent: false,
      uploadPercentage: 0,
    };
  },
  computed: {},
  watch: {},
  updated() {},
  methods: {
    inputChanged(e) {
      this.submitFiles(e.target.files);
    },
    submitFiles(files) {
      if (!files) {
        return;
      }
      if (this.prevent) {
        return;
      }
      let total = files.length;
      let number = 0;
      for (let file of files) {
        let formData = new FormData();
        formData.append("file", file);
        let postUrl = "/pest/default/file";
        if (this.uploadUrl) {
          postUrl = this.uploadUrl;
        }
        this.$http
          .post(postUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            number++;
            this.$emit("file-uploaded", {
              file: response.data,
              total: total,
              number: number,
            });
            this.prevent = true;
            this.prevent = false;
            this.uploadPercentage = 0;
          })
          .catch((error) => {});
      }
    },
    onInputChange() {},
    addFiles(files) {
      console.log(files);
      this.submitFiles(files);
    },
    openFileInput() {
      this.$refs.file.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-area {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff55;
  transition: 0.2s ease;
  border: 1px dashed black;

  &[data-active="true"] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #f3f3f3;
  }
}

.drop-area:hover {
  background: #f3f3f3;
  border: 1px solid black;
}
</style>
