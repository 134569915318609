import Dashboard from "@/modules/crm/views/app/Dashboard.vue";
import Settings from "@/modules/crm/views/app/Settings.vue";
import EmailSettings from "@/modules/crm/views/app/EmailSettings.vue";

export default [
  {
    path: "/",
    name: "dashboard",
    components: {
      default: Dashboard,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/settings",
    name: "settings",
    components: {
      default: Settings,
      // 'client-header': ClientHeader,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/email",
    name: "email-settings",
    components: {
      default: EmailSettings,
    },
    meta: { requiresAuth: true },
  },
];
