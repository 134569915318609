<template>
  <div class="ibox mb-2">
    <div class="ibox-content pb-2">
      <notification-user-changed-assignee
        v-if="notification.type === REQUEST_CHANGED_ASSIGNEE"
        :notification="notification"
      />
      <notification-user-fine-approved
        v-else-if="notification.type === FINE_APPROVED"
        :notification="notification"
      />
      <notification-user-new-record
        v-else-if="notification.type === REQUEST_NEW_RECORD"
        :notification="notification"
      />
      <notification-user-message
        v-else-if="notification.type === MESSAGE"
        :notification="notification"
      />
      <div v-else>Что-то непонятное. Позовите программиста.</div>

      <div class="time text-right small">
        {{ getNotificationCreateTime(notification) }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  FINE_APPROVED,
  getNotificationConfirmDatetime,
  getNotificationCreateDatetime,
  getNotificationCreateTime,
  getNotificationTypeTitle,
  getNotificationUser,
  MESSAGE,
  REQUEST_CHANGED_ASSIGNEE,
  REQUEST_NEW_RECORD,
} from "@/modules/notifications/decorators/notifications";
import NotificationUserChangedAssignee from "@/modules/notifications/views/details-target/NotificationUserChangedAssignee.vue";
import NotificationUserFineApproved from "@/modules/notifications/views/details-target/NotificationUserFineApproved.vue";
import NotificationUserNewRecord from "@/modules/notifications/views/details-target/NotificationUserNewRecord.vue";
import NotificationUserMessage from "@/modules/notifications/views/details-target/NotificationUserMessage.vue";

export default {
  name: "UserNotification",
  components: {
    NotificationUserMessage,
    NotificationUserNewRecord,
    NotificationUserFineApproved,
    NotificationUserChangedAssignee,
    // RequestNotificationCorrected,
    // NotificationCallToClient, RequestNotificationCreated, RequestNotificationDeclined, RequestNotificationDone, RequestNotificationChangedAssignee
  },
  props: {
    notification: {},
    toAdmin: {},
  },
  data() {
    return {
      REQUEST_CHANGED_ASSIGNEE,
      FINE_APPROVED,
      REQUEST_NEW_RECORD,
      MESSAGE,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {
    getNotificationCreateDatetime,
    getNotificationCreateTime,
    getNotificationConfirmDatetime,
    getNotificationUser,
    getNotificationTypeTitle,
  },
};
</script>

<style lang="scss" scoped>
.ibox-content {
  border-width: 1px !important;
  border-style: solid;
  word-break: break-word;
}
</style>
