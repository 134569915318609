
<b-modal
  id="modal-request-call-notification"
  ref="modal"
  title="Назначение звонка"
  cancel-title="Отмена"
  :show-hour="true"
  :show-minute="true"
  :show-second="false"
  ok-title="Назначить"
  title-tag="h3"
  @ok.prevent="handleTaskModalOk"
>
  <b-overlay :show="saving">
    <date-time-picker v-model="datetime" />
  </b-overlay>
</b-modal>
