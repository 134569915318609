
<b-overlay :show="loadingRequests" rounded="sm">
  <div
    v-for="(group, gIndex) in groupedRequests"
    :key="gIndex + '-g'"
    class="ibox"
  >
    <div class="ibox-title">
      {{ getAddressFormatted(group.address) }}
    </div>
    <div class="ibox-content d-flex flex-wrap">
      <request-card
        v-for="(request, rIndex) in group.requests"
        :key="request.id + '-r'"
        :r-i="rIndex"
        :request="request"
        :update-button="true"
        :lead-board="true"
        :contract-board="true"
        :short-version="true"
        :hide-handle="true"
        @duplicate-request="duplicateRequest"
        @show-popup="showRequestPopup"
      />
    </div>
  </div>
</b-overlay>
