
<div class="ibox">
  <div class="ibox-title d-flex align-items-center pr-4">
    <div>
      <h5>Уведомления</h5>
    </div>
  </div>
  <div class="ibox-content">
    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <div class="mr-2">
          <b-form-group label="Дата от">
            <datepicker
              v-model="search.dateFrom"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              @change="fetchData"
            ></datepicker>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Дата до">
            <datepicker
              v-model="search.dateTo"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              @change="fetchData"
            ></datepicker>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Тип">
            <b-form-select
              v-model="search.type"
              :options="notificationOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Исполнитель">
            <user-autocomplete
              v-model="search.assigneeId"
              :default-value="
                search.assigneeId
                  ? getAssigneeName(search.assignee, true)
                  : ''
              "
              @selected="selectAssignee"
            />
          </b-form-group>
        </div>

        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.total }}
        </div>
      </div>
    </div>

    <div v-if="!notifications.length && !loading">Еще нет уведомлений</div>
    <div v-else class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="col-time col-2">Время события</th>
            <th class="col-user col-2">Тип</th>
            <th class="col-user col-2">Пользователь</th>
            <th class="col-description col-8">Описание</th>
            <th class="col-status col-3">Статус</th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <full-notification
            v-for="(notification, index) in notifications"
            :key="index"
            :notification="notification"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">Загружается</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</div>
