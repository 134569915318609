<template>
  <div>
    <a v-if="show" href="" @click.prevent="toggle">Скрыть</a>
    <a v-else href="" @click.prevent="toggle">Показать подробности</a>
    <request-log-created-info
      v-if="
        [REQUEST_CREATED_MANUALLY, REQUEST_CREATED_DUE_IMPORT].includes(
          log.type
        ) &&
        request &&
        show
      "
      :request="request"
      class="mt-2"
    />
    <request-log-roistat-imported-info
      v-if="log.type === REQUEST_CREATED_FROM_ROISTAT && request && show"
      :request="request"
      class="mt-2"
    />
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  REQUEST_CREATED_DUE_IMPORT,
  REQUEST_CREATED_MANUALLY,
  REQUEST_CREATED_FROM_ROISTAT,
} from "@/modules/pest/decorators/logs.js";
import RequestLogCreatedInfo from "@/modules/pest/views/logs/details/RequestLogCreatedInfo";
import RequestLogRoistatImportedInfo from "@/modules/pest/views/logs/details/RequestLogRoistatImportedInfo.vue";

export default {
  name: "RequestLogCreated",
  components: { RequestLogRoistatImportedInfo, RequestLogCreatedInfo },
  props: ["log"],
  data() {
    return {
      REQUEST_CREATED_DUE_IMPORT,
      REQUEST_CREATED_MANUALLY,
      REQUEST_CREATED_FROM_ROISTAT,
      show: false,
      request: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
