<template>
  <div>
    <div v-if="log.fromCall && !log.replaceId">Открыто по звонку</div>
    <div v-if="log.replaceId">Дубликат. Открыли заявку {{ log.replaceId }}</div>
    <div v-if="log.originalId">Открыто вместо дубля {{ log.originalId }}</div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogOpen",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: { getUserName },
};
</script>

<style lang="scss" scoped></style>
