<template>
  <div class="ibox">
    <div class="ibox-content">
      <div class="">
        <h1>Контакты</h1>
        <div class="mb-2">
          <router-link
            :to="{ name: 'crm-client-create' }"
            class="btn btn-primary"
          >
            <font-awesome-icon icon="plus" />
            Добавить контакт
          </router-link>

          <!--        <b-button @click="showArchivedClients" class="btn btn-info ml-1" v-if="!archived">-->
          <!--          <font-awesome-icon icon="box"/>-->
          <!--          Показать архив-->
          <!--        </b-button>-->
        </div>
      </div>

      <div class="search">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-form-group label="Имя">
              <b-form-input v-model="search.name" tabindex="1"></b-form-input>
            </b-form-group>
          </div>
          <div class="mr-2">
            <b-form-group label="Телефон">
              <b-form-input v-model="search.phone" tabindex="3"></b-form-input>
            </b-form-group>
          </div>

          <div v-if="canManageClients" class="mr-2">
            <b-form-group label="Менеджер">
              <b-form-select
                v-model="search.managerId"
                :options="managersList"
                placeholder="Менеджер"
                tabindex="5"
                class="managerFilter"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>
          <div v-if="!loading" class="mt-2 mr-2">
            Найдено: {{ pagination.total }}
          </div>
        </div>
      </div>

      <b-table
        v-if="clients.length"
        responsive
        striped
        hover
        condensed
        :items="clients"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> Данные подгружаются</strong>
          </div>
        </template>

        <template #cell(icon)="data">
          <router-link
            :to="{ name: 'crm-client-view', params: { id: data.item.id } }"
            class=""
          >
            <font-awesome-icon
              v-if="data.item.type == c_TYPE_ORGANIZATION"
              icon="building"
            />
            <font-awesome-icon v-else icon="user" />
          </router-link>
        </template>

        <template #cell(name)="data">
          <router-link
            :to="{ name: 'crm-client-view', params: { id: data.item.id } }"
            class=""
          >
            {{ getClientName(data.item) }}
            <div class="client-organization">
              {{ getClientOrganization(data.item) }}
            </div>
          </router-link>
        </template>

        <template #cell(phone)="data">
          {{ getClientFirstPhone(data.item) }}
        </template>
        <template #cell(email)="data">
          {{ getClientFirstEmail(data.item) }}
        </template>
        <template #cell(manager)="data">
          {{ getUserName(data.item.manager) }}
        </template>
        <template #cell(numerationType)="data">
          {{ getNumerationType(data.item) }}
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getUserName } from "../../common";
import {
  getClientFirstEmail,
  getClientFirstPhone,
  getClientName,
  getClientOrganization,
  getNumerationTypeTitle,
  TYPE_EMPLOYEE,
  TYPE_ORGANIZATION,
} from "@/modules/crm/decorators/client";

export default {
  name: "CrmClientList",
  components: {},
  data() {
    return {
      c_TYPE_ORGANIZATION: TYPE_ORGANIZATION,
      c_TYPE_PERSON: TYPE_EMPLOYEE,
      clients: [{}],
      search: {
        name: "",
        phone: "",
        managerId: "",
      },
      managersList: [],

      loading: true,
      archived: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "icon",
          label: "",
          sortable: false,
        },
        {
          key: "name",
          label: "Имя",
          sortable: false,
        },
        {
          key: "phone",
          label: "Телефон",
          sortable: false,
        },
        {
          key: "email",
          label: "E-mail",
          sortable: false,
        },
        {
          key: "numerationType",
          label: "Нумерация",
          sortable: false,
        },
        this.canManageClients
          ? {
              key: "manager",
              label: "Менеджер",
              sortable: false,
            }
          : {},
      ],
      header: "Пользователи",
      isGroupListVisible: false,
      sampleGroupIndex: -1,
      sampleGroup: "",
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {
    this.fetchManagers();
  },
  methods: {
    getClientName,
    getClientOrganization,
    getUserName,
    getClientFirstPhone,
    getClientFirstEmail,
    getNumerationType: getNumerationTypeTitle,
    fetchManagers: function () {
      this.managersList = [];
      this.managersList.push({ value: "", text: "Все" });
      let usersUrl = "/users/admin/";
      let params = {
        page: this.page,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.managersList.push({
              value: item.id,
              text: this.getUserName(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchData: function () {
      this.loading = true;

      let clientsUrl = this.archived ? "/crm/clients/archived" : "/crm/clients";
      let params = {
        page: this.page,
        searchNames: this.search.name,
        searchContacts: this.search.phone,
        filterManagerId: this.search.managerId,
      };
      this.$http
        .get(clientsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.clients = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "crm-client-list",
        params: { page: pageNum },
      };
    },
    canManageClients() {
      return this.logged && this.$store.state.user.access.canManageClients;
    },
    clientRowClass(client, type) {
      if (!client) {
        return "";
      }
      if (!client.deadline) {
        return "";
      }
      if (new Date(client.softDeadline) < new Date()) {
        return "table-warning";
      }
      if (new Date(client.deadline) < new Date()) {
        return "table-danger";
      }
    },
    showArchivedClients() {
      this.archived = true;
      this.fetchData();
    },
    showActiveClients() {
      this.archived = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.client-organization {
  font-size: smaller;
  color: gray;
}
</style>
