
<div class="contact-card">
  <contact-view
    v-if="!updating"
    :client="client"
    :is-last="isLast"
    @delete-contact="clientDeleted"
  />
  <contact-form
    v-else
    :client-model="client"
    :data-loaded="true"
    @cancel-updating="cancelUpdate"
    @update-client-info="clientUpdated"
  />
</div>
