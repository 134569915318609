
<div class="ibox">
  <div class="ibox-title d-flex align-items-center">
    <h1>Сообщения</h1>
    <div class="ml-2">
      <router-link
        :to="{ name: 'notifications-message-create' }"
        class="btn btn-primary"
      >
        <font-awesome-icon icon="plus" />
        Отправить сообщение
      </router-link>
    </div>
  </div>
  <div class="ibox-content">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Время</th>
          <th>Отправитель</th>
          <th>Сообщение</th>
          <th>Получатели</th>
        </tr>
      </thead>
      <tbody v-if="messages.length">
        <tr v-for="(message, mIndex) in messages" :key="'m' + mIndex">
          <td>{{ getMessageCreateDatetime(message) }}</td>
          <td>{{ getMessageUserName(message) }}</td>
          <td>{{ message.text }}</td>
          <td>
            <div v-if="message.allMskSelected">
              Всем в Москве: {{ message.allMskCount }} чел.
            </div>
            <div v-if="message.allSpbSelected">
              Всем в Санкт-Петербурге: {{ message.allSpbCount }} чел.
            </div>
            <div v-if="message.loading">
              <font-awesome-icon icon="rotate" spin />
            </div>
            <div v-else>
              <div
                v-if="message.users"
                class="d-flex flex-wrap align-items-center"
              >
                <div
                  v-for="(info, uIndex) in message.users"
                  :key="'i' + uIndex"
                  class="mr-2 recipient"
                >
                  <font-awesome-icon v-if="info.readTime" icon="check" />
                  <font-awesome-icon v-else icon="arrow-right" />
                  {{ getUserName(info.user) }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <th colspan="4">Нет отправленных сообщений</th>
        </tr>
      </tbody>
    </table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
