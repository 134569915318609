
<div class="ibox">
  <div class="ibox-title d-flex align-items-center pr-4">
    <h1>Цены</h1>
    <div class="ml-2">
      <router-link
        :to="{ name: 'pest-price-create' }"
        class="btn btn-primary"
      >
        <font-awesome-icon icon="edit" />
        Изменить цены
      </router-link>
    </div>
  </div>
  <div class="ibox-content">
    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <b-form-group label="Город" prop="townIds" class="mr-2">
          <town-autocomplete
            ref="townAutocomplete"
            required=""
            :default-value="search.townId ? getTownTitle(search.town) : ''"
            @selected="selectTown"
          ></town-autocomplete>
        </b-form-group>
        <b-form-group label="Тип вредителя" prop="title" class="mr-2">
          <pest-autocomplete
            v-model="search.pestId"
            :default-value="getRequestPestName(search)"
            @selected="selectPest"
          ></pest-autocomplete>
        </b-form-group>
        <b-form-group label="Объект" prop="title" class="mr-2">
          <facility-autocomplete
            v-model="search.facilityId"
            :add-new="true"
            :default-value="getRequestFacilityName(search)"
            @selected="selectFacility"
          ></facility-autocomplete>
        </b-form-group>
        <b-form-group label="Физ\Юр. лицо" prop="title" class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getClientTypeClass(null)"
              @click.prevent="selectClientType(null)"
            >
              Все
            </button>
            <button
              class="btn"
              :class="getClientTypeClass(CLIENT_TYPE_IND)"
              @click.prevent="selectClientType(CLIENT_TYPE_IND)"
            >
              Физ. лицо
            </button>
            <button
              class="btn"
              :class="getClientTypeClass(CLIENT_TYPE_ORG)"
              @click.prevent="selectClientType(CLIENT_TYPE_ORG)"
            >
              Организация
            </button>
          </b-button-group>
        </b-form-group>
        <b-form-group label="Тип оплаты" prop="title" class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getClientPaymentTypeClass(null)"
              @click.prevent="selectClientPaymentType(null)"
            >
              Все
            </button>
            <button
              class="btn"
              :class="getClientPaymentTypeClass(PT_CASH)"
              @click.prevent="selectClientPaymentType(PT_CASH)"
            >
              Нал
            </button>
            <button
              class="btn"
              :class="getClientPaymentTypeClass(PT_CASHLESS)"
              @click.prevent="selectClientPaymentType(PT_CASHLESS)"
            >
              Безнал
            </button>
          </b-button-group>
        </b-form-group>

        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.total }}
        </div>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Город"
              param="townId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Тип вредителя"
              param="pestId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Объект"
              param="facilityId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Физ\Юр. лицо"
              param="clientType"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Тип оплаты"
              param="paymentType"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Стоимость"
              param="price"
              @change="fetchData"
            />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(price, index) in prices" :key="index">
          <td>{{ getTownTitle(price.town) }}</td>
          <td>{{ getRequestPestName(price) }}</td>
          <td>{{ getRequestFacilityName(price) }}</td>
          <td>
            {{ price.clientType == CLIENT_TYPE_ORG ? "Юр. лицо" : "Физ" }}
          </td>
          <td>{{ price.paymentType == PT_CASHLESS ? "Безнал" : "Нал" }}</td>
          <td>{{ price.price }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
