
<div>
  <strong>Рекорд:</strong> {{ log.hasRecord ? "Да" : "Нет" }} <br />
  <strong>Стажер:</strong> {{ log.wereNovice ? "Да" : "Нет" }} <br />
  <strong>Процент:</strong> {{ log.percent }}% <br />
  <strong>Комментарий:</strong> {{ log.comment }} <br />
  <strong>Комментарий:</strong> {{ log.comment }} <br />
  <ul
    v-if="request.resultFiles && request.resultFiles.lenght"
    class="files-block mb-2"
  >
    <li v-for="(file, fIndex) in request.resultFiles" :key="'i' + fIndex">
      <a
        v-b-tooltip.hover
        class="ml-2"
        :href="file.url"
        :title="file.name"
        target="_blank"
      >
        {{ file.name + "." + file.extension }}
      </a>
    </li>
  </ul>
</div>
