
<b-overlay :show="loading" rounded="sm">
  <b-form
    id="login-form"
    ref="loginForm"
    :model="loginForm"
    label-position="top"
    class="form--login"
    :show-message="false"
    novalidate
    @submit="submit"
  >
    <b-form-group label="Логин" prop="login">
      <b-form-input v-model="loginForm.login" required></b-form-input>
      <b-form-invalid-feedback>Введите логин</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Пароль" prop="password">
      <b-form-input
        v-model="loginForm.password"
        type="password"
        auto-complete="off"
        required
      ></b-form-input>
      <b-form-invalid-feedback>Введите пароль</b-form-invalid-feedback>
    </b-form-group>

    <div class="text-center">
      <b-button variant="primary" @click="submit">Войти</b-button>
    </div>
  </b-form>
</b-overlay>
