
<b-overlay :show="loadingContacts" rounded="sm">
  <div class="d-flex flex-wrap mt-2">
    <div
      v-for="(contact, cIndex) in contacts"
      :key="cIndex"
      class="mr-4 mb-4"
    >
      <div class="ibox mb-0">
        <div class="ibox-content contact-card-wrapper pb-2">
          <contact-card
            :client="contact"
            :is-last="contacts.length < 2"
            @update-client-info="clientUpdated"
            @cancel-updating="cancelUpdate"
            @client-deleted="clientDeleted"
          />
        </div>
      </div>
    </div>
  </div>
</b-overlay>
