import {
  CPO_SOURCE_NONE,
  CPO_SOURCE_ROISTAT,
} from "@/modules/pest/decorators/sources";
import { CAT_ORG } from "@/modules/pest/decorators/facilities";

export const PERIOD_DAILY = 1;
export const PERIOD_3_DAYS = 3;
export const PERIOD_7_DAYS = 7;
export const PERIOD_30_DAYS = 30;
export const PERIOD_MONTH = "month";

export const chartPeriodOptions = [
  { value: PERIOD_DAILY, text: "Показывать по дням" },
  { value: PERIOD_3_DAYS, text: "Группировать по 3 дня" },
  { value: PERIOD_7_DAYS, text: "Группировать по неделям" },
  { value: PERIOD_30_DAYS, text: "Группировать по 30 дней" },
  { value: PERIOD_MONTH, text: "Группировать по месяцам" },
];

export const getNetCostAssigneeSum = function (
  assigneeInfo,
  sourceInfo,
  pestInfo
) {
  if (sourceInfo.sourceId === CPO_SOURCE_ROISTAT) {
    return (
      assigneeInfo.netCostSum +
      assigneeInfo.successCount * getSourceCPO(sourceInfo, pestInfo)
    );
  } else {
    return assigneeInfo.netCostSum;
  }
};
export const getSourceTitle = function (source) {
  if (source.sourceId === CPO_SOURCE_NONE) {
    return "[Без источника]";
  }
  if (source.sourceId === CPO_SOURCE_ROISTAT) {
    return "Ройстат";
  }
  if (source.sourceName) {
    return source.sourceName;
  }
};

export const getProfitAssigneeSum = function (
  assigneeInfo,
  sourceInfo,
  pestInfo
) {
  return (
    assigneeInfo.successTotalSum -
    getNetCostAssigneeSum(assigneeInfo, sourceInfo, pestInfo)
  );
};
export const getSourceCPO = function (sourceInfo, pestInfo) {
  if (sourceInfo.sourceId === CPO_SOURCE_ROISTAT) {
    let adSum = pestInfo.adSum;
    return adSum / sourceInfo.successCount;
  } else {
    return 0;
  }
};
export const getSourceCPL = function (sourceInfo, pestInfo) {
  if (sourceInfo.sourceId === CPO_SOURCE_ROISTAT) {
    return pestInfo.adSum / sourceInfo.count;
  } else {
    return 0;
  }
};
export const getNetCostSourceSum = function (sourceInfo, pestInfo) {
  if (sourceInfo.sourceId === CPO_SOURCE_ROISTAT) {
    return pestInfo.adSum + sourceInfo.netCostSum;
  } else {
    return sourceInfo.netCostSum;
  }
};
export const getProfitSourceSum = function (sourceInfo, pestInfo) {
  if (sourceInfo.sourceId === CPO_SOURCE_ROISTAT) {
    return sourceInfo.profitSum - pestInfo.adSum;
  } else {
    return sourceInfo.profitSum;
  }
};
export const getSourceROI = function (sourceInfo, pestInfo) {
  let totalFirstPlusSecondSuccessSum =
    sourceInfo.successTotalSum + sourceInfo.secondarySuccessTotalSum;
  let totalFirstPlusSecondNetCost = 0;
  if (sourceInfo.sourceId === CPO_SOURCE_ROISTAT) {
    totalFirstPlusSecondNetCost =
      getNetCostSourceSum(sourceInfo, pestInfo) +
      sourceInfo.secondaryNetCostSum;
  } else {
    totalFirstPlusSecondNetCost =
      sourceInfo.netCostSum + sourceInfo.secondaryNetCostSum;
  }
  if (totalFirstPlusSecondNetCost > 0) {
    let roi =
      ((totalFirstPlusSecondSuccessSum - totalFirstPlusSecondNetCost) /
        totalFirstPlusSecondNetCost) *
      100;
    return roi;
  } else {
    return 0;
  }
};

export const getConversionToSecondary = function (secondaryCount, count) {
  if (count > 0) {
    return (secondaryCount / count) * 100;
  }
  return 0;
};
export const getAssigneeROI = function (assigneeInfo, sourceInfo, pestInfo) {
  let totalFirstPlusSecondSuccessSum =
    assigneeInfo.successTotalSum + assigneeInfo.secondarySuccessTotalSum;
  let totalFirstPlusSecondNetCost =
    getNetCostAssigneeSum(assigneeInfo, sourceInfo, pestInfo) +
    assigneeInfo.secondaryNetCostSum;
  if (totalFirstPlusSecondNetCost > 0) {
    let roi =
      ((totalFirstPlusSecondSuccessSum - totalFirstPlusSecondNetCost) /
        totalFirstPlusSecondNetCost) *
      100;
    return roi.toFixed(2);
  } else {
    return 0;
  }
};

export const getNetCostSum = function (info, parentInfo) {
  let cpo;
  let entered = false;
  if (parentInfo) {
    cpo = parentInfo.cpo
      ? parentInfo.cpo
      : info.cpo
      ? info.cpo
      : parentInfo.cpoDirect
      ? parentInfo.cpoDirect
      : 0;
    entered = parentInfo.cpo || info.cpo;
  } else {
    cpo = info.cpo ? info.cpo : info.cpoDirect ? info.cpoDirect : 0;
    entered = info.cpo || info.cpoDirect;
  }
  return info.netCostSum + (entered ? cpo * info.successCount : info.adSum);
};

export const getProfitSum = function (info, parentInfo) {
  let cpo;
  let entered = false;
  if (parentInfo) {
    cpo = parentInfo.cpo
      ? parentInfo.cpo
      : info.cpo
      ? info.cpo
      : parentInfo.cpoDirect
      ? parentInfo.cpoDirect
      : 0;
    entered = parentInfo.cpo || info.cpo;
  } else {
    cpo = info.cpo ? info.cpo : info.cpoDirect ? info.cpoDirect : 0;
    entered = info.cpo || info.cpoDirect;
  }
  return info.profitSum - (entered ? cpo * info.successCount : info.adSum);
};

export const getTotalProfitSum = function (pestInfo, sourceInfo, assigneeInfo) {
  if (assigneeInfo) {
    return (
      getProfitAssigneeSum(assigneeInfo, sourceInfo, pestInfo) +
      assigneeInfo.secondaryProfitSum
    );
  }
  if (sourceInfo) {
    return (
      getProfitSourceSum(sourceInfo, pestInfo) + sourceInfo.secondaryProfitSum
    );
  }
  return getProfitSum(pestInfo) + pestInfo.secondaryProfitSum;
};
export const getROI = function (info) {
  let totalFirstPlusSecondSuccessSum =
    info.successTotalSum + info.secondarySuccessTotalSum;
  let totalFirstPlusSecondNetCost =
    getNetCostSum(info) + info.secondaryNetCostSum;
  if (totalFirstPlusSecondNetCost > 0) {
    let roi =
      ((totalFirstPlusSecondSuccessSum - totalFirstPlusSecondNetCost) /
        totalFirstPlusSecondNetCost) *
      100;
    return roi.toFixed(2);
  } else {
    return 0;
  }
};

const cpoVal = function (attr, info, sourceInfo = null, assigneeInfo = null) {
  if (assigneeInfo) {
    return assigneeInfo[attr];
  }
  if (sourceInfo) {
    return sourceInfo[attr];
  }
  return info[attr];
};
export const CPOParams = [
  {
    title: "ROI,%",
    attr: "roi",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      if (assigneeInfo) {
        return getAssigneeROI(assigneeInfo, sourceInfo, pestInfo);
      }
      if (sourceInfo) {
        return getSourceROI(sourceInfo, pestInfo);
      }
      return getROI(pestInfo);
    },
  },
  {
    title: "СРО успех первичка",
    attr: "cpoDirect",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      if (assigneeInfo) {
        return null;
      }
      if (sourceInfo) {
        return getSourceCPO(sourceInfo, pestInfo);
      }
      return pestInfo.cpoDirect;
    },
  },
  {
    title: "CPL первичка",
    attr: "cpl",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      if (assigneeInfo) {
        return null;
      }
      if (sourceInfo) {
        return getSourceCPL(sourceInfo, pestInfo);
      }
      return pestInfo.count ? pestInfo.adSum / pestInfo.count : 0;
    },
  },
  {
    title: "Общее количество первичных сделок",
    attr: "count",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("count", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Кол-во незакрытых первичных сделок",
    attr: "newCount",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("newCount", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Количество проваленных первичных сделок",
    attr: "failedCount",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("failedCount", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Средний чек успешной первичной сделки",
    attr: "successAverageSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("successAverageSum", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Общая себестоимость первичных сделок",
    attr: "totalNetCostSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      if (assigneeInfo) {
        return getNetCostAssigneeSum(assigneeInfo, sourceInfo, pestInfo);
      }
      if (sourceInfo) {
        return getNetCostSourceSum(sourceInfo, pestInfo);
      }
      return getNetCostSum(pestInfo);
    },
  },
  {
    title: "Общая чистая прибыль первичных сделок",
    attr: "totalFirstProfitSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      if (assigneeInfo) {
        return getProfitAssigneeSum(assigneeInfo, sourceInfo, pestInfo);
      }
      if (sourceInfo) {
        return getProfitSourceSum(sourceInfo, pestInfo);
      }
      return getProfitSum(pestInfo);
    },
  },
  {
    title: "Общее количество повторных сделок",
    attr: "secondaryCount",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("secondaryCount", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Кол-во незакрытых повторных сделок",
    attr: "secondaryNewCount",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("secondaryNewCount", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Количество проваленных повторных сделок",
    attr: "secondaryFailedCount",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("secondaryFailedCount", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Средний чек успешной повторной сделки",
    attr: "secondarySuccessAverageSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal(
        "secondarySuccessAverageSum",
        pestInfo,
        sourceInfo,
        assigneeInfo
      );
    },
  },
  {
    title: "Общая себестоимость повторных сделок",
    attr: "secondaryNetCostSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("secondaryNetCostSum", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Общая чистая прибыль повторных сделок",
    attr: "secondaryProfitSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return cpoVal("secondaryProfitSum", pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Итоговая чистая прибыль",
    attr: "totalProfitSum",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      return getTotalProfitSum(pestInfo, sourceInfo, assigneeInfo);
    },
  },
  {
    title: "Конверсия в повторные продажи, %",
    attr: "conversionToSecondary",
    val: (pestInfo, sourceInfo = null, assigneeInfo = null) => {
      if (assigneeInfo) {
        return getConversionToSecondary(
          assigneeInfo.secondaryCount,
          assigneeInfo.count
        );
      }
      if (sourceInfo) {
        return getConversionToSecondary(
          sourceInfo.secondaryCount,
          sourceInfo.count
        );
      }
      return getConversionToSecondary(pestInfo.secondaryCount, pestInfo.count);
    },
  },
];
