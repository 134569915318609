/**
 * Причины смены менеджера в логах
 */

export const CHANGE_MANAGER_IMPORT_DUPLICATE = "Import duplicate from roistat";
export const CHANGE_MANAGER_CAUSE_OPENING_CALL = "Opening call";
export const CHANGE_MANAGER_CAUSE_LOADING_FROM_BITRIX = "Loading from bitrix";
export const CHANGE_MANAGER_CAUSE_UPDATING_REQUEST = "Updating request";
export const CHANGE_MANAGER_CAUSE_CHANGING_STATUS = "Changing status";
export const CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS = "Loading from logs";
export const CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS_FIX =
  "Loading from logs - fix";

export const causeTitles = {
  [CHANGE_MANAGER_IMPORT_DUPLICATE]: "Загружен дубль из ройстатаs",
  [CHANGE_MANAGER_CAUSE_OPENING_CALL]: "Открытие заявки",
  [CHANGE_MANAGER_CAUSE_CHANGING_STATUS]: "Смена статуса",
  [CHANGE_MANAGER_CAUSE_LOADING_FROM_BITRIX]: "Загружено из битрикса",
  [CHANGE_MANAGER_CAUSE_UPDATING_REQUEST]: "Редактирование вручную",
  [CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS]: "Проставлено из логов",
  [CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS_FIX]:
    "Проставлено из логов (итерация 2)",
};

export const getCauseTitle = function (log) {
  if (!log.cause) {
    return "[Причина не указана]";
  }
  return causeTitles[log.cause];
};
