/**
 * Форматирование телефона
 */
export const formatPhone = function (phone) {
  if (phone.length === 11) {
    // '71231231234'
    phone = "+" + phone;
  }
  return (
    phone.substring(0, 2) +
    "-" +
    phone.substring(2, 5) +
    "-" +
    phone.substring(5, 8) +
    "-" +
    phone.substring(8, 12)
  );
};
