
<div class="ibox">
  <div class="ibox-title">События в системе</div>
  <div class="ibox-content">
    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <div class="mr-2">
          <b-form-group label="Дата от">
            <datepicker
              v-model="dateFrom"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              @change="fetchData"
            ></datepicker>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Дата до">
            <datepicker
              v-model="dateTo"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              @change="fetchData"
            ></datepicker>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Номер заявки">
            <b-form-input v-model="requestNumber" tabindex="1"></b-form-input>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Событие">
            <b-form-select
              v-model="type"
              :options="typeOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Пользователь">
            <user-autocomplete
              v-model="userId"
              :default-value="userId ? getAssigneeName(user, true) : ''"
              @selected="selectUser"
            />
          </b-form-group>
        </div>

        <div class="align-self-center mt-2 mr-2">
          <button class="btn btn-info mr-2" @click.prevent="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </button>

          <button
            class="btn btn-outline-info mr-2"
            @click.prevent="resetFilter"
          >
            Сбросить
          </button>
        </div>
      </div>
    </div>

    <div v-if="!logs.length && !loading">Еще нет записей</div>
    <div v-else class="table-responsive">
      <b-overlay :show="loading" rounded="sm">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="col-1">Время</th>
              <th class="col-1">Заявка</th>
              <th class="col-2">Пользователь</th>
              <th class="col-3">Событие</th>
              <th class=" ">Описание</th>
            </tr>
          </thead>
          <tbody>
            <request-log
              v-for="(log, index) in logs"
              :key="index"
              :show-request="true"
              :log="log"
            />
          </tbody>
        </table>
      </b-overlay>

      <b-pagination-nav
        v-if="pagination.count > 1"
        :link-gen="linkGen"
        :value="pagination.page"
        :number-of-pages="pagination.count"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </div>
</div>
