<template>
  <b-form
    id="pest-reject-cause-form"
    ref="rejectCause"
    :model="rejectCause"
    class="pest-reject-cause-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="rejectCause.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="rejectCause.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-checkbox v-model="rejectCause.other" value="1">
            Другие (показывать
          </b-form-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="rejectCause.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteRejectCause"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getTownTitle } from "@/modules/pest/decorators/towns";

export default {
  name: "PestRejectCauseForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["rejectCause"],
  data() {
    return {
      phoneMask: false,
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.rejectCause.id;
    },
    name() {
      let name = this.rejectCause.name
        ? this.rejectCause.name
        : "Новый причина";
      return name ? name : "Причина";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    getTownTitle,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/reject-causes/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.rejectCause)
        .then((response) => {
          this.rejectCause = response.data;
          this.loading = false;
          this.$eventHub.$emit(
            "update-pest-reject-cause-info",
            this.rejectCause
          );
          // this.$router.push({name: 'pest-reject-cause-view', params: {id: this.rejectCause.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Причина отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/reject-causes";
      this.loading = true;
      this.$http
        .post(updateUrl, this.rejectCause)
        .then((response) => {
          this.rejectCause = response.data;
          this.$eventHub.$emit(
            "update-pest-reject-cause-info",
            this.rejectCause
          );
          this.$router.push({
            name: "pest-reject-cause-update",
            params: { id: this.rejectCause.id },
          });
          this.$bvToast.toast("Причина добавлен", {
            title: "Причина добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить причина",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-reject-cause-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteRejectCause() {
      if (confirm("Вы действительно хотите удалить причина?")) {
        let deleteUrl = "/pest/reject-causes/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast("Причина удален", {
              title: "Причина удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить причина",
                variant: "danger",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
