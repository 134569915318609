<template>

  <b-form :model="document" id="document-form" ref="document" class="document-form "
          @submit="createOrUpdate" @reset="onReset"
          novalidate>
    <div class="div">

      <b-form-file
          v-model="files"
          :state="Boolean(files)"
          ref="file"
          multiple
          placeholder="Выберите файлы или перенесите их сюда"
          drop-placeholder="Перенесите сюда"
          browse-text="Выбрать"
          @change="handleFileChange"
      ></b-form-file>


      <div v-if="files" class="mt-2">
        <table class="table table-striped">
          <thead>
          <tr>
            <!--            <td>Файл</td>-->
            <td>Название</td>
            <td>Тип</td>
            <td>Комментарий</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fileInfo, index) in info" :key="index">
            <!--            <td class="file-title">-->
            <!--              {{ fileInfo.sourceName }}-->
            <!--            </td>-->
            <td>
              <b-form-input v-model="fileInfo.name"></b-form-input>
            </td>
            <td>
              <b-form-select v-model="fileInfo.type" :options="fileTypes"></b-form-select>
            </td>
            <td>
              <b-form-input v-model="fileInfo.comment"></b-form-input>
            </td>
          </tr>

          </tbody>

        </table>
      </div>

    </div>

  </b-form>
</template>

<script>

import {VueMaskDirective} from 'v-mask'
import {getTypeTitle} from "@/modules/papers/decorators/types";

export default {
  directives: {mask: VueMaskDirective},
  name: 'document-form',
  components: {},
  props: [
    'document',
    'folderId',
  ],
  data() {
    return {
      files: [],
      info: [],
      fileTypes: [],
      // folderId: '',
      loading: false,
      submitButton: 'Сохранить',
    }
  },
  computed: {
    id() {
      return this.document.id;
    },
    name() {
      let name = this.document.name ? this.document.name : 'Новая задача';
      return name ? name : 'Задача';
    }
  },
  created: function () {
    this.fetchTypes();
  },
  watch: {},
  methods: {
    getTypeTitle,
    fetchTypes() {
      this.fileTypes = [];
      this.fileTypes.push({value: '', text: 'Выберите тип'});
      let usersUrl = '/papers/types';
      let params = {
        page: this.page
      };
      this.$http.get(usersUrl, {
        params: params
      }).then(response => {
        for (let item of response.data) {
          this.fileTypes.push({value: item.id, text: this.getTypeTitle(item)});
        }
      }).catch(error => {
        this.processRequestError(error);
      })
    },

    chooseFiles() {
      this.$refs.file.click()
    },
    handleFileChange(event) {
      this.info = [];
      for (let file of event.target.files) {
        this.info.push({
          sourceName: file.name,
          name: file.name,
          type: '',
          comment: '',
          // file: file,
        });
      }
    },
    update: function () {
      let updateUrl;
      updateUrl = '/papers/documents/' + this.id;
      this.loading = true;
      this.$http.put(updateUrl, this.document).then(response => {
        this.document = response.data;
        this.loading = false;
        this.$eventHub.$emit('update-document-info', this.document);
        this.$router.push({name: 'document-type-view', params: {id: this.document.id}});
        this.$bvToast.toast('Данные сохранены', {
          title: 'Задача отредактирована',
          type: 'success'
        })
      }).catch(error => {
        this.loading = false;
        if (!this.processRequestError(error)) {
          this.$bvToast.toast('Данные не сохранены', {
            title: 'Не удалось сохранить данные',
            type: 'danger'
          })
        }
      })
    },
    create: function (successCallback, errorCallback) {
      let updateUrl;
      updateUrl = '/papers/documents/upload';
      this.loading = true;
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append('files[' + i + ']', file);
      }
      for (let i = 0; i < this.info.length; i++) {
        let elInfo = this.info[i];
        formData.append('info[' + i + '][sourceName]', elInfo.sourceName);
        formData.append('info[' + i + '][name]', elInfo.name);
        formData.append('info[' + i + '][type]', elInfo.type);
        formData.append('info[' + i + '][comment]', elInfo.comment);
      }
      formData.append('folderId', this.folderId);
      this.$http.post(updateUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + this.$store.state.user.authKey
        }
      }).then(response => {
        this.document = response.data;
        // this.$eventHub.$emit('update-document-info', this.document);
        // this.$router.push({name: 'document-type-view', params: {id: this.document.id}});
        this.$bvToast.toast('Документ добавлен', {
          title: 'Документ добавлен',
          variant: 'success'
        });
        if (successCallback) {
          successCallback();
        }
      }).catch(error => {
        this.loading = false;
        if (!this.processRequestError(error)) {
          this.$bvToast.toast('Ошибка', {
            title: 'Не удалось добавить документ',
            variant: 'danger'
          })
        }
        if (errorCallback) {
          errorCallback();
        }

      })
    },
    createOrUpdate: function (event, successCallback, errorCallback) {
      event.preventDefault();
      let form = document.getElementById('document-form');
      let valid = form.checkValidity();
      form.classList.add('was-validated');
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast('Данные сохраняются', {
            title: 'Подождите',
            variant: 'info'
          });
          return;
        }
        let modal = this;
        this.create(successCallback, errorCallback)
        // if (this.id === 'new') {
        // } else {
        //   this.update()
        // }
        form.classList.remove('was-validated');
      } else {
        this.$bvToast.toast('В форме содержатся ошибки', {
          variant: 'danger'
        });
        return false
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
  }
}
</script>

<style lang="scss">
.file-title {
  word-break: break-all;
}

.custom-file-label {
  overflow: hidden;
}
</style>
