
<div>
  <h2>
    Мои задачи
    <small>
      <router-link
        :to="{ name: 'task-create' }"
        class="btn btn-primary btn-sm"
      >
        <font-awesome-icon icon="plus" />
        Добавить задачу
      </router-link>
    </small>
  </h2>
  <div class="mb-2"></div>
  <div>
    <b-button
      :variant="completed ? 'outline-info' : 'info'"
      class="btn"
      @click="showActiveTasks"
    >
      Делаю
    </b-button>

    <b-button
      :variant="!completed ? 'outline-info' : 'info'"
      class="btn ml-1"
      @click="showCompletedTasks"
    >
      Завершенные
    </b-button>
  </div>

  <b-table
    v-if="tasks.length"
    responsive
    striped
    hover
    condensed
    :items="tasks"
    :fields="fields"
    :busy="loading"
    :tbody-tr-class="taskRowClass"
  >
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle table-spinner"></b-spinner>
        <strong> Данные подгружаются</strong>
      </div>
    </template>

    <template #cell(title)="data">
      <router-link
        :to="{ name: 'task-view', params: { id: data.item.id } }"
        class=""
      >
        {{ data.item.title }}
      </router-link>
    </template>

    <template #cell(assignee)="data">
      {{ getUserName(data.item.author) }}
    </template>

    <template #cell(author)="data">
      {{ getUserName(data.item.assignee) }}
    </template>

    <template #cell(deadline)="data">
      {{ getTaskDeadline(data.item) }}
    </template>

    <template #cell(completeTime)="data">
      {{ getTaskCompleteTime(data.item) }}
    </template>

    <template #cell(complete)="data">
      <b-button
        v-if="!data.item.completed"
        class="btn-xs"
        variant="primary"
        @click="completeTask(data.item)"
      >
        <font-awesome-icon v-if="!data.item.loading" icon="check" />
        <font-awesome-icon v-else icon="rotate" spin />
      </b-button>
      <b-button
        v-else
        class="btn-xs"
        variant="primary"
        @click="restoreTask(data.item)"
      >
        <font-awesome-icon v-if="!data.item.loading" icon="box" />
        <font-awesome-icon v-else icon="rotate" spin />
      </b-button>
    </template>
  </b-table>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
