
<div>
  <div v-if="showSuccess" class="panel panel-primary mb-4">
    <div class="panel-heading">Подтверждение</div>
    <div class="panel-body">Сумма депозита успешно изменена</div>
    <button class="btn btn-primary mt-2" @click="closeSuccess">
      Закрыть
    </button>
  </div>

  <div v-else class="ibox">
    <div class="ibox-content">
      <div>
        <div class="d-flex align-items-center">
          <div class=""><strong> Депозит:</strong></div>
          <div class="h5 pt-0 mt-0 mr-1 ml-auto">
            <span v-if="loading">Загружается</span>
            <span v-else>{{ formatNumber(pledgeGoal) }} руб.</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class=""><strong> Накоплено:</strong></div>
          <div class="h5 pt-0 mt-0 mr-1 ml-auto">
            <span v-if="loading">Загружается</span>
            <span v-else>{{ formatNumber(pledgeSum) }} руб.</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class=""><strong> Осталось накопить:</strong></div>
          <div class="h5 pt-0 mt-0 mr-1 ml-auto">
            <span v-if="loading">Загружается</span>
            <span v-else>{{ formatNumber(pledgeDiff) }} руб.</span>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <b-button
          v-if="false && !showForm && canChangePledge"
          variant="primary"
          @click.prevent="showForm = true"
        >
          Изменить сумму депозита
        </b-button>

        <b-button
          v-if="false && !showForm && !canChangePledge"
          variant="default"
          @click.prevent="showForm = true"
        >
          Изменить сумму депозита
        </b-button>
      </div>
    </div>
  </div>
</div>
