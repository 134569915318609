<template>
  <div>
    <strong>Старая дата: </strong> {{ getDateTime(log.oldTime) }} <br />
    <strong>Новая дата: </strong> {{ getDateTime(log.newTime) }}<br />
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "RequestLogChangedDate",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getDateTime(time) {
      return dayjs.unix(time).utcOffset("+03:00").format("DD.MM.YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
