
<b-form
  ref="settings"
  :model="settings"
  class="settings-form"
  @submit="createOrUpdate"
>
  <!--    <h1>Настройки </h1>-->

  <b-tabs v-model="dataTabIndex" card>
    <b-tab title="Общее">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Заявки</h5>
            </div>
            <div class="ibox-content">
              <b-form-group
                label="Время в минутах до и после заявки на открытие номер квартиры"
              >
                <b-form-input
                  v-model="settings.timeIntervalMinutes"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Длительность заявки">
                <b-form-input
                  v-model="settings.requestDurationMinutes"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </b-tab>
    <b-tab title="Оплата и рекорд">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Рекорд</h5>
            </div>
            <div class="ibox-content">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group label="Текущий рекорд в Москве и области">
                    <b-form-input
                      v-model="settings.recordCurMskSum"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    label="Текущий рекорд в Санкт-Петербурге и области"
                  >
                    <b-form-input
                      v-model="settings.recordCurSpbSum"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <b-form-group label="Минимальная сумма рекорда">
                <b-form-input v-model="settings.recordSumMin"></b-form-input>
              </b-form-group>
              <b-form-group label="Максимальная сумма рекорда">
                <b-form-input v-model="settings.recordSumMax"></b-form-input>
              </b-form-group>
              <b-form-group label="Интервал увеличения рекорда">
                <b-form-input v-model="settings.recordSumInc"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Оплата</h5>
            </div>
            <div class="ibox-content">
              <b-form-group label="Процент оплаты">
                <b-form-input v-model="settings.earnPercent"></b-form-input>
              </b-form-group>
              <b-form-group label="Процент оплаты при рекорде">
                <b-form-input
                  v-model="settings.earnHigherPercent"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Стоимость аренды оборудования">
                <b-form-input
                  v-model="settings.equipmentPrice"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="ibox">
            <div class="ibox-title">
              <h5>Оплата стажерам</h5>
            </div>
            <div class="ibox-content">
              <b-form-group label="Количество рабочих смен">
                <b-form-input
                  v-model="settings.earnNoviceDays"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Процент оплаты">
                <b-form-input
                  v-model="settings.earnNovicePercent"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Процент оплаты при рекорде">
                <b-form-input
                  v-model="settings.earnNoviceHigherPercent"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Реферальная программа</h5>
            </div>
            <div class="ibox-content">
              <b-form-group label="Начисляемый процент командиру команды">
                <b-form-input
                  v-model="settings.teamHeadPercent"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </b-tab>
    <b-tab title="Депозит">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Депозит</h5>
            </div>
            <div class="ibox-content">
              <b-form-group label="Минимальный депозит для всех">
                <b-form-input v-model="settings.pledgeGoal"></b-form-input>
              </b-form-group>
              <b-form-group label="Лимит депозита, для блокировки мастера">
                <b-form-input
                  v-model="settings.pledgeLimitDiff"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Штраф за несдачу депозита">
                <b-form-input v-model="settings.pledgeFine"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="ibox">
            <div class="ibox-title">
              <h5>Магазины</h5>
            </div>
            <div class="ibox-content">
              <b-form-group label="Допустимая дистанция до магазина в метрах">
                <b-form-input v-model="settings.placeDistance"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-8">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Сумма депозита при заработке за смену:</h5>
            </div>
            <div class="ibox-content">
              <div
                v-for="(sRow, sIndex) in settings.pledgeSums"
                :key="sIndex"
                class="row"
              >
                <div class="col-3">
                  <b-form-group label="От">
                    <b-form-input v-model="sRow.from"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group label="До">
                    <b-form-input v-model="sRow.to"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group label="Сумма">
                    <b-input-group>
                      <b-form-input v-model="sRow.sum"></b-form-input>
                      <b-input-group-append>
                        <b-button
                          :variant="btnSumVariantClass(sRow, 'percent')"
                          @click.prevent="setSumVariant(sRow, 'percent')"
                          >%
                        </b-button>
                        <b-button
                          :variant="btnSumVariantClass(sRow, 'number')"
                          @click.prevent="setSumVariant(sRow, 'number')"
                        >
                          123
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-2 pt-4">
                  <button
                    class="btn btn-danger"
                    @click.prevent="removePledgeSum(sIndex)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>

              <button
                class="ml-auto btn btn-primary"
                @click.prevent="addPledgeSum"
              >
                <font-awesome-icon icon="plus" />
                Добавить
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-tab>
    <b-tab title="Статистика">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Затраты</h5>
            </div>
            <div class="ibox-content">
              <b-form-group label="CPO">
                <b-form-input v-model="settings.expensesCPO"></b-form-input>
              </b-form-group>
              <b-form-group label="Зарплата менеджера">
                <b-form-input
                  v-model="settings.expensesCharge"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Издержки офиса">
                <b-form-input
                  v-model="settings.expensesOffice"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>

  <div class=" ">
    <div class="ibox">
      <div class="ibox-content">
        <b-button variant="primary" @click="createOrUpdate"
          >{{ submitButton }}
        </b-button>
      </div>
    </div>
  </div>
</b-form>
