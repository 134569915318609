
<div class="ibox">
  <div class="ibox-title">
    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <h2 class="mr-2 d-none d-md-block">Статистика</h2>
        <div class="mr-2 mb-2">
          <a
            href="#"
            class="mr-2"
            :class="{ 'active-date': isToday }"
            @click.prevent="setToday"
          >
            Сегодня</a
          >
          <a
            href="#"
            class=""
            :class="{ 'active-date': isYesterday }"
            @click.prevent="setYesterday"
          >
            Вчера</a
          >
        </div>
        <div class="">
          <datepicker
            v-model="search.date"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
            @change="fetchData"
          ></datepicker>
        </div>
      </div>
      <div class="d-flex align-items-center"></div>
    </div>
  </div>
  <div class="ibox-content">
    <div v-if="loading">Данные загружаются</div>

    <div v-else-if="allStats">
      <ul v-if="allStats.hasTeammates" role="tablist" class="nav nav-tabs">
        <li role="presentation" class="nav-item">
          <a
            role="tab"
            aria-selected="false"
            aria-setsize="5"
            aria-posinset="1"
            href="#"
            target="_self"
            class="nav-link"
            :class="{ active: tab === 'my' }"
            tabindex="-1"
            @click.prevent="tab = 'my'"
            >Мои данные</a
          >
        </li>
        <li role="presentation" class="nav-item">
          <a
            role="tab"
            aria-selected="false"
            aria-setsize="5"
            aria-posinset="2"
            href="#"
            target="_self"
            class="nav-link"
            :class="{ active: tab === 'team' }"
            tabindex="-1"
            @click.prevent="tab = 'team'"
            >Моя команда</a
          >
        </li>
      </ul>
      <div v-if="tab === 'my'" class="pt-2">
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(fieldI, fIndex) in fields" :key="'f' + fIndex">
                <th class="label-cell">{{ fieldI.label }}</th>

                <td>
                  {{ formatNumber(allStats[fieldI.key]) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="tab === 'team'" class="pt-2">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Мастер</th>
                <th>Заявок</th>
                <th>Бонус</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(worker, iWorker) in allStats.teamStats"
                :key="'w' + iWorker"
              >
                <th>{{ worker.name }}</th>
                <th>{{ worker.count }}</th>
                <th>{{ worker.bonusSum }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else>Данных нет</div>
  </div>
</div>
