
<div class="data-record-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link
              :to="{ name: 'data-record-list', id: record.id }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Изменение вредителя
          </h1>
        </div>
        <div class="ibox-content">
          <data-record-form :record="record"></data-record-form>
        </div>
      </div>
    </div>
  </div>
</div>
