
<b-overlay :show="loading" rounded="sm">
  <b-form id="call-cause-form" ref="call-cause" class=" ">
    <b-form-group label="Статус" prop="statusId">
      <b-form-select
        v-model="call.callCauseId"
        :options="causesList"
        tabindex="1"
        required
      ></b-form-select>
    </b-form-group>
    <b-form-group
      v-if="call.callCauseId === -1"
      label="Описание"
      prop="title"
    >
      <b-form-textarea
        v-model="call.callCauseText"
        placeholder="Другая причина"
        tabindex="1"
        required
      ></b-form-textarea>
    </b-form-group>
  </b-form>
</b-overlay>
