
<datepicker
  ref="datepicker"
  v-model="date"
  :editable="true"
  :input-class="inputClass"
  :lang="lang"
  :format="dateFormat"
  :formatter="dayjsFormat"
  :placeholder="placeholder"
  prefix-class="xmx"
  type="datetime"
  @change="onChange"
>
</datepicker>
