<template>
  <div :data-counter="counter">
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="list-ol" fixed-width class="mr-1" />
        <!--              Описание-->
        <router-link
          v-if="!blocked || blocked !== BLOCKED"
          :to="{ name: 'pest-request-view', params: { id: request.id } }"
        >
          {{ getRequestNumber(request) }}
        </router-link>

        <span v-else> {{ getRequestNumber(request) }}</span>
      </div>
      <div class="ml-auto">
        <div class="handle">
          <font-awesome-icon icon="arrows-alt" fixed-width />
        </div>
      </div>
    </div>
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon
          v-if="request.isOrganization"
          icon="building"
          fixed-width
          class="mr-1"
        />
        <font-awesome-icon v-else icon="house-user" fixed-width class="mr-1" />
        <span> {{ getRequestClientName(request) }}</span>
      </div>
      <div
        v-if="
          request.stage === STAGE_DEAL &&
          !leadBoard &&
          canChangeAssignee &&
          request.status !== STATUS_DECLINE
        "
        class="ml-auto"
      >
        <a class="edit-assignee-link" @click.prevent="openAssigneePopup">
          <font-awesome-icon icon="user-cog" fixed-width />
        </a>
      </div>
    </div>

    <div v-if="leadBoard && request.stage === STAGE_DEAL" class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="user-cog" fixed-width class="mr-1" />
        <!-- Исполнитель-->
        {{ getRequestAssigneeName(request) }}
      </div>
      <div class="ml-auto">
        <a class="edit-assignee-link" @click.prevent="openAssigneePopup">
          <font-awesome-icon icon="edit" fixed-width />
        </a>
      </div>
    </div>

    <div
      class="mb-1"
      :class="{
        'text-success': request.isFirstTime,
        'text-info': !request.isFirstTime,
      }"
    >
      <font-awesome-icon icon="arrow-up-1-9" fixed-width class="mr-2" />
      <!--              Очередность -->
      <span v-if="request.isFirstTime">Первичное</span>
      <span v-else>Вторичное</span>
    </div>

    <div v-if="leadBoard || dispatchingBoard" class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="user-tie" fixed-width class="mr-1" />
        <!-- Исполнитель-->
        {{ getRequestManagerName(request) }}
      </div>
    </div>

    <div v-if="leadBoard" class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="link" fixed-width class="mr-1" />
        <!-- Источник-->
        {{ getRequestSourceName(request) }}
      </div>
    </div>

    <div v-if="leadBoard" class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="building" fixed-width class="mr-1" />
        <!-- Исполнитель-->
        {{ getRequestTownTitle(request) }}
      </div>
    </div>

    <div
      v-if="request.isOrganization && request.organizationTitle"
      class="mb-1"
    >
      <font-awesome-icon icon="building" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ request.organizationTitle }}
    </div>

    <div v-if="request.isOrganization && request.paymentType" class="mb-1">
      <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ getPaymentTypeTitle(request) }}
    </div>

    <div class="mb-1" :style="'color: ' + getRequestStatusColor(request)">
      <font-awesome-icon icon="tag" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ getRequestStatus(request) }}
    </div>

    <div v-if="false">
      <!-- Телефоны -->
      <div
        v-for="(pInfo, pIndex) in phoneList"
        :key="'ph' + pIndex"
        class="mb-1"
      >
        <font-awesome-icon icon="phone" fixed-width class="mr-1" />
        {{ pInfo.phone }}
      </div>
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="ruble-sign" fixed-width class="mr-1" />
      <!--              Сумма -->
      {{ getRequestDisplayableSum(request) }}

      <span v-if="request.earned"> / {{ request.earned }} </span>
      <span v-if="request.hasRecord">
        <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
      </span>

      <span v-if="request.isOrganization && request.paymentType == PT_CASHLESS">
        (Безнал)
      </span>
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="home" fixed-width class="mr-1" />
      <!-- Адрес -->
      {{ getRequestAddressFormatted(request) }}
    </div>

    <div v-if="request.subway" class="mb-1">
      <font-awesome-icon icon="map-marked-alt" fixed-width class="mr-1" />
      <!--              Метро -->
      {{ getSubwayTitle(request.subway) }}
    </div>
    <div
      v-if="request.pest"
      class="mb-1"
      :style="'color: ' + getRequestColor(request)"
    >
      <font-awesome-icon icon="bug" fixed-width class="mr-1" />
      <!--              Вредитель -->
      {{ getPestTitle(request.pest) }}
    </div>
    <div v-if="request.facility" class="mb-1">
      <font-awesome-icon icon="building" fixed-width class="mr-1" />
      <!--              Тип объекта -->
      {{ getFacilityTitle(request.facility) }}
    </div>
    <div v-if="request.town && false" class="mb-1">
      <font-awesome-icon icon="map-marker-alt" fixed-width class="mr-1" />
      <!--              Город -->
      {{ getRequestTownTitle(request) }}
    </div>
    <div class="mb-1 d-flex">
      <div>
        <!--              Время -->
        <font-awesome-icon icon="clock" fixed-width class="mr-1" />
        {{ getRequestDatetime(request) }}
      </div>
      <div class="ml-auto">
        <font-awesome-icon icon="stopwatch" fixed-width class="" />
        <a href="#" class="" @click.prevent="openTimePopup">
          {{ getRequestDuration(request) }}
        </a>
      </div>
    </div>

    <div v-if="request.warning" class="mb-1 text-warning">
      <font-awesome-icon icon="exclamation-triangle" fixed-width class="mr-1" />
      {{ request.warning }}
      <!--              Особое внимание -->
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="comment" fixed-width class="mr-1" />
      {{ request.comment }}
      <!--              Время -->
    </div>
  </div>
</template>

<script>
import {
  getCommunicationPhoneList,
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAddressFormatted,
  getRequestAssigneeName,
  getRequestBorderType,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDisplayableSum,
  getRequestDuration,
  getRequestManagerName,
  getRequestNumber,
  getRequestSourceName,
  getRequestStatus,
  getRequestStatusColor,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  PT_CASHLESS,
  servicesSum,
  STAGE_DEAL,
  STATUS_DECLINE,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests.js";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import { getUserName } from "@/modules/crm/common.js";
import {
  BLOCKED,
  REGULAR,
  UNBLOCKED,
} from "@/modules/users/decorators/userDecorator.js";
import {
  canChangeAssignee,
  canViewPhone,
} from "@/modules/users/access/common.js";
import { getSubwayTitle } from "@/modules/pest/decorators/subways";

export default {
  name: "RequestCardDispatchingFields",
  components: {},
  props: ["blocked", "counter", "request", "leadBoard", "dispatchingBoard"],
  data() {
    return {
      BLOCKED,
      UNBLOCKED,
      REGULAR,
      STATUS_DONE,
      STAGE_DEAL,
      STATUS_DECLINE,
      PT_CASHLESS,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    phoneList() {
      return getCommunicationPhoneList(this.request, true);
    },
    canChangeAssignee() {
      return canChangeAssignee(this.$store.state.user);
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getRequestDisplayableSum,
    getRequestNumber,
    getFacilityTitle,
    getRequestTitle,
    getPaymentTypeTitle,
    getRequestStatusColor,
    getRequestColor,
    getRequestBorderType,
    getPestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestSourceName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    servicesSum,
    getRequestTownTitle,
    getSubwayTitle,
    getRequestAddressFormatted,
    openRequest() {
      this.$emit("open-request");
    },
    updateTime(duration) {
      let updateUrl;
      updateUrl = "/pest/request/duration/" + this.id;
      // this.loading = true;
      this.$http
        .put(updateUrl, {
          duration: duration,
        })
        .then((response) => {
          this.$bvToast.toast("Данные сохранены", {
            title: "Время у заявки отредактировано",
            request: "success",
          });
        })
        .catch((error) => {
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось отредактировать время",
              request: "danger",
            });
          }
        });
    },
    openTimePopup() {
      let $this = this;
      this.$eventHub.$emit("time-popup-show", {
        request: this.request,
        success: function (duration) {
          $this.request.duration = duration;
          $this.updateTime(duration);
        },
        cancel: function () {},
      });
    },
    openAssigneePopup() {
      let $this = this;
      let request = this.request;
      this.$eventHub.$emit("assignee-popup-show", {
        request: this.request,
        success: function (assignee) {
          let changed = false;
          let oldAssigneeId = request.assigneeId;
          if (assignee) {
            if (assignee.id !== request.assigneeId) {
              changed = true;
              request.assigneeId = assignee.id;
              request.assignee = assignee;
            }
          } else {
            if (request.assigneeId) {
              request.assigneeId = 0;
              request.assignee = null;
              changed = true;
            }
          }
          if (changed) {
            $this.$eventHub.$emit("assignee-changed", {
              request: request,
              assignee: assignee,
              oldAssigneeId: oldAssigneeId,
            });
          }
        },
        cancel: function () {},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
