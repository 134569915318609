import AccountList from "@/modules/direct/views/accounts/AccountList";
import AccountCreate from "@/modules/direct/views/accounts/AccountCreate";
import AccountUpdate from "@/modules/direct/views/accounts/AccountUpdate";
import AccountView from "@/modules/direct/views/accounts/AccountView";

export default [
  {
    path: "/accounts/page:page?",
    name: "direct-account-list",
    components: {
      default: AccountList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/accounts/new",
    name: "direct-account-create",
    components: {
      default: AccountCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/accounts/:id/update",
    name: "direct-account-update",
    components: {
      default: AccountUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/accounts/:id",
    name: "direct-account-view",
    components: {
      default: AccountView,
    },
    meta: { requiresAuth: true },
  },
];
