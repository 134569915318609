import ServiceList from "@/modules/pest/views/services/ServiceList";
import ServiceCreate from "@/modules/pest/views/services/ServiceCreate";
import ServiceUpdate from "@/modules/pest/views/services/ServiceUpdate";
import ServiceView from "@/modules/pest/views/services/ServiceView";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/services/page:page?",
    name: "pest-service-list",
    components: {
      default: ServiceList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/services/new",
    name: "pest-service-create",
    components: {
      default: ServiceCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/services/:id/update",
    name: "pest-service-update",
    components: {
      default: ServiceUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/services/:id",
    name: "pest-service-view",
    components: {
      default: ServiceView,
    },
    meta: { requiresAuth: true },
  },
];
