
<div class="div">
  <inline-field
    label="Название"
    type="input"
    attr="title"
    :value="record.title"
    @update-prop="updateProp"
  ></inline-field>
  <inline-field
    label="Описание"
    type="input"
    attr="comment"
    :value="record.comment"
    @update-prop="updateProp"
  ></inline-field>
</div>
