<template>
  <div class="pest-town-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>
              <router-link
                :to="{ name: 'pest-project-list', id: project.id }"
                class="h4 text-muted"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>
              Изменение проекта
            </h1>
          </div>
          <div class="ibox-content">
            <pest-project-form :project="project"></pest-project-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectForm from "@/modules/pest/views/project/ProjectForm.vue";

export default {
  name: "ProjectUpdate",
  components: {
    "pest-project-form": ProjectForm,
  },
  data() {
    return {
      project: {
        id: "new",
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl() {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      let projectUrl = "/pest/projects/" + this.id;
      this.loading = true;
      this.$http
        .get(projectUrl)
        .then((response) => {
          this.loading = false;
          this.project = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
