
<b-form
  id="pest-source-form"
  ref="source"
  :model="source"
  class="pest-source-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="source.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Тип" prop="title">
          <b-form-select
            v-model="source.type"
            :options="sourceTypeOptions"
            placeholder="Тип"
            tabindex="1"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Проект" prop="projectId">
          <b-form-select
            v-model="source.projectId"
            :options="projectSelectOptions"
            placeholder="Проект"
            tabindex="1"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="source.type === TYPE_MANUAL"
          label="Назначение"
          prop="title"
        >
          <b-form-select
            v-model="source.target"
            :options="sourceTargetOptions"
            placeholder="Тип"
            tabindex="1"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="source.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Поиск только по телефону" prop="title">
          <b-form-checkbox v-model="source.searchOnlyByPhone" value="1">
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="source.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteSource"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
