
<div class="ibox mb-0">
  <div class="ibox-title search-ibox-wrapper">
    <div class="search">
      <div class="d-flex align-items-center" style="overflow-x: auto">
        <div class="mr-2">
          <button
            v-if="callNotificationsCount"
            v-b-tooltip.hover.right
            class="btn mr-2"
            :class="{
              'btn-outline-secondary': !search.withCallNotification,
              'btn-secondary': search.withCallNotification,
            }"
            type="button"
            title="Назначенные звонки"
            @click.prevent="toggleCallNotificationFilter"
          >
            <font-awesome-icon icon="phone" />
            <strong> {{ callNotificationsCount }}</strong>
          </button>

          <button
            class="btn btn-outline-secondary"
            :disabled="loading"
            @click="showSelectColumnsPopup"
          >
            <font-awesome-icon icon="fa-solid fa-gear" />
          </button>
        </div>

        <common-filter
          class="ml-auto mr-2"
          :all-statuses="allStatuses"
          :loading="loading"
          @search="startSearch"
        />

        <div v-if="!loading" class="mr-2">
          Найдено:
          <span>{{ pagination.total }}</span>
        </div>

        <div class="ml-2">
          <button
            v-b-tooltip.hover.left
            class="p-0 managerDashboardBtn"
            title="Дашборд операторов"
            @click.prevent="showManagerDashboard = !showManagerDashboard"
          >
            <img
              :src="chartIcon"
              alt="Дашборд операторов"
              class="managerDashboardIcon"
            />
            <!--              <font-awesome-icon icon="chart-pie" />-->
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox-content gray-bg pl-0 pr-0 requests-box">
    <b-overlay :show="loading" style="height: 100%">
      <div class="cont">
        <vuescroll :style="{ width: '100%', height: '100%' }">
          <div class="d-flex align-items-stretch">
            <div
              v-for="(requestsColumn, r2Index) in allRequests"
              :key="r2Index + 'col'"
              class="ibox ml-1 mr-1 mb-0"
              :class="{ 'd-none': !isColumnSelected(requestsColumn) }"
            >
              <div
                class="ibox-title pl-2 pr-2 pt-1 pb-1 assigneeHeader"
                :class="statusHeaderClass(requestsColumn.status)"
                :style="statusHeaderStyle(requestsColumn.status)"
              >
                <div
                  class="man-col-header-wrapper"
                  :style="statusHeaderStyle(requestsColumn.status)"
                >
                  <div class="man-col-header h6 pb-0 pt-1">
                    <div class="d-flex">
                      <div class="user-header-name">
                        {{ getStatusName(requestsColumn.status) }}&nbsp;
                      </div>
                      <div class="user-header-count ml-auto">
                        <small>{{ requestsColumn.count }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <vuescroll
                class="foo"
                :style="{ width: '100%', height: '85vh' }"
              >
                <div class="ibox-content pl-2 pr-2">
                  <button
                    v-if="
                      requestsColumn.stage === STAGE_LEAD && r2Index === 0
                    "
                    class="btn btn-block btn-outline-info mb-2"
                    @click.prevent="openNewRequestForm(requestsColumn.status)"
                  >
                    <font-awesome-icon icon="plus" />
                    Добавить лид
                  </button>

                  <draggable
                    class="list-group"
                    :list="requestsColumn.items"
                    group="people"
                    handle=".handle"
                    :disabled="
                      !editing || isDraggableDisabled(requestsColumn)
                    "
                    @change="
                      updateStatus(
                        $event,
                        requestsColumn.stage,
                        requestsColumn.status
                      )
                    "
                  >
                    <request-card
                      v-for="(request, rIndex) in requestsColumn.items"
                      :key="request.id + '-r'"
                      :counter="counter"
                      :r-i="rIndex"
                      :request="request"
                      :status="requestsColumn.status"
                      :update-button="true"
                      :lead-board="true"
                      :short-version="true"
                      @show-popup="showRequestPopup"
                    ></request-card>

                    <button
                      v-if="requestsColumn.loadMore"
                      class="btn btn-block btn-outline-info mt-2"
                      :disabled="requestsColumn.loadingData"
                      @click.prevent="loadMore(requestsColumn)"
                    >
                      <span v-if="!requestsColumn.loadingData"
                        >Загрузить еще</span
                      >
                      <span v-else
                        ><font-awesome-icon icon="rotate" spin />
                        Загружается</span
                      >
                    </button>
                  </draggable>
                </div>
              </vuescroll>
            </div>
          </div>
        </vuescroll>
      </div>
    </b-overlay>
  </div>

  <request-global-popup></request-global-popup>
  <manager-dashboard-popup
    :show="showManagerDashboard"
  ></manager-dashboard-popup>

  <board-columns-popup
    :columns="columns"
    :selected-columns="selectedColumns"
    @changed="columnsSelected"
  ></board-columns-popup>
</div>
