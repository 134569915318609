
<div class="ibox">
  <div class="">
    <div class="ibox-title">
      <div class="d-flex align-items-center">
        <h1>Статусы</h1>
        <div class="ml-4">
          <router-link
            :to="{ name: 'crm-status-create' }"
            class="btn btn-primary"
          >
            <font-awesome-icon fixed-width icon="plus" />
            Добавить
          </router-link>
        </div>
      </div>
    </div>

    <div class="ibox-content table-responsive">
      <div class="search">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-form-group label="Имя">
              <b-form-input v-model="search.name" tabindex="1"></b-form-input>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon fixed-width icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            Найдено: {{ pagination.totalCount }}
          </div>
        </div>
      </div>

      <hr />

      <div v-if="error">{{ error }}</div>

      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th class="check-column">
              <b-form-checkbox
                v-model="checkAll"
                :value="true"
                @change="checkAllCheckboxes"
              ></b-form-checkbox>
            </th>
            <th>
              <div v-if="hasCheckedItems" class="">
                <b-dropdown text="" variant="default btn-xs">
                  <b-dropdown-item
                    href="#"
                    @click.prevent="deleteSelectedStatuses"
                    >Удалить выбранное
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </th>
            <th>Название</th>
            <th>Порядок</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr v-for="(status, index) in statuses" :key="'p' + index" class="">
            <td class="check-column">
              <b-form-checkbox
                v-model="status.checked"
                :value="true"
                @change="checkCheckboxStatus"
              ></b-form-checkbox>
            </td>
            <td class="check-column">
              <b-dropdown text="" variant="default btn-xs">
                <b-dropdown-item
                  :to="{
                    name: 'crm-status-update',
                    params: { id: status.id },
                  }"
                >
                  Редактировать
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click.prevent="deleteStatus(status)"
                  >Удалить
                </b-dropdown-item>
              </b-dropdown>
            </td>
            <td>
              <router-link
                :to="{ name: 'crm-status-update', params: { id: status.id } }"
              >
                {{ getStatusName(status) }}
              </router-link>
            </td>
            <td>
              {{ status.order }}
            </td>

            <td>
              <div v-if="status.newStatus">
                <font-awesome-icon fixed-width icon="check" />
                Для новых заявок
              </div>
              <div v-if="status.startWork">
                <font-awesome-icon fixed-width icon="arrow-right" />
                В работе
              </div>
              <div v-if="status.duplicate">
                <font-awesome-icon fixed-width icon="check" />
                Дубль
              </div>
              <div v-if="status.trash">
                <font-awesome-icon fixed-width icon="check" />
                Спам \ Повторный звонок
              </div>
              <div v-if="status.closeStatus">
                <font-awesome-icon fixed-width icon="check" />
                Лид провален
              </div>
              <div v-if="status.termination && false">
                <font-awesome-icon fixed-width icon="check" />
                Расторжение
              </div>
              <div v-if="status.moveToDeal">
                <font-awesome-icon fixed-width icon="check" />
                Перенести в сделки
              </div>
              <div v-if="status.autoInitialCall">
                <font-awesome-icon fixed-width icon="check" />
                Первичный автодозвон
              </div>
              <div v-if="status.autoRepeatedCall">
                <font-awesome-icon fixed-width icon="check" />
                Автодозвон при отсутствии ответа
              </div>
              <div v-if="status.withoutAnswer">
                <font-awesome-icon fixed-width icon="check" />
                Без ответа
              </div>
              <div v-if="status.hidden">
                <font-awesome-icon fixed-width icon="eye-slash" />
                Скрыт от пользователей
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="loading">Загружается</div>

      <!--
                count: {{ pagination.totalCount }}
      -->

      <b-pagination-nav
        v-if="pagination.pageCount > 1"
        :link-gen="linkGen"
        :value="pagination.pageNumber"
        :number-of-pages="pagination.pageCount"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </div>
</div>
