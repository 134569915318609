
<div>
  <table class="table table-striped request-info">
    <tbody>
      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Номер
        </td>
        <td>
          {{ request.externalId }}
        </td>
      </tr>
      <tr>
        <td><font-awesome-icon icon="paperclip" fixed-width /> Проект</td>
        <td>
          {{ getRequestProjectTitle(request) }}
        </td>
      </tr>
      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Название
        </td>
        <td>
          {{ request.title }}
        </td>
      </tr>

      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Комментарий
        </td>
        <td>
          {{ request.text }}
        </td>
      </tr>

      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Имя
        </td>
        <td>
          {{ request.name }}
        </td>
      </tr>

      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Метка roistat
        </td>
        <td>
          {{ request.roistat }}
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon
            v-if="request.isOrganization"
            icon="building"
            fixed-width
          />
          <font-awesome-icon v-else icon="user" fixed-width />
          Клиент
        </td>
        <td>
          <span v-if="request.clientId">
            {{ getRequestClientName(request) }}
            <router-link
              v-if="canOpenToAssignee"
              :to="{
                name: 'crm-client-view',
                params: { id: request.clientId },
              }"
              class=""
            >
              <font-awesome-icon
                icon="external-link-square-alt"
                fixed-width
              />
            </router-link>
          </span>
          <span v-else>[Не назначен]</span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="phone" fixed-width />
          Телефон
        </td>
        <td>
          <div class="d-flex">
            <div v-if="canViewPhone" class="">{{ request.phone }}</div>
            <div v-else class="">У вас нет доступа к телефону</div>
          </div>
        </td>
      </tr>

      <tr v-if="request.comment">
        <td>
          <font-awesome-icon icon="comment" fixed-width />
          Комментарий
        </td>
        <td>
          <span>{{ request.comment }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
