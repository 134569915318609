<template>
  <div class="client-header">
    <b-row>
      <b-col>
        <h1>
          <router-link v-if="returnUrl" :to="returnUrl" class="ml-1 mr-1 h4">
            <font-awesome-icon icon="arrow-left" />
          </router-link>
          {{ clientName }}
          <small v-if="showButtons">
            <router-link
              :to="{ name: 'crm-client-update', params: { id: client.id } }"
              class="ml-1 mr-1 h6"
            >
              <font-awesome-icon icon="edit" />
            </router-link>

            <a href="" class="h6 ml-2" @click.prevent="deleteClient">
              <font-awesome-icon icon="trash" />
            </a>
          </small>
        </h1>
      </b-col>
    </b-row>

    <!--    <b-row class="mb-2">-->
    <!--      <b-col>-->
    <!--        <router-link :to="{ name: 'crm-client-view',params:{id:id,} }"-->
    <!--                     class="btn"-->
    <!--                     :class="{'btn-info': tab === 'info', 'btn-default': tab !== 'info'}"-->
    <!--        >-->
    <!--          Информация и задачи-->
    <!--        </router-link>-->

    <!--        <router-link :to="{ name: 'document-list',params:{id:id,} }"-->
    <!--                     class="btn ml-1"-->
    <!--                     :class="{'btn-info': tab === 'docs', 'btn-default': tab !== 'docs'}"-->
    <!--                     v-if="id !== 'new' ">-->
    <!--          Документы-->
    <!--        </router-link>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
  </div>
</template>

<script>
import { getClientName } from "@/modules/crm/decorators/client";

export default {
  name: "ClientHeader",
  components: {},
  props: ["client", "tab", "returnUrl", "showButtons"],
  data() {
    return {
      loading: false,
      // client: {},
    };
  },
  computed: {
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
    clientName() {
      return getClientName(this.client);
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created() {
    this.checkUrl();
    this.$eventHub.$on("update-client-info", this.updateInfo);
  },
  beforeDestroy() {
    this.$eventHub.$off("update-client-info");
  },

  methods: {
    deleteClient() {
      if (confirm("Вы действительно хотите удалить клиента?")) {
        let deleteUrl = "/crm/clients/" + this.client.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            // this.client.archived = 1;
            this.loading = false;
            this.$router.push({ name: "crm-client-list" });
            // this.$eventHub.$emit('update-client-info', this.client);
            this.$bvToast.toast("Клиент удален", {
              title: "Клиент удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить клиента",
                variant: "danger",
              });
            }
          });
      }
    },
    checkUrl: function () {
      if (this.id === "new") {
        this.header = "Новый клиент";
      } else {
        // this.fetchData()
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
