
<b-modal
  id="modal-contract-phone-popup"
  ref="modal"
  title="Новый телефон"
  size="sm"
  class="contract-popup"
  cancel-title="Отмена"
  ok-title="Сохранить"
  title-tag="h3"
  @hide="handleHide"
  @shown="onShown"
  @ok.prevent="handleTaskModalOk"
>
  <template #default>
    <b-overlay :show="loading" rounded="sm">
      <b-form
        id="phone-form"
        ref="phone"
        class="phone-form"
        novalidate
        @submit.prevent="createOrUpdate"
      >
        <b-form-group label="Телефон" prop="phone">
          <b-form-input
            id="phone-field"
            v-model="phone"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-overlay>
  </template>

  <template #footer>
    <div class="mt-2">
      <button
        v-if="!loading"
        class="btn btn-primary mr-2"
        @click.prevent="createOrUpdate"
      >
        <font-awesome-icon fixed-width icon="plus" />
        Добавить
      </button>
      <button v-else class="btn btn-primary mr-2" disabled>
        <font-awesome-icon fixed-width icon="rotate" spin />
        Добавляется
      </button>
      <button class="btn btn-outline-info" @click.prevent="handleHide">
        <font-awesome-icon fixed-width icon="arrow-left" />
        Отмена
      </button>
    </div>
  </template>
</b-modal>
