import SubwayList from "@/modules/pest/views/subways/SubwayList";
import SubwayCreate from "@/modules/pest/views/subways/SubwayCreate";
import SubwayUpdate from "@/modules/pest/views/subways/SubwayUpdate";
import SubwayView from "@/modules/pest/views/subways/SubwayView";

export default [
  {
    path: "/subways/page:page?",
    name: "pest-subway-list",
    components: {
      default: SubwayList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/subways/new",
    name: "pest-subway-create",
    components: {
      default: SubwayCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/subways/:id/update",
    name: "pest-subway-update",
    components: {
      default: SubwayUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/subways/:id",
    name: "pest-subway-view",
    components: {
      default: SubwayView,
    },
    meta: { requiresAuth: true },
  },
];
