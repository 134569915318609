
<div :data-counter="counter">
  <div class="mb-1 d-flex">
    <div>
      <font-awesome-icon icon="list-ol" fixed-width class="mr-1" />
      <!--              Описание-->
      <a
        v-if="!blocked || blocked !== BLOCKED"
        href="#"
        class=""
        @click.prevent="openRequest"
      >
        {{ getRequestNumber(request) }}
      </a>
      <span v-else> {{ getRequestNumber(request) }}</span>
    </div>
    <div v-if="!hideHandle" class="ml-auto">
      <div class="handle">
        <font-awesome-icon icon="arrows-alt" fixed-width />
      </div>
    </div>
    <div v-if="showDuplicate" class="ml-auto">
      <div class="handle">
        <span class="label label-primary mr-2">
          {{ request.indexNumber }}</span
        >
        <a
          v-b-popover.html.hover
          href="#"
          title="Дублировать"
          @click.prevent="duplicateRequest"
        >
          <font-awesome-icon v-if="!duplicating" icon="copy" fixed-width />
          <font-awesome-icon v-else icon="rotate" spin fixed-width />
        </a>
      </div>
    </div>
  </div>
  <div class="mb-1 d-flex">
    <div>
      <font-awesome-icon icon="paperclip" fixed-width class="mr-2" />
      <span>{{ getRequestProjectTitle(request) }}</span>
    </div>
  </div>
  <div class="mb-1 d-flex">
    <div>
      <font-awesome-icon icon="arrow-up-1-9" fixed-width class="mr-2" />
      <!--              Очередность -->
      <span v-if="request.isFirstTime">Первичное</span>
      <span v-else>Вторичное</span>
    </div>

    <div
      v-if="request.callNotifications && request.callNotifications.length"
      class="ml-auto"
    >
      <a
        v-b-popover.html.hover
        href="#"
        :title="getRequestCallNotification(request)"
        @click.prevent
      >
        <font-awesome-icon icon="bell" fixed-width />
      </a>
    </div>
  </div>

  <div class="mb-1" :style="getKanbanCardTextColor(status)">
    <font-awesome-icon icon="tag" fixed-width class="mr-1" />
    <!--              Описание-->
    {{ getRequestStatus(request) }}
  </div>

  <div>
    <!-- Телефоны -->
    <div
      v-for="(pInfo, pIndex) in phoneList"
      :key="'ph' + pIndex"
      class="mb-1"
    >
      <font-awesome-icon icon="phone" fixed-width class="mr-1" />
      {{ pInfo.phone }}
    </div>
  </div>

  <div v-if="leadBoard" class="mb-1 d-flex">
    <div>
      <font-awesome-icon icon="user-tie" fixed-width class="mr-1" />
      <!-- Исполнитель-->
      {{ getRequestManagerName(request) }}
    </div>
  </div>

  <div class="mb-1" :style="'color: ' + getRequestColor(request)">
    <font-awesome-icon icon="bug" fixed-width class="mr-1" />
    <!--              Вредитель -->
    {{ getPestTitle(request.pest) }}
  </div>

  <div class="mb-1 d-flex">
    <div>
      <!--              Время -->
      <font-awesome-icon icon="clock" fixed-width class="mr-1" />
      {{ getRequestDatetime(request) }}
    </div>
    <div v-if="false" class="ml-auto">
      <font-awesome-icon icon="stopwatch" fixed-width class="" />
      <a href="#" class="" @click.prevent="openTimePopup">
        {{ getRequestDuration(request) }}
      </a>
    </div>
  </div>
  <div class="mb-1">
    <font-awesome-icon icon="ruble-sign" fixed-width class="mr-1" />
    <!--              Сумма -->
    {{ getRequestDisplayableSum(request) }}

    <span v-if="request.earned"> / {{ request.earned }} </span>
    <span v-if="request.hasRecord">
      <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
    </span>

    <span v-if="request.isOrganization && request.paymentType == PT_CASHLESS">
      (Безнал)
    </span>
  </div>

  <div v-if="leadBoard" class="mb-1 d-flex">
    <div>
      <font-awesome-icon icon="user-cog" fixed-width class="mr-1" />
      <!-- Исполнитель-->
      {{ getRequestAssigneeName(request) }}
    </div>
    <div v-if="false" class="ml-auto">
      <a class="edit-assignee-link" @click.prevent="openAssigneePopup">
        <font-awesome-icon icon="edit" fixed-width />
      </a>
    </div>
  </div>
</div>
