
<div class="client-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-content pb-1">
          <h1>Добавление контактного лица</h1>
          <client-form
            :client-model="newClient"
            :data-loaded="!loading"
            :return-url="{ name: 'crm-client-list' }"
          ></client-form>
        </div>
      </div>
    </div>
  </div>
</div>
