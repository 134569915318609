<template>
  <b-form
    v-if="dataLoaded"
    id="client-form"
    ref="client"
    :model="client"
    class="client-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Фамилия" prop="lastName">
            <b-form-input v-model="client.lastName" placeholder="Фамилия" />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Имя" prop="firstName">
            <b-form-input v-model="client.firstName" placeholder="Имя" />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Отчество" prop="patronymic">
            <b-form-input v-model="client.patronymic" placeholder="Отчество" />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Организация" prop="firm">
            <b-form-input v-model="client.firm" placeholder="Организация" />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Должность" prop="post">
            <b-form-input v-model="client.post" placeholder="Должность" />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Ответственный" prop="responsiblePerson">
            <b-form-input v-model="client.responsiblePerson"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div
        v-for="(phone, phoneIndex) in client.phones"
        :key="'p' + phoneIndex"
        class="row"
      >
        <div class="col-12">
          <b-form-group label="Телефон" prop="phone">
            <b-form-input
              v-model="phone.phone"
              v-mask="'+7 (###) ###-####'"
            ></b-form-input>
            <small class="form-text text-muted">
              <a
                v-if="phoneIndex === 0"
                href="#"
                @click.prevent="addPhone(phoneIndex)"
                >Добавить</a
              >
              <a
                v-if="phoneIndex > 0 && client.phones.length > 0"
                href="#"
                @click.prevent="removePhone(phoneIndex)"
                >Удалить</a
              >
            </small>
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="">
      <b-form-group label="Адрес" prop="address">
        <b-form-input v-model="client.address"></b-form-input>
      </b-form-group>
    </div>

    <b-button variant="primary" @click="createOrUpdate">
      <font-awesome-icon icon="check" />
      Добавить
    </b-button>

    <b-button variant="outline-default" class="ml-2" @click="cancelUpdate">
      <font-awesome-icon icon="times" />
      Отмена
    </b-button>

    <b-button
      v-if="client.id > 0"
      variant="danger"
      class="ml-2"
      @click="deleteClient"
    >
      <font-awesome-icon icon="trash" />
      Удалить
    </b-button>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common.js";
import {
  TYPE_ADMIN,
  TYPE_FINE_MANAGER,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_WORKER,
} from "@/modules/users/decorators/users.js";
import { VueMaskDirective } from "v-mask";

export default {
  name: "ContactForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["clientModel", "dataLoaded"],
  data() {
    return {
      TYPE_ADMIN,
      TYPE_WORKER,
      TYPE_LOGIST,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,
      client: {},
      loading: false,
    };
  },
  computed: {
    id() {
      return this.client.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
  },
  watch: {
    $route(to, from) {},
    clientModel: {
      immediate: true,
      handler(obj) {
        this.client = Object.assign({}, obj);
      },
    },
  },
  created: function () {},
  mounted: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/crm/clients/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.client)
        .then((response) => {
          this.client = response.data;
          this.loading = false;
          this.$bvToast.toast("Данные сохранены", {
            title: "Клиент отредактирован",
            type: "success",
          });
          this.$emit("update-client-info", this.client);
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось удалить клиента",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/crm/clients";
      this.loading = true;
      this.$http
        .post(updateUrl, this.client)
        .then((response) => {
          this.client = response.data;
          this.$emit("update-client-info", this.client);
          // this.$router.push({name: 'crm-client-view', params: {id: this.client.id}});
          this.$bvToast.toast("Клиент добавлен", {
            title: "Клиент добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить клиента",
              variant: "danger",
            });
          }
        });
    },
    deleteClient() {
      if (confirm("Вы действительно хотите удалить клиента?")) {
        let deleteUrl = "/crm/clients/" + this.client.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            // this.client.archived = 1;
            this.loading = false;
            this.$router.push({ name: "crm-client-list" });
            // this.$eventHub.$emit('update-client-info', this.client);
            this.$bvToast.toast("Клиент удален", {
              title: "Клиент удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить клиента",
                variant: "danger",
              });
            }
          });
      }
    },

    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("client-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    addPhone() {
      this.client.phones.push({
        phone: "",
        type: "mobile",
      });
    },
    removePhone(index) {
      this.client.phones.splice(index, 1);
      return false;
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.client);
    },
    selectManager(data) {
      if (data && data.value) {
        this.client.managerId = data.value.id;
        this.client.manager = data.value;
      } else {
        this.client.managerId = null;
        this.client.manager = {};
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss"></style>
