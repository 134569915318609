
<b-form
  v-if="dataLoaded"
  id="client-form"
  ref="client"
  :model="client"
  class="client-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="row">
    <div class="col-12">
      <b-form-group label="Тип" prop="type">
        <b-form-radio-group
          id="client-type"
          v-model="client.type"
          name="client-type"
          :options="types"
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <b-form-group label="Нумерация" prop="type">
        <b-form-radio-group
          id="client-numeration-type"
          v-model="client.numeration"
          name="client-numeration-type"
          :options="numerationTypes"
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <div v-if="client.numeration === NUMERATION_CLIENT" class="col-6">
      <b-form-group label="Начало нумерации" prop="numerationStart">
        <b-form-input
          v-model="client.numerationStart"
          placeholder="Начало нумерации"
        />
      </b-form-group>
    </div>
    <div v-else class="col-6">
      <b-form-group label="Начало нумерации" prop="numerationStart">
        <b-form-input
          v-model="maxIssueNumber"
          placeholder="Начало нумерации"
          disabled
        />
      </b-form-group>
    </div>
  </div>

  <div v-if="client.type === TYPE_ORGANIZATION">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название организации" prop="title">
          <b-form-input
            v-model="client.title"
            placeholder="Название организации"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Фамилия" prop="lastName">
          <b-form-input
            v-model="client.lastName"
            placeholder="Фамилия"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Имя" prop="firstName">
          <b-form-input
            v-model="client.firstName"
            placeholder="Имя"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Отчество" prop="patronymic">
          <b-form-input
            v-model="client.patronymic"
            placeholder="Отчество"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Должность" prop="post">
          <b-form-input
            v-model="client.post"
            placeholder="Должность"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div v-if="client.type === TYPE_PERSON" class="row">
      <div class="col-12">
        <b-form-group label="Место работы" prop="post">
          <b-form-input
            v-model="client.workTitle"
            placeholder="Место работы"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>

  <div
    v-for="(phone, phoneIndex) in client.phones"
    :key="'p' + phoneIndex"
    class="row"
  >
    <div class="col-7">
      <b-form-group label="Телефон" prop="phone">
        <p>{{ phone.phone }}</p>
        <b-form-input
          v-model="phone.phone"
          v-mask="'+7 (###) ###-####'"
        ></b-form-input>
        <small class="form-text text-muted">
          <a
            v-if="phoneIndex === 0"
            href="#"
            @click.prevent="addPhone(phoneIndex)"
            >Добавить</a
          >
          <a
            v-if="phoneIndex > 0 && client.phones.length > 0"
            href="#"
            @click.prevent="removePhone(phoneIndex)"
            >Удалить</a
          >
        </small>
      </b-form-group>
    </div>

    <div class="col-4">
      <b-form-group label="Тип" prop="type">
        <b-form-select v-model="phone.type" :options="contactTypes">
        </b-form-select>
      </b-form-group>
    </div>
  </div>

  <div
    v-for="(email, emailIndex) in client.emails"
    :key="'e' + emailIndex"
    class="row"
  >
    <div class="col-7">
      <b-form-group label="Электронная почта" prop="email">
        <b-form-input v-model="email.email"></b-form-input>
        <small class="form-text text-muted">
          <a
            v-if="emailIndex === 0"
            href="#"
            @click.prevent="addEmail(emailIndex)"
            >Добавить</a
          >
          <a
            v-if="emailIndex > 0 && client.emails.length > 0"
            href="#"
            @click.prevent="removeEmail(emailIndex)"
            >Удалить</a
          >
        </small>
      </b-form-group>
    </div>

    <div class="col-4">
      <b-form-group label="Тип" prop="type">
        <b-form-select v-model="email.type" :options="emailContactTypes">
        </b-form-select>
      </b-form-group>
    </div>
  </div>

  <div v-if="client.type === TYPE_PERSON" class="row">
    <div class="col-6">
      <b-form-group label="День рождения" prop="birthday">
        <datepicker
          v-model="client.birthday"
          v-mask="'##.##.####'"
          input-class="form-control"
          :editable="true"
          lang="ru"
          :format="dateFormat"
          type="date"
          value-type="format"
        >
        </datepicker>
      </b-form-group>
    </div>

    <div class="col-6">
      <b-form-group label="Пол" prop="gender">
        <b-form-radio-group
          id="client-gender"
          v-model="client.gender"
          name="client-gender"
          :options="genders"
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>

  <div v-if="client.type === TYPE_ORGANIZATION" class="">
    <b-form-group label="Полное название" prop="fullTitle">
      <b-form-input v-model="client.fullTitle"></b-form-input>
    </b-form-group>
    <b-form-group label="Физический адрес" prop="address">
      <b-form-input v-model="client.address"></b-form-input>
    </b-form-group>
    <b-form-group label="Почтовый адрес" prop="postAddress">
      <b-form-input v-model="client.postAddress"></b-form-input>
    </b-form-group>
    <b-form-group label="Юридический адрес" prop="legalAddress">
      <b-form-input v-model="client.legalAddress"></b-form-input>
    </b-form-group>
    <b-form-group label="ИНН" prop="inn">
      <b-form-input v-model="client.inn"></b-form-input>
    </b-form-group>
    <b-form-group label="КПП" prop="kpp">
      <b-form-input v-model="client.kpp"></b-form-input>
    </b-form-group>
    <b-form-group label="ОГРН" prop="ogrn">
      <b-form-input v-model="client.ogrn"></b-form-input>
    </b-form-group>
    <b-form-group label="Банковский счет" prop="bankAccout">
      <b-form-input v-model="client.bankAccout"></b-form-input>
    </b-form-group>
  </div>
  <div v-if="client.type === TYPE_PERSON" class="">
    <hr />

    <div class="row">
      <div class="col-6">
        <b-form-group label="Серия паспорта" prop="passportSerial">
          <b-form-input v-model="client.passportSerial"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Номер паспорта" prop="passportNumber">
          <b-form-input v-model="client.passportNumber"></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <b-form-group label="Дата выдачи" prop="passportIssuedDate">
          <datepicker
            v-model="client.passportIssuedDate"
            v-mask="'##.##.####'"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
          >
          </datepicker>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Код подразделения" prop="passportIssuedByCode">
          <b-form-input v-model="client.passportIssuedByCode"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="Кем выдан" prop="passportIssuedByTitle">
      <b-form-input v-model="client.passportIssuedByTitle"></b-form-input>
    </b-form-group>
    <hr />
  </div>
  <div v-else class="">
    <b-form-group label="Адрес" prop="address">
      <b-form-input v-model="client.address"></b-form-input>
    </b-form-group>
  </div>

  <div v-if="client.type !== TYPE_ORGANIZATION" class="row">
    <div class="col-12">
      <b-form-group label="Организация" prop="organizationId">
        <autocomplete
          ref="searchOrganization"
          :search="searchOrganization"
          class=""
          :default-value="organizationName"
          placeholder="Поиск по клиентам"
          aria-label="Поиск по клиентам"
          :get-result-value="getResultOrganizationAutocompleteValue"
          @submit="handleOrganizationSubmit"
        ></autocomplete>
      </b-form-group>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <b-form-group label="Ответственный" prop="managerId">
        <autocomplete
          ref="searchManager"
          :search="searchManager"
          class=""
          :default-value="managerName"
          placeholder="Поиск по клиентам"
          aria-label="Поиск по клиентам"
          :get-result-value="getResultManagerAutocompleteValue"
          @submit="handleManagerSubmit"
        ></autocomplete>
      </b-form-group>
    </div>
  </div>

  <b-button variant="primary" @click="createOrUpdate">
    <font-awesome-icon icon="check" />
    {{ submitButton }}
  </b-button>

  <b-button
    v-if="id !== 'new'"
    variant="outline-default"
    class="ml-2"
    @click="cancelUpdate"
  >
    <font-awesome-icon icon="arrow-left" />
    Отмена
  </b-button>

  <b-button
    v-if="client.id > 0"
    variant="danger"
    class="ml-2"
    @click="deleteClient"
  >
    <font-awesome-icon icon="trash" />
    Удалить
  </b-button>
</b-form>
