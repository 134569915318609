
<div v-if="!logged">
  <div class="ibox mb-1">
    <div class="ibox-content">
      <h2>У вас нет доступа к данной странице</h2>
    </div>
  </div>
</div>

<div v-else>
  <div v-if="isClient()">
    <div class="ibox mb-1">
      <div class="ibox-content">
        <issues></issues>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="false" class="row">
      <div class="col-12">
        <div class="ibox mb-1">
          <div class="ibox-content">
            <employees></employees>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="ibox mb-1">
          <div class="ibox-content">
            <tasks></tasks>
          </div>
        </div>
      </div>

      <div class="col-8">
        <div class="ibox mb-1">
          <div class="ibox-content">
            <issues></issues>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
