
<b-form
  id="pest-price-form"
  ref="price"
  :model="price"
  class="pest-price-form"
  novalidate
  @submit.prevent="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-md-3">
        <b-form-group label="Город" prop="townIds">
          <town-autocomplete
            ref="townAutocomplete"
            required=""
            :default-value="price.townId ? getTownTitle(price.town) : ''"
            @selected="selectTown"
          ></town-autocomplete>
        </b-form-group>
      </div>

      <div class="col-md-3">
        <b-form-group label="Тип вредителя" prop="title">
          <pest-autocomplete
            v-model="price.pestId"
            :default-value="getRequestPestName(price)"
            @selected="selectPest"
          ></pest-autocomplete>
        </b-form-group>
      </div>

      <div class="col-md-3">
        <b-form-group label="Физ\Юр. лицо" prop="title">
          <b-button-group>
            <button
              class="btn"
              :class="getClientTypeClass(CLIENT_TYPE_IND)"
              @click.prevent="selectClientType(CLIENT_TYPE_IND)"
            >
              Физ. лицо
            </button>
            <button
              class="btn"
              :class="getClientTypeClass(CLIENT_TYPE_ORG)"
              @click.prevent="selectClientType(CLIENT_TYPE_ORG)"
            >
              Организация
            </button>
          </b-button-group>
        </b-form-group>
      </div>
      <div v-if="price.clientType === CLIENT_TYPE_ORG" class="col-md-3">
        <b-form-group label="Тип оплаты" prop="title">
          <b-button-group>
            <button
              class="btn"
              :class="getClientPaymentTypeClass(PT_CASH)"
              @click.prevent="selectClientPaymentType(PT_CASH)"
            >
              Нал
            </button>
            <button
              class="btn"
              :class="getClientPaymentTypeClass(PT_CASHLESS)"
              @click.prevent="selectClientPaymentType(PT_CASHLESS)"
            >
              Безнал
            </button>
          </b-button-group>
        </b-form-group>
      </div>
    </div>

    <hr />

    <b-overlay :show="loadingPrices" rounded="sm"></b-overlay>

    <div v-if="prices.length">
      <div v-for="(aPrice, pIndex) in prices" :key="pIndex" class="row">
        <div class="col-3">
          <b-form-group label="Объект" prop="title">
            <facility-autocomplete
              v-model="aPrice.facilityId"
              :add-new="true"
              :default-value="getRequestFacilityName(aPrice)"
              @selected="selectFacility($event, aPrice)"
            ></facility-autocomplete>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group label="Стоимость" prop="title">
            <b-form-input
              v-model="aPrice.price"
              placeholder=""
              number
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group label="Комментарий" prop="title">
            <b-form-input
              v-model="aPrice.comment"
              placeholder=""
              number
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-2 pt-3">
          <button
            class="btn btn-danger mt-2"
            @click.prevent="removePrice(pIndex)"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>

      <button class="btn btn-primary" @click.prevent="addPrice">
        <font-awesome-icon icon="plus" />
        Добавить
      </button>
    </div>
  </div>
</b-form>
