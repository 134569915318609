
<div class="task-page">
  <div class="ibox">
    <div class="ibox-title pb-3">
      <div class="d-flex">
        <div class="ml-1 mr-2 mt-2">
          <router-link :to="{ name: 'task-list' }" class="h4 text-muted">
            <font-awesome-icon icon="arrow-left" />
          </router-link>
        </div>
        <div>
          <h1>Задача: {{ task.title }}</h1>
          <h4 class="font-bold no-margins">
            Автор: {{ getUserName(task.author) }}

            <span v-if="task.completed">
              | <font-awesome-icon icon="check-circle" /> Выполнена в
              {{ getTaskCompleteTime(task) }}
            </span>
          </h4>
        </div>
        <div class="ml-auto">
          <div>
            <b-button
              v-if="!task.completed"
              class=""
              variant="primary"
              @click="completeTask"
            >
              <font-awesome-icon v-if="!task.loading" icon="check" />
              <font-awesome-icon v-else icon="rotate" spin />
              Выполнить
            </b-button>
            <b-button v-else class="" variant="info" @click="restoreTask">
              <font-awesome-icon v-if="!task.loading" icon="box" />
              <font-awesome-icon v-else icon="rotate" spin />
              Вернуть в работу
            </b-button>
            <router-link
              :to="{ name: 'task-update', id: task.id }"
              class="btn btn-primary ml-1"
            >
              <font-awesome-icon icon="edit" />
              Изменить
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <task-info :task="task" @update-prop="updateProp"></task-info>
    </div>
  </div>
</div>
