
<div class="client-issues-wrapper">
  <div class="">
    <b-table
      v-if="issues.length"
      responsive
      striped
      hover
      condensed
      :items="issues"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{ name: 'issue-view', params: { id: data.item.id } }"
          class=""
        >
          {{ getIssueTitle(data.item) }}
        </router-link>
        <br />
        <small>
          {{ getIssueGroupTitle(data.item) }}
        </small>
      </template>

      <template #cell(status)="data">
        {{ getIssueStatus(data.item) }}
      </template>

      <template #cell(manager)="data">
        {{ getIssueManagerName(data.item) }}
      </template>

      <template #cell(timeCreated)="data">
        {{ getIssueCreateTime(data.item) }}
      </template>

      <template #cell(deadline)="data">
        {{ getIssueDeadline(data.item) }}
      </template>

      <template #cell(timeUpdated)="data">
        {{ getIssueUpdateTime(data.item) }}
      </template>
    </b-table>
    <div v-else class="empty-list-message">
      У данного контакта нет активных дел
    </div>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
