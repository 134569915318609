<template>
  <div class="client-issues-wrapper">
    <div class="">
      <b-table
        v-if="issues.length"
        responsive
        striped
        hover
        condensed
        :items="issues"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> Данные подгружаются</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{ name: 'issue-view', params: { id: data.item.id } }"
            class=""
          >
            {{ getIssueTitle(data.item) }}
          </router-link>
          <br />
          <small>
            {{ getIssueGroupTitle(data.item) }}
          </small>
        </template>

        <template #cell(status)="data">
          {{ getIssueStatus(data.item) }}
        </template>

        <template #cell(manager)="data">
          {{ getIssueManagerName(data.item) }}
        </template>

        <template #cell(timeCreated)="data">
          {{ getIssueCreateTime(data.item) }}
        </template>

        <template #cell(deadline)="data">
          {{ getIssueDeadline(data.item) }}
        </template>

        <template #cell(timeUpdated)="data">
          {{ getIssueUpdateTime(data.item) }}
        </template>
      </b-table>
      <div v-else class="empty-list-message">
        У данного контакта нет активных дел
      </div>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getIssueClientName,
  getIssueCreateTime,
  getIssueDeadline,
  getIssueGroupTitle,
  getIssueManagerName,
  getIssueStatus,
  getIssueTitle,
  getIssueUpdateTime,
} from "@/modules/law/decorators/issue";

export default {
  name: "IssueList",
  components: {},
  props: ["client"],
  data() {
    return {
      issues: [{}],
      search: {
        title: "",
        managerId: "",
      },
      managersList: [],

      loading: true,
      archived: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: "Название",
        },
        {
          key: "status",
          label: "Статус",
        },
        {
          key: "timeCreated",
          label: "Создано",
        },
        {
          key: "deadline",
          label: "Крайний срок",
        },
        {
          key: "timeUpdated",
          label: "Ближайшая активность",
        },
      ],
    };
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getIssueTitle,
    getIssueGroupTitle,
    getIssueManagerName,
    getIssueStatus,
    getIssueClientName,
    getIssueCreateTime,
    getIssueDeadline,
    getIssueUpdateTime,
    fetchData: function () {
      this.loading = true;

      let issuesUrl = this.archived ? "/law/issues/archived" : "/law/issues";
      let params = {
        page: this.page,
        searchNames: this.search.title,
        clientId: this.client.id,
      };
      this.$http
        .get(issuesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.issues = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
          this.error = error;
        });
    },
    linkGen(pageNum) {
      return {
        name: "issue-list",
        params: { page: pageNum },
      };
    },
    showArchivedIssues() {
      this.archived = true;
      this.fetchData();
    },
    showActiveIssues() {
      this.archived = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.issue-organization {
  font-size: smaller;
  color: gray;
}

.empty-list-message {
}

.client-issues-wrapper {
  min-height: 100px;
}
</style>
