
<div>
  Вам назначена заявка
  <router-link
    :to="{ name: 'pest-request-view', params: { id: notification.itemId } }"
    class=""
  >
    #{{ notification.itemId ? notification.itemId : "Без номера" }}
  </router-link>
</div>
