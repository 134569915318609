import DefaultRoutes from "@/modules/direct/routers/defaultRouter.js";
import AccountRoutes from "@/modules/direct/routers/accountRouter.js";
import CampaignRoutes from "@/modules/direct/routers/campaignsRouter.js";
import ReportRoutes from "@/modules/direct/routers/reportsRouter.js";
import RecordRoutes from "@/modules/direct/routers/recordsRouter.js";
import ResultRoutes from "@/modules/direct/routers/resultRouter.js";
import MoneyResultRoutes from "@/modules/direct/routers/moneyResultRouter.js";

export default DefaultRoutes.concat(
  AccountRoutes,
  CampaignRoutes,
  ReportRoutes,
  RecordRoutes,
  ResultRoutes,
  MoneyResultRoutes
);
