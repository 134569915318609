export const TYPE_SHADOW = -2;
export const TYPE_ADMIN = 1;
export const TYPE_LOGIST = 2;
export const TYPE_WORKER = 3;
export const TYPE_CLIENT = 4;
export const TYPE_MANAGER = 5;
export const TYPE_FINE_MANAGER = 6;

export const typeTitles = {
  [TYPE_SHADOW]: "Поддержка",
  [TYPE_ADMIN]: "Администратор",
  [TYPE_LOGIST]: "Логист",
  [TYPE_MANAGER]: "Менеджер",
  [TYPE_FINE_MANAGER]: "Контроллер",
  [TYPE_WORKER]: "Исполнитель",
  [TYPE_CLIENT]: "Клиент",
};

export const typeSelectOptions = [
  // {'value': TYPE_SHADOW, 'text': 'Поддержка'},
  { value: TYPE_ADMIN, text: "Администратор" },
  { value: TYPE_LOGIST, text: "Логист" },
  { value: TYPE_MANAGER, text: "Менеджер" },
  { value: TYPE_FINE_MANAGER, text: "Менеджер по претензиям" },
  { value: TYPE_WORKER, text: "Исполнитель" },
  // {'value': TYPE_CLIENT, 'text': 'Клиент'},
];

export const getTypeTitle = function (user) {
  return typeTitles[user.type];
};
