
<div class="ibox">
  <div class="ibox-content">
    <h1>Данные отчета</h1>
    <div class="mb-2"></div>

    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <div class="mr-2">
          <b-form-group label="Дата" prop="date">
            <date-picker v-model="search.date"></date-picker>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Аккаунт" prop="accountId">
            <multiply-autocomplete
              ref="accountAutocomplete"
              :get-data="fetchAccountData"
              :get-title="getAccountTitle"
              @selected="selectMultipleAccount"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Кампания" prop="campaignId">
            <multiply-autocomplete
              ref="campaignAutocomplete"
              :get-data="fetchCampaignData"
              :get-title="getCampaignTitle"
              @selected="selectMultipleCampaign"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Отчет" prop="reportId">
            <multiply-autocomplete
              ref="reportAutocomplete"
              :get-data="fetchReportData"
              :get-title="getReportTitle"
              @selected="selectMultipleReport"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Вредитель" prop="pestId">
            <multiply-autocomplete
              ref="pestAutocomplete"
              :get-data="fetchPestData"
              :get-title="getPestTitle"
              @selected="selectMultiplePest"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Регион" prop="pestId">
            <multiply-autocomplete
              ref="townAutocomplete"
              :get-data="fetchRegionData"
              :get-title="getRegionTitle"
              @selected="selectMultipleRegion"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.total }}
        </div>
      </div>
    </div>

    <table class="table table-striped table-condensed">
      <thead>
        <tr>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Аккаунт"
              param="accountId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Отчет"
              param="reportId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Кампания"
              param="campaignId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Дата"
              param="dateD"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Регион"
              param="regionId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Вредитель"
              param="pestId"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Количество"
              param="count"
              @change="fetchData"
            />
          </th>
          <th>
            <sort-column-title
              v-model="search.sort"
              title="Сумма"
              param="sumD"
              @change="fetchData"
            />
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="(record, rIndex) in records" :key="'r' + rIndex">
          <td>
            <!-- Аккаунт -->
            {{ getRecordAccountName(record) }}
          </td>
          <td>
            <!-- Отчет -->
            {{ getRecordReportTitle(record) }}
          </td>
          <td>
            <!-- Кампания -->
            {{ getRecordCampaignName(record) }}
          </td>
          <td>
            <!-- Дата -->
            {{ getRecordDate(record) }}
          </td>
          <td>
            <!-- Регион -->
            {{ getRecordRegionName(record) }}
          </td>
          <td>
            <!-- Вредитель -->
            {{ getRecordPestName(record) }}
          </td>
          <td>
            <!-- Количество -->
            {{ record.count }}
          </td>
          <td>
            <!-- Сумма -->
            {{ getRecordFormattedSum(record) }}
          </td>
        </tr>
        <tr>
          <td colspan="6"></td>
          <td>
            <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
            <strong v-else>{{ formatNumber(stats.total.count) }}</strong>
          </td>
          <td>
            <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
            <strong v-else>{{ formatNumber(stats.total.sum) }}</strong>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8">Загружается</td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
