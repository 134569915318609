import axios from "axios";
import store from "@/store";
import Vue from "vue";
import * as Sentry from "@sentry/vue";

let base;
let socket;
if (import.meta.env.MODE === "production") {
  base = import.meta.env.VITE_API_PROD_URL;
  socket = import.meta.env.VITE_SOCKET_PROD_URL;
} else {
  base = import.meta.env.VITE_API_DEV_URL;
  socket = import.meta.env.VITE_SOCKET_DEV_URL;
}

export function apiSetToken(token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token;
}

export function getAxiosInstance() {
  const token = store.getters["auth/getToken"];

  const instance = axios.create({
    baseURL: base,
  });

  if (token) {
    instance.defaults.headers.common.Authorization = token;
  }

  if (import.meta.env.MODE === "production") {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
  }

  return instance;
}

export function onInstanceGetData(options, cb) {
  Vue.prototype.$http
    .get(options.url, options.precedence)
    .then((response) => {
      cb(null, response);
    })
    .catch((error) => {
      cb(error, null);
    });
}

export const BASE_URL = base;
export const SOCKET_URL = socket;
