
<div>
  <div v-if="canAddFine" class="ibox">
    <div class="ibox-content">
      <div class="d-flex align-items-center">
        <h1>Штрафы</h1>
        <router-link
          v-if="canAddFine"
          :to="{ name: 'pest-fine-create' }"
          class="btn btn-primary ml-2"
        >
          <font-awesome-icon icon="plus" />
          Добавить штраф
        </router-link>
      </div>
      <div v-if="canAddFine" class="d-flex flex-wrap align-items-center">
        <b-button-group class="mt-2 mr-2">
          <button
            class="btn"
            :class="getTownClass(MSK)"
            @click.prevent="selectTown(MSK)"
          >
            Москва
          </button>
          <button
            class="btn"
            :class="getTownClass(SPB)"
            @click.prevent="selectTown(SPB)"
          >
            СПБ
          </button>
        </b-button-group>
        <div class="mr-2 mt-2">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>
        <user-autocomplete
          v-model="search.employeeId"
          :default-value="getEmployeeName(search.employee, 'Сотрудник')"
          :type="TYPE_WORKER"
          class="mr-2 mt-2"
          @selected="selectEmployee"
        />

        <user-autocomplete
          v-model="search.managerId"
          :type="[
            TYPE_ADMIN,
            TYPE_LOGIST,
            TYPE_MANAGER,
            TYPE_FINE_MANAGER,
            TYPE_SHADOW,
          ]"
          :default-value="getEmployeeName(search.manager, 'Кто назначил')"
          class="mr-2 mt-2"
          @selected="selectManager"
        />

        <b-button-group class="mr-2 mt-2">
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getStatusBtnClass('filterNew')"
            title="Новые"
            @click.prevent="setStatusBtn('filterNew')"
          >
            <font-awesome-icon icon="file" />
          </button>
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getStatusBtnClass('filterProcessing')"
            title="В обработке"
            @click.prevent="setStatusBtn('filterProcessing')"
          >
            <font-awesome-icon icon="rotate" />
          </button>
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getStatusBtnClass('filterVerified')"
            title="Подтвержденные"
            @click.prevent="setStatusBtn('filterVerified')"
          >
            <font-awesome-icon icon="check" />
          </button>
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getStatusBtnClass('filterDeclined')"
            title="Отклоненные"
            @click.prevent="setStatusBtn('filterDeclined')"
          >
            <font-awesome-icon icon="times" />
          </button>
        </b-button-group>
        <b-button-group class="mr-2 mt-2">
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getPaidBtnClass('paid')"
            title="Оплаченные"
            @click.prevent="setPaidBtn('paid')"
          >
            Оплаченные
          </button>
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getPaidBtnClass('notPaid')"
            title="Неоплаченные"
            @click.prevent="setPaidBtn('notPaid')"
          >
            Неоплаченные
          </button>
        </b-button-group>

        <b-button-group class="mr-2 mt-2">
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getPaidBtnClass('deposited')"
            title="В кассе"
            @click.prevent="setPaidBtn('deposited')"
          >
            В кассе
          </button>
          <button
            v-b-tooltip.hover
            class="btn"
            :class="getPaidBtnClass('notDeposited')"
            title="Не в кассе"
            @click.prevent="setPaidBtn('notDeposited')"
          >
            Не в кассе
          </button>
        </b-button-group>
      </div>
    </div>
  </div>

  <div class="ibox">
    <div class="ibox-title d-none d-md-block">Штрафы</div>
    <div class="ibox-content table-responsive">
      <b-overlay :show="loading" rounded="sm">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Дата</th>
              <th v-if="canAddFine">Статус</th>
              <th>Сумма</th>
              <th v-if="canAddFine">Сотрудник</th>
              <th>Комментарий</th>
              <th v-if="canAddFine">Ответ</th>
              <th v-if="canAddFine">Кто назначил</th>
              <th v-if="canAddFine">Кто проверил</th>
              <th v-if="canAddFine" class="paid-comment">Оплачен</th>
            </tr>
          </thead>
          <tbody v-if="fines && fines.length">
            <tr v-for="(fine, fIndex) in fines" :key="fIndex">
              <td>
                <font-awesome-icon :icon="getFineStatusIcon(fine)" />&nbsp;
              </td>
              <td>
                <router-link
                  v-if="canAddFine"
                  :to="{ name: 'pest-fine-update', params: { id: fine.id } }"
                  class=""
                >
                  {{ getFineTime(fine) }}
                </router-link>
                <span v-else>
                  {{ getFineTime(fine) }}
                </span>
              </td>
              <td v-if="canAddFine">
                {{ getFineStatusTitle(fine) }}
              </td>
              <td>
                {{ fine.sum }}
              </td>
              <td v-if="canAddFine">
                {{ getUserName(fine.employee) }}
              </td>
              <td>
                {{ fine.comment }}
                <div v-if="!canAddFine">
                  {{ fine.review }}
                </div>
              </td>
              <td v-if="canAddFine">
                {{ fine.review }}
              </td>
              <td v-if="canAddFine">
                {{ getUserName(fine.manager) }}
              </td>
              <td v-if="canAddFine">
                {{ getUserName(fine.reviewer) }}
              </td>
              <td v-if="canAddFine">
                <div class="mb-1">
                  <div v-if="fine.isPaid">
                    <font-awesome-icon
                      fixed-width
                      icon="fa-solid fa-money-bill"
                    />
                    Оплачен
                    <div v-if="fine.payTime">
                      {{ getFinePayTime(fine) }}
                    </div>
                  </div>
                  <div v-else>
                    <font-awesome-icon
                      fixed-width
                      icon="fa-solid fa-money-bill"
                      class="text-muted"
                    />
                    Не оплачен
                  </div>
                </div>
                <div>
                  <div v-if="fine.isDeposited">
                    <font-awesome-icon
                      fixed-width
                      icon="fa-solid fa-cash-register "
                    />
                    В кассе
                    <div v-if="fine.depositTime">
                      {{ getDepositTime(fine) }}
                    </div>
                  </div>
                  <div v-else>
                    <font-awesome-icon
                      fixed-width
                      icon="fa-solid fa-cash-register "
                      class="text-muted"
                    />
                    Не в кассе
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="canAddFine ? 10 : 8">Загружается</td>
            </tr>
          </tbody>
        </table>
      </b-overlay>

      <b-pagination-nav
        v-if="pagination.count > 1"
        :link-gen="linkGen"
        :value="pagination.page"
        :number-of-pages="pagination.count"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </div>
</div>
