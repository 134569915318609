
<div class="ibox mb-1">
  <div class="ibox-content">
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <h2>Сотрудники</h2>
        <ul>
          <li>
            <router-link :to="{ name: 'users-user-list' }" class="">
              Пользователи
              <span class="hint">Редактирование пользователей</span>
            </router-link>
          </li>
          <li v-if="false">
            <router-link :to="{ name: 'workgroup-list' }" class="">
              Группы пользователей
              <span class="hint"
                >Группы используются при распределении дел</span
              >
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'pest-assignee-day-schedule' }"
              class=""
            >
              Расписание на день
              <span class="hint">Расписание исполнителей на день </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-assignee-schedule' }" class="">
              Расписание на неделю
              <span class="hint">Расписание исполнителей на неделю </span>
            </router-link>
          </li>
        </ul>

        <h2>Заявки</h2>

        <ul>
          <li v-if="canSeeRequestTableList">
            <router-link :to="{ name: 'pest-request-list' }" class="">
              Заявки
              <span class="hint">Перечень заявок в виде таблицы</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'pest-settings' }" class="">
              Настройки заявок
              <span class="hint">Рекорд и прочие</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'crm-status-list' }" class="">
              Статусы
              <span class="hint">Статусы лидов</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-price-list' }" class="">
              Ценовые подсказки
              <span class="hint">Предложения цены для нового лида</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-3">
        <h2>Справочники по заявкам</h2>
        <ul>
          <li>
            <router-link :to="{ name: 'pest-project-list' }" class="">
              Проекты
              <span class="hint">Перечень проектов </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-town-list' }" class="">
              Города
              <span class="hint"
                >Справочник городов, в которых оказываются услуги
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-subway-list' }" class="">
              Метро
              <span class="hint">Метро в городах</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-place-list' }" class="">
              Магазины
              <span class="hint">Справочник магазинов и других мест </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-service-list' }" class="">
              Услуги
              <span class="hint">Справочник услуг оказываемых клиентам</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-product-list' }" class="">
              Товары
              <span class="hint">Справочник товаров</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-resource-list' }" class="">
              Материалы
              <span class="hint"
                >Справочник используемых ресурсов и материалов</span
              >
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-equipment-list' }" class="">
              Оборудование
              <span class="hint"
                >Оборудование, передаваемое исполнителям</span
              >
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-facility-list' }" class="">
              Типы объектов
              <span class="hint">Справочник типов объектов</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-pest-list' }" class="">
              Вредители
              <span class="hint">Типы вредители в заявках </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-call-cause-list' }" class="">
              Причины звонков клиенту
              <span class="hint">Указываются исполнителем при звонке</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-reject-cause-list' }" class="">
              Причины отказа заявки
              <span class="hint">Указываются при отмене заявки</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-source-list' }" class="">
              Источники заявок
              <span class="hint">Метки с Roistat</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-aftercall-fail-list' }" class="">
              Причина провала прозвона
              <span class="hint">Указываются в исходе прозвона</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="true" class="col-sm-12 col-md-3">
        <div v-if="canSeeClosedReports">
          <h2>Яндекс Директ</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'direct-dashboard' }" class="">
                Консоль
                <span class="hint">Сводная информация и команды</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'direct-account-list' }" class="">
                Учетные записи
                <span class="hint">Токены доступа</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'direct-campaign-list' }" class="">
                Рекламные кампании
                <span class="hint">Справочник кампаний</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'direct-ad-report' }" class="">
                Отчет по рекламе
                <span class="hint">Данны затрат по рекламе из Яндекса</span>
              </router-link>
            </li>

            <li v-if="isShadow">
              <router-link :to="{ name: 'direct-report-list' }" class="">
                Отчеты
                <span class="hint">Справочник отчетов из яндекса</span>
              </router-link>
            </li>
            <li v-if="isShadow">
              <router-link :to="{ name: 'direct-record-list' }" class="">
                Данные выгрузок из яндекса
                <span class="hint">Голые данные с яндекса</span>
              </router-link>
            </li>
            <li v-if="isShadow">
              <router-link
                :to="{ name: 'direct-money-result-list' }"
                class=""
              >
                Отчет по суммам затрат кампаний
                <span class="hint">Данные по кампаниям</span>
              </router-link>
            </li>
            <li v-if="isShadow">
              <router-link :to="{ name: 'direct-result-list' }" class="">
                Итоговый отчет
                <span class="hint">Консолидированные итоговые данные</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="true" class="col-sm-12 col-md-3">
        <h2>Другие</h2>
        <ul>
          <li v-if="false">
            <router-link
              v-if="canSeeAllNotifications"
              :to="{ name: 'notifications-notification-all-list' }"
              class=""
            >
              Уведомления
              <span class="hint">Все уведомления пользователям</span>
            </router-link>
          </li>
          <li>
            <router-link
              v-if="canSeeRequestHistory"
              :to="{ name: 'request-log-all-list' }"
              class=""
            >
              История событий
              <span class="hint">Все события в системе </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pest-shop-resource-list' }" class="">
              Материалы в магазине
              <span class="hint">Справочник материалов в магазине</span>
            </router-link>
          </li>
          <li>
            <router-link
              v-if="canSeeRequestHistory"
              :to="{ name: 'sms-message-list' }"
              class=""
            >
              Смс сообщения
              <span class="hint">Смс сообщения </span>
            </router-link>
          </li>

          <li v-if="isAdmin">
            <router-link :to="{ name: 'service-menu' }" class="">
              Сервисное меню
              <span class="hint">Служебные запросы</span>
            </router-link>
          </li>

          <li><system-version /></li>
        </ul>
        <ul></ul>
      </div>
    </div>
  </div>
</div>
