
<div class="ibox">
  <div class="ibox-title d-flex pr-2">
    <h4>Конвертация заявки</h4>
  </div>
  <div class="ibox-content">
    <b-form
      id="convert-request-form"
      ref="request"
      class="convert-request-form"
      novalidate
      @submit.prevent="createOrUpdate('roistat')"
      @reset="onReset"
    >
      <div class="div">
        <b-form-group label="Номер" prop="title">
          <template #label> Номер заявки</template>
          <b-form-input v-model="number" tabindex="1" required></b-form-input>
        </b-form-group>
        <b-form-group label="Номер" prop="title">
          <template #label> Номер контракта</template>
          <b-form-input
            v-model="contractId"
            tabindex="1"
            placeholder="Оставьте пустым для нового"
          ></b-form-input>
        </b-form-group>
      </div>
    </b-form>
  </div>

  <div class="ibox-footer d-flex">
    <div class=" ">
      <b-button v-if="!saving" variant="primary" @click="createOrUpdate">
        <font-awesome-icon icon="paper-plane" />
        Сконвертировать
      </b-button>
    </div>

    <b-button v-if="saving" variant="primary" disabled @click.prevent>
      <font-awesome-icon icon="sync" spin="" />
      Отправляется
    </b-button>
  </div>
</div>
