export const MSK = 1;
export const SPB = 2;
export const DXB = 6;
export const MSK_C = 4;
export const SPB_C = 5;

export const townOptions = [
  {
    value: MSK,
    text: "Москва",
  },
  {
    value: MSK_C,
    text: "Московская область",
  },
  {
    value: SPB,
    text: "Санкт-Петербург",
  },
  {
    value: SPB_C,
    text: "Ленинградская область",
  },
];

export const getTownTitle = function (town) {
  if (!town || !town.title) {
    return "[Не назначен]";
  }
  return town.title;
};
