
<div>
  <table class="table table-striped res-table">
    <tbody>
      <tr>
        <td>
          <font-awesome-icon icon="user-cog" fixed-width />
          Исполнитель
        </td>
        <td>
          <span v-if="request.assigneeId">
            {{ getRequestAssigneeName(request) }}
            <router-link
              :to="{
                name: 'users-user-view',
                params: { id: request.ass4igneeId },
              }"
              class=""
            >
              <font-awesome-icon
                icon="external-link-square-alt"
                fixed-width
              />
            </router-link>
          </span>
          <span v-else>[Не назначен]</span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="car" fixed-width />
          Транспорт
          <font-awesome-icon icon="arrow-right" fixed-width />
        </td>
        <td>
          <span v-if="request.tripSum || request.tripDistance">
            {{ request.tripSum }}р – {{ request.tripDistance }}км
          </span>
          <span v-else>Не задано</span>
        </td>
      </tr>

      <tr v-if="request.tripBackSum || request.tripBackDistance">
        <td>
          <font-awesome-icon icon="car" fixed-width />
          Транспорт
          <font-awesome-icon icon="arrow-left" fixed-width />
        </td>
        <td>
          <span v-if="request.tripBackSum || request.tripBackDistance">
            {{ request.tripBackSum }}р – {{ request.tripBackDistance }}км
          </span>
          <span v-else>Не задано</span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Сумма
        </td>
        <td>
          {{ request.sum }}
        </td>
      </tr>
      <tr v-if="hasAnyResources(request)">
        <td>
          <font-awesome-icon icon="vial" fixed-width />
          Материалы
        </td>
        <td>
          <ul class="mb-0 pl-3">
            <li
              v-for="(resource, rIndex) in request.resources"
              :key="'r' + rIndex"
            >
              {{
                resource.resource
                  ? resource.resource.title
                  : "[Без материала]"
              }}
              – {{ resource.count }}
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="cogs" fixed-width />
          Услуги
        </td>
        <td>
          <ul class="mb-0 pl-3">
            <li
              v-for="(service, rIndex) in request.services"
              :key="'r' + rIndex"
            >
              {{ service.title }} – {{ service.price }}
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request) && request.prevRecordSum">
        <td>
          <font-awesome-icon icon="star" fixed-width />
          Предыдущий рекорд
        </td>
        <td>
          <strong> {{ request.prevRecordSum }}р</strong>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Стоимость услуг
        </td>
        <td>
          <strong> {{ servicesSum(request) }}р</strong>
        </td>
      </tr>
      <tr v-if="equipmentSum(request) > 0">
        <td>
          <font-awesome-icon icon="tools" fixed-width />
          Оборудование
        </td>
        <td>
          <strong> {{ equipmentSum(request) }}р</strong>
        </td>
      </tr>

      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Заработано
        </td>
        <td>
          <strong> {{ request.earned }}р</strong>
          <span v-if="request.earnPercent">
            ({{ request.earnPercent }} %)
          </span>
          <span v-if="request.hasRecord">
            <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
          </span>
        </td>
      </tr>
      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          ... +
          <font-awesome-icon icon="car" fixed-width />
          -
          <font-awesome-icon icon="tools" fixed-width />
        </td>
        <td>
          <strong> {{ calcProfitSum(request) }}р</strong>
        </td>
      </tr>

      <tr v-if="hasAnyServices(request)">
        <td>
          <font-awesome-icon icon="money-bill" fixed-width />
          К выплате
        </td>
        <td>
          <strong> {{ request.paymentSum }}р</strong>
        </td>
      </tr>

      <tr v-if="request.assigneeComment">
        <td>
          <font-awesome-icon icon="comment" fixed-width />
          Комментарий
        </td>
        <td>
          <span>{{ request.assigneeComment }}</span>
        </td>
      </tr>

      <tr v-if="request.resultFiles && request.resultFiles.length">
        <td>
          <font-awesome-icon icon="file" fixed-width />
          Файлы
        </td>
        <td>
          <div class="files-block">
            <ul class="pl-4">
              <li
                v-for="(file, fIndex) in request.resultFiles"
                :key="'i' + fIndex"
              >
                <a
                  v-b-tooltip.hover
                  :href="file.url"
                  :title="file.name"
                  target="_blank"
                >
                  {{ file.name + "." + file.extension }}
                </a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
