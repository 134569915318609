

<b-form :model="document" id="document-form" ref="document" class="document-form "
        @submit="createOrUpdate" @reset="onReset"
        novalidate>
  <div class="div">

    <b-form-file
        v-model="files"
        :state="Boolean(files)"
        ref="file"
        multiple
        placeholder="Выберите файлы или перенесите их сюда"
        drop-placeholder="Перенесите сюда"
        browse-text="Выбрать"
        @change="handleFileChange"
    ></b-form-file>


    <div v-if="files" class="mt-2">
      <table class="table table-striped">
        <thead>
        <tr>
          <!--            <td>Файл</td>-->
          <td>Название</td>
          <td>Тип</td>
          <td>Комментарий</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(fileInfo, index) in info" :key="index">
          <!--            <td class="file-title">-->
          <!--              {{ fileInfo.sourceName }}-->
          <!--            </td>-->
          <td>
            <b-form-input v-model="fileInfo.name"></b-form-input>
          </td>
          <td>
            <b-form-select v-model="fileInfo.type" :options="fileTypes"></b-form-select>
          </td>
          <td>
            <b-form-input v-model="fileInfo.comment"></b-form-input>
          </td>
        </tr>

        </tbody>

      </table>
    </div>

  </div>

</b-form>
