<template>
  <div class="ibox mb-0">
    <div class="ibox-title search-ibox-wrapper">
      <div class="search">
        <div class="d-flex align-items-center" style="overflow-x: auto">
          <div class="mr-2">
            <button
              v-if="callNotificationsCount"
              v-b-tooltip.hover.right
              class="btn mr-2"
              :class="{
                'btn-outline-secondary': !search.withCallNotification,
                'btn-secondary': search.withCallNotification,
              }"
              type="button"
              title="Назначенные звонки"
              @click.prevent="toggleCallNotificationFilter"
            >
              <font-awesome-icon icon="phone" />
              <strong> {{ callNotificationsCount }}</strong>
            </button>

            <button
              class="btn btn-outline-secondary"
              :disabled="loading"
              @click="showSelectColumnsPopup"
            >
              <font-awesome-icon icon="fa-solid fa-gear" />
            </button>
          </div>

          <common-filter
            class="ml-auto mr-2"
            :all-statuses="allStatuses"
            :loading="loading"
            @search="startSearch"
          />

          <div v-if="!loading" class="mr-2">
            Найдено:
            <span>{{ pagination.total }}</span>
          </div>

          <div class="ml-2">
            <button
              v-b-tooltip.hover.left
              class="p-0 managerDashboardBtn"
              title="Дашборд операторов"
              @click.prevent="showManagerDashboard = !showManagerDashboard"
            >
              <img
                :src="chartIcon"
                alt="Дашборд операторов"
                class="managerDashboardIcon"
              />
              <!--              <font-awesome-icon icon="chart-pie" />-->
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-content gray-bg pl-0 pr-0 requests-box">
      <b-overlay :show="loading" style="height: 100%">
        <div class="cont">
          <vuescroll :style="{ width: '100%', height: '100%' }">
            <div class="d-flex align-items-stretch">
              <div
                v-for="(requestsColumn, r2Index) in allRequests"
                :key="r2Index + 'col'"
                class="ibox ml-1 mr-1 mb-0"
                :class="{ 'd-none': !isColumnSelected(requestsColumn) }"
              >
                <div
                  class="ibox-title pl-2 pr-2 pt-1 pb-1 assigneeHeader"
                  :class="statusHeaderClass(requestsColumn.status)"
                  :style="statusHeaderStyle(requestsColumn.status)"
                >
                  <div
                    class="man-col-header-wrapper"
                    :style="statusHeaderStyle(requestsColumn.status)"
                  >
                    <div class="man-col-header h6 pb-0 pt-1">
                      <div class="d-flex">
                        <div class="user-header-name">
                          {{ getStatusName(requestsColumn.status) }}&nbsp;
                        </div>
                        <div class="user-header-count ml-auto">
                          <small>{{ requestsColumn.count }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <vuescroll
                  class="foo"
                  :style="{ width: '100%', height: '85vh' }"
                >
                  <div class="ibox-content pl-2 pr-2">
                    <button
                      v-if="
                        requestsColumn.stage === STAGE_LEAD && r2Index === 0
                      "
                      class="btn btn-block btn-outline-info mb-2"
                      @click.prevent="openNewRequestForm(requestsColumn.status)"
                    >
                      <font-awesome-icon icon="plus" />
                      Добавить лид
                    </button>

                    <draggable
                      class="list-group"
                      :list="requestsColumn.items"
                      group="people"
                      handle=".handle"
                      :disabled="
                        !editing || isDraggableDisabled(requestsColumn)
                      "
                      @change="
                        updateStatus(
                          $event,
                          requestsColumn.stage,
                          requestsColumn.status
                        )
                      "
                    >
                      <request-card
                        v-for="(request, rIndex) in requestsColumn.items"
                        :key="request.id + '-r'"
                        :counter="counter"
                        :r-i="rIndex"
                        :request="request"
                        :status="requestsColumn.status"
                        :update-button="true"
                        :lead-board="true"
                        :short-version="true"
                        @show-popup="showRequestPopup"
                      ></request-card>

                      <button
                        v-if="requestsColumn.loadMore"
                        class="btn btn-block btn-outline-info mt-2"
                        :disabled="requestsColumn.loadingData"
                        @click.prevent="loadMore(requestsColumn)"
                      >
                        <span v-if="!requestsColumn.loadingData"
                          >Загрузить еще</span
                        >
                        <span v-else
                          ><font-awesome-icon icon="rotate" spin />
                          Загружается</span
                        >
                      </button>
                    </draggable>
                  </div>
                </vuescroll>
              </div>
            </div>
          </vuescroll>
        </div>
      </b-overlay>
    </div>

    <request-global-popup></request-global-popup>
    <manager-dashboard-popup
      :show="showManagerDashboard"
    ></manager-dashboard-popup>

    <board-columns-popup
      :columns="columns"
      :selected-columns="selectedColumns"
      @changed="columnsSelected"
    ></board-columns-popup>
  </div>
</template>

<script>
import {
  checkFilter,
  CLIENT_TYPE_IND,
  emptyRequest,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestDeadline,
  getRequestManagerName,
  getRequestStatus,
  getRequestTitle,
  getRequestUpdateTime,
  STAGE_DEAL,
  STAGE_LEAD,
  STATUS_DECLINE,
  STATUS_DISPATCHED,
  STATUS_DONE,
  STATUS_NEW,
  STATUS_PROGRESS,
} from "@/modules/pest/decorators/requests.js";
import { getAssigneeName, getUserName } from "@/modules/crm/common.js";
import RequestCard from "@/modules/pest/views/requests/card/RequestCard.vue";
import draggable from "vuedraggable";
import { MSK, SPB } from "@/modules/pest/decorators/towns.js";
import {
  getStatusName,
  statusHeaderStyle,
} from "@/modules/crm/decorators/statusDecorator.js";
import CommonFilter from "@/components/shared/CommonFilter.vue";
import clone from "just-clone";
import RequestGlobalPopup from "@/modules/pest/views/requests/kanban/RequestGlobalPopup";
import dayjs from "dayjs";
import { formatPhone } from "@/modules/pest/decorators/phones";
import {
  highlightRequest,
  updateRequest,
} from "@/modules/pest/helpers/kanban/requests";
import ManagerDashboardPopup from "@/modules/pest/views/requests/kanban/ManagerDashboardPopup.vue";
import chartIcon from "../../../../../assets/img/managers/chart.png";
import vuescroll from "vuescroll";
import BoardColumnsPopup from "@/modules/pest/views/requests/kanban/popup/BoardColumnsPopup.vue";
import { mapActions, mapGetters } from "vuex";
import { KANBAN_SELECTED_COLUMNS } from "@/modules/pest/helpers/kanban/kanban";

export default {
  name: "RequestBoard",
  components: {
    BoardColumnsPopup,
    ManagerDashboardPopup,
    RequestGlobalPopup,
    CommonFilter,
    RequestCard,
    draggable: draggable,
    vuescroll,
  },
  data() {
    return {
      counter: 0,
      showManagerDashboard: false,
      chartIcon: chartIcon,
      STAGE_LEAD,
      STAGE_DEAL,
      STATUS_DECLINE,
      STATUS_DISPATCHED,
      STATUS_DONE,
      STATUS_NEW,
      STATUS_PROGRESS,
      MSK,
      SPB,
      newRequest: {
        id: "new",
        clientType: CLIENT_TYPE_IND,
        title: "",
        paymentType: null,
        parentId: "",
        groupId: "",
        tripDistance: "",
        tripSum: "",
        leadStatusId: null,
        leadStatus: null,
        managerId: this.$store.state.user.id,
        manager: clone(this.$store.state.user.profile),
      },
      allRequests: [],
      allStatuses: [],
      leads: [],
      requests: [],
      search: {
        title: "",
        date: "",
        dateFrom: "",
        dateTo: "",
        createDateFrom: "",
        createDateTo: "",
        townId: null,
        leadStatusesIds: null,
        dealStatusesIds: null,
        managerId: "",
        assigneeId: "",
        clientType: "",
        pestId: "",
        sourceId: "",
        isFirstTime: "",
        aftercallStatusId: "",
        aftercallFailId: "",
        withCallNotification: null,
        serviceSumFrom: null,
        serviceSumTo: null,
      },
      filteredCount: 0,
      callNotificationsCount: 0,
      filter: {
        search: "",
      },
      townList: [],
      openRequests: [],
      closedRequests: [],
      statusesList: [],
      shownCallNotifications: [],
      loadingData: false,
      savingStatus: false,
      editing: true,
      archived: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      dateFormat: "DD.MM.YYYY",
      toastId: 0,
      selectedCols: null,
      columns: [],
    };
  },
  watch: {
    $route(to, from) {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters({
      userId: "user/userId",
      shouldAutoShowManagerDashboard: "auth/shouldAutoShowManagerDashboard",
    }),
    loading() {
      return this.loadingData;
    },
    projectId() {
      return this.$route.params.project !== undefined
        ? parseInt(this.$route.params.project)
        : 1;
    },
    selectedColumns: {
      get: function () {
        let cols = this.getProp()(KANBAN_SELECTED_COLUMNS);
        return cols ? cols : this.selectedCols;
      },
      set: function (columns) {
        this.selectedCols = columns;
        this.setProp({ key: KANBAN_SELECTED_COLUMNS, value: columns });
        this.$forceUpdate();
      },
    },
  },
  created() {
    // this.search.date = dayjs().format('DD.MM.YYYY');
    this.$eventHub.$on("request-created", this.addNewRequest);
    this.$eventHub.$on("s-request-update", this.onRequestUpdate);
    this.$eventHub.$on("s-request-create", this.onRequestCreate);
    this.$eventHub.$on("s-incoming-call", this.onCallNotification);
    this.$eventHub.$on("s-incoming-call-end", this.onCallEndNotification);
    this.$eventHub.$on("s-request-change-lead-status", this.leadStatusChanged);
    this.$eventHub.$on(
      "s-scheduled-call-notifications",
      this.onScheduledCallNotifications
    );

    if (this.shouldAutoShowManagerDashboard) {
      setInterval(() => {
        this.showManagerDashboard = true;
      }, 60 * 60 * 1000); // раз в час показываем
    }
  },
  mounted() {
    if (this.shouldAutoShowManagerDashboard) {
      this.showManagerDashboard = true;
    }

    this.fetchData();
    document.getElementById("page-wrapper").classList.add("board");
  },
  destroyed() {
    document.getElementById("page-wrapper").classList.remove("board");
  },
  beforeDestroy() {
    // this.$eventHub.$off('assignee-changed');
    // this.$eventHub.$off('request-created');
    // this.$eventHub.$off('s-incoming-call');
    // this.$eventHub.$off('s-request-update');
    // this.$eventHub.$off('s-request-change-lead-status');
  },
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    columnsSelected(columns) {
      this.selectedColumns = columns;
    },
    isColumnSelected(col) {
      if (!this.selectedColumns) return true;
      return this.selectedColumns.includes(col.status.id);
    },

    getStatusName,
    getUserName,
    getAssigneeName,
    getRequestTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    checkFilter,
    getRequestDeadline,
    getRequestUpdateTime,
    statusHeaderStyle,
    startSearch(params) {
      Object.assign(this.search, params);
      this.fetchData();
    },
    toggleCallNotificationFilter() {
      this.search.withCallNotification = !this.search.withCallNotification
        ? 1
        : null;
      this.fetchData();
    },
    fetchData: function () {
      this.fetchKanbanData();
    },
    processStatusesList() {
      this.allRequests = [];
      // this.allRequests.push({
      //   'status': {id: 0},
      //   'items': [],
      // });
      this.columns = [];

      for (let status of this.statusesList) {
        if (status.stage === STAGE_LEAD) {
          let id = status.id ? status.id : 0;
          this.allRequests.push({
            loadMore: true,
            loadingData: false,
            status: status,
            count: status.count,
            stage: STAGE_LEAD,
            items: [],
          });

          this.columns.push({ id: status.id, title: status.title });
        }
      }
      this.setRequestStatuses();
      this.allStatuses = this.allRequests;
    },
    setRequestStatuses() {
      let dealNewStatus = this.statusesList.find(
        (s) => s.stage === STAGE_DEAL && s.status === STATUS_NEW
      );
      this.columns.push({ id: "NEW", title: "Оформлен заказ" });
      this.allRequests.push({
        status: {
          color: "#00AAE3",
          textColor: "#ffffff",
          cardColor: "#00AAE3",
          title: "Оформлен заказ",
          id: "NEW",
          dealStatus: STATUS_NEW,
        },
        loadMore: true,
        loadingData: false,
        stage: STAGE_DEAL,
        items: [],
        count: dealNewStatus ? dealNewStatus.count : 0,
      });
      let dealDeclineStatus = this.statusesList.find(
        (s) => s.stage === STAGE_DEAL && s.status === STATUS_DECLINE
      );
      this.columns.push({ id: "DECLINE", title: "Заказ провален" });
      this.allRequests.push({
        status: {
          color: "#FF100E",
          textColor: "#ffffff",
          cardColor: "#FF100E",
          title: "Заказ провален",
          id: "DECLINE",
          dealStatus: STATUS_DECLINE,
        },
        loadMore: true,
        loadingData: false,
        stage: STAGE_DEAL,
        items: [],
        count: dealDeclineStatus ? dealDeclineStatus.count : 0,
      });
      let dealDoneStatus = this.statusesList.find(
        (s) => s.stage === STAGE_DEAL && s.status === STATUS_DONE
      );
      this.columns.push({ id: "DONE", title: "Заказ успешен" });
      this.allRequests.push({
        status: {
          color: "#dbf199",
          textColor: "#000000",
          cardColor: "#1AB394",
          title: "Заказ успешен",
          id: "DONE",
          dealStatus: STATUS_DONE,
        },
        stage: STAGE_DEAL,
        loadMore: true,
        loadingData: false,
        items: [],
        count: dealDoneStatus ? dealDoneStatus.count : 0,
      });
    },

    // updateRequest(request) {
    //   for (let cmpRequest of this.requests) {
    //     if (cmpRequest.id === request.id) {
    //       Object.assign(cmpRequest, request);
    //       this.filterRequests();
    //       return;
    //     }
    //   }
    // },

    addNewRequest(newRequest) {
      let sameBoard = newRequest.projectId === this.projectId;
      let found = this.requests.find((r) => r.id === newRequest.id);
      console.log({
        message: "new request",
        request: newRequest,
        found: found,
        sameBoard: sameBoard,
        requestProjectId: newRequest.projectId,
        thisProjectId: this.projectId,
      });
      if (!found && sameBoard) {
        this.requests.push(newRequest);
        this.pagination.total++;
        this.filterRequests();
      }
    },
    filterRequests(initial) {
      for (let info of this.allRequests) {
        info.items = this.requests.filter((r) => {
          let sameStage = r.stage === info.stage;
          let sameStatus;
          if (info.stage === STAGE_LEAD) {
            sameStatus = r.leadStatusId === info.status.id;
          } else {
            sameStatus = info.status.dealStatus === r.status;
          }
          return sameStage && sameStatus;
        });
        if (initial) {
          info.loadMore = !!info.items.length;
        }
      }
    },
    fetchKanbanData: function () {
      if (this.loadingData) {
        return;
      }
      this.loadingData = true;
      let requestsUrl = "/pest/request/kanban";
      let params = {
        search: this.search.text,
        phoneNumber: this.search.phoneNumber,
        cardId: this.search.cardId,
        address: this.search.address,
        townId: this.search.townId,
        dealStatusesIds: this.search.dealStatusesIds,
        leadStatusesIds: this.search.leadStatusesIds,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        createDateFrom: this.search.createDateFrom,
        createDateTo: this.search.createDateTo,
        orderDateFrom: this.search.orderDateFrom,
        orderDateTo: this.search.orderDateTo,
        paymentTypes: this.search.paymentTypes,
        assigneeId: this.search.assigneeId,
        managerId: this.search.managerId,
        pestId: this.search.pestId,
        sourceId: this.search.sourceId,
        clientType: this.search.clientType,
        isFirstTime: this.search.isFirstTime,
        aftercallStatusId: this.search.aftercallStatusId,
        aftercallFailId: this.search.aftercallFailId,
        withCallNotification: this.search.withCallNotification,
        serviceSumFrom: this.search.serviceSumFrom,
        serviceSumTo: this.search.serviceSumTo,
        projectId: this.projectId,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then(({ data }) => {
          this.statusesList = data.statuses;
          this.processStatusesList();
          this.requests = data.requests;
          this.callNotificationsCount = data.callNotificationsCount;
          this.filterRequests(true);
          this.loadingData = false;
          window.scrollTo(0, 0);
          let requestCount = 0;
          this.statusesList.forEach(function (status) {
            requestCount += status.count;
          });
          this.pagination.total = requestCount;
        })
        .catch((error) => {
          this.loadingData = false;
          this.processRequestError(error);
        });
    },
    /**
     * подзагрузка данных
     * @param info
     */
    loadMore: function (info) {
      if (info.loadingData) {
        return;
      }
      info.loadingData = true;
      let requestsUrl = "/pest/request/kanban";
      let params = {
        loadMore: true,
        stage: info.stage,
        offset: info.items.length,
        search: this.search.text,
        phoneNumber: this.search.phoneNumber,
        cardId: this.search.cardId,
        address: this.search.address,
        paymentTypes: this.search.paymentTypes,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        createDateFrom: this.search.createDateFrom,
        createDateTo: this.search.createDateTo,
        orderDateFrom: this.search.orderDateFrom,
        orderDateTo: this.search.orderDateTo,
        pestId: this.search.pestId,
        facilityId: this.search.facilityId,
        assigneeId: this.search.assigneeId,
        managerId: this.search.managerId,
        isFirstTime: this.search.isFirstTime,
        clientType: this.search.clientType,
        townId: this.search.townId,
        leadStatusesIds: this.search.leadStatusesIds,
        dealStatusesIds: this.search.dealStatusesIds,
        aftercallStatusId: this.search.aftercallStatusId,
        aftercallFailId: this.search.aftercallFailId,
        serviceSumFrom: this.search.serviceSumFrom,
        serviceSumTo: this.search.serviceSumTo,
        withCallNotification: this.search.withCallNotification,
        projectId: this.projectId,
      };
      if (info.stage === STAGE_LEAD) {
        params.leadStatusId = info.status.id;
      } else {
        params.status = info.status.dealStatus;
      }
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then(({ data }) => {
          this.requests.push(...data.requests);
          if (!data.requests.length) {
            info.loadMore = false;
          }
          this.callNotificationsCount = data.callNotificationsCount;
          this.filterRequests();
          info.loadingData = false;
        })
        .catch((error) => {
          info.loadingData = false;
          this.processRequestError(error);
        });
    },
    updateStatus(event, newStage, newStatus) {
      if (!("added" in event)) {
        return;
      }
      let request = event.added.element;
      if (request.stage !== newStage) {
        // console.log('stage');
        request.stage = newStage;
      } else {
        // console.log('status');
        request.leadStatus = newStatus;
        request.leadStatusId = newStatus.id;
      }
      this.saveStatusId(request, true);
    },
    onScheduledCallNotifications(info) {
      if (!info || !info.message || !info.message.notifications) {
        return;
      }

      for (let notification of info.message.notifications) {
        let currentUserId = this.userId;
        let requestId = notification.requestId;

        if (notification.userId !== currentUserId) {
          continue;
        }
        if (this.shownCallNotifications.includes(requestId)) {
          continue;
        }
        this.shownCallNotifications.push(requestId);

        const h = this.$createElement;
        this.toastId++;
        let toastId = "toast" + requestId;

        // let request = this.requests.find(r => r.id === requestId);
        let callTime = dayjs.unix(notification.time).format("HH:mm");
        let toastTitle = "Назначен звонок на " + callTime;
        let buttonTitle = "Заявка №" + requestId;
        const msg = h("p", { class: ["text-center", "mb-0"] }, [
          h(
            "button",
            {
              on: { click: () => this.openCall(toastId, requestId) },
              class: "btn btn-danger",
            },
            buttonTitle
          ),
        ]);

        this.$bvToast.toast(msg, {
          id: toastId,
          title: toastTitle,
          toaster: "b-toaster-top-center",
          solid: true,
          appendToast: true,
          noAutoHide: true,
          noCloseButton: true,
        });
      }
    },
    leadStatusChanged(info) {
      let $this = this;
      for (let request of this.requests) {
        if (request.id === info.requestId) {
          request.leadStatusId = info.newStatusId;
          this.filterRequests();
          highlightRequest(this, request, true, true);
          return;
        }
      }
      console.log(info);
    },
    onRequestCreate(info) {
      this.addNewRequest(info.request);
    },
    onRequestUpdate(info) {
      let $this = this;
      let updatedRequest = info.request;
      updateRequest(this, updatedRequest);
      highlightRequest(this, updatedRequest, true, true);
      // for (let cmpRequest of this.requests) {
      //   if (cmpRequest.id === updatedRequest.id) {
      //     updatedRequest.highlighted = true;
      //     Object.assign(cmpRequest, updatedRequest);
      //     highlightRequest(this, updatedRequest, true, true);
      //     setTimeout(function () {
      //       cmpRequest.highlighted = false;
      //       $this.counter++;
      //       $this.filterRequests();
      //     }, 500);
      //     return;
      //   }
      // }
    },
    saveStatusId(request, force) {
      let updateUrl;
      updateUrl = "/pest/request/status/" + request.id;
      if (this.savingStatus && !force) {
        return;
      }
      this.savingStatus = true;
      let params = {
        id: request.id,
        stage: request.stage,
        leadStatusId: request.leadStatusId,
      };
      this.$http
        .put(updateUrl, params)
        .then((response) => {
          request = response.data;
          this.savingStatus = false;
          this.$emit("update-request-info", request);
          // this.$router.push({name: 'request-view', params: {id: this.request.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Статус изменен",
            request: "success",
          });
        })
        .catch((error) => {
          this.savingStatus = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось изменить статус",
              request: "danger",
            });
          }
        });
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    getTownClass(town) {
      if (this.search.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-info";
      }
    },
    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },
    statusHeaderClass(status) {
      return "";
    },

    isDraggableDisabled(info) {
      return (
        info.stage === STAGE_DEAL ||
        (info.stage === STAGE_LEAD && info.status.closeStatus)
      );
    },
    showRequestPopup({ request }, fromCall) {
      let $this = this;
      this.$eventHub.$emit("show-request-global-popup", {
        requestId: request.id,
        fromCall: fromCall,
        projectId: this.projectId,
        success: function () {},
      });
    },

    showUpdatePopup(info) {
      let $this = this;
      let request = info.request;
      // this.$eventHub.$emit('show-request-global-popup', {
      this.$eventHub.$emit("request-global-popup", {
        requestId: request,
        contractId: request.contractId,
        success: function (request) {
          Object.assign(info.request, request);
          $this.filterRequests();
        },
        cancel: function () {},
      });
    },

    showContractPopup(info) {
      let $this = this;
      let request = info.request;
      let contractId = info.contractId;
      this.$eventHub.$emit("modal-contract-popup", {
        request: request,
        contractId: contractId,
        success: function (request) {
          // Object.assign(info.request, request);
          // $this.filterRequests();
        },
        cancel: function () {},
      });
    },

    setEmptyRequest(status) {
      this.newRequest = clone(emptyRequest);
      this.newRequest.id = "new";
      this.newRequest.leadStatusId = status.id;
      this.newRequest.leadStatus = status;
      this.newRequest.stage = status.stage;
      this.newRequest.managerId = this.$store.state.user.id;
      this.newRequest.communications = [];
      this.newRequest.manager = clone(this.$store.state.user.profile);
      this.newRequest.projectId = this.projectId;
    },
    openNewRequestForm(status) {
      let $this = this;
      this.setEmptyRequest(status);

      this.$eventHub.$emit("show-request-global-popup", {
        request: this.newRequest,
        requestId: "new",
        hide: function (request) {
          $this.newRequest = request;
        },
        success: function (request) {
          $this.requests.push(request);
          $this.filterRequests();
          $this.pagination.total++;
          this.setEmptyRequest(status);
        },
        cancel: function () {},
      });
    },
    openCall(toastId, requestId) {
      this.$bvToast.hide(toastId);
      // let request = this.requests.find(r => r.id === requestId);
      if (requestId) {
        this.showRequestPopup({ request: { id: requestId } }, true);
      } else {
        console.log("Request " + requestId + " not found on board");
      }
    },

    onCallNotification(e) {
      let request = e.request;
      let userId = e.userId;
      let currentUserId = this.userId;
      if (currentUserId === userId) {
        this.showCallNotification(request); // Показываем уведомление о входящем звонке на доске
        highlightRequest(this, request, true); // Подсвечиваем заявку
      } else {
        console.log(["call for another manager", e]);
      }
    },
    onCallEndNotification(e) {
      let request = e.request;
      let toastId = "toast" + request.id;
      this.$bvToast.hide(toastId);
      highlightRequest(this, request, false); // Снимаем подсветку заявки
    },

    /**
     * Показываем уведомление о входящем звонке на доске
     * @param request
     */
    showCallNotification(request) {
      this.toastId++;
      const h = this.$createElement;
      let toastId = "toast" + request.id;
      // let request = this.requests.find(r => r.id === requestId);
      let title = "Звонок с неизвестного номера";
      if (request) {
        // title = request.phone + ' | Заявка №' + request.id;
        title = formatPhone(request.phone);
        if (request.contractId) {
          title += " | Контракт №" + request.contractId;
        } else {
          title += " | Заявка №" + request.id;
        }
      }
      const msg = h("p", { class: ["text-center", "mb-0"] }, [
        h(
          "button",
          {
            on: { click: () => this.openCall(toastId, request.id) },
            class: "btn btn-success ",
          },
          title
        ),
      ]);

      this.$bvToast.toast(msg, {
        id: toastId,
        title: "Входящий звонок",
        toaster: "b-toaster-top-left",
        solid: true,
        appendToast: true,
        noAutoHide: true,
        noCloseButton: true,
      });
    },
    showSelectColumnsPopup() {
      this.$eventHub.$emit("modal-board-columns-popup", {});
    },
  }, // methods
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
}

.request-organization {
  font-size: smaller;
  color: gray;
}

.man-col {
  border: 1px solid #cecece;
  //border-right: 1px solid #cecece;
  //padding-left: 10px;
  //padding-right: 10px;
}

.man-col-header {
  padding-bottom: 10px;
  //border-bottom: 3px solid #1ab394;
  max-width: 250px;
  text-overflow: ellipsis;
}

.man-col-header-wrapper {
  //position: -webkit-sticky;
  //position: sticky;
  //padding-top: 10px;
  //top: 0;
  background: #ffffff;
  //border: 1px gray;
}

.list-group {
  min-width: 180px;
  max-width: 250px;
  min-height: 250px;
  padding-bottom: 100px;
}

.ibox-content {
  height: 85vh;
  max-height: 85vh;
  //height: 82vh;
  //max-height: 82vh;
}

.cont {
  overflow-x: scroll;
  overflow-y: hidden;

  height: auto;
  max-height: 100vh;
}

.requests-box {
  overflow-y: hidden !important;
}
.requests-box .ibox:first-child {
  margin-left: 0 !important;
}
.search-ibox-wrapper {
  padding-right: 15px !important;
}

.man-col-header-wrapper {
  width: 240px;
}

.user-time-slot-wrapper {
  width: 250px;
  font-size: smaller;
}

.user-time-slot {
  font-size: smaller;
  float: left;
  margin-right: 2px;
}

.time-slot-half {
  color: #c5c5c5;
}

.time-slot-half:hover {
  color: inherit;
}

.user-time-slot.busy .time-slot-half {
  color: lightgray;
}

.user-time-slot.busy {
  text-decoration: line-through;
  color: lightgray;
}

.user-time-slot.user-busy,
.user-time-slot.user-busy .time-slot-half {
  text-decoration: line-through;
  color: #ee7981;
}

.user-time-slot {
  cursor: pointer;
}

.user-header-name {
  height: 25px;
  overflow-y: hidden;
}

.assigneeHeader {
  border-bottom: 3px solid gray;
}

.assigneeHeader.assigneeDuty1 {
  border-bottom: 3px solid #f5be25;
}

.assigneeHeader.assigneeDuty2 {
  border-bottom: 3px solid #ee7981;
}

.assigneeHeader.assigneeDayOff {
  border-bottom: 3px solid #17a2b8;
}

.ibox-title.editing {
  background: rgba(245, 150, 155, 0.85);
}

.mx-datepicker {
  height: 33px !important;
}
</style>
<style>
.mx-datepicker .form-control {
  /*font-size: 0.8rem !important;*/
  /*padding: 9px 12px !important;*/
}

.cont > .__vuescroll > .__rail-is-vertical {
  display: none !important;
}
.cont > .__vuescroll > .__rail-is-horizontal {
  position: fixed !important;
}
.__bar-is-horizontal,
.__bar-is-vertical {
  background: #676767 !important;
  opacity: 1 !important;
}
.__bar-is-horizontal {
  height: 8px !important;
}
.managerDashboardBtn {
  border: 0;
  background: inherit;
}

.managerDashboardIcon {
  max-width: 100%;
  max-height: 34px;
}
</style>
