<template>
  <div>
    <input
      id="file-input"
      ref="file"
      class="d-none"
      type="file"
      @input="inputChanged"
    />
    <button class="btn btn-info" :disabled="loading" @click="openFileInput">
      Загрузить дисконтные карты из файла
    </button>

    <font-awesome-icon v-if="loading" icon="rotate" class="ml-2" spin />
  </div>
</template>

<script>
export default {
  name: "UsersCardsImport",
  components: {},
  data() {
    return { loading: false };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {},
  },
  created: function () {},

  methods: {
    openFileInput() {
      this.$refs.file.click();
    },
    inputChanged(e) {
      this.submitFiles(e.target.files);
    },
    submitFiles(files) {
      if (!files) {
        return;
      }
      if (this.loading) return;
      this.loading = true;
      for (let file of files) {
        let formData = new FormData();
        formData.append("file", file);
        let postUrl = "/users/admin/import-discount-cards";
        this.$http
          .post(postUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.loading = false;
            this.$emit("updated", {});
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
