import dayjs from "dayjs";
import { getUserName } from "@/modules/crm/common";

export const getLogDatetime = function (log) {
  return dayjs.unix(log.time).format("DD.MM.YYYY HH:mm");
};

export const getLogUser = function (log) {
  return getUserName(log.user);
};
export const getLogRequestTitle = function (log) {
  return log.itemId;
  // return getUserName(log.user);
};

export const REQUEST_OPEN = "request_open";
export const REQUEST_CHANGED_ASSIGNEE = "change_assignee";
export const REQUEST_CHANGED_FIELDS = "change_fields";
export const REQUEST_CHANGED_MANAGER = "change_manager";
export const REQUEST_CHANGED_DATE = "change_date";
export const REQUEST_CHANGED_STATUS = "change_status";
export const REQUEST_BITRIX_CHANGED_ASSIGNEE = "bitrix_change_assignee";
export const REQUEST_BITRIX_CHANGED_DATE = "bitrix_change_date";
export const REQUEST_BITRIX_CHANGED_STATUS = "bitrix_change_status";
export const REQUEST_DONE = "request_done";
export const REQUEST_DECLINED = "request_declined";
export const REQUEST_CREATED_MANUALLY = "request_created_manually";
export const REQUEST_CREATED_DUE_IMPORT = "request_created_due_import";
export const REQUEST_CREATED_FROM_ROISTAT = "request_created_from_roistat";
export const CALL_TO_CLIENT = "call_to_client";
export const ZADARMA_CALL_RECORD = "call_record";
export const MANGO_CALL_RECORD = "mango_call_record";

export const REQUEST_CORRECTED = "request_corrected";
export const LEAD_CREATED_MANUALLY = "lead_created_manually";
export const LEAD_CHANGED_STATUS = "lead_change_status";
export const REQUEST_NEW_RECORD = "new_record";
export const EMPLOYEE_UNBLOCKED = "employee_unblocked";
export const REQUEST_ACCESS_APPARTMENT_SUCCESS =
  "request_access_appartment_success";
export const REQUEST_ACCESS_APPARTMENT_FAILURE =
  "request_access_appartment_failure";
export const REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE =
  "request_appartment_show_to_assignee";
export const REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE =
  "request_appartment_hide_from_assignee";
export const USER_COORDINATES = "user_coordinates";
export const DEPOSIT_CREATED = "deposit_created";
export const PAYMENT_CREATED = "payment_created";
export const CONVERTED_TO_CONTRACT = "converted_to_contract";
export const REQUEST_RETURN_VISIT = "request_return_visit";
export const DUPLICATE_LEAD = "duplicate_lead";
export const DUPLICATE_LEAD_ASSIGNED = "duplicate_lead_assigned";

export const LEAD_AUTO_INITIAL_CALL_QUEUED = "auto_initial_call_queued";
export const LEAD_AUTO_INITIAL_CALL_ANSWERED = "auto_initial_call_answered";
export const LEAD_AUTO_INITIAL_CALL_NOT_ANSWERED =
  "auto_initial_call_not_answered";
export const LEAD_AUTO_RECURRING_CALL_QUEUED = "auto_recurring_call_queued";
export const LEAD_AUTO_RECURRING_CALL_ANSWERED = "auto_recurring_call_answered";
export const LEAD_AUTO_RECURRING_CALL_NOT_ANSWERED =
  "auto_recurring_call_not_answered";
export const LEAD_AUTO_RECURRING_CALL_STOPPED = "auto_recurring_call_stopped";

export const NOTIFICATION_CALL_ADDED = "notification_call_added";
export const NOTIFICATION_CALL_DELETED = "notification_call_deleted";

export const REQUEST_YANDEX_CONVERSION_SEND =
  "request_yandex_offline_conversion_send";
export const REQUEST_YANDEX_CONVERSION_SKIPPED =
  "request_yandex_offline_conversion_skipped";

export const REQUEST_AFTERCALL = "request_aftercall";
export const REQUEST_REJECTED = "request_rejected";
export const REQUEST_ORDER_CREATED = "request_order_created";

export const USER_CHANGE_PASSWORD = "user_change_password";
export const USER_LOGIN_OK = "user_login_ok";
export const USER_LOGIN_ERROR = "user_login_error";
export const USER_LOGOUT = "user_logout";
export const REQUEST_REANIMATED = "request_reanimated";

export const logTitles = {
  [REQUEST_OPEN]: "Просмотр заявки",
  [REQUEST_CHANGED_ASSIGNEE]: "Смена мастера",
  [REQUEST_CHANGED_MANAGER]: "Смена менеджера",
  [REQUEST_CHANGED_DATE]: "Смена даты",
  [REQUEST_CHANGED_STATUS]: "Смена статуса",
  [REQUEST_BITRIX_CHANGED_ASSIGNEE]: "Смена мастера из битрикса",
  [REQUEST_BITRIX_CHANGED_DATE]: "Смена даты из битрикса",
  [REQUEST_BITRIX_CHANGED_STATUS]: "Смена статуса из битрикса",
  [REQUEST_DONE]: "Закрытие заявки",
  [REQUEST_DECLINED]: "Отклонение заявки",
  [REQUEST_CREATED_MANUALLY]: "Создана новая заявка вручную",
  [REQUEST_CHANGED_FIELDS]: "Заявка изменена",
  [REQUEST_CREATED_DUE_IMPORT]: "Загружена новая заявка из битрикса",
  [REQUEST_CREATED_FROM_ROISTAT]: "Новая заявка из Roistat",
  [CALL_TO_CLIENT]: "Звонок клиенту",
  [ZADARMA_CALL_RECORD]: "Звонок клиенту",
  [MANGO_CALL_RECORD]: "Звонок c клиентом",
  [REQUEST_CORRECTED]: "Заявка скорректирована",
  [LEAD_CREATED_MANUALLY]: "Лид добавлен менеджером",
  [LEAD_CHANGED_STATUS]: "Смена статуса лида",
  [REQUEST_NEW_RECORD]: "Новый рекорд",
  [CONVERTED_TO_CONTRACT]: "Оформление контракта",
  [DUPLICATE_LEAD]: "Повторное обращение по лиду",
  [DUPLICATE_LEAD_ASSIGNED]: "Привязано повторное обращение по лиду",
  [REQUEST_RETURN_VISIT]: "Оформлена повторная заявка",

  [EMPLOYEE_UNBLOCKED]: "Мастер разблокирован",
  [REQUEST_ACCESS_APPARTMENT_SUCCESS]: "Мастер посмотрел квартиру",
  [REQUEST_ACCESS_APPARTMENT_FAILURE]: "Квартира недоступна для просмотра",
  [REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE]: "Квартира открыта для мастера",
  [REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE]: "Квартира скрыта для мастера",
  [USER_COORDINATES]: "Координаты мастера",
  [DEPOSIT_CREATED]: "Внесена касса",
  [PAYMENT_CREATED]: "Зарегистрирован платеж",

  [LEAD_AUTO_INITIAL_CALL_QUEUED]:
    "Первичный звонок по заявке поставлен в очередь",
  [LEAD_AUTO_INITIAL_CALL_ANSWERED]: "Клиент ответил на звонок",
  [LEAD_AUTO_INITIAL_CALL_NOT_ANSWERED]: "Нет ответа на звонок",
  [LEAD_AUTO_RECURRING_CALL_QUEUED]:
    "Повторный звонок по заявке поставлен в очередь",
  [LEAD_AUTO_RECURRING_CALL_ANSWERED]: "Клиент ответил на повторный звонок",
  [LEAD_AUTO_RECURRING_CALL_NOT_ANSWERED]: "Нет ответа на повторный звонок",
  [LEAD_AUTO_RECURRING_CALL_STOPPED]: "Повторные звонки прекращены",

  [NOTIFICATION_CALL_ADDED]: "Назначено напоминание о звонке",
  [NOTIFICATION_CALL_DELETED]: "Удалено напоминание о звонке",

  [REQUEST_AFTERCALL]: "Внесен исход прозвона",
  [REQUEST_REJECTED]: "Заявка провалена",
  [REQUEST_REANIMATED]: "Сделка реанимирована",
  [REQUEST_ORDER_CREATED]: "Заказ оформлен",
  [REQUEST_YANDEX_CONVERSION_SEND]: "Отправлен результат оффлайн-конверсии",
  [REQUEST_YANDEX_CONVERSION_SKIPPED]:
    "Результат оффлайн-конверсии не будет отправляться",

  [USER_CHANGE_PASSWORD]: "Смена пароля пользователя",
  [USER_LOGIN_OK]: "Вход пользователя в систему",
  [USER_LOGIN_ERROR]: "Неудачный вход пользователя в систему",
  [USER_LOGOUT]: "Выход пользователя из системы",
};

export const logOptions = Object.entries(logTitles).map(([k, v]) => {
  return { value: k, text: v };
});

export const getLogTypeTitle = function (log) {
  if (!log.type) {
    return "[Не назначено]";
  }
  return logTitles[log.type];
};
