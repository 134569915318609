import { getTownTitle } from "@/modules/pest/decorators/towns";

const getSubwayTitle = function (subway, defaultTitle) {
  if (!subway || !subway.title) {
    return defaultTitle !== undefined ? defaultTitle : "[Не назначен]";
  }
  return subway.title;
};
const getSubwayTownTitle = function (subway) {
  if (!subway || !subway.town) {
    return "[Не назначен]";
  }
  return getTownTitle(subway.town);
};

export { getSubwayTitle, getSubwayTownTitle };
