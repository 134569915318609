
<tr>
  <th>Итого</th>
  <th>{{ formatNumber(getROITotal()) }}</th>
  <th></th>
  <th></th>
  <th>{{ formatNumber(getTotalCount()) }}</th>
  <th>{{ formatNumber(data.total.newCount) }}</th>
  <th>
    <font-awesome-icon icon="circle" />
    {{ formatNumber(getTotalFailedCount()) }}
  </th>
  <th>{{ formatNumber(data.total.successAverageSum) }}</th>
  <th>{{ formatNumber(getTotalNetCostSum()) }}</th>
  <th>{{ formatNumber(getTotalProfitSum()) }}</th>
  <th>{{ formatNumber(data.total.secondaryCount) }}</th>
  <th>{{ formatNumber(data.total.secondaryNewCount) }}</th>
  <th>{{ formatNumber(data.total.secondaryFailedCount) }}</th>
  <th>{{ formatNumber(data.total.secondarySuccessAverageSum) }}</th>
  <th>{{ formatNumber(data.total.secondaryNetCostSum) }}</th>
  <th>{{ formatNumber(data.total.secondaryProfitSum) }}</th>
  <th>
    {{ formatNumber(getTotalProfitSum() + data.total.secondaryProfitSum) }}
  </th>
  <th>
    {{
      formatNumber(
        getConversionToSecondary(data.total.secondaryCount, getTotalCount())
      )
    }}
  </th>
</tr>
