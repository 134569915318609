
<div class="ibox">
  <div class="ibox-content">
    <h1>Вредители</h1>
    <div class="mb-2">
      <router-link :to="{ name: 'pest-pest-create' }" class="btn btn-primary">
        <font-awesome-icon icon="plus" />
        Добавить вредителя
      </router-link>
    </div>

    <table v-if="pests.length" class="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th>Название</th>
          <th>Процент</th>
          <th>Описание</th>
          <th>Сортировка</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pest, pIndex) in pests" :key="pIndex">
          <td class="color-col">
            <div
              class="p-1 color-box"
              :style="{ background: pest.color }"
            ></div>
          </td>
          <td>
            <router-link
              :to="{ name: 'pest-pest-update', params: { id: pest.id } }"
              class=""
            >
              {{ pest.title ? pest.title : "[Без названия]" }}
            </router-link>
          </td>
          <td>
            <span v-if="pest.percentType === PERCENT_TYPE_MANUAL">
              {{ pest.percentRegular }} / {{ pest.percentRecord }}
            </span>
          </td>
          <td>
            {{ pest.comment }}
            <div v-if="pest.service === SERVICE_DOUBLE">
              {{ getPestsDoubleNames(pest) }}
            </div>
          </td>
          <td>
            {{ pest.order }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
