<template>
  <div :data-aftercall-id="log.aftercallId" :data-fail-id="log.failId">
    <strong>Статус:</strong> {{ getAftercallStatusTitle(log) }} <br /><span
      v-if="log.status === AFTERCALL_STATUS_NOT_REGISTERED"
      ><strong>Причина:</strong> {{ log.failTitle }}</span
    >
    <br /><strong>Менеджер:</strong> {{ getUserName(log.manager) }}
    <br /><strong>Комментарий:</strong> {{ log.comment }}
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  AFTERCALL_STATUS_NOT_REGISTERED,
  getAftercallStatusTitle,
} from "@/modules/pest/decorators/aftercall";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogAftercall",
  components: {},
  props: ["log"],
  data() {
    return {
      AFTERCALL_STATUS_NOT_REGISTERED,
      show: false,
      request: {},
    };
  },
  computed: {
    callTime() {
      return dayjs.unix(this.log.time).format("DD.MM.YYYY в HH:mm");
    },
  },
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    getAftercallStatusTitle,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
