<template>
  <div>
    <strong>Старый мастер: </strong> {{ getUserName(log.oldAssignee) }} <br />
    <strong>Новый мастер: </strong> {{ getUserName(log.newAssignee) }}<br />
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogChangedAssignee",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: { getUserName },
};
</script>

<style lang="scss" scoped></style>
