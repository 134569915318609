<template>
  <div class="table-responsive">
    <table class="table table-bordered table-condensed">
      <thead>
        <tr>
          <th>Имя</th>
          <th>Лиды</th>
          <th>Успешные первички</th>
          <th>Успешные вторички</th>
          <th @click="sortData">
            Конверсия, %
            <span class="ml-2">
              <font-awesome-icon v-if="sort" icon="arrow-down" />
              <font-awesome-icon v-else icon="arrow-up" />
            </span>
          </th>
        </tr>
      </thead>
      <template v-for="(info, ind) in sourceData.results">
        <tbody :key="'ind' + ind">
          <tr v-if="hasRowData(info)">
            <td>
              {{ getUserName(info.user) }}
            </td>
            <td>
              {{ info.totalCount }}
            </td>
            <td>
              {{ info.firstSuccessCount }}
            </td>
            <td>
              {{ info.nextSuccessCount }}
            </td>
            <td>
              {{ info.conversion }}
            </td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManagersDataTable",
  props: {
    sourceData: null,
    hasData: () => false,
  },
  data() {
    return {
      sort: false,
    };
  },
  watch: {
    sourceData(val) {
      this.refreshTable();
    },
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    getUserName,
    sortData() {
      this.sort = !this.sort;
      if (this.sort) {
        this.sortSourceDataDesc();
      } else {
        this.sortSourceDataAsc();
      }
    },
    refreshTable() {
      this.sort = false;
      this.sortData();
      this.$forceUpdate();
    },
    sortSourceDataAsc() {
      this.sourceData.results.sort((a, b) => {
        return a.conversion - b.conversion;
      });
    },
    sortSourceDataDesc() {
      this.sourceData.results.sort((a, b) => {
        return b.conversion - a.conversion;
      });
    },
    hasRowData(info) {
      return (
        info.totalCount ||
        parseInt(info.firstSuccessCount) ||
        parseInt(info.nextSuccessCount)
      );
    },
  },
};
</script>

<style scoped></style>
