
<div class="ibox">
  <div class="ibox-content">
    <h1>Отчет</h1>
    <div class="mb-2"></div>

    <div class="search">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <b-form-group label="Имя">
            <b-form-input v-model="search.name" tabindex="1"></b-form-input>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Аккаунт" prop="accountId">
            <multiply-autocomplete
              ref="accountAutocomplete"
              :get-data="fetchAccountData"
              :get-title="getAccountTitle"
              @selected="selectMultipleAccount"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Дата" prop="date">
            <date-picker v-model="search.date"></date-picker>
          </b-form-group>
        </div>

        <div v-if="false" class="mr-2">
          <b-form-group label="Вредитель" prop="pestId">
            <multiply-autocomplete
              ref="pestAutocomplete"
              :get-data="fetchPestData"
              :get-title="getPestTitle"
              @selected="selectMultiplePest"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div v-if="false" class="mr-2">
          <b-form-group label="Регион" prop="pestId">
            <multiply-autocomplete
              ref="townAutocomplete"
              :get-data="fetchRegionData"
              :get-title="getRegionTitle"
              @selected="selectMultipleRegion"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="mr-2">
          <b-form-group label="Статус" prop="status">
            <multiply-autocomplete
              ref="townAutocomplete"
              :get-data="fetchStatusData"
              :get-title="getStatusTitle"
              @selected="selectMultipleStatus"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.total }}
        </div>
      </div>
    </div>

    <b-table
      v-if="reports.length"
      responsive
      striped
      hover
      condensed
      :items="reports"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{ name: 'direct-report-update', params: { id: data.item.id } }"
          class=""
        >
          {{ data.item.title ? data.item.title : "[Без названия]" }}
        </router-link>
      </template>

      <template #cell(date)="data">
        {{ getReportDate(data.item) }}
      </template>
      <template #cell(account)="data">
        {{ getReportAccountName(data.item) }}
      </template>
      <template #cell(regionId)="data">
        {{ getReportRegionName(data.item) }}
      </template>
      <template #cell(status)="data">
        {{ getReportStatusTitle(data.item) }}
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
