
<b-form
  v-if="dataLoaded"
  id="request-form"
  ref="request"
  :model="request"
  class="request-form gray-bg"
  novalidate
  @submit.prevent="createOrUpdate"
  @reset="onReset"
  @keydown.enter="onEnterDown"
>
  <div class="ibox">
    <div class="ibox-title d-flex pr-4 toptitle">
      <div>
        <div class="d-flex align-items-baseline">
          <h3 class="mt-0">{{ getRequestStageTitle(request) }}</h3>
          <div v-if="request.id !== 'new'" class="ml-2 small">
            [Лид создан {{ getRequestCreateDatetime(request) }}]
          </div>
        </div>
        <div>
          <div v-if="request.source" class="ml-auto">
            <a :href="getRequestSourceUrl(request)" target="_blank">{{
              request.source.title
            }}</a>
          </div>
        </div>
        <!--          <div v-if="request.id!=='new'">Дата создания | Менеджер</div>-->
      </div>
      <div class="ml-auto">
        <request-call-notifications :request="request" />
      </div>
      <div v-if="showRepeat" class="ml-2">
        <button
          class="btn btn-primary"
          @click.prevent="createReturnVisit(request)"
        >
          <font-awesome-icon icon="reply" />
          Назначить повтор
        </button>
      </div>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <b-form-group label="Менеджер" prop="managerId">
            <user-autocomplete
              v-model="request.managerId"
              :type="TYPE_MANAGER"
              :disabled="!canChangeManager"
              :default-value="getRequestManagerName(request)"
              @selected="selectManager"
            ></user-autocomplete>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-4">
          <b-form-group label="Статус" prop="leadStatusId">
            <b-input-group>
              <b-form-select
                v-model="request.leadFormStatusId"
                :options="statusesList"
                :disabled="loadingStatuses"
                @change="updateLeadStatus"
              />
              <b-input-group-append v-if="loadingStatuses">
                <button class="btn btn-outline-secondary" @click.prevent>
                  <font-awesome-icon icon="rotate" spin />
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-4">
          <b-form-group label="Мастер" prop="assigneeId">
            <user-autocomplete
              v-model="request.assigneeId"
              :type="TYPE_WORKER"
              :default-value="getRequestAssigneeName(request)"
              :disabled="!canChangeAssignee"
              :show-reset="request.hasPredefinedAssignee"
              @selected="selectAssignee"
            ></user-autocomplete>
          </b-form-group>

          <b-form-group
            v-if="showAssigneeCancelCause"
            label="Причина отмены мастера"
            prop="assigneeCancelCause"
          >
            <b-form-textarea
              v-model="request.assigneeCancelCause"
              required
              :disabled="!showAssigneeCancelCauseField"
              :state="validation.assigneeCancelCause"
            ></b-form-textarea>
            <b-form-invalid-feedback
              >Введите причину отмены мастера
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-4">
          <b-form-group label="Проект" prop="project">
            <universal-autocomplete
              ref="projectAutocomplete"
              :add-new="false"
              :default-value="
                request.projectId ? getProjectTitle(request.project) : ''
              "
              :required="true"
              :get-title="getProjectTitle"
              :get-data="getProjects"
              :disabled="projectDisabled"
              @selected="selectProject"
            ></universal-autocomplete>
            <b-form-invalid-feedback :force-show="!request.projectId"
              >Выберите проект
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-if="showManualSource" class="col-sm-12 col-md-4">
          <b-form-group
            :label="request.contractId ? 'Источник заявки' : 'Источник лида'"
            prop="manualSource"
          >
            <source-autocomplete
              ref="sourceAutocomplete"
              v-model="request.sourceId"
              :type="TYPE_MANUAL"
              :target="request.contractId ? TARGET_IN_CONTRACT : TARGET_ALL"
              :state="validation.sourceId"
              :project-id="request.projectId"
              :required="true"
              :disabled="!request.projectId"
              @selected="selectSource"
            ></source-autocomplete>
            <b-form-invalid-feedback :force-show="!validation.sourceId"
              >Выберите источник
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div v-if="showRejectionComment" class="row">
        <div class="col-sm-12 col-md-4"></div>
        <div class="col-sm-12 col-md-8">
          <b-form-group label="Причина провала" prop="rejectionComment">
            <b-form-textarea
              v-model="request.rejectionComment"
              required
              :state="validation.rejectionComment"
            ></b-form-textarea>
            <b-form-invalid-feedback
              >Введите причину провала
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <!--  новые контакты -->
      <fieldset>
        <legend
          id="__BVID__420__BV_label_"
          class="bv-no-focus-ring col-form-label pt-0"
        >
          Контакты
          <a
            v-b-tooltip.hover
            class="btn btn-xs btn-outline-info ml-2"
            title="Добавить контакт"
            @click.prevent="addCommunication"
          >
            <font-awesome-icon icon="plus" />
            Добавить контакт
          </a>
        </legend>
        <div
          v-if="request.communications && request.communications.length"
          class="d-flex flex-wrap"
        >
          <div
            v-for="(communication, cIndex) in request.communications"
            :key="'communication' + cIndex"
            class="contact-card px-2 pt-2 pb-2 mr-2 mb-2"
          >
            <!--                style="position: relative"-->
            <div class="d-flex mb-2">
              <div>
                <h4>Контакт {{ cIndex + 1 }}</h4>
              </div>

              <div class="ml-auto">
                <button
                  v-if="false"
                  v-b-tooltip.hover
                  class="btn btn-outline-info success btn-xs"
                  title="Добавить телефон"
                  @click.prevent="editContact(communication)"
                >
                  <font-awesome-icon icon="pencil" />
                  Редактировать
                </button>

                <button
                  v-if="request.contractId"
                  v-b-tooltip.hover
                  class="btn btn-outline-danger btn-xs ml-2"
                  title="Удалить контакт"
                  @click.prevent="deleteCommunication(cIndex)"
                >
                  <font-awesome-icon icon="times" />
                  Удалить
                </button>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="mb-2">
                <b-button-group>
                  <div v-if="!request.contractId">
                    <b-form-input
                      v-model="communication.clientName"
                      class="contact-text-input"
                      required
                      :state="
                        checkValidCommunicationClientName(
                          communication.clientName
                        )
                      "
                      :disabled="!!communication.blockedName"
                      placeholder="Введите имя"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Введите имя без цифр
                    </b-form-invalid-feedback>
                  </div>

                  <universal-autocomplete
                    v-else
                    :default-value="
                      communication.clientId
                        ? communication.clientName
                          ? communication.clientName
                          : '[Нет имени]'
                        : 'Выберите контакт'
                    "
                    :add-new="false"
                    :get-title="getClientName"
                    :get-data="getClientsForRequest"
                    :disabled="!!communication.clientId"
                    @selected="
                      selectClientForCommunication($event, communication)
                    "
                  ></universal-autocomplete>
                  <button
                    v-if="false"
                    class="btn btn-outline-danger"
                    @click.prevent="deleteCommunication(cIndex)"
                  >
                    <font-awesome-icon icon="times" />
                  </button>
                </b-button-group>
              </div>
            </div>
            <div>
              <div
                v-for="(phone, pIndex) in communication.phones"
                :key="'phone' + cIndex + '-' + pIndex"
                class="mb-2 d-flex phones"
              >
                <b-form-input
                  v-if="!request.contractId"
                  v-model="communication.phones[pIndex]"
                  class="contact-text-input"
                  :disabled="
                    communication.blocked && pIndex < communication.count
                  "
                  placeholder="Введите телефон"
                ></b-form-input>
                <universal-autocomplete
                  v-else
                  :default-value="phone ? phone : 'Выберите телефон'"
                  :add-new="false"
                  :get-title="getClientPhoneFromContact"
                  :get-data="getClientCardPhones(communication.clientId)"
                  :disabled="!communication.clientId"
                  @selected="
                    selectPhoneForCommunication(
                      $event,
                      communication.clientId,
                      communication,
                      pIndex
                    )
                  "
                ></universal-autocomplete>

                <button
                  v-if="request.contractId"
                  v-b-tooltip.hover
                  class="btn btn-outline-gray btn-xs"
                  :disabled="!request.contractId"
                  title="Удалить телефон из контакта"
                  @click.prevent="
                    deletePhoneFromCommunication(communication, pIndex)
                  "
                >
                  <font-awesome-icon fixed-width icon="times" />
                </button>
                <button
                  v-b-tooltip.hover
                  class="btn btn-success btn-sm ml-auto"
                  title="Добавить телефон"
                  @click.prevent="makeCallInternal(communication, phone)"
                >
                  <font-awesome-icon icon="phone" />
                  Позвонить
                </button>
              </div>
              <!--              v-if="!communication.hideButtons"-->
              <div class="d-flex">
                <div>
                  <button
                    v-b-tooltip.hover
                    class="btn btn-outline-primary btn-xs"
                    title="Добавить телефон"
                    @click.prevent="addPhoneToCommunication(communication)"
                  >
                    <font-awesome-icon icon="plus" />
                    Добавить телефон
                  </button>
                </div>

                <div v-if="false">
                  <button
                    v-b-tooltip.hover
                    title="Удалить контакт"
                    class="btn btn-outline-danger btn-xs ml-2"
                    @click.prevent="deleteContact(cIndex)"
                  >
                    Удалить контакт
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <!--Расчет стоимости-->
  <div class="ibox">
    <div class="ibox-title d-flex">
      <h3>Расчет стоимости</h3>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Город" prop="townIds">
            <town-autocomplete
              ref="townAutocomplete"
              required=""
              :state="validation.town"
              :default-value="
                request.townId ? getTownTitle(request.town) : ''
              "
              @selected="selectTown"
            ></town-autocomplete>
            <b-form-invalid-feedback :force-show="validation.town === false"
              >Выберите город
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Тип вредителя" prop="title">
            <pest-autocomplete
              v-model="request.pestId"
              :state="validation.pest"
              :default-value="getRequestPestName(request)"
              @selected="selectPest"
            ></pest-autocomplete>
            <b-form-invalid-feedback :force-show="validation.pest === false"
              >Выберите вредителя
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Объект" prop="title">
            <facility-autocomplete
              v-model="request.facilityId"
              :add-new="true"
              :state="validation.facility"
              :type="request.clientType"
              :default-value="getRequestFacilityName(request)"
              @selected="selectFacility"
            ></facility-autocomplete>
            <b-form-invalid-feedback
              :force-show="validation.facility === false"
              >Выберите объект
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="Тип"
            prop="title"
            :state="validation.clientType"
          >
            <b-button-group>
              <button
                class="btn"
                :class="getClientTypeClass(CLIENT_TYPE_IND)"
                @click.prevent="selectClientType(CLIENT_TYPE_IND)"
              >
                Физ. лицо
              </button>
              <button
                class="btn"
                :class="getClientTypeClass(CLIENT_TYPE_ORG)"
                @click.prevent="selectClientType(CLIENT_TYPE_ORG)"
              >
                Организация
              </button>
            </b-button-group>
            <b-form-invalid-feedback
              :force-show="validation.clientType === false"
              >Выберите тип клиента
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group
            v-if="request.clientType === CLIENT_TYPE_ORG"
            label="Тип оплаты"
            prop="title"
            :state="validation.paymentType"
          >
            <b-button-group>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(PT_CASH)"
                @click.prevent="selectClientPaymentType(PT_CASH)"
              >
                Нал
              </button>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(PT_CASHLESS)"
                @click.prevent="selectClientPaymentType(PT_CASHLESS)"
              >
                Безнал
              </button>
            </b-button-group>
            <b-form-invalid-feedback
              :force-show="validation.paymentType === false"
              >Выберите тип оплаты
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group
            v-if="request.paymentType === PT_CASHLESS"
            label="Оплачено"
            prop="title"
            :state="validation.clientPaymentReceived"
          >
            <b-button-group>
              <button
                class="btn"
                :class="getClientPaymentReceivedClass(1)"
                @click.prevent="selectClientPaymentReceived(1)"
              >
                Да
              </button>
              <button
                class="btn"
                :class="getClientPaymentReceivedClass(0)"
                @click.prevent="selectClientPaymentReceived(0)"
              >
                Нет
              </button>
            </b-button-group>
            <b-form-invalid-feedback
              :force-show="validation.paymentType === false"
              >Выберите тип оплаты
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Тип расчета" prop="title">
            <b-button-group>
              <button
                class="btn"
                :class="getClientExchangeSourceType(false)"
                @click.prevent="selectExchangeSourceType(0)"
              >
                Стандарт
              </button>
              <button
                class="btn"
                :class="getClientExchangeSourceType(true)"
                @click.prevent="selectExchangeSourceType(1)"
              >
                Биржа
              </button>
            </b-button-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="Стоимость" prop="title">
            <div class="input-group">
              <b-button-group class="mr-1">
                <button
                  v-for="(price, pIndex) in prices"
                  :key="'p' + pIndex"
                  class="btn"
                  :class="getPriceClass(price.price)"
                  @click.prevent="selectPrice(price.price)"
                >
                  {{ price.price }}
                  {{ price.comment ? "(" + price.comment + ")" : "" }}
                </button>
                <button
                  class="btn"
                  :class="getPriceClass('-')"
                  @click.prevent="selectPrice('-')"
                >
                  Другое
                </button>
              </b-button-group>
              <b-form-input
                v-model="request.knownSum"
                :disabled="!priceManual"
              ></b-form-input>
            </div>

            <!--              <b-form-input v-model="request.sum" placeholder="" number></b-form-input>-->
          </b-form-group>
        </div>

        <div class="col-md-2">
          <b-form-group label="Дорога, км" prop="title">
            <b-form-input v-model="request.tripDistance"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Дорога, р" prop="title">
            <b-form-input v-model="request.tripSum"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div
        v-if="
          request.clientType === CLIENT_TYPE_ORG &&
          request.paymentType === PT_CASHLESS
        "
      >
        <hr />
        <div class="panel panel-warning mb-4">
          <div class="panel-heading">
            <font-awesome-icon icon="exclamation-triangle" />
            Убедись, что клиент получил смс во время разговора
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Телефон">
              <b-form-input v-model="sendSmsPhone"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3 pt-4">
            <button
              v-if="!sendingSms"
              class="btn btn-outline-info"
              @click.prevent="sendSms"
            >
              <font-awesome-icon icon="paper-plane" />
              Отправить
            </button>
            <button v-else class="btn btn-outline-info disabled">
              <font-awesome-icon icon="rotate" spin />
              Отправляется
            </button>
          </div>
          <div class="col-md-4 pt-4">
            <div
              v-if="showSuccessSmsMessage"
              class="panel panel-success mb-0"
            >
              <div class="panel-heading">
                <font-awesome-icon icon="ok" />
                Сообщение отправлено
              </div>
            </div>
            <div v-if="showErrorSmsMessage" class="panel panel-warning mb-0">
              <div class="panel-heading">
                <font-awesome-icon icon="exclamation-triangle" />
                {{ errorSmsMessage }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Текст">
              <b-form-textarea
                v-model="sendSmsText"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>

      <div
        v-if="[SPB_C, MSK_C].includes(request.townId)"
        class="panel panel-warning mb-0"
      >
        <div class="panel-heading">
          <font-awesome-icon icon="exclamation-triangle" />
          Для расчета стоимости дороги заполни полный адрес
        </div>
      </div>
    </div>
  </div>
  <!--Адрес-->
  <div class="ibox">
    <div class="ibox-title d-flex">
      <h3>Адрес</h3>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Поиск по адресу" prop="address">
            <dadata-autocomplete
              v-model="request.searchAddress"
              placeholder="ул Ленина, 24"
              @selected="selectAddress"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <b-form-group label="Индекс" prop="">
            <b-form-input v-model="request.addressIndex"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Населенный пункт" prop="">
            <b-form-input v-model="request.addressTown"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group label="Улица" prop="address">
            <b-form-input
              v-model="request.address"
              :state="validation.address"
            ></b-form-input>
            <b-form-invalid-feedback>Введите адрес</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group v-if="showSubway" label="Метро" prop="subwayId">
            <subway-autocomplete
              ref="subwayAutocomplete"
              required=""
              :town-id="request.townId"
              :all="true"
              :default-value="
                request.subwayId ? getSubwayTitle(request.subway) : ''
              "
              @selected="selectSubway"
            ></subway-autocomplete>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <b-form-group label="Дом" prop="">
            <b-form-input v-model="request.addressHouse"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Корпус" prop="">
            <b-form-input v-model="request.addressBlock"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Подъезд" prop="">
            <b-form-input v-model="request.addressEntrance"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-2">
          <b-form-group label="Этаж" prop="appartment">
            <b-form-input v-model="request.addressFloor"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Квартира" prop="appartment">
            <template #label>
              Квартира
              <request-appartment-visibility
                class="ml-auto"
                :request="request"
                :assignee-can-see-address="request.assigneeCanSeeAddress"
              />
            </template>
            <b-form-input v-model="request.appartment"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group label="Домофон" prop="appartment">
            <b-form-input v-model="request.addressIntercom"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <b-form-group label="Доп информация" prop="addressInfo">
            <b-form-input v-model="request.addressInfo"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Координаты" prop="addressInfo">
            {{ request.addressCoords }}
          </b-form-group>
        </div>
      </div>

      <request-form-map
        ref="map"
        :town-id="request.townId"
        :coords="request.addressCoords"
        @distance="distanceCalculated"
      />
    </div>
  </div>

  <!-- Выезды по цепочке -->
  <requests-sequence-history
    v-if="sequence && sequence.length > 1"
    :sequence="sequence"
    :request:="request"
  />

  <!--Время-->
  <div class="ibox">
    <div class="ibox-title d-flex">
      <h3>Время</h3>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            label="Дата"
            prop="duration"
            :state="validation.dateDay"
          >
            <datepicker
              v-model="request.dateDay"
              input-class="form-control"
              lang="ru"
              type="date"
              value-type="format"
              :editable="true"
              :format="dateFormat"
              :state="validation.dateDay"
              :disabled-date="disabledAssigneeDates"
              @change="dateChanged"
            ></datepicker>
            <b-form-invalid-feedback
              :force-show="validation.dateDay === false"
              >Выберите дату
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Время" prop="duration">
            <b-form-select
              v-model="request.dateTime"
              :options="timeOptions"
              :state="validation.dateTime"
              :disabled="!request.dateDay"
            ></b-form-select>
            <b-form-invalid-feedback>Выберите время</b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-4 pt-4">
          <font-awesome-icon
            v-if="loadingBusiness"
            icon="rotate"
            class="mt-2"
            spin
          />
          <!--            <button class="btn btn-outline-info mt-2" v-if="loadingBusiness">-->
          <!--            </button>-->
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="comment">
            <b-form-textarea
              v-model="request.comment"
              placeholder="Описание"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Особое внимание" prop="warning">
            <b-form-textarea
              v-model="request.warning"
              placeholder="Особое внимание"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
  <!--Сохранить-->
  <div v-if="!embedded" class="ibox">
    <div class="ibox-content">
      <b-button variant="primary" @click.prevent="createOrUpdate">
        <font-awesome-icon icon="check" />
        Сохранить
      </b-button>
    </div>
  </div>

  <contact-popup />
  <new-contact-phone-popup />
</b-form>
