
<b-form
  id="pest-equipment-form"
  ref="equipment"
  :model="equipment"
  class="pest-equipment-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <b-form-group label="Название" prop="title">
      <b-form-input
        v-model="equipment.title"
        placeholder="Название"
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Тип оплаты">
      <b-form-radio-group
        v-model="equipment.paymentType"
        :options="paymentTypesOptions"
        placeholder="100"
        tabindex="1"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      v-if="equipment.paymentType === EQUIPMENT_PAYMENT_PERCENT"
      label="Процент от сделки"
      prop="unit"
    >
      <b-form-input
        v-model="equipment.percent"
        placeholder=""
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group v-else label="Фиксированная стоимость">
      <b-form-input
        v-model="equipment.price"
        placeholder="100"
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Описание" prop="title">
      <b-form-textarea
        v-model="equipment.comment"
        placeholder="Описание"
        tabindex="1"
      ></b-form-textarea>
    </b-form-group>

    <div class="row">
      <div class="col-6">
        <b-form-group label="Вредители" prop="title">
          <div v-if="!pestsList.length">
            <font-awesome-icon icon="rotate" spin />
            Загружаются
          </div>
          <b-form-checkbox-group
            v-else
            v-model="equipment.pests"
            :options="pestsList"
            stacked
            tabindex="1"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Типы объектов" prop="title">
          <div v-if="!facilitiesList.length">
            <font-awesome-icon icon="rotate" spin />
            Загружаются
          </div>
          <b-form-checkbox-group
            v-else
            v-model="equipment.facilities"
            :options="facilitiesList"
            stacked
            tabindex="1"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="equipment.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteEquipment"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
