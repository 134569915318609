
<div>
  <div>
    <strong>Старый менеджер: </strong> {{ getUserName(log.oldManager) }}
  </div>
  <div>
    <strong>Новый менеджер: </strong> {{ getUserName(log.newManager) }}
  </div>
  <div v-if="log.cause">
    <strong>Причина: </strong> {{ getCauseTitle(log) }}
  </div>
</div>
