import PestList from "@/modules/pest/views/pests/PestList";
import PestCreate from "@/modules/pest/views/pests/PestCreate";
import PestUpdate from "@/modules/pest/views/pests/PestUpdate";
import PestView from "@/modules/pest/views/pests/PestView";

export default [
  {
    path: "/pests/page:page?",
    name: "pest-pest-list",
    components: {
      default: PestList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/pests/new",
    name: "pest-pest-create",
    components: {
      default: PestCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/pests/:id/update",
    name: "pest-pest-update",
    components: {
      default: PestUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/pests/:id",
    name: "pest-pest-view",
    components: {
      default: PestView,
    },
    meta: { requiresAuth: true },
  },
];
