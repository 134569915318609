
<div>
  <a href="#" @click.prevent="showSums = !showSums">
    Сумма: {{ log.totalSum }}</a
  >
  <br />
  <div v-if="showSums">
    Сумма заявок: {{ log.requestSum }}<br />
    Штрафы: {{ log.fineSum }}<br />
    Депозит: {{ log.pledgeSum }}<br />
  </div>

  <span v-if="log.disabledDepositGeo">Координаты не проверялись</span>
  <span v-else>Координаты проверены</span>
  <a href="#" @click.prevent="showIds = !showIds"> Идентификаторы:</a>
  <div v-if="showIds">
    finesIds: {{ log.finesIds }}<br />
    requestIds: {{ log.requestIds }}<br />
  </div>
</div>
