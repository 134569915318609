
<div class="client-page">
  <div class="row">
    <div class="col-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-content pb-1">
          <client-header
            :client="client"
            :return-url="{
              name: 'crm-client-view',
              params: { id: client.id },
            }"
            :show-buttons="false"
          ></client-header>
          <client-form
            :client="client"
            :return-url="{
              name: 'crm-client-view',
              params: { id: client.id },
            }"
          ></client-form>
        </div>
      </div>
    </div>
  </div>
</div>
