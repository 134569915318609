
<b-modal
  id="modal-client-popup"
  ref="modal"
  title="Новый клиент"
  size="lg"
  class="contract-popup"
  cancel-title="Отмена"
  ok-title="Сохранить"
  title-tag="h3"
  @hide="handleHide"
  @shown="onShown"
  @ok.prevent="handleTaskModalOk"
>
  <template #default>
    <b-form
      id="phone-form"
      ref="phone"
      class="client-form"
      @submit.prevent="createOrUpdate"
    >
      <div>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <b-form-group label="Фамилия" prop="lastName">
              <b-form-input
                v-model="client.lastName"
                placeholder="Фамилия"
                :state="checkFioHasNumbers"
              />
            </b-form-group>
          </div>

          <div class="col-md-4 col-sm-12">
            <b-form-group label="Имя" prop="firstName">
              <b-form-input
                v-model="client.firstName"
                required
                placeholder="Имя"
                :state="checkNameHasNumbers"
              />
            </b-form-group>
          </div>

          <div class="col-md-4 col-sm-12">
            <b-form-group label="Отчество" prop="patronymic">
              <b-form-input
                v-model="client.patronymic"
                placeholder="Отчество"
                :state="checkPatronymicHasNumbers"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <b-form-group label="Организация" prop="firm">
              <b-form-input v-model="client.firm" placeholder="Организация" />
            </b-form-group>
          </div>
          <div class="col-md-4 col-sm-12">
            <b-form-group label="Должность" prop="post">
              <b-form-input v-model="client.post" placeholder="Должность" />
            </b-form-group>
          </div>
          <div class="col-md-4 col-sm-12">
            <b-form-group label="Ответственный" prop="responsiblePerson">
              <b-form-input
                v-model="client.responsiblePerson"
                placeholder="Ответственный"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div
          v-for="(phone, phoneIndex) in client.phones"
          :key="'p' + phoneIndex"
          class="row"
        >
          <div class="col-md-4 col-sm-12">
            <b-form-group label="Телефон" prop="phone">
              <b-form-input
                v-model="phone.phone"
                v-mask="'+7 (###) ###-####'"
              ></b-form-input>
              <small class="form-text text-muted">
                <a
                  v-if="phoneIndex === 0"
                  href="#"
                  @click.prevent="addPhone(phoneIndex)"
                  >Добавить</a
                >
                <a
                  v-if="phoneIndex > 0 && client.phones.length > 0"
                  href="#"
                  @click.prevent="removePhone(phoneIndex)"
                  >Удалить</a
                >
              </small>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-sm-12">
          <b-form-group label="Адрес" prop="address">
            <b-form-input v-model="client.address"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-form>
  </template>

  <template #footer>
    <b-button variant="primary" @click="createOrUpdate">
      <font-awesome-icon icon="check" />
      Добавить
    </b-button>

    <b-button variant="outline-default" class="ml-2" @click="cancelUpdate">
      <font-awesome-icon icon="times" />
      Отмена
    </b-button>

    <b-button
      v-if="client.id > 0"
      variant="danger"
      class="ml-2"
      @click="deleteClient"
    >
      <font-awesome-icon icon="trash" />
      Удалить
    </b-button>
  </template>

  <b-form
    v-if="false"
    id="phone-form1"
    ref="phone"
    class="phone-form"
    novalidate
    @submit="createOrUpdate"
  >
    <b-form-group label="Телефон" prop="phone">
      <b-form-input id="phone-field" v-model="phone" required></b-form-input>
      <div class="mt-2">
        <button
          v-if="!loading"
          class="btn btn-primary mr-2"
          @click.prevent="createOrUpdate"
        >
          <font-awesome-icon fixed-width icon="plus" />
          Добавить
        </button>
        <button v-else class="btn btn-primary mr-2" disabled>
          <font-awesome-icon fixed-width icon="rotate" spin />
          Добавляется
        </button>
        <button class="btn btn-outline-info" @click.prevent="handleHide">
          <font-awesome-icon fixed-width icon="arrow-left" />
          Отмена
        </button>
      </div>
    </b-form-group>
  </b-form>
</b-modal>
