export const getStatusName = function (status) {
  if (!status) {
    return "[Не назначен]";
  }
  let title = status.title || "";
  if (title) {
    return title;
  } else {
    return "[Без названия]";
  }
};

export const emptyStatus = {
  id: "new",
  title: "",
  info: {},
};

export const getKanbanStatusColor = function (status) {
  if (!status.color) {
    return "";
  }
  return status.color;
};

export const getKanbanStatusTextColor = function (status) {
  if (!status.textColor) {
    return "";
  }
  return status.textColor;
};
export const getKanbanCardTextColor = function (status = false) {
  if (!status) return "";
  if (!status.cardColor) {
    return "";
  }
  return " color: " + status.cardColor;
};
export const statusHeaderStyle = function (status = false) {
  if (!status) return "";
  let t = "";
  if (getKanbanStatusColor(status)) {
    t += " background: " + getKanbanStatusColor(status) + ";";
  }
  if (getKanbanStatusTextColor(status)) {
    t += " color: " + getKanbanStatusTextColor(status) + ";";
  }
  return t;
};
