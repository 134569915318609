<template>
  <div>
    <h2>
      Мои дела
      <small>
        <router-link
          v-if="canAddIssue()"
          :to="{ name: 'issue-create' }"
          class="btn btn-primary btn-sm"
        >
          <font-awesome-icon icon="plus" />
          Добавить дело
        </router-link>
      </small>
    </h2>

    <b-table
      v-if="issues.length"
      responsive
      striped
      hover
      condensed
      :items="issues"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{ name: 'issue-view', params: { id: data.item.id } }"
          class=""
        >
          {{ getIssueTitle(data.item) }}
        </router-link>
        <br />
        <small>
          {{ getIssueGroupTitle(data.item) }}
        </small>
      </template>

      <template #cell(status)="data">
        {{ getIssueStatus(data.item) }}
      </template>

      <template #cell(client)="data">
        <router-link
          v-if="data.item.clientId"
          :to="{ name: 'crm-client-view', params: { id: data.item.clientId } }"
          class=""
        >
          {{ getIssueClientName(data.item) }}
        </router-link>
        <div v-else>
          {{ getIssueClientName(data.item) }}
        </div>
      </template>

      <template #cell(manager)="data">
        <router-link
          v-if="data.item.managerId"
          :to="{ name: 'users-user-view', params: { id: data.item.managerId } }"
          class=""
        >
          {{ getIssueManagerName(data.item) }}
        </router-link>
        <div v-else>
          {{ getIssueManagerName(data.item) }}
        </div>
      </template>

      <template #cell(timeCreated)="data">
        {{ getIssueCreateTime(data.item) }}
      </template>

      <template #cell(deadline)="data">
        {{ getIssueDeadline(data.item) }}
      </template>

      <template #cell(timeUpdated)="data">
        {{ getIssueUpdateTime(data.item) }}
      </template>
    </b-table>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getIssueClientName,
  getIssueCreateTime,
  getIssueDeadline,
  getIssueGroupTitle,
  getIssueManagerName,
  getIssueStatus,
  getIssueTitle,
  getIssueUpdateTime,
} from "@/modules/law/decorators/issue";

import { canAddIssue } from "@/modules/users/rbac";

export default {
  name: "IssueList",
  components: {},
  data() {
    return {
      issues: [{}],
      search: {
        title: "",
        managerId: "",
      },
      managersList: [],

      loading: true,
      archived: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: "Название",
        },
        {
          key: "status",
          label: "Статус",
        },
        {
          key: "client",
          label: "Клиент",
        },
        {
          key: "deadline",
          label: "Крайний срок",
        },
      ],
      header: "Дела",
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    canAddIssue,
    getIssueTitle,
    getIssueGroupTitle,
    getIssueManagerName,
    getIssueStatus,
    getIssueClientName,
    getIssueCreateTime,
    getIssueDeadline,
    getIssueUpdateTime,
    fetchData: function () {
      this.loading = true;

      let issuesUrl = "/law/issues";
      let params = {
        page: this.page,
        userId: this.$store.state.user.id,
        searchNames: this.search.title,
      };
      this.$http
        .get(issuesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.issues = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    linkGen(pageNum) {
      return {
        name: "issue-list",
        params: { page: pageNum },
      };
    },
    showArchivedIssues() {
      this.archived = true;
      this.fetchData();
    },
    showActiveIssues() {
      this.archived = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.issue-organization {
  font-size: smaller;
  color: gray;
}
</style>
