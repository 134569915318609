<template>
  <div class="notifications-message-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link
              :to="{ name: 'notifications-message-list' }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <div>
            <h1>
              {{ message.title }}
            </h1>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <notifications-message-info
          :message="message"
          @update-prop="updateProp"
        ></notifications-message-info>
      </div>
    </div>
  </div>
</template>

<script>
import MessageInfo from "./MessageInfo";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "Message",
  components: {
    "notifications-message-info": MessageInfo,
  },
  data() {
    return {
      tabIndex: 0,
      message: {
        id: "new",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      this.message.phone = "";
      let messagesUrl = "/notifications/messages/" + this.id;
      this.loading = true;
      this.$http
        .get(messagesUrl)
        .then((response) => {
          this.loading = false;
          this.message = response.data;
          this.message.managerName = this.message.manager.username;
          this.header = this.message.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = "/notifications/messages/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.message)
        .then((response) => {
          this.message = response.data;
          this.loading = false;
          this.$eventHub.$emit(
            "update-notifications-message-info",
            this.message
          );
          this.$bvToast.toast("Данные сохранены", {
            title: "Город отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>
