<template>
  <b-form
    ref="settings"
    :model="settings"
    class="settings-form"
    @submit="createOrUpdate"
  >
    <h1>Настройки импорта</h1>

    <div class="row">
      <div class="col-md-4">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h5>Параметры сервера</h5>
          </div>
          <div class="ibox-content">
            <b-form-group label="Сервер">
              <b-form-select
                v-model="settings.emailSelectServer"
                :options="servers"
              ></b-form-select>
            </b-form-group>
            <b-form-group v-if="ownServer" label="Адрес сервера">
              <b-form-input v-model="settings.emailServer"></b-form-input>
            </b-form-group>
            <b-form-group label="Логин">
              <b-form-input v-model="settings.emailLogin"></b-form-input>
            </b-form-group>
            <b-form-group label="Пароль">
              <b-form-input
                v-model="settings.emailPassword"
                type="password"
              ></b-form-input>
            </b-form-group>
            <b-form-group v-if="ownServer" label="Ssl">
              <b-form-input v-model="settings.emailSsl"></b-form-input>
            </b-form-group>
            <b-form-group v-if="ownServer" label="Тип соединения">
              <b-form-select
                v-model="settings.emailConnection"
                :options="connections"
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Искать только в непрочитанных письмах">
              <b-form-checkbox
                v-model="settings.searchOnlyUnseenEmail"
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group label="Отмечать письма как прочитанные">
              <b-form-checkbox
                v-model="settings.markEmailAsSeen"
              ></b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h5>Параметры поиска</h5>
          </div>
          <div class="ibox-content">
            <div v-for="(filter, index) in settings.filters" :key="index">
              <div class="row">
                <div class="col-md-3">
                  <b-form-group label="Email отправителя">
                    <b-form-input v-model="filter.emailFrom"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <b-form-group label="Заголовок содержит">
                    <b-form-input v-model="filter.emailSubject"></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-1 align-self-center">
                  <button
                    v-if="index === 0"
                    class="btn btn-primary"
                    @click="addFilter"
                  >
                    <font-awesome-icon icon="plus" />
                  </button>
                  <button
                    v-if="index !== 0"
                    class="btn btn-danger"
                    @click="removeFilter(index)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-button variant="primary" @click="createOrUpdate"
          >{{ submitButton }}
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  name: "Settings",
  components: {},
  data() {
    return {
      labelWidth: "150px",
      settings: {
        emailSelectServer: "",
        emailServer: "",
        emailLogin: "",
        emailPassword: "",
        emailSsl: "",
        emailConnection: "",
        emailFrom: "",
        emailSubject: "",
        emailFilename: "",
        searchOnlyUnseenEmail: true,
        markEmailAsSeen: true,
        filters: [],
      },
      connections: {
        pop3: "IMAP",
        imap: "POP3",
      },
      servers: {
        "": "Выберите сервер",
        yandex: "Yandex",
        "mail.ru": "Mail.ru",
        gmail: "Google Gmail",
        own: "Другой сервер",
      },
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    ownServer() {
      // console.log(this.settings.emailSelectServer);
      return this.settings.emailSelectServer === "own";
    },
  },
  watch: {},
  mounted: function () {
    this.setDefaultFilter();
    this.fetchData();
  },
  methods: {
    setDefaultFilter() {
      this.settings.filters = [
        {
          emailSubject: "",
          emailFrom: "",
        },
      ];
    },
    fetchData: function () {
      this.loading = true;
      let settingsUrl = "crm/site/email-settings";
      this.$http
        .get(settingsUrl)
        .then((response) => {
          this.loading = false;
          this.settings = response.data;
          this.settings.searchOnlyUnseenEmail =
            !!response.data.searchOnlyUnseenEmail;
          this.settings.markEmailAsSeen = !!response.data.markEmailAsSeen;
          if (!this.settings.filters || this.settings.filters.length == 0) {
            this.setDefaultFilter();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    update: function () {
      let updateUrl = "crm/site/email-settings";
      this.loading = true;
      this.$http
        .post(updateUrl, this.settings)
        .then((response) => {
          this.settings = response.data;
          this.settings.searchOnlyUnseenEmail =
            !!response.data.searchOnlyUnseenEmail;
          this.settings.markEmailAsSeen = !!response.data.markEmailAsSeen;
          if (!this.settings.filters || this.settings.filters.length == 0) {
            this.setDefaultFilter();
          }

          this.loading = false;
          this.$bvToast.toast("Настройки сохранены", {
            title: "Реквизиты обновлены",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось сохранить настройки",
              type: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();

      // this.$refs['settings'].validate((valid) => {
      //   if (valid) {
      this.update();
      // } else {
      //   this.$bvToast.toast('Произошла неизвестная ошибка', {
      //     variant: 'danger'
      //   });
      //   console.log('error submit!!');
      //   return false
      // }
      // })
    },

    addFilter() {
      this.settings.filters.push({
        // id: -1,
        subject: "",
        from: "",
      });
    },
    removeFilter(i) {
      this.settings.filters.splice(i, 1);
      console.log(i);
      console.log(this);
    },
  },
};
</script>

<style lang="scss" scoped></style>
