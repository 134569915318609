
<div class="ibox">
  <div class="ibox-content">
    <h3>Отчет по рекламе</h3>

    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <div class="mr-2">
          <b-form-group label="Дата" prop="date">
            <date-range-picker v-model="dateRange"></date-range-picker>
          </b-form-group>
        </div>

        <div class="mr-2 d-none">
          <b-form-group label="Вредитель" prop="pestId">
            <multiply-autocomplete
              ref="pestAutocomplete"
              :get-data="fetchPestData"
              :get-title="getPestTitle"
              @selected="selectMultiplePest"
            ></multiply-autocomplete>
          </b-form-group>
        </div>

        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>
      </div>
    </div>

    <table
      class="table table-striped table-condensed table-bordered table-hover"
    >
      <thead>
        <tr>
          <th></th>
          <th colspan="3" class="text-center">МСК и МО</th>
          <th colspan="3" class="text-center">СПБ и ЛО</th>
        </tr>
        <tr>
          <th>
            <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="Вредитель" param="pestId"/>-->
            Вредитель
          </th>
          <th>
            <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="МСК и МО, ₽" param="mskRub"/>-->
            Затраты, ₽
          </th>
          <th>
            Успешные первички
            <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
            <!--                               title="Успешные первички, МСК и МО" param="firstTimeSuccessCountMsk"/>-->
          </th>
          <!--          <th>-->
          <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
          <!--                               title="Количество продаж в директе, МСК и МО" param="count"/>-->
          <!--          </th>-->

          <th>
            <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="CPO, МСК и МО" param="mskCPO"/>-->
            CPO
          </th>

          <th>
            <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="СПБ и ЛО, ₽" param="spbRub"/>-->
            Затраты, ₽
          </th>
          <th>
            <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
            <!--                               title="Успешные первички, СПБ и ЛО" param="firstTimeSuccessCountSpb"/>-->
            Успешные первички
          </th>

          <!--          <th>-->
          <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
          <!--                               title="Количество продаж в директе, СПБ и ЛО" param="count"/>-->
          <!--          </th>-->
          <th>
            <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="CPO, СПБ и ЛО" param="spbCPO"/>-->
            CPO
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="(result, rIndex) in results" :key="'r' + rIndex">
          <td>
            <!--   Вредитель-->
            {{ getResultPestName(result) }}
          </td>

          <td>
            <!-- МСК и МО, ₽ -->
            {{ formatNumber(result.mskRub) }}
          </td>
          <td>
            <!-- Количество успешных первичек, МСК и МО -->
            {{ formatNumber(result.firstTimeSuccessCountMsk) }}
          </td>
          <!--          <td>-->
          <!--            &lt;!&ndash; Количество продаж в директе, МСК и МО &ndash;&gt;-->
          <!--            {{ formatNumber(result.directSalesCountMsk) }}-->
          <!--          </td>-->
          <td>
            <!-- CPO, МСК и МО -->
            {{ formatNumber(result.mskCPO) }}
          </td>
          <td>
            <!-- СПБ и ЛО, ₽ -->
            {{ formatNumber(result.spbRub) }}
          </td>
          <td>
            <!-- Количество успешных первичек, СПБ и ЛО -->
            {{ formatNumber(result.firstTimeSuccessCountSpb) }}
          </td>
          <!--          <td>-->
          <!--            &lt;!&ndash; Количество продаж в директе, СПБ и ЛО &ndash;&gt;-->
          <!--            {{ formatNumber(result.directSalesCountSpb) }}-->
          <!--          </td>-->
          <td>
            <!-- CPO, СПБ и ЛО -->
            {{ formatNumber(result.spbCPO) }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8">Загружается</td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
