
<b-form
  id="direct-account-form"
  ref="account"
  :model="account"
  class="direct-account-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="account.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Валюта" prop="token">
          <b-form-select
            v-model="account.currency"
            :options="currencyOptions"
            placeholder=""
            tabindex="1"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Ключ" prop="token">
          <b-form-input
            v-model="account.token"
            placeholder=""
            tabindex="1"
          ></b-form-input>

          <a target="_blank" :href="tokenLink">1. Получить код с яндекса</a
          ><br />
          <a
            target="_blank"
            href="https://ru.dez-crm.ru/backend/api/direct/default/token?code="
          >
            2. Ввести полученный код по адресу: <br />
            https://ru.dez-crm.ru/backend/api/direct/default/token?code=123456
          </a>
          <br />
          3. Ввести access_token в поле выше
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="account.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Учет по группе" prop="groupAccount">
          <b-form-checkbox
            v-model="account.groupAccount"
            tabindex="1"
            value="1"
          ></b-form-checkbox>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="account.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteAccount"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
