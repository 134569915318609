
<div class="ibox">
  <div class="ibox-content">
    <h1>Задачи</h1>

    <div>
      <b-button
        :variant="completed ? 'outline-info' : 'info'"
        class="btn"
        @click="showActiveTasks"
      >
        В работе
      </b-button>

      <b-button
        :variant="!completed ? 'outline-info' : 'info'"
        class="btn ml-1"
        @click="showCompletedTasks"
      >
        Завершенные
      </b-button>

      <router-link :to="{ name: 'task-create' }" class="btn btn-primary ml-2">
        <font-awesome-icon icon="plus" />
        Добавить задачу
      </router-link>
    </div>

    <b-table
      v-if="tasks.length"
      responsive
      striped
      hover
      condensed
      :items="tasks"
      :fields="fields"
      :busy="loading"
      :tbody-tr-class="taskRowClass"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{ name: 'task-view', params: { id: data.item.id } }"
          class=""
        >
          {{ data.item.title }}
        </router-link>
      </template>

      <template #cell(type)="data">
        {{ getTaskTypeTitle(data.item.type) }}
      </template>
      <template #cell(assignee)="data">
        {{ getUserName(data.item.author) }}
      </template>

      <template #cell(author)="data">
        {{ getUserName(data.item.assignee) }}
      </template>

      <template #cell(deadline)="data">
        {{ getTaskDeadline(data.item) }}
      </template>

      <template #cell(completeTime)="data">
        {{ getTaskCompleteTime(data.item) }}
      </template>

      <template #cell(complete)="data">
        <b-button
          v-if="!data.item.completed"
          class="btn-xs"
          variant="primary"
          @click="completeTask(data.item)"
        >
          <span v-if="!data.item.loading">
            <font-awesome-icon icon="check" /> Завершить
          </span>
          <font-awesome-icon v-else icon="rotate" spin />
        </b-button>
        <b-button
          v-else
          class="btn-xs"
          variant="info"
          @click="restoreTask(data.item)"
        >
          <span v-if="!data.item.loading">
            <font-awesome-icon icon="box" /> Открыть
          </span>
          <font-awesome-icon v-else icon="rotate" spin />
        </b-button>
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
