import CallCauseList from "@/modules/pest/views/call-causes/CallCauseList";
import CallCauseCreate from "@/modules/pest/views/call-causes/CallCauseCreate";
import CallCauseUpdate from "@/modules/pest/views/call-causes/CallCauseUpdate";
import CallCauseView from "@/modules/pest/views/call-causes/CallCauseView";

export default [
  {
    path: "/call-causes/page:page?",
    name: "pest-call-cause-list",
    components: {
      default: CallCauseList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/call-causes/new",
    name: "pest-call-cause-create",
    components: {
      default: CallCauseCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/call-causes/:id/update",
    name: "pest-call-cause-update",
    components: {
      default: CallCauseUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/call-causes/:id",
    name: "pest-call-cause-view",
    components: {
      default: CallCauseView,
    },
    meta: { requiresAuth: true },
  },
];
