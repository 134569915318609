
<div v-if="show" class="dashboard">
  <b-overlay :show="loading" rounded="sm">
    <table class="table table-striped mb-0">
      <tbody>
        <tr v-for="(info, iIndex) in stats" :key="iIndex" class="userRow">
          <td class="align-top">
            <img v-if="iIndex === 0" :src="imgPlace1" class="placeImg" />
            <img v-if="iIndex === 1" :src="imgPlace2" class="placeImg" />
            <img v-if="iIndex === 2" :src="imgPlace3" class="placeImg" />
          </td>
          <td class="align-middle">
            <div
              v-if="info.user.image"
              :style="{
                'background-image': 'url(' + info.user.image.url + ')',
              }"
              class="img-circle userImage"
            ></div>
            <div v-else class="circle"></div>
          </td>
          <td class="align-middle userName">{{ getUserTitle(info.user) }}</td>
          <td
            class="align-top p-0 speedometerCell"
            style="position: relative"
          >
            <div class="speedometerWrapper">
              <img
                :src="imgSpeedometer"
                alt="speedometer"
                class="imgSpeedometer"
              />

              <div
                class="conversion-circle"
                :class="getCircleColorClass(info.conversion)"
              ></div>
              <div
                class="conversion"
                :class="getTextColorClass(info.conversion)"
              >
                {{ info.conversion }}%
              </div>
            </div>
          </td>
          <td class="align-middle">
            <font-awesome-icon icon="sack-dollar" />
            {{ info.total }}
          </td>
        </tr>
      </tbody>
    </table>

    <button
      v-if="false"
      class="btn btn-info btn-xs"
      @click.prevent="fetchData"
    >
      обновить
    </button>
  </b-overlay>
</div>
