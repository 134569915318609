import MessageCreate from "@/modules/notifications/views/messages/MessageCreate";
import MessageList from "@/modules/notifications/views/messages/MessageList";
import MessageUpdate from "@/modules/notifications/views/messages/MessageUpdate";
import MessageView from "@/modules/notifications/views/messages/MessageView";

export default [
  {
    path: "/messages/list/:page?",
    name: "notifications-message-list",
    components: {
      default: MessageList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/messages/new",
    name: "notifications-message-create",
    components: {
      default: MessageCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/messages/:id/update",
    name: "notifications-message-update",
    components: {
      default: MessageUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/messages/:id",
    name: "notifications-message-view",
    components: {
      default: MessageView,
    },
    meta: { requiresAuth: true },
  },
];
