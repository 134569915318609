
<div v-if="show">
  <button v-if="false" class="btn btn-info" @click.prevent="fetchData">
    Refresh
  </button>
  <b-overlay :show="loadingData" rounded="sm">
    <b-form-group class="ml-4" style="width: 250px">
      <b-form-select
        v-model="period"
        :options="chartPeriodOptions"
        @change="fetchData"
      />
    </b-form-group>

    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </b-overlay>
</div>
