<template>
  <b-form
    id="pest-place-form"
    ref="place"
    :model="place"
    class="pest-place-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="place.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Город" prop="townId">
        <b-button-group>
          <button
            class="btn"
            :class="getTownClass(MSK)"
            @click.prevent="selectTown(MSK)"
          >
            Москва
          </button>
          <button
            class="btn"
            :class="getTownClass(SPB)"
            @click.prevent="selectTown(SPB)"
          >
            Санкт-Петербург
          </button>
        </b-button-group>
      </b-form-group>

      <b-form-group label="Поиск по адресу" prop="color">
        <dadata-autocomplete
          v-model="searchAddress"
          @selected="addressSelected"
        ></dadata-autocomplete>
      </b-form-group>

      <b-form-group label="Сохраненный адрес" prop="color">
        <b-form-input v-model="place.address"></b-form-input>
      </b-form-group>

      <b-form-group label="Координаты" prop="color">
        <b-form-input v-model="place.coords"></b-form-input>
      </b-form-group>

      <!--      <div class="row">-->
      <!--        <div class="col-12">-->
      <!--          <b-form-group label="Описание" prop="title">-->
      <!--            <b-form-textarea v-model="place.comment" placeholder="Описание"-->
      <!--                             tabindex="1"></b-form-textarea>-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="place.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deletePlace"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getTownTitle, MSK, SPB } from "@/modules/pest/decorators/towns";
import DadataAutocomplete from "@/components/shared/DadataAutocomplete.vue";

export default {
  name: "PestPlaceForm",
  directives: { mask: VueMaskDirective },
  components: { DadataAutocomplete },
  props: ["place"],
  data() {
    return {
      MSK,
      SPB,
      phoneMask: false,
      loading: false,
      searchAddress: "",
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.place.id;
    },
    name() {
      let name = this.place.name ? this.place.name : "Новый место";
      return name ? name : "Место";
    },
  },
  watch: {
    place: {
      immediate: true,
      handler(obj) {
        this.searchAddress = obj.address;
      },
    },
  },
  created: function () {},
  methods: {
    getUserName,
    getTownTitle,

    getTownClass(town) {
      if (this.place.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-info";
      }
    },
    selectTown(town) {
      this.place.townId = town;
      this.fetchData();
    },

    update: function () {
      let updateUrl;
      updateUrl = "/pest/places/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.place)
        .then((response) => {
          this.place = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-place-info", this.place);
          // this.$router.push({name: 'pest-place-view', params: {id: this.place.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Место отредактировано",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/places";
      this.loading = true;
      this.$http
        .post(updateUrl, this.place)
        .then((response) => {
          this.place = response.data;
          this.$eventHub.$emit("update-pest-place-info", this.place);
          this.$router.push({
            name: "pest-place-update",
            params: { id: this.place.id },
          });
          this.$bvToast.toast("Место добавлен", {
            title: "Место добавлено",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить место",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-place-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    handleTownSubmit(result) {
      this.place.townId = result.id;
    },
    getResultTownAutocompleteValue(town) {
      return this.getTownTitle(town);
    },
    searchTown(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let categoriesUrl = "/pest/towns/search";
        this.loadingTownsList = true;
        this.$http
          .get(categoriesUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loadingTownsList = false;
            resolve(response.data);
            // this.task = response.data;
            // this.header = this.task.email;
          })
          .catch((error) => {
            this.loadingTownsList = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    deletePlace() {
      if (confirm("Вы действительно хотите удалить место?")) {
        let deleteUrl = "/pest/places/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast("Место удален", {
              title: "Место удалено",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить место",
                variant: "danger",
              });
            }
          });
      }
    },
    addressSelected(item) {
      if (!item.value || !item.value.data) {
        return;
      }
      this.place.address = item.value.value;
      let lat = item.value.data.geo_lat;
      let lon = item.value.data.geo_lon;
      this.place.coords = "[" + lat + "," + lon + "]";
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
