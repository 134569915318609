
<div class="data-record-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>Новый вредитель</h1>
        </div>
        <div class="ibox-content">
          <data-record-form :record="record"></data-record-form>
        </div>
      </div>
    </div>
  </div>
</div>
