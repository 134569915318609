
<div class="pb-4">
  <div v-for="(day, dIndex) in days" :key="'d' + dIndex">
    <div class="mb-2 mt-2 text-center">
      <div class="btn btn-outline btn-primary btn-rounded">
        {{ day }}
      </div>
    </div>
    <div
      v-for="(notification, notIndex) in notificationsByDays[day]
        .notifications"
      :key="notIndex"
    >
      <user-notification :notification="notification" />
    </div>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
