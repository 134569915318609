
<div id="app" class="gray-bg">
  <div id="nav">
    <page-header></page-header>
  </div>
  <div id="page-wrapper" class="pl-0 pr-0">
    <b-container fluid="" class=" ">
      <router-view />

      <div
        v-if="hashChanged && $root.env !== 'development'"
        class="refresh-container"
      >
        <div class="notification-header">
          <button
            type="button"
            class="close-refresh-modal"
            aria-label="Close"
          >
            <span aria-hidden="true"><i class="fal fa-times fa-sm"></i></span>
          </button>
        </div>
        <div class="notification-body">
          <div class="notification-button">
            <p class="text-center font12">
              Появилось обновление. Сохраните всю вашу текущую работу и
              нажмите кнопку "Обновиться"
            </p>
            <p class="text-center">
              <span class="font10">Без обновления могут быть ошибки</span>
            </p>
          </div>
          <div class="refresh-button text-center">
            <button class="btn btn-default" @click="reloadApp">
              Обновиться
            </button>
          </div>
        </div>
      </div>
    </b-container>

    <task-popup></task-popup>
  </div>
</div>
