
<div class="ibox">
  <div class="ibox-content">
    <h1>Источники заявок Roistat</h1>
    <div class="mb-2">
      <router-link
        :to="{ name: 'pest-source-create' }"
        class="btn btn-primary"
      >
        <font-awesome-icon icon="plus" />
        Добавить источник
      </router-link>
    </div>

    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <b-form-group label="Название" class="mr-2">
          <b-form-input v-model="search.title" tabindex="1"></b-form-input>
        </b-form-group>

        <b-form-group label="Тип" prop="title" class="mr-2">
          <b-form-select
            v-model="search.type"
            :options="sourceTypeOptions"
            placeholder="Тип"
            tabindex="1"
          ></b-form-select>
        </b-form-group>

        <div class="mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.total }}
        </div>
      </div>
    </div>
    <b-table
      v-if="sources.length"
      responsive
      striped
      hover
      condensed
      :items="sources"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong>Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{ name: 'pest-source-update', params: { id: data.item.id } }"
          class=""
        >
          {{ data.item.title ? data.item.title : "[Без названия]" }}
        </router-link>
      </template>
      <template #cell(type)="data">
        {{ getSourceTypeTitle(data.item) }}
      </template>
      <template #cell(target)="data">
        <div
          v-if="
            data.item.type === TYPE_MANUAL &&
            data.item.target === TARGET_IN_CONTRACT
          "
        >
          Для заявок
        </div>
      </template>
      <template #cell(searchOnlyByPhone)="data">
        {{ data.item.searchOnlyByPhone ? "Да" : "Нет" }}
      </template>
      <template #cell(projectId)="data">
        {{ projectName(data.item.projectId) }}
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
