
<div>
  <a v-if="show" href="" @click.prevent="toggle">Скрыть</a>
  <a v-else href="" @click.prevent="toggle">Показать подробности</a>
  <div v-if="show">
    <table class="table table-striped request-info">
      <tbody>
        <tr
          v-for="(info, ind) in log.fields"
          :key="ind"
          :class="getClass(info)"
        >
          <td class="title">
            <font-awesome-icon :icon="getIcon(info)" fixed-width />
            {{ getTitle(info) }}
          </td>
          <td class="val">
            <div v-if="info.attr === 'time'">
              {{ getDatetime(info.oldVal) }}
            </div>
            <div v-else-if="info.attr === 'project'">
              {{ getProjectTitle(info.oldVal) }}
            </div>
            <div v-else-if="info.attr === 'clientType'">
              {{ info.oldVal === CLIENT_TYPE_ORG ? "Юр. лицо" : "Физ. лицо" }}
            </div>
            <div v-else-if="info.attr === 'paymentType'">
              {{ info.oldVal === PT_CASHLESS ? "Безнал" : "Нал" }}
            </div>
            <div v-else-if="info.attr === 'resultFiles'">
              <span v-for="(file, fIndex) in info.oldVal" :key="'i' + fIndex">
                <a
                  v-b-tooltip.hover
                  :href="file.url"
                  :title="file.name"
                  target="_blank"
                >
                  {{ file.name + "." + file.extension }}
                </a>
              </span>
            </div>
            <div v-else-if="isBoolAttr(info)">
              {{ info.oldVal ? "Да" : "Нет" }}
            </div>
            <div v-else>{{ getOldVal(info) }}</div>
          </td>
          <td class="arrow">
            <font-awesome-icon icon="arrow-right" />
          </td>
          <td class="val">
            <div v-if="info.attr === 'time'">
              {{ getDatetime(info.newVal) }}
            </div>
            <div v-else-if="info.attr === 'project'">
              {{ getProjectTitle(info.newVal) }}
            </div>
            <div v-else-if="info.attr === 'clientType'">
              {{ info.newVal === CLIENT_TYPE_ORG ? "Юр. лицо" : "Физ. лицо" }}
            </div>
            <div v-else-if="info.attr === 'paymentType'">
              {{ info.newVal === PT_CASHLESS ? "Безнал" : "Нал" }}
            </div>
            <div v-else-if="info.attr === 'resultFiles'">
              <div v-if="info.newVal.length" class="files-block">
                <span
                  v-for="(file, fIndex) in info.newVal"
                  :key="'i' + fIndex"
                >
                  <a
                    v-b-tooltip.hover
                    :href="file.url"
                    :title="file.name"
                    target="_blank"
                  >
                    {{ file.name + "." + file.extension }}
                  </a>
                </span>
              </div>
              <div v-else>Нет файлов</div>
            </div>
            <div v-else-if="isBoolAttr(info)">
              {{ info.newVal ? "Да" : "Нет" }}
            </div>
            <div v-else>{{ getNewVal(info) }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
