import PlaceList from "@/modules/pest/views/places/PlaceList";
import PlaceCreate from "@/modules/pest/views/places/PlaceCreate";
import PlaceUpdate from "@/modules/pest/views/places/PlaceUpdate";
import PlaceView from "@/modules/pest/views/places/PlaceView";

export default [
  {
    path: "/places/page:page?",
    name: "pest-place-list",
    components: {
      default: PlaceList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/places/new",
    name: "pest-place-create",
    components: {
      default: PlaceCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/places/:id/update",
    name: "pest-place-update",
    components: {
      default: PlaceUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/places/:id",
    name: "pest-place-view",
    components: {
      default: PlaceView,
    },
    meta: { requiresAuth: true },
  },
];
