<template>
  <b-form
    id="direct-account-form"
    ref="account"
    :model="account"
    class="direct-account-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="account.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Валюта" prop="token">
            <b-form-select
              v-model="account.currency"
              :options="currencyOptions"
              placeholder=""
              tabindex="1"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Ключ" prop="token">
            <b-form-input
              v-model="account.token"
              placeholder=""
              tabindex="1"
            ></b-form-input>

            <a target="_blank" :href="tokenLink">1. Получить код с яндекса</a
            ><br />
            <a
              target="_blank"
              href="https://ru.dez-crm.ru/backend/api/direct/default/token?code="
            >
              2. Ввести полученный код по адресу: <br />
              https://ru.dez-crm.ru/backend/api/direct/default/token?code=123456
            </a>
            <br />
            3. Ввести access_token в поле выше
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="account.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Учет по группе" prop="groupAccount">
            <b-form-checkbox
              v-model="account.groupAccount"
              tabindex="1"
              value="1"
            ></b-form-checkbox>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="account.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteAccount"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { currencyOptions } from "@/modules/direct/decorators/accounts";

export default {
  name: "DirectAccountForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["account"],
  data() {
    return {
      currencyOptions,
      phoneMask: false,
      loading: false,
      clientId: "294ee875530d4ec588054e2a367b45ee",
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.account.id;
    },
    tokenLink() {
      return (
        "https://oauth.yandex.ru/authorize?response_type=code&client_id=" +
        this.clientId
      );
    },
    name() {
      let name = this.account.name ? this.account.name : "Новый аккаунт";
      return name ? name : "Аккаунт";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/direct/accounts/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.account)
        .then((response) => {
          this.account = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-direct-account-info", this.account);
          // this.$router.push({name: 'direct-account-view', params: {id: this.account.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Аккаунт отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/direct/accounts";
      this.loading = true;
      this.$http
        .post(updateUrl, this.account)
        .then((response) => {
          this.account = response.data;
          this.$eventHub.$emit("update-direct-account-info", this.account);
          this.$router.push({
            name: "direct-account-update",
            params: { id: this.account.id },
          });
          this.$bvToast.toast("Аккаунт добавлен", {
            title: "Аккаунт добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить аккаунт",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("direct-account-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteAccount() {
      if (confirm("Вы действительно хотите удалить аккаунт?")) {
        let deleteUrl = "/direct/accounts/" + this.account.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "direct-account-list" });
            this.$bvToast.toast("Аккаунт удален", {
              title: "Аккаунт удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить аккаунт",
                variant: "danger",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
