<template>
  <div class="ibox">
    <div class="ibox-title d-flex align-items-center pr-4">
      <div>
        <h5>Уведомления</h5>
      </div>
    </div>
    <div class="ibox-content">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mr-2">
            <b-form-group label="Дата от">
              <datepicker
                v-model="search.dateFrom"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Дата до">
              <datepicker
                v-model="search.dateTo"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Тип">
              <b-form-select
                v-model="search.type"
                :options="notificationOptions"
                tabindex="1"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Исполнитель">
              <user-autocomplete
                v-model="search.assigneeId"
                :default-value="
                  search.assigneeId
                    ? getAssigneeName(search.assignee, true)
                    : ''
                "
                @selected="selectAssignee"
              />
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            Найдено: {{ pagination.total }}
          </div>
        </div>
      </div>

      <div v-if="!notifications.length && !loading">Еще нет уведомлений</div>
      <div v-else class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="col-time col-2">Время события</th>
              <th class="col-user col-2">Тип</th>
              <th class="col-user col-2">Пользователь</th>
              <th class="col-description col-8">Описание</th>
              <th class="col-status col-3">Статус</th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <full-notification
              v-for="(notification, index) in notifications"
              :key="index"
              :notification="notification"
            />
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">Загружается</td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination-nav
        v-if="pagination.count > 1"
        :link-gen="linkGen"
        :value="pagination.page"
        :number-of-pages="pagination.count"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import FullNotification from "@/modules/notifications/views/notifications/FullNotification.vue";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import { getAssigneeName } from "@/modules/crm/common.js";
import {
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestManagerName,
  getRequestStatus,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  servicesSum,
} from "@/modules/pest/decorators/requests.js";
import DatePicker from "vue2-datepicker";
import { notificationOptions } from "@/modules/notifications/decorators/notifications.js";

export default {
  name: "AllNotifications",
  components: {
    UserAutocomplete,
    FullNotification,
    Datepicker: DatePicker,
  },
  props: [],
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      search: {
        assignee: {},
        assigneeId: null,
        title: "",
        type: "",
        dateFrom: "",
        dateTo: "",
        managerId: "",
        externalId: "",
        status: null,
      },
      notifications: [],
      notificationOptions: [
        { text: "Все", value: null },
        ...notificationOptions,
      ],
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      loading: false,
      updating: false,
      unreadCount: 0,
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created() {
    this.checkUrl();
  },
  beforeDestroy() {},
  methods: {
    getAssigneeName,
    servicesSum,
    getRequestTitle,
    getRequestDatetime,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getRequestTownTitle,
    selectAssignee(data) {
      if (data && data.value) {
        this.search.assigneeId = data.value.id;
        this.search.assignee = data.value;
      } else {
        this.search.assigneeId = null;
        this.search.assignee = {};
      }
      this.fetchData();
    },

    checkUrl: function () {
      this.fetchData();
    },
    fetchData: function () {
      let itemsUrl = "/notifications/notification/all";
      let params = {
        userId: this.search.assigneeId,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        type: this.search.type,
        page: this.page,
      };
      this.loading = true;
      this.$http
        .get(itemsUrl, {
          params: params,
        })
        .then((response) => {
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          this.notifications = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.notifications = [];
          this.loading = false;
          this.processRequestError(error);
        });
    },
    markAllAsRead() {
      if (this.updating) {
        return;
      }
      this.updating = true;

      let itemsUrl = "/notifications/notification/confirm-all";
      let params = {};
      this.updating = true;
      this.$http
        .post(
          itemsUrl,
          {},
          {
            params: params,
          }
        )
        .then((response) => {
          this.updating = false;
          for (let notification of this.notifications) {
            notification.readTime = response.data.readTime;
          }
          this.unreadCount = 0;

          this.$toast.open({
            message: "Все уведомления отмечены как просмотренные",
            type: "success",
          });
        })
        .catch((error) => {
          this.updating = false;
          this.processRequestError(error);
        });
    },
    linkGen(pageNum) {
      return {
        name: "notifications-notification-user-list",
        params: { page: pageNum },
      };
    },
    eventRead() {
      console.log(1);
      this.unreadCount--;
    },
  },
};
</script>

<style scoped>
.log-list {
  max-height: 300px;
  overflow-y: auto;
}

.col-time {
  min-width: 100px;
}

.col-user {
  min-width: 100px;
}

.col-description {
}

.col-status {
  min-width: 250px;
}
</style>
