import dayjs from "dayjs";
import { getUserName } from "@/modules/crm/common";

export const getNotificationDate = function (notification) {
  return dayjs.unix(notification.createTime).format("DD.MM.YYYY");
};

export const getNotificationCreateTime = function (notification) {
  return dayjs.unix(notification.createTime).format("HH:mm");
};

export const getNotificationCreateDatetime = function (notification) {
  return dayjs.unix(notification.createTime).format("DD.MM.YYYY HH:mm");
};
export const getNotificationConfirmDatetime = function (notification) {
  return dayjs.unix(notification.readTime).format("DD.MM.YYYY HH:mm");
};

export const getNotificationUser = function (notification) {
  return getUserName(notification.user);
};

export const REQUEST_CHANGED_ASSIGNEE = "change_assignee";
export const FINE_APPROVED = "fine_approved";
export const REQUEST_NEW_RECORD = "new_record";
export const MESSAGE = "message";

export const notificationTitles = {
  [REQUEST_CHANGED_ASSIGNEE]: "Смена мастера",
  [FINE_APPROVED]: "Назначен штраф",
  [REQUEST_NEW_RECORD]: "Новый рекорд",
  [MESSAGE]: "Сообщение",
};
export const notificationOptions = [
  { text: "Смена мастера", value: REQUEST_CHANGED_ASSIGNEE },
  { text: "Назначен штраф", value: FINE_APPROVED },
  { text: "Новый рекорд", value: REQUEST_NEW_RECORD },
  { text: "Сообщение", value: MESSAGE },
];

export const getNotificationTypeTitle = function (notification) {
  if (!notification.type) {
    return "[Не назначено]";
  }
  return notificationTitles[notification.type];
};
