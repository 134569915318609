<template>
  <b-form
    id="pest-service-form"
    ref="service"
    :model="service"
    class="pest-service-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="service.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Единица измерения" prop="unit">
            <b-form-input
              v-model="service.unit"
              placeholder="шт."
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="service.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="service.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteService"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";

export default {
  name: "PestServiceForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["service"],
  data() {
    return {
      phoneMask: false,
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.service.id;
    },
    name() {
      let name = this.service.name ? this.service.name : "Новый услуга";
      return name ? name : "Услуга";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/services/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.service)
        .then((response) => {
          this.service = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-service-info", this.service);
          // this.$router.push({name: 'pest-service-view', params: {id: this.service.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Услуга отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/services";
      this.loading = true;
      this.$http
        .post(updateUrl, this.service)
        .then((response) => {
          this.service = response.data;
          this.$eventHub.$emit("update-pest-service-info", this.service);
          this.$router.push({
            name: "pest-service-update",
            params: { id: this.service.id },
          });
          this.$bvToast.toast("Услуга добавлен", {
            title: "Услуга добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить услуга",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-service-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteService() {
      if (confirm("Вы действительно хотите удалить услуга?")) {
        let deleteUrl = "/pest/services/" + this.service.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-service-list" });
            this.$bvToast.toast("Услуга удален", {
              title: "Услуга удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить услуга",
                variant: "danger",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
