import Vue from "vue";
import "virtual:vite-plugin-sentry/sentry-config";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import BootstrapVue, { ToastPlugin } from "bootstrap-vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import processRequestError from "./modules/users/access.js";

import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import "@trevoreyre/autocomplete-vue/dist/style.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/style.css";
import "./assets/css/app.css";
import "./assets/css/datepicker.scss";

import "vue-toast-notification/dist/theme-default.css";
import "./assets/css/toaster.css";
import { mutations } from "@/store/socket/types.js";

import VueToast from "vue-toast-notification";

import "dayjs/locale/ru";
import dayjs from "dayjs";
import { library } from "@beaversoft/fontawesome-svg-core";
import { fas } from "@beaversoft/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@beaversoft/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { default as defaultSentryOptions } from "@/plugins/sentry";

import VueAPI from "@/plugins/api/";
import { SOCKET_URL } from "@/plugins/api/helpers";
import VueNativeSock from "vue-native-websocket";

dayjs.locale("ru");

library.add(fas);

// if (import.meta.env.MODE === "production") {
//   const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;
//   Sentry.init({
//     ...defaultSentryOptions,
//     release,
//     Vue,
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 0.2,
//     logErrors: true,
//   });
//   Sentry.setUser({
//     id: store.state.user.id,
//     username: store.state.user.name,
//   });
// }

Vue.component("FontAwesomeIcon", FontAwesomeIcon);

Vue.mixin({
  methods: {
    processRequestError: processRequestError,
  },
});

Vue.prototype.$eventHub = new Vue(); // Global event bus
// Vue.use(VueNativeSock, SOCKET_URL, {
//   // store: store,
//   format: 'json',
//   reconnection: true, // (Boolean) whether to reconnect automatically (false)
//   reconnectionAttempts: 0, // (Number) number of reconnection attempts before giving up (Infinity),
//   reconnectionDelay: 1000, // (Number) how long to initially wait before attempting a new (1000)
// });

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.use(VueToast, {
  position: "top",
  duration: 5000,
});

Vue.config.productionTip = false;
Vue.use(ToastPlugin);
Vue.use(BootstrapVue);
Vue.use(Autocomplete);
Vue.use(VueAPI);

Vue.use(VueNativeSock, SOCKET_URL, {
  format: "json",
  store: store,
  mutations: mutations,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
