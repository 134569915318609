<template>
  <div class="task-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>
              <router-link :to="returnRoute" class="h4 text-muted">
                <font-awesome-icon icon="arrow-left" />
              </router-link>
              Создание задачи
            </h1>
          </div>
          <div class="ibox-content">
            <task-form
              :task="task"
              :return-url="returnRoute"
              :own-window="true"
            ></task-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskForm from "./TaskForm";
import { TYPE_TASK } from "@/modules/crm/decorators/task";
import dayjs from "dayjs";

export default {
  name: "Task",
  components: {
    "task-form": TaskForm,
  },
  data() {
    return {
      task: {
        id: "new",
        type: TYPE_TASK,
        typeId: "",
        deadline: dayjs().format("DD.MM.YYYY"),
        deadlineT: "",
        assigneeId: "", //this.$store.state.user.id,
        assignee: "", //this.$store.state.user.profile,
        comment: "",
        result: "",
        completed: false,
        links: {
          clientId: false,
          client: false,
        },
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
    returnUrl() {
      return this.$route.params.returnUrl;
    },
    returnRoute() {
      if (this.returnUrl) {
        return this.returnUrl;
      } else {
        return { name: "task-list" };
      }
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
