<template>
  <div>
    Новый рекорд <br />
    Сумма услуг: <strong>{{ notification.serviceSum }}</strong
    ><br />
    Новый рекорд: <strong>{{ notification.newSum }}</strong
    ><br />
    Предыдущий рекорд: <strong>{{ notification.recordCurSum }}</strong
    ><br />

    <span v-if="notification.newSum1">
      <strong>Рекорд был сброшен </strong><br
    /></span>
    <!--      <span v-if="notification.newSum2"> <strong >Рекорд округлен </strong><br></span>-->
    Сумма, которую надо превысить:
    <strong>{{ notification.compareRecordSum }}</strong
    ><br />
    Минимальная планка для рекорда:
    <strong>{{ notification.recordSumMin }}</strong
    ><br />
    Максимальная планка для рекорда:
    <strong>{{ notification.recordSumMax }}</strong
    ><br />
    <!--      Интервал повышения: <strong>{{ notification.recordSumInc }}</strong> <br>-->
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "NotificationFullNewRecord",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {
    sum() {
      return this.formatNumber(this.notification.serviceSum);
    },
  },
  watch: {},
  methods: {
    getUserName,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
