
<b-modal
  id="modal-board-columns-popup"
  ref="modal"
  cancel-title="Отмена"
  ok-title="Сохранить"
  title-tag="h3"
  @ok="handleDealModalOk"
>
  <template #modal-title> Показывать отмеченные столбцы</template>
  <div v-for="(col, cIndex) in columns" :key="cIndex">
    <b-form-checkbox v-model="checkedColumns" :value="col.id">
      {{ col.title }}
    </b-form-checkbox>
  </div>
</b-modal>
