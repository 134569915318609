
<div class="ibox">
  <div class="ibox-title" :class="{ editing: editing }">
    <div class="search">
      <div class="d-flex flex-wrap align-items-center">
        <h2 class="mr-2">Расписание</h2>
        <div class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getTownClass(MSK)"
              @click.prevent="selectTown(MSK)"
            >
              Москва
            </button>
            <button
              class="btn"
              :class="getTownClass(SPB)"
              @click.prevent="selectTown(SPB)"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
          <!--            <b-form-select v-model="search.townId" :options="townList" tabindex="1"></b-form-select>-->
        </div>
        <div class="mr-2">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>

        <div class="align-self-center ml-auto">
          <button
            v-if="editing"
            class="btn btn-danger ml-1"
            @click="toggleEdit"
          >
            <font-awesome-icon
              icon="exclamation-triangle"
            />&nbsp;Редактирование включено
          </button>
          <button
            v-if="!editing"
            class="btn btn-outline-info ml-1"
            @click="toggleEdit"
          >
            <font-awesome-icon icon="check" />&nbsp; Редактирование отключено
          </button>
        </div>
      </div>
    </div>
  </div>

  <div ref="ibox" class="ibox-content p-0">
    <div v-if="!loading" class="table-div">
      <table
        class="table table-bordered table-schedule"
        :class="{ editing: editing }"
      >
        <thead>
          <tr>
            <th class="user-name">Мастер</th>
            <th
              v-for="(date, ddIndex) in dates"
              :key="'dd' + ddIndex"
              class="statusCell"
              :class="getDateHeaderCellClass(date)"
            >
              {{ formattedDateHeader(date) }}
              – {{ getShiftCount(date) }}
            </th>
          </tr>
        </thead>
        <tbody ref="tbody" :style="tbodyStyle">
          <tr v-for="(info, aIndex) in schedule" :key="'a' + aIndex">
            <th
              class="user-name d-flex"
              @click.prevent="changeAssigneeComment(info.assignee)"
            >
              <div>
                {{ getAssigneeName(info.assignee) }}
              </div>

              <div
                v-if="info.assignee.scheduleComment"
                v-b-tooltip.hover
                class="comment-icon ml-auto"
                :title="info.assignee.scheduleComment"
                @click.prevent
              >
                <font-awesome-icon icon="comment" />
              </div>
            </th>
            <!--                @mouseenter="cellEnter(dIndex,info)"-->
            <!--                @mouseleave="cellLeave(date,info)"-->
            <td
              v-for="(date, dIndex) in info.dates"
              :key="'d' + dIndex"
              :class="getStatusClass(date, dIndex)"
              @mousedown="startSelect(info, date)"
              @mouseover="processSelect(info, date)"
              @mouseup="endSelect(info, date)"
              @click.prevent="toggleStatus(info.assignee, dIndex, date)"
            >
              <div
                v-if="date.comment"
                v-b-tooltip.hover
                class="comment-icon ml-1 mt-1"
                :title="date.comment"
                @click.prevent
              >
                <font-awesome-icon icon="comment" />
              </div>

              <div v-else-if="!date.selected" class="cell-info">
                {{ formattedDate(dIndex) }}<br />
                {{ getAssigneeName(info.assignee) }}
              </div>
              <div v-if="date.selected" class="selected">
                <font-awesome-icon icon="check" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="p-2">Данные загружаются</div>
  </div>

  <schedule-record-popup></schedule-record-popup>
  <schedule-multiple-record-popup></schedule-multiple-record-popup>
  <assignee-comment-popup></assignee-comment-popup>
</div>
