
<div class="ibox">
  <div class="ibox-title" :class="{ editing: editing }">
    <div class="search">
      <div class="d-flex align-items-center">
        <h2 class="mr-2">Расписание</h2>
        <div class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getTownClass(MSK)"
              @click.prevent="selectTown(MSK)"
            >
              Москва
            </button>
            <button
              class="btn"
              :class="getTownClass(SPB)"
              @click.prevent="selectTown(SPB)"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
          <!--            <b-form-select v-model="search.townId" :options="townList" tabindex="1"></b-form-select>-->
        </div>
        <div class="mr-2">
          <datepicker
            v-model="search.date"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
            @change="fetchData"
          ></datepicker>
        </div>

        <div>
          <button class="btn btn-outline-info">
            <font-awesome-icon
              icon="rotate"
              :spin="loading"
              @click.prevent="fetchData"
            />
          </button>
        </div>

        <div v-if="canEditSchedule" class="align-self-center ml-auto">
          <button
            v-if="editing"
            class="btn btn-danger ml-1"
            @click="toggleEdit"
          >
            <font-awesome-icon
              icon="exclamation-triangle"
            />&nbsp;Редактирование включено
          </button>
          <button
            v-if="!editing"
            class="btn btn-outline-info ml-1"
            @click="toggleEdit"
          >
            <font-awesome-icon icon="check" />&nbsp; Редактирование отключено
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox-content pl-0 pr-0" :class="{ editing: editing }">
    <div v-if="loaded" class="table-div">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Время</th>
            <th
              v-for="(info, aIndex) in schedule"
              :key="'a' + aIndex"
              :class="userDutyClass(info)"
            >
              {{ getAssigneeName(info.assignee, false) }}
            </th>
          </tr>
          <tr>
            <th></th>
            <th
              v-for="(info, aIndex) in schedule"
              :key="'a' + aIndex"
              class="shortTimeInfo"
            >
              <div
                class="evenness_wrapper d-flex"
                :class="getEvennessClass(info)"
              >
                <a
                  class="evenness_odd"
                  @click.prevent="setEvennessClass(info, 'odd')"
                >
                  <font-awesome-icon icon="arrow-down" />
                </a>
                <a
                  class="evenness_even"
                  @click.prevent="setEvennessClass(info, 'even')"
                >
                  <font-awesome-icon icon="arrow-down" />
                </a>
              </div>
              <div
                v-for="(time, tIndex) in mainSlots"
                :key="'t' + tIndex"
                class="time"
                :class="timeSlotClass(info, time)"
                @click="toggleTime(info, time + ':00', info.assignee.id)"
              >
                {{ time }}
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(timeSlot, tIndex) in slots"
            :key="'t' + tIndex"
            :class="{ highlighted: highlightedSlots.includes(timeSlot) }"
          >
            <th class="timeCell">
              {{ timeSlot }}
            </th>
            <td
              v-for="(info, dIndex) in schedule"
              :key="'h' + dIndex"
              :class="getCellClass(info, timeSlot)"
              @click="toggleTime(info, timeSlot, info.assignee.id)"
            >
              <div
                v-if="
                  info.slots[timeSlot].status && info.slots[timeSlot].start
                "
              >
                <router-link
                  :to="{
                    name: 'pest-request-view',
                    params: { id: info.slots[timeSlot].request.id },
                  }"
                  target="_blank"
                >
                  {{ timeSlot }}
                  <small>
                    {{ info.slots[timeSlot].request.number }}
                  </small>
                </router-link>
              </div>
              <div v-else class="time">
                {{ timeSlot }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="p-2">Данные загружаются</div>
  </div>
</div>
