<template>
  <div v-if="!logged">
    <div class="ibox mb-1">
      <div class="ibox-content">
        <h2>У вас нет доступа к данной странице</h2>
      </div>
    </div>
  </div>

  <div v-else>
    <div v-if="isClient()">
      <div class="ibox mb-1">
        <div class="ibox-content">
          <issues></issues>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="false" class="row">
        <div class="col-12">
          <div class="ibox mb-1">
            <div class="ibox-content">
              <employees></employees>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="ibox mb-1">
            <div class="ibox-content">
              <tasks></tasks>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div class="ibox mb-1">
            <div class="ibox-content">
              <issues></issues>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DTasks from "@/modules/crm/views/app/dashboard/DTasks";
import DIssues from "@/modules/crm/views/app/dashboard/DIssues";
import DEmployees from "@/modules/crm/views/app/dashboard/DEmployees";
import { isClient } from "@/modules/users/rbac";

export default {
  name: "Settings",
  components: {
    tasks: DTasks,
    issues: DIssues,
    employees: DEmployees,
  },
  computed: {
    logged() {
      return !this.$store.state.user.isGuest;
    },
  },

  methods: {
    isClient,
  },
};
</script>

<style scoped>
h2 {
  padding-left: 5px;
}

ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

ul li a {
  display: block;
  margin-left: 0;
  margin-bottom: 10px;
  padding: 5px;
}

ul li:hover {
  background: #f5f4f4;
}

span.hint {
  display: block;
  color: #929191;
  font-size: smaller;
}
</style>
