<template>
  <b-overlay :show="loadingRequests" rounded="sm">
    <div
      v-for="(group, gIndex) in groupedRequests"
      :key="gIndex + '-g'"
      class="ibox"
    >
      <div class="ibox-title">
        {{ getAddressFormatted(group.address) }}
      </div>
      <div class="ibox-content d-flex flex-wrap">
        <request-card
          v-for="(request, rIndex) in group.requests"
          :key="request.id + '-r'"
          :r-i="rIndex"
          :request="request"
          :update-button="true"
          :lead-board="true"
          :contract-board="true"
          :short-version="true"
          :hide-handle="true"
          @duplicate-request="duplicateRequest"
          @show-popup="showRequestPopup"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import RequestCard from "@/modules/pest/views/requests/card/RequestCard.vue";
import RequestForm from "@/modules/pest/views/requests/view/RequestForm.vue";
import RequestPopupInfo from "@/modules/pest/views/requests/dispatching/_request/RequestPopupInfo.vue";
import clone from "just-clone";
import {
  emptyRequest,
  getAddressFormatted,
  getRequestNumber,
  STAGE_LEAD,
} from "@/modules/pest/decorators/requests.js";

export default {
  name: "ContractRequests",
  components: { RequestCard },
  props: ["contractId"],
  data() {
    return {
      updating: false,
      groupedRequests: [],
      requests: [],
      curRequest: null,
      loadingRequests: false,
    };
  },
  computed: {},
  watch: {
    contractId: {
      immediate: true,
      updating: false,
      handler(contractId) {
        this.contractId = contractId;
        this.fetchRequests();
      },
    },
  },
  created: function () {
    this.$eventHub.$on("request-duplicated", this.onRequestDuplicated);
  },
  mounted() {},
  methods: {
    getAddressFormatted,
    showRequestPopup(info) {
      this.$emit("open-request", {
        requestId: info.request.id,
      });
    },
    onRequestDuplicated({ oldRequest, newRequest }) {
      this.requests.push(newRequest);
      this.groupRequests();
      this.curRequest = newRequest;
      this.showRequest = true;
      this.$emit("start-updating");
      this.$emit("open-request", {
        requestId: newRequest.id,
      });
    },
    fetchRequests() {
      this.loadingRequests = true;

      let requestsUrl = "/pest/requests";
      let params = {
        contractId: this.contractId,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingRequests = false;
          this.requests = response.data;
          this.$emit("number-list-updated", this.getRequestNumberList());
          this.groupRequests();
        })
        .catch((error) => {
          this.loadingRequests = false;
          this.processRequestError(error);
        });
    },
    groupAddress(address) {
      return address ? address : "Без адреса";
    },
    getRequestNumberList() {
      let list = [];
      for (let request of this.requests) {
        let number = getRequestNumber(request);
        list.push({ value: request.id, text: number });
      }
      return list;
    },
    groupRequests() {
      let $this = this;
      this.groupedRequests = [];
      for (let request of this.requests) {
        if (
          request.stage === STAGE_LEAD &&
          request.leadStatus &&
          (request.leadStatus.trash || request.leadStatus.duplicate)
        ) {
          // не показываем спам/повторный звонок
          continue;
        }
        let address = this.groupAddress(request.address);
        let group = this.groupedRequests.find((q) => {
          return $this.groupAddress(q.address) === address;
        });
        if (!group) {
          group = {
            address: address,
            requests: [],
          };
          this.groupedRequests.push(group);
        }
        group.requests.push(request);
      }
      for (let group of this.groupedRequests) {
        group.requests.sort((a, b) => {
          return a.indexNumber - b.indexNumber;
        });
      }
    },
    duplicateRequest(request) {
      this.$emit("duplicate-request", request);
    },
    onRequestUpdate(info) {
      let updatedRequest = info.request;
      for (let cmpRequest of this.requests) {
        if (cmpRequest.id === updatedRequest.id) {
          Object.assign(cmpRequest, updatedRequest);
          return;
        }
      }
    },

    addNewRequest() {
      let newRequest = clone(emptyRequest);
      // newRequest.leadStatusId = status.id;
      // newRequest.leadStatus = status;
      newRequest.stage = STAGE_LEAD;
      newRequest.managerId = this.$store.state.user.id;
      newRequest.contractId = this.contractId;
      newRequest.manager = clone(this.$store.state.user.profile);
      this.requests.push(newRequest);
      this.curRequest = newRequest;
      this.updating = true;
      this.showRequest = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
