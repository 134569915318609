<template>
  <div>
    Звонок с клиентом:
    <div v-if="!emptyInfo">
      <audio :src="file" controls></audio>
    </div>
    <div v-else>Идет в настоящее время</div>
  </div>
</template>

<script>
import { getUserTitle } from "@/modules/users/decorators/userDecorator.js";

export default {
  name: "RequestLogMangoCallRecord",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {
    file() {
      return this.log.info.callFileUrl;
    },
    emptyInfo() {
      return !this.log.info.callFile || !this.log.info.callFileUrl;
    },
  },
  watch: {},
  methods: { getUserTitle },
};
</script>

<style lang="scss" scoped></style>
