import EquipmentList from "@/modules/pest/views/equipments/EquipmentList";
import EquipmentCreate from "@/modules/pest/views/equipments/EquipmentCreate";
import EquipmentUpdate from "@/modules/pest/views/equipments/EquipmentUpdate";
import EquipmentView from "@/modules/pest/views/equipments/EquipmentView";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/equipments/page:page?",
    name: "pest-equipment-list",
    components: {
      default: EquipmentList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/equipments/new",
    name: "pest-equipment-create",
    components: {
      default: EquipmentCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/equipments/:id/update",
    name: "pest-equipment-update",
    components: {
      default: EquipmentUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/equipments/:id",
    name: "pest-equipment-view",
    components: {
      default: EquipmentView,
    },
    meta: { requiresAuth: true },
  },
];
