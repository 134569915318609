import RegionList from "@/modules/pest/views/regions/RegionList";
import RegionCreate from "@/modules/pest/views/regions/RegionCreate";
import RegionUpdate from "@/modules/pest/views/regions/RegionUpdate";
import RegionView from "@/modules/pest/views/regions/RegionView";

export default [
  {
    path: "/regions/page:page?",
    name: "pest-region-list",
    components: {
      default: RegionList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/regions/new",
    name: "pest-region-create",
    components: {
      default: RegionCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/regions/:id/update",
    name: "pest-region-update",
    components: {
      default: RegionUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/regions/:id",
    name: "pest-region-view",
    components: {
      default: RegionView,
    },
    meta: { requiresAuth: true },
  },
];
