
<b-modal
  id="modal-assignee-comment"
  ref="modal"
  :title="modalTitle"
  cancel-title="Отмена"
  ok-title="Сохранить"
  title-tag="h3"
  @ok="handleTaskModalOk"
>
  <b-form-group label="Комментарий" prop="title">
    <b-form-textarea v-model="comment" tabindex="1"></b-form-textarea>
  </b-form-group>
</b-modal>
