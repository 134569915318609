
<div
  class="p-2 mb-2"
  :class="{
    closed: isClosed(request),
    'request-card': !forEmployee,
    'employee-request-card': forEmployee,
  }"
  :style="{
    border:
      '3px  ' +
      getRequestBorderType(request) +
      ' ' +
      getRequestColor(request),
  }"
  @dblclick="openRequest"
>
  <div class="mb-1 d-flex">
    <div>
      <font-awesome-icon icon="list-ol" fixed-width class="mr-1" />
      <!--              Описание-->
      <router-link
        v-if="request.clientId"
        :to="{ name: 'pest-request-view', params: { id: request.id } }"
        class=""
      >
        {{ request.externalId }}
      </router-link>
      <span v-else>[Не назначен]</span>
    </div>
    <div class="ml-auto">
      <span v-if="request.isFirstTime">Первичное</span>
      <span v-else>Вторичное</span>
    </div>
  </div>
  <div class="mb-1 d-flex">
    <div>
      <font-awesome-icon
        v-if="request.isOrganization"
        icon="building"
        fixed-width
        class="mr-1"
      />
      <font-awesome-icon v-else icon="user" fixed-width class="mr-1" />
      <span
        v-if="request.clientId"
        :to="{ name: 'pest-request-view', params: { id: request.id } }"
        class=""
      >
        {{ getRequestClientName(request) }}
      </span>
      <span v-else>[Не назначен]</span>
    </div>
    <div v-if="!forEmployee" class="ml-auto">
      <a class="edit-assignee-link" @click.prevent="openAssigneePopup">
        <font-awesome-icon icon="edit" fixed-width />
        <font-awesome-icon icon="user-cog" fixed-width />
      </a>
    </div>
  </div>

  <div
    v-if="request.isOrganization && request.organizationTitle"
    class="mb-1"
  >
    <font-awesome-icon icon="building" fixed-width class="mr-1" />
    <!--              Описание-->
    {{ request.organizationTitle }}
  </div>

  <div class="mb-1">
    <font-awesome-icon icon="map" fixed-width class="mr-1" />
    <!--              координаты-->
    <span v-if="request.addressCoords">
      {{ request.addressCoords }}
    </span>
    <span v-else>Нет</span>
  </div>

  <div
    v-if="request.isOrganization && request.paymentType && false"
    class="mb-1"
  >
    <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
    <!--              Описание-->
    {{ getPaymentTypeTitle(request) }}
  </div>

  <div
    v-if="!forEmployee"
    class="mb-1"
    :style="'color: ' + getRequestStatusColor(request)"
  >
    <font-awesome-icon icon="tag" fixed-width class="mr-1" />
    <!--              Описание-->
    {{ getRequestStatus(request) }}
  </div>
  <div v-if="!forEmployee && false" class="mb-1">
    <font-awesome-icon icon="phone" fixed-width class="mr-1" />
    <!--              Описание-->
    {{ request.phone }}
  </div>
  <div v-if="!forEmployee && request.contactPhone && false" class="mb-1">
    <font-awesome-icon icon="phone-volume" fixed-width class="mr-1" />
    <!--              Описание-->
    {{ request.contactPhone }}
  </div>

  <div class="mb-1">
    <font-awesome-icon icon="ruble-sign" fixed-width class="mr-1" />
    <!--              Сумма -->
    {{ request.sum }}

    <span v-if="request.earned"> / {{ request.earned }} </span>
    <span v-if="request.hasRecord">
      <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
    </span>

    <span v-if="request.isOrganization"> (Безнал) </span>
  </div>

  <div class="mb-1">
    <font-awesome-icon icon="home" fixed-width class="mr-1" />
    <!--              Сумма -->
    {{ address(request.address) }}
  </div>

  <div v-if="request.subway" class="mb-1">
    <font-awesome-icon icon="map-marked-alt" fixed-width class="mr-1" />
    <!--              Метро -->
    {{ request.subway }}
  </div>
  <div
    v-if="request.pest && false"
    class="mb-1"
    :style="'color: ' + getRequestColor(request)"
  >
    <font-awesome-icon icon="bug" fixed-width class="mr-1" />
    <!--              Вредитель -->
    {{ getPestTitle(request.pest) }}
  </div>
  <div v-if="request.facility && false" class="mb-1">
    <font-awesome-icon icon="building" fixed-width class="mr-1" />
    <!--              Тип объекта -->
    {{ getFacilityTitle(request.facility) }}
  </div>
  <div v-if="request.town && false" class="mb-1">
    <font-awesome-icon icon="map-marker-alt" fixed-width class="mr-1" />
    <!--              Город -->
    {{ getRequestTownTitle(request) }}
  </div>
  <div class="mb-1 d-flex">
    <div>
      <!--              Время -->
      <font-awesome-icon icon="clock" fixed-width class="mr-1" />
      {{ getRequestDatetime(request) }}
    </div>
    <div class="ml-auto">
      <font-awesome-icon icon="stopwatch" fixed-width class="" />
      <a href="#" class="" @click.prevent="openTimePopup">
        {{ getRequestDuration(request) }}
      </a>
    </div>
  </div>

  <div v-if="request.warning && false" class="mb-1 text-warning">
    <font-awesome-icon icon="exclamation-triangle" fixed-width class="mr-1" />
    {{ request.warning }}
    <!--              Особое внимание -->
  </div>

  <div v-if="false" class="mb-1">
    <font-awesome-icon icon="comment" fixed-width class="mr-1" />
    {{ request.comment }}
    <!--              Время -->
  </div>
</div>
