
<div v-if="!loadingRequests && sequence.length > 1" class="mb-3">
  <button
    v-for="(request, srIndex) in sequence"
    :key="'sr' + srIndex"
    class="btn mr-2"
    :class="getRequestTabClass(request.id)"
    @click.prevent="showRequestPopup(request)"
  >
    <span class="label mr-2" :class="getLabelTabClass(request.id)">
      {{ request.indexNumber }}</span
    >
    {{ getRequestNumber(request) }}
  </button>
  <button v-if="addingRequest" class="btn btn-primary mr-2" @click.prevent>
    Новая заявка
  </button>
</div>
