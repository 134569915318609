<template>
  <a
    v-b-tooltip.hover
    class=""
    :class="{
      'text-success': canAssigneeViewAppartment,
      'text-danger': !canAssigneeViewAppartment,
    }"
    :title="iconTitle"
    @click.prevent="toggleToAssignee(!canAssigneeViewAppartment)"
  >
    <font-awesome-icon v-if="opening" icon="rotate" spin />
    <font-awesome-icon v-else-if="canAssigneeViewAppartment" icon="eye" />
    <font-awesome-icon v-else icon="eye-slash" />
  </a>
</template>

<script>
import {
  canSeeRequestAppartment,
  canViewPhone,
} from "@/modules/users/access/common";

export default {
  name: "RequestAppartmentVisibility",
  components: {},
  props: {
    request: {
      type: Object,
    },
    assigneeCanSeeAddress: {
      type: Boolean,
    },
  },
  data() {
    return {
      opening: false,
    };
  },
  computed: {
    iconTitle() {
      if (this.canAssigneeViewAppartment) {
        return "Квартира отображается исполнителю";
      } else {
        return "Квартира скрыта от исполнителя";
      }
    },
    canAssigneeViewAppartment() {
      return this.request.assigneeCanSeeAddress;
    },
    canSeeAppartment() {
      return canSeeRequestAppartment(this.$store.state.user);
    },
    canIViewAppartment() {
      return this.canViewPhone || this.request.assigneeCanSeeAddress;
    },
    canOpenToAssignee() {
      return this.canViewPhone;
    },
    canViewPhone() {
      return canViewPhone(this.$store.state.user);
    },
  },
  watch: {},
  created: function () {},
  methods: {
    toggleToAssignee(status) {
      if (!this.canOpenToAssignee) {
        return;
      }
      if (status === undefined) {
        status = true;
      }
      if (this.opening) {
        return;
      }
      let callUrl = "/pest/request/addr/" + this.request.id;
      this.opening = true;
      this.$http
        .post(callUrl, {
          status: status,
        })
        .then((response) => {
          this.$emit("toggle-visibility", status);
          this.request.assigneeCanSeeAddress = status;
          if (status) {
            this.$bvToast.toast("Адрес открыт исполнителю", {
              title: "Доступ открыт",
              variant: "success",
            });
          } else {
            this.$bvToast.toast("Адрес закрыт исполнителю", {
              title: "Доступ закрыт",
              variant: "success",
            });
          }

          this.opening = false;
        })
        .catch((error) => {
          this.emit;
          this.$bvToast.toast("Не удалось открыть адрес", {
            title: "Ошибка",
            variant: "danger",
          });
          this.opening = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
