const state = {
  data: {},
};

// getters
const getters = {
  getProp: (state) => (key) => {
    return state.data[key];
  },
};

// actions
const actions = {
  setProp: ({ commit }, payload) => {
    commit("setData", payload);
  },
};

// mutations
const mutations = {
  setData: (state, { key, value }) => {
    state.data[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
