<template>
  <b-navbar
    :fixed="getFixed"
    toggleable="lg"
    type="dark"
    variant="dark"
    class="top-menu mb-3 py-0"
  >
    <b-navbar-brand v-if="!isWorker" href="#">CRM</b-navbar-brand>
    <div class="mobile-page-title d-sm-block d-md-none">{{ curPageTitle }}</div>
    <b-navbar-nav
      v-if="logged && canSeeOwnNotifications"
      class="ml-auto d-md-none"
    >
      <b-nav-item
        v-if="canSeeOwnNotifications"
        :active="
          isMenuItemActive('notifications-notification-user-list') ||
          unreadNotificationCount > 0
        "
        :to="{ name: 'notifications-notification-user-list' }"
        @click="setMenuItem('notifications-notification-user-list')"
      >
        <font-awesome-icon icon="bell" />
        <span
          v-if="unreadNotificationCount > 0"
          class="label label-primary ml-2"
          >{{ unreadNotificationCount }}</span
        >
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <hr class="d-lg-none w-100 my-0" />

    <b-collapse v-if="logged" id="nav-collapse" is-nav class="metismenu">
      <b-navbar-nav>
        <!--        <b-nav-item @click="setMenuItem('dashboard')"
                            :active="isMenuItemActive('dashboard')"
                            :to="{ name: 'crm-client-list' }">Главная</b-nav-item>-->
        <template v-if="canSeeKanban">
          <b-nav-item
            v-if="canSeeKanban"
            :active="isMenuItemActive('pest-request-board-global')"
            :to="{
              name: 'pest-request-board-global',
              params: { global: true },
            }"
            @click="setMenuItem('pest-request-board-global')"
            >КАНБАН
          </b-nav-item>
        </template>
        <template v-if="!canSeeAllKanbans && canSeeKanban && false">
          <b-nav-item
            v-if="canSeeKanban"
            :active="isMenuItemActive('pest-request-board')"
            :to="{
              name: 'pest-request-board',
              params: { project: getUserProject },
            }"
            @click="setMenuItem('pest-request-board')"
            >КАНБАН
          </b-nav-item>
        </template>
        <template v-if="canSeeAllKanbans && canSeeKanban && false">
          <b-nav-item-dropdown>
            <template #button-content>КАНБАН</template>
            <template v-for="(project, pIndex) in projects">
              <b-dropdown-item
                :key="pIndex"
                :to="{
                  name: 'pest-request-board',
                  params: { project: project.id },
                }"
                @click="setMenuItem('pest-request-board')"
              >
                {{ project.title }}
              </b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
        </template>

        <b-nav-item-dropdown
          v-if="
            canSeeRequestDispatchingBoard ||
            canSeeRequestMap ||
            canSeeSchedule ||
            canSeeSchedule
          "
          right
          :active="
            isSubmenuItemActive([
              'request-dispatching-board',
              'pest-request-map',
              'pest-time-busyness',
              'pest-assignee-day-schedule',
            ])
          "
        >
          <template #button-content> ЛОГИСТИКА</template>
          <b-dropdown-item
            v-if="canSeeRequestDispatchingBoard"
            :to="{ name: 'request-dispatching-board' }"
            @click="setMenuItem('request-dispatching-board')"
            >Распределение
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeRequestMap && false"
            :to="{ name: 'pest-request-map' }"
            @click="setMenuItem('pest-request-map')"
            >Карта
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeSchedule"
            :to="{ name: 'pest-time-busyness' }"
            @click="setMenuItem('pest-time-busyness')"
            >Нагрузка
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeSchedule"
            :to="{ name: 'pest-assignee-day-schedule' }"
            @click="setMenuItem('pest-assignee-day-schedule')"
            >Расписание на день
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown v-if="canSeeSchedule" right>
          <!-- Using 'button-content' slot -->
          <template #button-content> ГРАФИК РАБОТ</template>
          <b-dropdown-item :to="{ name: 'pest-assignee-schedule' }"
            >График мастеров
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="canSeeRequestList && isWorker"
          :active="isMenuItemActive('pest-employee-current-request-list')"
          :to="{ name: 'pest-employee-current-request-list' }"
          @click="setMenuItem('pest-employee-current-request-list')"
          >Заявки в работе
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="!isWorker && (canSeeFines || canSeePayments)"
          right
        >
          <template #button-content> ФИНАНСЫ</template>
          <b-dropdown-item v-if="canSeeFines" :to="{ name: 'pest-fine-list' }"
            >Штрафы
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeePayments"
            :to="{ name: 'pest-settlements' }"
            >Взаиморасчеты
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeFines"
            :to="{ name: 'pest-penalty-list' }"
            >Перечень штрафов
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeDeposits"
            :to="{ name: 'pest-deposit-list' }"
            >Инкассации
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          v-if="canSeePayments || canSeeSettings || canSeeManagerReport"
          right
        >
          <template #button-content> АНАЛИТИКА</template>
          <b-dropdown-item v-if="canSeeSettings" :to="{ name: 'pest-stats' }"
            >Статистика
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeClosedReports"
            :to="{ name: 'pest-report-cpo' }"
            >Отчет CPO
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeClosedReports"
            :to="{ name: 'direct-ad-report' }"
            >Отчет по рекламе
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeManagerReport"
            :to="{ name: 'pest-report-managers' }"
            >Отчет по менеджерам
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="showHeaderUserMenu"
          :active="isMenuItemActive('users-user-list')"
          :to="{ name: 'users-user-list' }"
          @click="setMenuItem('users-user-list')"
          >ПОЛЬЗОВАТЕЛИ
        </b-nav-item>

        <b-nav-item
          v-if="canSeeRequestList && isWorker"
          :active="isMenuItemActive('pest-own-schedule')"
          :to="{ name: 'pest-own-schedule' }"
          @click="setMenuItem('pest-own-schedule')"
          >Мое расписание
        </b-nav-item>
        <b-nav-item
          v-if="canSeeOwnStats"
          :active="isMenuItemActive('pest-employee-archive-request-list')"
          :to="{ name: 'pest-employee-archive-request-list' }"
          @click="setMenuItem('pest-employee-archive-request-list')"
          >Закрытые заявки
        </b-nav-item>
        <b-nav-item
          v-if="canSeeOwnStats"
          :active="isMenuItemActive('pest-own-stats')"
          :to="{ name: 'pest-own-stats' }"
          @click="setMenuItem('pest-own-stats')"
          >Статистика
        </b-nav-item>

        <b-nav-item
          v-if="canSeeFines && isWorker"
          :active="isMenuItemActive('pest-fine-list')"
          :to="{ name: 'pest-fine-list' }"
          @click="setMenuItem('pest-fine-list')"
          >Мои штрафы
        </b-nav-item>

        <b-nav-item
          v-if="canSeeFines && isWorker"
          :active="isMenuItemActive('pest-penalties-list')"
          :to="{ name: 'pest-penalties-list' }"
          @click="setMenuItem('pest-penalties-list')"
          >Перечень штрафов
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="isWorker"
          right
          :class="{ active: isMenuItemActive('pest-deposit-employee') }"
          :active="isMenuItemActive('pest-deposit-employee')"
        >
          <template #button-content> Инкассация</template>
          <b-dropdown-item
            :active="isMenuItemActive('pest-deposit-employee-create')"
            :to="{ name: 'pest-deposit-employee-create' }"
            @click="setMenuItem('pest-deposit-employee')"
            >Сдать кассу
          </b-dropdown-item>
          <b-dropdown-item
            :active="isMenuItemActive('pest-deposit-employee-list')"
            :to="{ name: 'pest-deposit-employee-list' }"
            @click="setMenuItem('pest-deposit-employee')"
            >История
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="isWorker"
          :active="isMenuItemActive('pest-employee-pledge')"
          :to="{ name: 'pest-employee-pledge' }"
          @click="setMenuItem('pest-employee-pledge')"
          >Мой депозит
        </b-nav-item>

        <b-nav-item
          v-if="canSeeOwnNotifications"
          :active="isMenuItemActive('notifications-notification-user-list')"
          :to="{ name: 'notifications-notification-user-list' }"
          @click="setMenuItem('notifications-notification-user-list')"
          >Уведомления
          <span
            v-if="unreadNotificationCount"
            class="label label-primary ml-2"
            >{{ unreadNotificationCount }}</span
          >
        </b-nav-item>

        <b-nav-item-dropdown v-if="!isWorker && canSeeAllNotifications" right>
          <template #button-content> СООБЩЕНИЯ</template>
          <b-dropdown-item
            v-if="canSeeFines"
            :to="{ name: 'notifications-notification-all-list' }"
            >Уведомления
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeePayments"
            :to="{ name: 'notifications-message-list' }"
            >Сообщения
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="canSeeSettings"
          :active="isMenuItemActive('settings')"
          :to="{ name: 'settings' }"
          @click="setMenuItem('settings')"
          >НАСТРОЙКИ
        </b-nav-item>

        <b-nav-item-dropdown v-if="canSeeServiceSections" right>
          <template #button-content> СЛУЖЕБНОЕ</template>
          <b-dropdown-item :to="{ name: 'service-dashboard' }"
            >Заявки
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <!--                <b-nav-item :to="{ name: 'request-list' }" v-if="canViewRequests">Заявки</b-nav-item>-->
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-lg-auto align-items-lg-center">
        <b-nav-item-dropdown right class="left-border">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            {{ username }}
          </template>

          <b-dropdown-item @click="logout">Выйти</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-collapse v-else id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/login">Вход</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getUserName } from "@/modules/crm/common";
import {
  canSeeAllNotifications,
  canSeeClientList,
  canSeeDeposits,
  canSeeFines,
  canSeeKanban,
  canSeeAllKanbans,
  canSeeManagerReport,
  canSeeOwnNotifications,
  canSeeOwnSchedule,
  canSeeOwnStats,
  canSeePayments,
  canSeeReports,
  canSeeRequestList,
  canSeeRequestMap,
  canSeeSchedule,
  canSeeSettings,
  isGuest,
  isWorker,
  showHeaderUserMenu,
} from "@/modules/users/access/common.js";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      activeMenuItem: "dashboard",
      unreadNotificationCount: 0,
      projects: [],
    };
  },
  created() {
    this.$eventHub.$on("select-header-menu-item", this.onSelectMenuItem);
    if (this.logged && this.canSeeOwnNotifications) {
      this.fetchUnreadCount();
      setTimeout(this.fetchUnreadCount, 60000);
    }
    this.fetchProjects();
    this.$eventHub.$on("update-unread-count", this.fetchUnreadCount);
  },
  computed: {
    ...mapGetters({
      canSeeClosedReports: "auth/canSeeClosedReports",
      canSeeServiceSections: "auth/canSeeServiceSections",
      canSeeRequestDispatchingBoard: "auth/canSeeRequestDispatchingBoard",
    }),
    getFixed() {
      return this.isWorker ? "top" : "";
    },
    getUserProject() {
      return this.$store.state.user.profile.projectId;
    },
    logged() {
      return !isGuest(this.$store.state.user);
    },
    curPageTitle() {
      if (isGuest(this.$store.state.user)) {
        return "";
      }
      return this.$store.state.user.pageTitle;
    },
    isWorker() {
      return isWorker(this.$store.state.user);
    },
    username() {
      if (this.$store.state.user.profile) {
        return getUserName(this.$store.state.user.profile);
      } else {
        return this.$store.state.user.name;
      }
    },
    canSeeSettings() {
      return canSeeSettings(this.$store.state.user);
    },
    canSeeManagerReport() {
      return canSeeManagerReport(this.$store.state.user);
    },
    canSeeKanban() {
      return canSeeKanban(this.$store.state.user);
    },
    canSeeAllKanbans() {
      return canSeeAllKanbans(this.$store.state.user);
    },
    canSeeClientList() {
      return canSeeClientList(this.$store.state.user);
    },
    canSeeRequestList() {
      return canSeeRequestList(this.$store.state.user);
    },
    canSeeRequestMap() {
      return canSeeRequestMap(this.$store.state.user);
    },
    canSeeSchedule() {
      return canSeeSchedule(this.$store.state.user);
    },
    canSeeOwnSchedule() {
      return canSeeOwnSchedule(this.$store.state.user);
    },
    canSeeOwnStats() {
      return canSeeOwnStats(this.$store.state.user);
    },
    canSeeFines() {
      return canSeeFines(this.$store.state.user);
    },
    canSeeDeposits() {
      return canSeeDeposits(this.$store.state.user);
    },
    canSeeReports() {
      return canSeeReports(this.$store.state.user);
    },
    canSeeOwnNotifications() {
      return canSeeOwnNotifications(this.$store.state.user);
    },
    canSeePayments() {
      return canSeePayments(this.$store.state.user);
    },
    showHeaderUserMenu() {
      return showHeaderUserMenu(this.$store.state.user);
    },
    canSeeAllNotifications() {
      return canSeeAllNotifications(this.$store.state.user);
    },
  },

  mounted: function () {},

  methods: {
    ...mapActions({
      setToken: "auth/setToken",
      userLogout: "user/logout",
    }),

    logout: function () {
      this.setToken(null);
      this.userLogout();
      this.$router.push({ name: "users-login" });
    },
    setMenuItem(item) {
      return (this.activeMenuItem = item);
    },
    isMenuItemActive(item) {
      return this.activeMenuItem === item;
    },
    isSubmenuItemActive(items) {
      return items.includes(this.activeMenuItem);
    },
    onSelectMenuItem(item) {
      this.setMenuItem(item);
    },
    fetchProjects() {
      let projectsUrl = "/pest/projects?enabled=1";
      this.$http
        .get(projectsUrl)
        .then((response) => {
          response.data.forEach((element) => {
            this.projects.push({ id: element.id, title: element.title });
          });
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchUnreadCount: function () {
      let itemsUrl = "/notifications/notification/unread-count";
      this.$http
        .get(itemsUrl)
        .then((response) => {
          this.unreadNotificationCount = response.data.unreadCount;
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-weight: 500 !important;
  font-size: 14px !important;
}

li.nav-item > .nav-link.active {
  background: #1ab394;
  /*color: black;*/
}
</style>
<style>
a.dropdown-toggle {
  padding-top: 10px !important;
}

.top-navigation .navbar-brand {
  padding: 15px 15px;
}

.mobile-page-title {
  /*padding: 15px 25px;*/
  font-size: 18px;
  line-height: 20px;
  color: white;
}

li.nav-item.b-nav-dropdown > a.nav-link {
  font-weight: 500 !important;
  font-size: 14px !important;
}

li.nav-item.b-nav-dropdown.active > a.nav-link {
  background: #1ab394;
  color: #fff;
}

a.dropdown-toggle {
  padding-top: 8px !important;
}
</style>
