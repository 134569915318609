<template>
  <b-form :model="comment" id="comment-form" ref="comment" class="comment-form " @submit="createOrUpdate"
          novalidate>

    <div class="d-flex">
      <b-form-textarea v-model="comment.message" row="3" tabindex="2">
      </b-form-textarea>
      <div class="ml-2">
        <b-button variant="success" @click="create">
          <font-awesome-icon icon="check"/>
          Написать
        </b-button>
      </div>
    </div>

  </b-form>
</template>


<script>


export default {
  name: "CommentForm",
  components: {},
  props: [
    'model',
    'type',
  ],
  data() {
    return {
      comment: {
        message: '',
        userId: '',
      },
      loading: false
    }
  },
  computed: {},
  watch: {},
  created: function () {
  },
  methods: {

    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById('comment-form');
      let valid = form.checkValidity();
      form.classList.add('was-validated');
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast('Данные сохраняются', {
            title: 'Подождите',
            variant: 'info'
          });
          return;
        }
        this.create()
        form.classList.remove('was-validated');
      } else {
        this.$bvToast.toast('В форме содержатся ошибки', {
          variant: 'danger'
        });
        return false
      }
    },
    create: function () {
      let updateUrl;
      updateUrl = '/crm/comments';
      this.comment.type = this.type;
      this.comment.itemId = this.model.id;
      this.loading = true;
      this.$http.post(updateUrl, this.comment).then(response => {
        this.comment = response.data;
        this.$emit('comment-added', this.comment);
        this.comment = {
          message: '',
          userId: '',
        };
        this.$bvToast.toast('Комментарий добавлен', {
          title: 'Комментарий добавлен',
          variant: 'success'
        });
      }).catch(error => {
        this.loading = false;
        if (!this.processRequestError(error)) {
          this.$bvToast.toast('Ошибка', {
            title: 'Не удалось добавить комментарий',
            variant: 'danger'
          })
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
