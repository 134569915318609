<template>
  <b-modal
    id="modal-task"
    ref="modal"
    title="Добавить задачу"
    cancel-title="Отмена"
    ok-title="Сохранить"
    title-tag="h3"
    @ok="handleTaskModalOk"
  >
    <task-form ref="taskForm" :task="task" :own-window="false"></task-form>
  </b-modal>
</template>

<script>
import TaskForm from "@/modules/crm/views/tasks/TaskForm";
import { TYPE_TASK } from "@/modules/crm/decorators/task";
import dayjs from "dayjs";

export default {
  name: "TaskPopup",
  components: {
    "task-form": TaskForm,
  },
  data() {
    return {
      data: {},
      successHandler: false,
      cancelHandler: false,
      task: {},
      blankTask: {
        id: "new",
        type: TYPE_TASK,
        typeId: "",
        deadline: dayjs().format("DD.MM.YYYY"),
        deadlineT: "",
        assigneeId: "", //this.$store.state.user.id,
        assignee: "", //this.$store.state.user.profile,
        comment: "",
        result: "",
        completed: false,
        links: {
          clientId: false,
          client: false,
        },
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$eventHub.$on("task-popup-show", this.openPopup);
  },
  beforeDestroy() {
    this.$eventHub.$off("task-popup-show");
  },
  methods: {
    handleSuccess() {
      this.$bvModal.hide("modal-task");
      if (this.successHandler) {
        this.successHandler();
      }
      this.successHandler = false;
      this.data = {};
    },
    handleTaskModalOk(bvModalEvt) {
      this.$refs.taskForm.createOrUpdate(bvModalEvt, this.handleSuccess);
    },
    openPopup(info) {
      this.task = Object.assign(this.blankTask, info.data);
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-task");
    },
  },
};
</script>

<style lang="scss" scoped></style>
