// store.js
import Vue from "vue";
import Vuex from "vuex";
import {
  SOCKET_ONCLOSE,
  SOCKET_ONERROR,
  SOCKET_ONMESSAGE,
  SOCKET_ONOPEN,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ERROR,
} from "./types";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
    },
  },
  mutations: {
    [SOCKET_ONOPEN](state, event) {
      state.socket.isConnected = true;
      console.log("socket connected");
    },
    [SOCKET_ONCLOSE](state, event) {
      state.socket.isConnected = false;
      console.log("socket disconnected");
    },
    [SOCKET_ONERROR](state, event) {
      console.log("socket error");
      console.error(state, event);
    },
    // default handler called for all methods
    [SOCKET_ONMESSAGE](state, message) {
      state.socket.message = message;
      console.log(message);
    },
    // mutations for reconnect methods
    [SOCKET_RECONNECT](state, count) {
      console.info(state, count);
    },
    [SOCKET_RECONNECT_ERROR](state) {
      state.socket.reconnectError = true;
    },
  },
  actions: {
    changeLeadStatus(state, message) {
      this._vm.$eventHub.$emit("s-request-change-lead-status", message);
    },
    createRequest(state, message) {
      this._vm.$eventHub.$emit("s-request-create", message);
    },
    updateRequest(state, message) {
      this._vm.$eventHub.$emit("s-request-update", message);
    },
    incomingCall(state, message) {
      this._vm.$eventHub.$emit("s-incoming-call", message);
    },
    incomingCallEnd(state, message) {
      this._vm.$eventHub.$emit("s-incoming-call-end", message);
    },
    scheduledCallNotifications(state, message) {
      this._vm.$eventHub.$emit("s-scheduled-call-notifications", message);
    },
    test(state, message) {
      console.log(message);
    },
  },
};
