
<div class="row">
  <div class="col-md-6 col-lg-6 col-sm-12">
    <div class="ibox">
      <div class="">
        <div class="ibox-title">
          <h5 class="h5">Материалы в наличии у мастера</h5>
        </div>
        <div class="ibox-content">
          <button
            v-if="false"
            class="btn btn-info"
            @click.prevent="fetchData"
          >
            Reload
          </button>
          <b-overlay :show="loading" rounded="sm">
            <table class="table table-condensed table-striped table-bordered">
              <thead>
                <tr>
                  <th>Материал</th>
                  <th>Объем / Количество, мл/г</th>
                </tr>
              </thead>
              <tbody v-if="items.length">
                <tr
                  v-for="(item, index) in items"
                  :key="'p' + index"
                  class=""
                >
                  <td>{{ item.resource.title }}</td>
                  <td>
                    {{ item.amount ? item.amount : 0 }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-if="loading">
                  <td colspan="7">Загружается</td>
                </tr>
                <tr v-else>
                  <td colspan="7">Нет материалов</td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-lg-6 col-sm-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5 class="h5">Изменение материалов</h5>
      </div>

      <div class="ibox-content">
        <b-overlay :show="updating" rounded="sm">
          <b-form @submit.prevent="submit">
            <div class="d-flex align-items-center">
              <b-form-group label="Материал " class="mr-2">
                <universal-autocomplete
                  ref="resourceAutocomplete"
                  :get-title="(p) => p.title"
                  :get-data="getResources"
                  :default-value="
                    resource && resource.title ? resource.title : ''
                  "
                  @selected="selectResource"
                />
              </b-form-group>

              <b-form-group label="Объем / Количество, мл/г" class="mr-2">
                <b-form-input
                  v-model="amount"
                  placeholder="10, 100, -100, -15.5"
                  tabindex="1"
                ></b-form-input>
              </b-form-group>

              <div>
                <button
                  class="btn btn-info mt-2"
                  :disabled="!resourceId || !amount"
                  @click.prevent="submit"
                >
                  Изменить
                </button>
              </div>
            </div>
          </b-form>
        </b-overlay>
      </div>
    </div>
  </div>
</div>
