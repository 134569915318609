<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Данные отчета</h1>
      <div class="mb-2"></div>

      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mr-2">
            <b-form-group label="Дата" prop="date">
              <date-picker v-model="search.date"></date-picker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Аккаунт" prop="accountId">
              <multiply-autocomplete
                ref="accountAutocomplete"
                :get-data="fetchAccountData"
                :get-title="getAccountTitle"
                @selected="selectMultipleAccount"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Кампания" prop="campaignId">
              <multiply-autocomplete
                ref="campaignAutocomplete"
                :get-data="fetchCampaignData"
                :get-title="getCampaignTitle"
                @selected="selectMultipleCampaign"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Отчет" prop="reportId">
              <multiply-autocomplete
                ref="reportAutocomplete"
                :get-data="fetchReportData"
                :get-title="getReportTitle"
                @selected="selectMultipleReport"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Вредитель" prop="pestId">
              <multiply-autocomplete
                ref="pestAutocomplete"
                :get-data="fetchPestData"
                :get-title="getPestTitle"
                @selected="selectMultiplePest"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Регион" prop="pestId">
              <multiply-autocomplete
                ref="townAutocomplete"
                :get-data="fetchRegionData"
                :get-title="getRegionTitle"
                @selected="selectMultipleRegion"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            Найдено: {{ pagination.total }}
          </div>
        </div>
      </div>

      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Аккаунт"
                param="accountId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Отчет"
                param="reportId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Кампания"
                param="campaignId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Дата"
                param="dateD"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Регион"
                param="regionId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Вредитель"
                param="pestId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Количество"
                param="count"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Сумма"
                param="sumD"
                @change="fetchData"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr v-for="(record, rIndex) in records" :key="'r' + rIndex">
            <td>
              <!-- Аккаунт -->
              {{ getRecordAccountName(record) }}
            </td>
            <td>
              <!-- Отчет -->
              {{ getRecordReportTitle(record) }}
            </td>
            <td>
              <!-- Кампания -->
              {{ getRecordCampaignName(record) }}
            </td>
            <td>
              <!-- Дата -->
              {{ getRecordDate(record) }}
            </td>
            <td>
              <!-- Регион -->
              {{ getRecordRegionName(record) }}
            </td>
            <td>
              <!-- Вредитель -->
              {{ getRecordPestName(record) }}
            </td>
            <td>
              <!-- Количество -->
              {{ record.count }}
            </td>
            <td>
              <!-- Сумма -->
              {{ getRecordFormattedSum(record) }}
            </td>
          </tr>
          <tr>
            <td colspan="6"></td>
            <td>
              <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
              <strong v-else>{{ formatNumber(stats.total.count) }}</strong>
            </td>
            <td>
              <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
              <strong v-else>{{ formatNumber(stats.total.sum) }}</strong>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">Загружается</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  getRecordAccountName,
  getRecordCampaignName,
  getRecordDate,
  getRecordFormattedSum,
  getRecordPestName,
  getRecordRegionName,
  getRecordReportTitle,
  getRecordStatusTitle,
  statusOptions,
} from "@/modules/direct/decorators/records";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete";
import { getAccountTitle } from "@/modules/direct/decorators/accounts";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { MSK, SPB } from "@/modules/pest/decorators/towns";
import DatePicker from "@/components/shared/DatePicker";
import { getReportTitle } from "@/modules/direct/decorators/reports";
import SortColumnTitle from "@/components/shared/SortColumnTitle";
import { getCampaignTitle } from "@/modules/direct/decorators/campaigns";
import { formatNumber } from "@/modules/pest/decorators/common";

export default {
  name: "DirectRecordList",
  components: { SortColumnTitle, MultiplyAutocomplete, DatePicker },
  data() {
    return {
      records: [{}],
      search: {
        title: "",
        status: [],
        date: null,
        accountId: null,
        pestId: null,
        regionId: null,
        reportId: null,
        campaignId: null,
        sort: null,
      },
      loading: true,
      loadingStats: true,
      stats: [],

      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Отчета",
      fields: [
        {
          key: "account",
          label: "Аккаунт",
          sortable: false,
        },
        {
          key: "reportId",
          label: "Отчет",
          sortable: false,
        },
        {
          key: "campaignId",
          label: "Кампания",
          sortable: false,
        },
        {
          key: "date",
          label: "Дата",
          sortable: false,
        },
        {
          key: "regionId",
          label: "Регион",
          sortable: false,
        },
        {
          key: "pestId",
          label: "Вредитель",
          sortable: false,
        },

        {
          key: "count",
          label: "Количество",
          sortable: false,
        },
        {
          key: "sum",
          label: "Сумма",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getUserName,
    getRecordAccountName,
    getCampaignTitle,
    getRecordCampaignName,
    getRecordReportTitle,
    getReportTitle,
    getAccountTitle,
    getRecordStatusTitle,
    getPestTitle,
    getRecordDate,
    formatNumber,
    getRecordFormattedSum,
    getRecordPestName,
    getRecordRegionName,
    fetchData: function () {
      this.loading = true;
      let recordsUrl = "/direct/records";
      let params = {
        page: this.page,
        title: this.search.name,
        status: this.search.status,
        date: this.search.date,
        accountId: this.search.accountId,
        campaignId: this.search.campaignId,
        reportId: this.search.reportId,
        pestId: this.search.pestId,
        regionId: this.search.regionId,
        sort: this.search.sort,
        pageSize: 200,
      };
      this.$http
        .get(recordsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.records = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
          this.fetchStats();
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchStats: function () {
      this.loadingStats = true;

      let recordsUrl = "/direct/record/stats";
      let params = {
        page: this.page,
        title: this.search.name,
        status: this.search.status,
        date: this.search.date,
        accountId: this.search.accountId,
        campaignId: this.search.campaignId,
        reportId: this.search.reportId,
        pestId: this.search.pestId,
        regionId: this.search.regionId,
        sort: this.search.sort,
        pageSize: 200,
      };
      this.$http
        .get(recordsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;
          this.stats = response.data;
          console.log(this.stats);
        })
        .catch((error) => {
          this.loadingStats = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "direct-record-list",
        params: { page: pageNum },
      };
    },

    fetchAccountData(search, resolve) {
      let accountUrl = "/direct/accounts";
      let params = {
        search: search,
      };
      this.$http
        .get(accountUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultipleAccount(info) {
      this.search.accountId = [];
      if (info) {
        for (let account of info) {
          this.search.accountId.push(account.id);
        }
      }
      this.fetchData();
    },
    fetchCampaignData(search, resolve) {
      let campaignUrl = "/direct/campaigns";
      let params = {
        accountId: this.search.accountId,
        title: search,
      };
      this.$http
        .get(campaignUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultipleCampaign(info) {
      this.search.campaignId = [];
      if (info) {
        for (let campaign of info) {
          this.search.campaignId.push(campaign.id);
        }
      }
      this.fetchData();
    },

    fetchReportData(search, resolve) {
      let reportUrl = "/direct/reports";
      let params = {
        search: search,
        accountId: this.search.accountId,
      };
      this.$http
        .get(reportUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultipleReport(info) {
      this.search.reportId = [];
      if (info) {
        for (let report of info) {
          this.search.reportId.push(report.id);
        }
      }
      this.fetchData();
    },

    fetchPestData(search, resolve) {
      let pestUrl = "/pest/pests";
      let params = {
        search: search,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = [{ id: -1, title: "Не назначен" }, ...response.data];
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultiplePest(info) {
      this.search.pestId = [];
      if (info) {
        for (let pest of info) {
          this.search.pestId.push(pest.id);
        }
      }
      this.fetchData();
    },

    fetchRegionData(search, resolve) {
      resolve([
        {
          id: -1,
          text: "Не назначено",
        },
        {
          id: MSK,
          text: "Москва",
        },
        {
          id: SPB,
          text: "Санкт-Петербург",
        },
      ]);
    },
    getRegionTitle(region) {
      return region.text;
    },
    selectMultipleRegion(info) {
      this.search.regionId = [];
      if (info) {
        for (let region of info) {
          this.search.regionId.push(region.id);
        }
      }
      this.fetchData();
    },

    fetchStatusData(search, resolve) {
      resolve(statusOptions);
    },
    getStatusTitle(status) {
      return status.text;
    },
    selectMultipleStatus(info) {
      this.search.status = [];
      if (info) {
        for (let status of info) {
          this.search.status.push(status.id);
        }
      }
      this.fetchData();
    },
  }, //methods
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
