
<div v-if="canViewData" class="client-page">
  <div class="row">
    <div class="col-12">
      <div class="ibox mb-2">
        <div class="ibox-title pb-1">
          <h1>
            <router-link
              :to="{ name: 'crm-client-list' }"
              class="ml-1 mr-1 h4"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>

            {{ clientName }}

            <small>
              <a
                href=""
                class="h6 ml-2"
                @click.prevent="toggleUpdate(!updating)"
              >
                <font-awesome-icon v-if="updating" icon="eye" />
                <font-awesome-icon v-else icon="edit" />
              </a>
              <a href="" class="h6 ml-2" @click.prevent="deleteClient">
                <font-awesome-icon icon="trash" />
              </a>
            </small>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!updating" class="row">
    <div class="col-md-4">
      <b-tabs v-model="infoTabIndex" card @activate-tab="onInfoTabSelect">
        <b-tab :title="clientTabTitle" active no-body>
          <client-info :client="client"></client-info>
        </b-tab>
        <b-tab
          v-if="client.type === TYPE_ORGANIZATION"
          title="Контакты"
          no-body
        >
          <organization-contacts
            ref="contacts"
            :client="client"
          ></organization-contacts>
        </b-tab>
      </b-tabs>
    </div>

    <div v-if="!loading" class="col-md-8">
      <b-tabs v-model="dataTabIndex" card @activate-tab="onDataTabSelect">
        <b-tab title="Дела" active no-body>
          <div class="ibox">
            <div class="ibox-content">
              <client-issues ref="issues" :client="client"></client-issues>
            </div>
          </div>
        </b-tab>
        <b-tab title="Задачи" active no-body>
          <div class="ibox">
            <div class="ibox-content">
              <client-tasks ref="tasks" :client="client"></client-tasks>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>

  <div v-else class="row">
    <div class="col-12">
      <div class="ibox mb-1">
        <div class="ibox-content pb-1">
          <client-form
            :client-model="client"
            :data-loaded="!loading"
            :return-url="{
              name: 'crm-client-view',
              params: { id: client.id },
            }"
            @update-client-info="afterClientUpdate"
            @cancel-updating="toggleUpdate(false)"
          ></client-form>
        </div>
      </div>
    </div>
  </div>
</div>
