const state = {
  isGuest: true,
  id: null,
  name: "Гость",
  authKey: "",
  type: "",
  profile: {},
  enabled: true,
  projectIds: [],
  pageTitle: "",
  access: {
    canViewLeads: false,
    canViewIssues: false,
    canViewClients: false,
    canViewTasks: false,
    canViewDocs: false,
    canViewSettings: false,

    canViewContacts: false,
    canUpdateRequests: false,
    canCompleteRequests: false,
    canDispatch: false,
    canViewMap: false,
    canViewSchedule: false,
    canSeeClosedReports: false,
  },
};

// getters
const getters = {
  userId: (state) => {
    return state.id;
  },
  projectIds: (state) => {
    return state.projectIds;
  },
  profile: (state) => {
    return state.profile;
  },
};

// actions
const actions = {
  setUserInfo: ({ commit }, payload) => {
    commit("setUserInfo", payload);
  },
  setPageTitle: ({ commit }, payload) => {
    commit("setPageTitle", payload);
  },
  logout: ({ commit }) => {
    commit("setUserInfo", {
      isGuest: true,
      id: null,
      name: "Гость",
      type: "",
      authKey: "",
      enabled: true,
      profile: {},
      access: {
        canViewLeads: false,
        canViewIssues: false,
        canViewClients: false,
        canViewTasks: false,
        canViewDocs: false,
        canViewSettings: false,
        canViewContacts: false,
        canUpdateRequests: false,
        canCompleteRequests: false,
        canDispatch: false,
        canViewMap: false,
        canViewSchedule: false,
      },
    });
  },
};

// mutations
const mutations = {
  setUserInfo: (state, payload) => {
    state.isGuest = payload.isGuest;
    state.id = payload.id;
    state.type = payload.userType;
    state.enabled = payload.enabled;
    state.name = payload.name;
    state.authKey = payload.authKey;
    state.access = payload.access;
    state.profile = payload.profile;
    state.projectIds = payload.projectIds;
  },
  setPageTitle: (state, payload) => {
    state.pageTitle = payload.pageTitle;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
