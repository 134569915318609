<template>
  <div class="d-flex relative">
    <div v-if="canDeleteContractContacts" class="deleteContact primary-star">
      <a
        v-b-tooltip.hover
        href="#"
        class="primary-star"
        title="Удалить контакт"
        @click.prevent="deleteContact"
      >
        <font-awesome-icon v-if="!deleting" icon="fa-trash" />
        <font-awesome-icon v-else icon="rotate" spin />
      </a>
    </div>
    <div>
      <h4>ФИО</h4>
      <p>{{ clientFullName }}</p>
      <div v-if="client.phones && client.phones.length">
        <div
          v-for="(phone, phoneIndex) in client.phones"
          :key="'p' + phoneIndex"
        >
          <h4>
            Телефон <span v-if="phoneIndex > 0">{{ phoneIndex + 1 }}</span>
          </h4>
          <p>{{ phone.phone ? phone.phone : " – " }}</p>
        </div>
      </div>
      <div v-else>
        <h4>Телефон</h4>
        <p>–</p>
      </div>
      <h4>Адрес</h4>
      <p>{{ client.address ? client.address : " – " }}</p>
      <h4>Организация</h4>
      <p>{{ client.firm ? client.firm : " – " }}</p>
      <h4>Должность</h4>
      <p>{{ client.post ? client.post : " – " }}</p>
      <h4>Ответственный</h4>
      <p>{{ client.responsiblePerson }}</p>
    </div>
    <div class="ml-auto d-flex h-100">
      <div class="add-phone">
        <a
          v-b-tooltip.hover
          href="#"
          class="primary-star"
          title="Добавить телефон"
          @click.prevent="addPhone"
        >
          <font-awesome-icon icon="fa-phone" />
        </a>
      </div>
      <div class="ml-2 primary-star">
        <a
          v-if="!client.primary"
          v-b-tooltip.hover
          href="#"
          title="Добавить контакт"
          @click.prevent="makePrimary"
        >
          <font-awesome-icon icon="fa-regular fa-star" />
        </a>
        <a
          v-else
          v-b-tooltip.hover
          href="#"
          title="Основной контакт"
          @click.prevent
        >
          <font-awesome-icon icon="star" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common.js";
import { canDeleteContractContacts } from "@/modules/users/access/common";

export default {
  name: "ContactView",
  components: {},
  props: ["client", "isLast"],
  data() {
    return {
      makingPrimary: false,
      deleting: false,
    };
  },
  computed: {
    clientFullName() {
      let info = [
        this.client.lastName,
        this.client.firstName,
        this.client.patronymic,
      ];
      let str = info.join(" ").trim();
      return str ? str : " – ";
    },
    canDeleteContractContacts() {
      return canDeleteContractContacts(this.$store.state.user) && !this.isLast;
    },
  },
  watch: {},
  created: function () {
    this.$eventHub.$on("client-primary", this.checkPrimary);
  },
  methods: {
    getUserName,
    makePrimary() {
      this.client.primary = true;
      this.savePrimary();
      this.$eventHub.$emit("client-primary", this.client);
    },
    checkPrimary(client) {
      if (client.id !== this.client.id) {
        this.client.primary = false;
      }
    },
    clientUpdated(client) {
      this.client = client;
      this.updating = false;
      this.$emit("update-client-info", client);
    },
    addPhone() {
      let $this = this;
      this.$eventHub.$emit("modal-contract-phone-popup", {
        clientId: this.client.id,
        success: function ({ phone }) {
          console.log(999);
          $this.client.phones.push({
            phone: phone,
            type: "mobile",
          });
        },
        cancel: function () {},
      });
    },
    savePrimary: function () {
      let updateUrl;
      updateUrl = "/crm/clients/primary/" + this.client.id;
      if (this.makingPrimary) {
        return;
      }
      this.makingPrimary = true;
      this.$http
        .post(updateUrl, {
          id: this.id,
          contractId: this.client.contractId,
        })
        .then((response) => {
          this.makingPrimary = false;
          this.$bvToast.toast("Контакт сделан основным", {
            title: "Данные сохранены",
            type: "success",
          });
        })
        .catch((error) => {
          this.makingPrimary = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Не удалось обновить контакт", {
              title: "Данные не сохранены",
              type: "danger",
            });
          }
        });
    },
    cancelUpdate(client) {
      this.updating = false;
      this.$emit("cancel-updating", client);
    },
    deleteContact() {
      if (confirm("Вы действительно хотите удалить клиента?")) {
        let deleteUrl = "/crm/clients/" + this.client.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.deleting = false;
            this.$bvToast.toast("Клиент удален", {
              title: "Клиент удален",
              variant: "success",
            });
            this.$emit("delete-contact", this.client);
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить клиента",
                variant: "danger",
              });
            }
          });
      }
      console.log(this.client);
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-star a {
  color: #000000;
}

.primary-star:hover a {
  color: #007bff;
}

.add-phone a {
  color: rgb(103, 106, 108);
}

.add-phone:hover a {
  color: #007bff;
}
.deleteContact {
  position: absolute;
  bottom: 0;
  right: 0;
}
.relative {
  position: relative;
}
</style>
