<template>
  <div class="ibox">
    <div class="ibox-title d-flex pr-2">
      <h4>Имитация входящего звонка</h4>
    </div>
    <div class="ibox-content">
      <p>
        У менеджера появится уведомление со звонком, и связанной с ним карточкой
      </p>
      <b-form
        id="test-call"
        ref="request"
        class="test-call"
        novalidate
        @submit.prevent="createOrUpdate('roistat')"
        @reset="onReset"
      >
        <div class="div">
          <b-form-group label="Номер телефона" prop="title">
            <b-form-input
              v-model="number"
              tabindex="1"
              placeholder=""
            ></b-form-input>
          </b-form-group>
          <!--          <b-form-group label="Номер заявки" prop="title">-->
          <!--            <b-form-input v-model="number" tabindex="1" required></b-form-input>-->
          <!--          </b-form-group>-->
          <b-form-group label="Менеджер" prop="title">
            <user-autocomplete
              v-model="userId"
              :default-value="getEmployeeName(user)"
              :type="userTypes"
              @selected="selectManager"
            />
          </b-form-group>
        </div>
      </b-form>
    </div>

    <div class="ibox-footer d-flex">
      <div class=" ">
        <b-button v-if="!saving" variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="number" />
          Инициировать звонок
        </b-button>

        <b-button v-if="!saving" variant="primary" @click="endCall">
          <font-awesome-icon icon="number" />
          Закончить звонок
        </b-button>
      </div>

      <b-button v-if="saving" variant="primary" disabled @click.prevent>
        <font-awesome-icon icon="sync" spin="" />
        Отправляется
      </b-button>
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete";
import {
  TYPE_ADMIN,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_SHADOW,
} from "@/modules/users/decorators/users";

export default {
  name: "TestCall",
  components: { UserAutocomplete },
  data() {
    return {
      number: "",
      saving: false,
      userId: 1,
      user: {
        id: 1,
        name: "Тех. поддержка",
      },
      userTypes: [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST, TYPE_MANAGER],
    };
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.refreshForm();
  },
  methods: {
    refreshForm() {
      // this.number = '';
      // this.userId = null;
    },
    create: function () {
      let updateUrl;
      if (this.saving) {
        this.$bvToast.toast("Данные сохраняются", {
          title: "Подождите",
          variant: "info",
        });
        return;
      }
      this.saving = true;
      updateUrl = "/pest/call/test-call";
      this.$http
        .post(updateUrl, {
          number: this.number,
          userId: this.userId,
        })
        .then((response) => {
          this.saving = false;
          this.$bvToast.toast("Запрос отправлен", {
            title: "Успешно",
            variant: "success",
          });
          // this.refreshForm();
        })
        .catch((error) => {
          this.saving = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Не удалось отправить запрос", {
              title: "Ошибка",
              variant: "danger",
            });
          }
        });
    },
    endCallInternal: function () {
      let updateUrl;
      if (this.saving) {
        this.$bvToast.toast("Данные сохраняются", {
          title: "Подождите",
          variant: "info",
        });
        return;
      }
      this.saving = true;
      updateUrl = "/pest/call/test-call-end";
      this.$http
        .post(updateUrl, {
          number: this.number,
          userId: this.userId,
        })
        .then((response) => {
          this.saving = false;
          this.$bvToast.toast("Запрос отправлен", {
            title: "Успешно",
            variant: "success",
          });
          // this.refreshForm();
        })
        .catch((error) => {
          this.saving = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Не удалось отправить запрос", {
              title: "Ошибка",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      let form = document.getElementById("test-call");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        this.create();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    endCall: function () {
      let form = document.getElementById("test-call");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        this.endCallInternal();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    getEmployeeName(user) {
      return user ? getUserName(user) : "Выберите";
    },
    selectManager(data) {
      if (data && data.value) {
        this.userId = data.value.id;
        this.user = data.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
