
<tr class=" " :class="notificationClass(notification)">
  <td class="">
    {{ getNotificationCreateDatetime(notification) }}
  </td>
  <td>
    {{ getNotificationTypeTitle(notification) }}
  </td>
  <td>
    {{ getNotificationUser(notification) }}
  </td>
  <td class="">
    <notification-full-changed-assignee
      v-if="notification.type === REQUEST_CHANGED_ASSIGNEE"
      :notification="notification"
    />

    <notification-full-fine-approved
      v-else-if="notification.type === FINE_APPROVED"
      :notification="notification"
    />
    <notification-full-new-record
      v-else-if="notification.type === REQUEST_NEW_RECORD"
      :notification="notification"
    />
    <notification-full-message
      v-else-if="notification.type === MESSAGE"
      :notification="notification"
    />
    <div v-else>Что-то непонятное. Позовите программиста.</div>
  </td>
  <td>
    <div v-if="notification.readTime">
      Прочитано в
      {{ getNotificationConfirmDatetime(notification) }}
    </div>
    <div v-else>Не прочитано</div>
  </td>
</tr>
