import AssigneeSchedule from "@/modules/pest/views/schedule/AssigneeSchedule";
import AssigneeDaySchedule from "@/modules/pest/views/schedule/AssigneeDaySchedule";
import UserSchedule from "@/modules/pest/views/schedule/UserSchedule";
import TimeBusyness from "@/modules/pest/views/schedule/TimeBusyness";

export default [
  {
    path: "/settings/schedule",
    name: "pest-assignee-schedule",
    components: {
      default: AssigneeSchedule,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/settings/busyness",
    name: "pest-time-busyness",
    components: {
      default: TimeBusyness,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/my-schedule",
    name: "pest-own-schedule",
    components: {
      default: UserSchedule,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/settings/day-schedule",
    name: "pest-assignee-day-schedule",
    components: {
      default: AssigneeDaySchedule,
    },
    meta: { requiresAuth: true },
  },
];
