<template>
  <div>

    <router-link :to="{ name: 'document-list' }" :class="curTabClass('documents')">Документы</router-link>
    <router-link :to="{ name: 'document-type-list' }" :class="curTabClass('types')+' ml-2'">Типы документов
    </router-link>
  </div>
</template>


<script>


export default {
  name: "Tabs",
  components: {},
  props: [
    'tab'
  ],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {
  },
  methods: {
    curTabClass(alias) {
      return this.tab === alias ? 'btn btn-success' : 'btn btn-outline-primary';
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
