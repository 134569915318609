<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Смс сообщения</h1>
      <div class="mb-2"></div>

      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Время</th>
            <th>Текст</th>
            <th>Статус</th>
            <th>Получатель</th>
            <th>Отправитель</th>
            <th>Шлюз</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(message, mIndex) in messages" :key="mIndex">
            <td>{{ getMessageTime(message) }}</td>
            <td>{{ message.text }}</td>
            <td>{{ getMessageStatusTitle(message) }}</td>
            <td>{{ message.phone }}</td>
            <td>{{ getMessageUserName(message) }}</td>
            <td>Манго</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getMessageStatusTitle,
  getMessageTime,
  getMessageUserName,
} from "@/modules/sms/decorators/messages";

export default {
  name: "MessageList",
  components: {},
  data() {
    return {
      messages: [{}],
      search: {
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Сообщение",
      fields: [
        {
          key: "title",
          label: "Название",
          sortable: false,
        },
        {
          key: "comment",
          label: "Описание",
          sortable: false,
        },
        {
          key: "color",
          label: "Цвет",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getMessageTime,
    getMessageStatusTitle,
    getMessageUserName,
    fetchData: function () {
      this.loading = true;

      let messagesUrl = "/sms/messages";
      let params = {
        page: this.page,
      };
      this.$http
        .get(messagesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.messages = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "sms-message-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
