<template>
  <div class="documentType-page">
    <div class="row">
      <div class="col-md-6 " style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>
              <router-link :to="{ name: 'document-type-list'}" class="h4 text-muted">
                <font-awesome-icon icon="arrow-left"/>
              </router-link>
              Новый тип документа
            </h1>
          </div>
          <div class="ibox-content ">
            <documentType-form :documentType="documentType"
                               :return-url="{ name: 'document-type-list'}"></documentType-form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import DocumentTypeForm from "./DocumentTypeForm";

export default {
  name: 'documentType',
  components: {
    'documentType-form': DocumentTypeForm,
  },
  data() {
    return {
      documentType: {
        id: 'new',
        title: '',
      },
      loading: false,
    }
  },
  computed: {
    id() {
      return 'new';
    },
  },
  watch: {
    '$route'(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
