
<div class="ibox">
  <div class="ibox-content">
    <h1>Материалы в магазине</h1>
    <div class="mb-2 d-flex">
      <router-link
        :to="{ name: 'pest-shop-resource-create' }"
        class="btn btn-primary"
      >
        <font-awesome-icon icon="plus" />
        Добавить материал
      </router-link>

      <shop-resource-import
        class="ml-2"
        @updated="fetchData"
      ></shop-resource-import>
    </div>

    <div class="search">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <b-form-group label="Название, артикул">
            <b-form-input
              v-model="search.title"
              tabindex="3"
              @change="fetchData"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.totalCount }}
        </div>
      </div>
    </div>

    <b-table
      v-if="shopResources.length"
      responsive
      striped
      hover
      condensed
      :items="shopResources"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{
            name: 'pest-shop-resource-update',
            params: { id: data.item.id },
          }"
          class=""
        >
          {{ data.item.title ? data.item.title : "[Без названия]" }}
        </router-link>
      </template>

      <template #cell(resource)="data">
        {{ data.item.resource ? data.item.resource.title : "" }}
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
