<template>
  <div class="board-buttons">
    <div v-if="requestUpdating">
      <button
        :disabled="requestSaving"
        class="btn btn-outline-info mr-2"
        @click.prevent="closeRequestCard"
      >
        <font-awesome-icon icon="arrow-left" />
        Отмена
      </button>

      <b-button
        v-if="!requestSaving"
        :disabled="requestSaving"
        variant="primary"
        @click.prevent="saveRequest"
      >
        <font-awesome-icon icon="check" />
        Сохранить
      </b-button>
      <b-button
        v-else
        :disabled="requestSaving"
        variant="primary"
        @click.prevent
      >
        <font-awesome-icon icon="rotate" spin />
        Сохраняется
      </b-button>
    </div>
    <div v-else-if="isContract && !requestOpened">
      <b-button
        variant="primary"
        :disabled="requestOpening"
        @click.prevent="addNewRequest"
      >
        <font-awesome-icon icon="plus" />
        Добавить заявку
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupRequestButtons",
  components: {},
  props: {
    isContract: {
      type: Boolean,
      default: false,
    },
    requestUpdating: {
      type: Boolean,
      default: false,
    },
    requestOpened: {
      type: Boolean,
      default: false,
    },
    requestOpening: {
      type: Boolean,
      default: false,
    },
    requestSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    closeRequestCard() {
      this.$emit("close-request-card");
    },
    saveRequest() {
      this.$emit("save-request");
    },
    addNewRequest() {
      this.$emit("add-new-request");
    },
  },
};
</script>

<style lang="scss" scoped>
.board-buttons {
  min-height: 34px;
}
</style>
