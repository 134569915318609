import DirectDashboard from "@/modules/direct/views/common/DirectDashboard.vue";
import DirectAdReport from "@/modules/direct/views/common/DirectAdReport.vue";

export default [
  {
    path: "/direct/dashboard",
    name: "direct-dashboard",
    components: {
      default: DirectDashboard,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/direct/ad-report",
    name: "direct-ad-report",
    components: {
      default: DirectAdReport,
    },
    meta: { requiresAuth: true },
  },
];
