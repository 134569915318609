
<b-form
  v-if="dataLoaded"
  id="status-form"
  ref="status"
  :model="status"
  class="status-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="row">
    <div class="col-12">
      <b-form-group label="Название" prop="title">
        <b-form-input
          v-model="status.title"
          placeholder="Название"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Порядок" prop="title">
        <b-form-input
          v-model="status.order"
          placeholder="Порядок"
        ></b-form-input>
      </b-form-group>
      <div class="row">
        <div class="col-6">
          <b-form-group label="Цвет фона" prop="color">
            <b-form-input v-model="status.color" type="color"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Цвет текста" prop="textColor">
            <b-form-input
              v-model="status.textColor"
              type="color"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Цвет текста в карточке" prop="cardColor">
            <b-form-input
              v-model="status.cardColor"
              type="color"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Настройки" prop="title">
        <b-form-checkbox v-model="status.hidden" value="1">
          Скрыть от пользователей
        </b-form-checkbox>
      </b-form-group>

      <b-form-group label="Логика обработки" prop="title">
        <b-form-checkbox v-model="status.newStatus" value="1">
          Для новых заявок
        </b-form-checkbox>

        <b-form-checkbox v-model="status.startWork" value="1">
          Взято в работу
        </b-form-checkbox>

        <b-form-checkbox v-model="status.duplicate" value="1">
          Дубль
        </b-form-checkbox>

        <b-form-checkbox v-model="status.trash" value="1">
          Спам \ Повторный звонок
        </b-form-checkbox>

        <b-form-checkbox v-model="status.moveToDeal" value="1">
          Перенести в сделки
        </b-form-checkbox>

        <b-form-checkbox v-model="status.closeStatus" value="1">
          Лид провален
        </b-form-checkbox>

        <b-form-checkbox
          v-if="false && status.closeStatus"
          v-model="status.termination"
          value="1"
        >
          Расторжение
        </b-form-checkbox>

        <b-form-checkbox v-model="status.autoInitialCall" value="1">
          Первичный автодозвон
        </b-form-checkbox>

        <b-form-checkbox v-model="status.autoRepeatedCall" value="1">
          Автодозвон при отсутствии ответа
        </b-form-checkbox>

        <b-form-checkbox v-model="status.withoutAnswer" value="1">
          Без ответа
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>

  <div class="ibox-footer">
    <b-button variant="primary" @click="createOrUpdate">
      <font-awesome-icon icon="check" />
      {{ submitButton }}
    </b-button>

    <b-button
      v-if="id !== 'new'"
      variant="outline-default"
      class="ml-2"
      @click="cancelUpdate"
    >
      <font-awesome-icon icon="arrow-left" />
      Назад к списку
    </b-button>

    <b-button
      v-if="status.id > 0"
      variant="danger"
      class="ml-2"
      @click="deleteStatus"
    >
      <font-awesome-icon icon="trash" />
      Удалить
    </b-button>
  </div>
</b-form>
