<template>
  <div class="client-page">
    <div class="row">
      <div class="col-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-content pb-1">
            <client-header
              :client="client"
              :return-url="{
                name: 'crm-client-view',
                params: { id: client.id },
              }"
              :show-buttons="false"
            ></client-header>
            <client-form
              :client="client"
              :return-url="{
                name: 'crm-client-view',
                params: { id: client.id },
              }"
            ></client-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientForm from "./ClientForm";
import {
  NUMERATION_COMMON,
  TYPE_EMPLOYEE,
} from "@/modules/crm/decorators/client";
import ClientHeader from "./ClientHeader";

export default {
  name: "Client",
  components: {
    "client-header": ClientHeader,
    "client-form": ClientForm,
  },
  data() {
    return {
      genders: [
        { text: "M", value: "male" },
        { text: "Ж", value: "female" },
      ],
      client: {
        id: "new",
        type: TYPE_EMPLOYEE,
        managerId: "",
        managerName: "",
        firstName: "",
        lastName: "",
        patronymic: "",
        gender: "",
        phone: "",
        email: "",
        phoneSearch: "",
        birthday: "",
        clientBirthPlace: "",
        country: "",
        town: "",
        addressPassport: "",
        addressLiving: "",
        // 'addressPassport': '',
        // 'addressLiving': '',
        address: "",
        phones: [""],
        emails: [""],
        passportSerial: "",
        passportNumber: "",
        passportAddress: "",
        passportIssuedDate: "",
        passportIssuedByTitle: "",
        passportIssuedByCode: "",

        numerationStart: 1,
        numeration: NUMERATION_COMMON,
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl() {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    checkContactsEmptyRecords() {
      if (!this.client.phones) {
        this.client.phones = [
          {
            phone: "",
            type: "mobile",
          },
        ];
      }
      if (!this.client.emails) {
        this.client.emails = [
          {
            email: "",
            type: "work",
          },
        ];
      }
    },
    fetchData() {
      let clientsUrl = "/crm/clients/" + this.id;
      this.loading = true;
      this.$http
        .get(clientsUrl)
        .then((response) => {
          this.loading = false;
          this.client = response.data;
          this.checkContactsEmptyRecords();
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
