
<b-form
  id="direct-campaign-form"
  ref="campaign"
  :model="campaign"
  class="direct-campaign-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="campaign.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="Аккаунт" prop="title">
      <account-autocomplete
        v-model="campaign.accountId"
        :default-value="getCampaignAccountName(campaign)"
        @selected="selectAccount"
      ></account-autocomplete>
    </b-form-group>

    <b-form-group label="Тип вредителя (один)" prop="title">
      <pest-autocomplete
        v-model="campaign.pestId"
        :default-value="getCampaignPestName(campaign)"
        @selected="selectPest"
      ></pest-autocomplete>
    </b-form-group>

    <b-form-group label="Тип вредителя(несколько)" prop="title">
      <multiply-autocomplete
        ref="pestAutocomplete"
        :get-data="fetchPestData"
        :get-title="getPestTitle"
        @selected="selectMultiplePest"
      ></multiply-autocomplete>
      <div v-if="campaign.pestIds.length">
        Выбранные: {{ getCampaignPestName(campaign) }}<br />
        <a @click.prevent="clearPests">Очистить</a>
      </div>
    </b-form-group>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="campaign.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="campaign.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteCampaign"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
