import {getGroupTitle} from "@/modules/law/decorators/group";
import {getUserName} from "@/modules/crm/common";
import dayjs from "dayjs";
import {getClientName} from "@/modules/crm/decorators/client";


const STATUS_NEW = 0;
const STATUS_DISPATCHED = 1;
const STATUS_PROGRESS = 2;
const STATUS_DONE = 9;
const STATUS_DECLINE = -1;
const STATUS_UNKNOWN = -999;

const statusTitles = {
    STATUS_NEW: 'Новый',
    STATUS_DISPATCHED: 'Распределенный',
    STATUS_PROGRESS: 'В работе',
    STATUS_DONE: 'Завершен',
    STATUS_DECLINE: 'Отклонен',
    STATUS_UNKNOWN: '[Не проставлен]',
};


const getIssueGroupTitle = function (issue) {
    if (!issue.group) {
        return '[Не назначено]';
    }
    return getGroupTitle(issue.group)
}

const getIssueTitle = function (issue) {
    if (!issue) {
        return '[Не назначено]';
    }
    let number = issue.number || '?';
    if (!issue.title) {
        return number + ': [Без названия]';
    }
    return number + ': ' + issue.title;
}

const getIssueManagerName = function (issue) {
    return getUserName(issue.manager);
}
const getIssueStatus = function (issue) {
    if (issue.status === STATUS_NEW) {
        return 'Новый'
    }
    if (issue.status === STATUS_DISPATCHED) {
        return 'Распределен'
    }
    if (issue.status === STATUS_PROGRESS) {
        return 'В работе'
    }
    if (issue.status === STATUS_DONE) {
        return 'Завершен'
    }
    if (issue.status === STATUS_DECLINE) {
        return 'Отклонен'
    }
    return '[Не проставлен]'
}


const getIssueClientName = function (issue) {
    if (!issue.client) {
        return '[Не назначен]';
    }
    return getClientName(issue.client);
}
const getIssueCreateTime = function (issue) {
    return dayjs.unix(issue.timeCreated).format('DD.MM.YYYY');
}
const getIssueDeadline = function (issue) {
    return issue.deadline
        ? dayjs(issue.deadline).format('DD.MM.YYYY')
        : '';
}
const getIssueUpdateTime = function (issue) {
    return dayjs.unix(issue.timeUpdated).format('DD.MM.YYYY');
}
const getDecisionTime = function (issue) {
    return dayjs.unix(issue.decisionTime).format('DD.MM.YYYY HH:mm:ss');
}


export {
    getIssueGroupTitle,
    getIssueTitle,
    getIssueManagerName,
    getIssueStatus,
    getIssueClientName,
    getIssueCreateTime,
    getIssueDeadline,
    getIssueUpdateTime,
    getDecisionTime,
    STATUS_NEW, STATUS_DISPATCHED, STATUS_PROGRESS, STATUS_DONE, STATUS_DECLINE,

};
