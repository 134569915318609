<template>
  <b-form
    v-if="dataLoaded"
    id="status-form"
    ref="status"
    :model="status"
    class="status-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="status.title"
            placeholder="Название"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Порядок" prop="title">
          <b-form-input
            v-model="status.order"
            placeholder="Порядок"
          ></b-form-input>
        </b-form-group>
        <div class="row">
          <div class="col-6">
            <b-form-group label="Цвет фона" prop="color">
              <b-form-input v-model="status.color" type="color"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Цвет текста" prop="textColor">
              <b-form-input
                v-model="status.textColor"
                type="color"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group label="Цвет текста в карточке" prop="cardColor">
              <b-form-input
                v-model="status.cardColor"
                type="color"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-form-group label="Настройки" prop="title">
          <b-form-checkbox v-model="status.hidden" value="1">
            Скрыть от пользователей
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Логика обработки" prop="title">
          <b-form-checkbox v-model="status.newStatus" value="1">
            Для новых заявок
          </b-form-checkbox>

          <b-form-checkbox v-model="status.startWork" value="1">
            Взято в работу
          </b-form-checkbox>

          <b-form-checkbox v-model="status.duplicate" value="1">
            Дубль
          </b-form-checkbox>

          <b-form-checkbox v-model="status.trash" value="1">
            Спам \ Повторный звонок
          </b-form-checkbox>

          <b-form-checkbox v-model="status.moveToDeal" value="1">
            Перенести в сделки
          </b-form-checkbox>

          <b-form-checkbox v-model="status.closeStatus" value="1">
            Лид провален
          </b-form-checkbox>

          <b-form-checkbox
            v-if="false && status.closeStatus"
            v-model="status.termination"
            value="1"
          >
            Расторжение
          </b-form-checkbox>

          <b-form-checkbox v-model="status.autoInitialCall" value="1">
            Первичный автодозвон
          </b-form-checkbox>

          <b-form-checkbox v-model="status.autoRepeatedCall" value="1">
            Автодозвон при отсутствии ответа
          </b-form-checkbox>

          <b-form-checkbox v-model="status.withoutAnswer" value="1">
            Без ответа
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="ibox-footer">
      <b-button variant="primary" @click="createOrUpdate">
        <font-awesome-icon icon="check" />
        {{ submitButton }}
      </b-button>

      <b-button
        v-if="id !== 'new'"
        variant="outline-default"
        class="ml-2"
        @click="cancelUpdate"
      >
        <font-awesome-icon icon="arrow-left" />
        Назад к списку
      </b-button>

      <b-button
        v-if="status.id > 0"
        variant="danger"
        class="ml-2"
        @click="deleteStatus"
      >
        <font-awesome-icon icon="trash" />
        Удалить
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { VueMaskDirective } from "v-mask";

export default {
  name: "StatusForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["statusModel", "dataLoaded"],
  data() {
    return {
      status: {},
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.status.id;
    },
  },
  watch: {
    $route(to, from) {},
    statusModel: {
      immediate: true,
      handler(obj) {
        this.status = Object.assign({}, obj);
      },
    },
  },
  created: function () {},
  mounted: function () {},
  methods: {
    processStatusVariable(status) {
      let props = {
        hidden: status.hidden,
        title: status.title,
        order: status.order,
        color: status.color,
        textColor: status.textColor,
        cardColor: status.cardColor,
        newStatus: status.newStatus,
        startWork: status.startWork,
        duplicate: status.duplicate,
        trash: status.trash,
        moveToDeal: status.moveToDeal,
        closeStatus: status.closeStatus,
        termination: status.termination,
        autoInitialCall: status.autoInitialCall,
        autoRepeatedCall: status.autoRepeatedCall,
        withoutAnswer: status.withoutAnswer,
      };
      if (status.id && status.id !== "new") {
        props.id = status.id;
      }
      return props;
    },
    update: function () {
      let updateUrl;
      updateUrl = "/crm/statuses/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.processStatusVariable(this.status))
        .then((response) => {
          this.loading = false;
          this.$bvToast.toast("Данные сохранены", {
            title: "Статус отредактирован",
            type: "success",
          });
          this.$emit(
            "update-status-info",
            this.processStatusVariable(this.status)
          );
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось удалить статус",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/crm/statuses";
      this.loading = true;
      this.$http
        .post(updateUrl, this.processStatusVariable(this.status))
        .then((response) => {
          this.status = response.data;
          this.$eventHub.$emit("update-status-info", this.status);
          this.$router.push({
            name: "crm-status-update",
            params: { id: this.status.id },
          });
          this.$bvToast.toast("Статус добавлен", {
            title: "Статус добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить статуса",
              variant: "danger",
            });
          }
        });
    },
    deleteStatus() {
      if (confirm("Вы действительно хотите удалить статуса?")) {
        let deleteUrl = "/crm/statuses/" + this.status.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            // this.status.archived = 1;
            this.loading = false;
            this.$router.push({ name: "crm-status-list" });
            // this.$eventHub.$emit('update-status-info', this.status);
            this.$bvToast.toast("Статус удален", {
              title: "Статус удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить статуса",
                variant: "danger",
              });
            }
          });
      }
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("status-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    cancelUpdate() {
      this.$router.push({ name: "crm-status-list" });
      // this.$emit('cancel-updating', this.status);
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.ibox-footer {
  bottom: 0;
}
</style>
