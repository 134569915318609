
<div>
  <table class="table table-striped request-info">
    <tbody>
      <!--          <tr>-->
      <!--            <td>-->
      <!--              Статус-->
      <!--            </td>-->
      <!--            <td>-->
      <!--              {{ getRequestStatus(request) }}-->
      <!--            </td>-->
      <!--          </tr>-->
      <tr>
        <td class="title-column">
          <font-awesome-icon icon="list-ol" fixed-width />
          Номер
        </td>
        <td>
          {{ getRequestNumber(request) }}
        </td>
      </tr>
      <tr v-if="request.isOrganization && request.organizationTitle">
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Организация
        </td>
        <td>
          {{ request.organizationTitle }}
        </td>
      </tr>

      <tr v-if="request.isOrganization">
        <td>
          <font-awesome-icon icon="money-bill" fixed-width />
          Тип оплаты
        </td>
        <td>
          {{ getPaymentTypeTitle(request) }}
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="user-cog" fixed-width />
          Исполнитель
        </td>
        <td>
          <span v-if="request.assigneeId">
            {{ getRequestAssigneeName(request) }}
            <router-link
              v-if="false"
              :to="{
                name: 'users-user-view',
                params: { id: request.assigneeId },
              }"
              class=""
            >
              <font-awesome-icon
                icon="external-link-square-alt"
                fixed-width
              />
            </router-link>
          </span>
          <span v-else>[Не назначен]</span>
        </td>
      </tr>

      <tr v-if="request.assignee1stId">
        <td>
          <font-awesome-icon icon="user-cog" fixed-width />
          Исполнитель по первичке
        </td>
        <td>
          <span v-if="request.assignee1stId">
            {{ getRequestAssignee1stName(request) }}
            <router-link
              v-if="false"
              :to="{
                name: 'users-user-view',
                params: { id: request.assignee1stId },
              }"
              class=""
            >
              <font-awesome-icon
                icon="external-link-square-alt"
                fixed-width
              />
            </router-link>
          </span>
          <span v-else>[Не назначен]</span>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="clock" fixed-width />
          Время
        </td>
        <td>
          {{ getRequestDatetime(request) }} –
          {{ getRequestDuration(request) }}
        </td>
      </tr>

      <tr v-if="request.town">
        <td>
          <font-awesome-icon icon="map-marker" fixed-width />
          Город
        </td>
        <td>
          {{ request.town.title }}
        </td>
      </tr>
      <tr v-if="request.subwayOld">
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Метро (старое)
        </td>
        <td>
          {{ request.subwayOld }}
        </td>
      </tr>
      <tr v-if="request.subway">
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Метро
        </td>
        <td>
          {{ getSubwayTitle(request.subway) }}
        </td>
      </tr>
      <tr>
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Адрес
        </td>
        <td>
          <span v-if="isWorker" v-html="getMapLink(request)"></span>
          <span v-else>
            {{ getRequestAddressFormatted(request) }}
          </span>
        </td>
      </tr>
      <tr v-if="request.addressInfo">
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Адрес. доп
        </td>
        <td>
          {{ request.addressInfo }}
        </td>
      </tr>
      <tr v-if="false">
        <td>
          <font-awesome-icon icon="map" fixed-width />
          Адрес (координаты)
        </td>
        <td>
          {{ request.addressCoords }}
        </td>
      </tr>

      <tr
        v-if="
          request.addressEntrance &&
          (showAppartmentAfterRequest || canSeeAppartment)
        "
      >
        <td>
          <font-awesome-icon icon="door-open" fixed-width />
          Подъезд
        </td>
        <td>{{ request.addressEntrance }}</td>
      </tr>
      <tr
        v-if="
          request.addressFloor &&
          (showAppartmentAfterRequest || canSeeAppartment)
        "
      >
        <td>
          <font-awesome-icon icon="list-ol" fixed-width />
          Этаж
        </td>
        <td>{{ request.addressFloor }}</td>
      </tr>

      <tr
        v-if="
          request.addressIntercom &&
          (showAppartmentAfterRequest || canSeeAppartment)
        "
      >
        <td>
          <font-awesome-icon icon="phone" fixed-width />
          Домофон
        </td>
        <td>{{ request.addressIntercom }}</td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Квартира
        </td>
        <td>
          <div class=" ">
            <div v-if="canIViewAppartment">
              <div v-if="showAppartmentAfterRequest || canSeeAppartment">
                <span v-if="request.appartment">{{
                  request.appartment
                }}</span>
                <span v-else>[Квартира не назначена]</span>
              </div>
              <div v-else class="d-flex">
                <div>Нажмите на кнопку, чтобы увидеть квартиру</div>
                <div class="text-right" style="width: 200px">
                  <a
                    v-if="!requestingAddress"
                    class="btn btn-primary btn-sm"
                    @click.prevent="requestToShowAddress"
                  >
                    <font-awesome-icon icon="map" />
                    Показать мне
                  </a>
                  <a v-else class="btn btn-primary btn-sm" @click.prevent>
                    <font-awesome-icon spin icon="rotate" />
                    Идет запрос
                  </a>
                </div>
              </div>
            </div>
            <div v-else>Вы не видите номер квартиры</div>

            <div v-if="!canIViewAppartment" class="ml-auto">
              <a
                v-if="!requestingAddress"
                class="btn btn-primary btn-sm"
                @click.prevent="requestToShowAddress()"
              >
                <font-awesome-icon icon="map" />
                Показать мне
              </a>
              <a v-else class="btn btn-primary btn-sm" @click.prevent>
                <font-awesome-icon spin icon="rotate" />
                Идет запрос
              </a>
            </div>
          </div>
        </td>
      </tr>

      <tr v-if="canOpenToAssignee">
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Видимость квартиры
        </td>

        <td>
          <div class="d-flex">
            <div class="">
              <span v-if="canAssigneeViewAppartment">
                <font-awesome-icon icon="check" fixed-width />
                Номер отображается исполнителю
              </span>
              <span v-else>
                <font-awesome-icon icon="times" fixed-width />
                Номер скрыт от исполнителя
              </span>
            </div>
            <div class="ml-auto">
              <div v-if="canOpenToAssignee">
                <button
                  v-if="!opening"
                  class="btn btn-primary btn-sm"
                  @click.prevent="
                    toggleToAssignee(!canAssigneeViewAppartment)
                  "
                >
                  <span v-if="canAssigneeViewAppartment">
                    <font-awesome-icon icon="eye-slash" />
                    Скрыть от исполнителя
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="eye" />
                    Открыть исполнителю
                  </span>
                </button>
                <button
                  v-else
                  class="btn btn-primary btn-sm"
                  @click.prevent=""
                >
                  <font-awesome-icon spin icon="rotate" />
                  <span v-if="canAssigneeViewAppartment">
                    Открываем доступ
                  </span>
                  <span v-else> Скрываем доступ </span>
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr v-for="(pInfo, pIndex) in phones" :key="'p' + pIndex">
        <td>
          <font-awesome-icon icon="phone" fixed-width />
          Телефон
        </td>
        <td>
          <div class="d-flex">
            <div>
              <div v-if="canViewPhone" class="">{{ pInfo.phone }}</div>
              <div class="">{{ pInfo.clientName }}</div>
            </div>
            <div v-if="request.id !== 'new'" class="ml-auto">
              <button
                v-if="calling"
                class="btn btn-success btn-sm"
                @click.prevent=""
              >
                <font-awesome-icon spin icon="rotate" />
                Соединяю
              </button>

              <button
                v-else
                v-b-modal.modal-call-cause
                class="btn btn-success btn-sm"
                @click.prevent="setCallInfo(pInfo.cIndex, pInfo.pIndex)"
              >
                <font-awesome-icon icon="phone" />
                Позвонить
              </button>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <font-awesome-icon icon="ruble-sign" fixed-width />
          Сумма заявки указанная менеджером
        </td>
        <td>
          {{ request.knownSum }}
        </td>
      </tr>

      <tr v-if="request.warning">
        <td>
          <font-awesome-icon icon="exclamation-triangle" fixed-width />
          Особое внимание
        </td>
        <td>
          <span>{{ request.warning }}</span>
        </td>
      </tr>
      <tr v-if="request.pest">
        <td>
          <font-awesome-icon icon="bug" fixed-width />
          Вредитель
        </td>
        <td :style="'color: ' + getRequestColor(request)">
          <span>{{ getPestTitle(request.pest) }}</span>
        </td>
      </tr>
      <tr v-if="request.facility">
        <td>
          <font-awesome-icon icon="building" fixed-width />
          Тип объекта
        </td>
        <td>
          <span>{{ getFacilityTitle(request.facility) }}</span>
        </td>
      </tr>

      <tr v-if="request.fromExchange">
        <td>
          <font-awesome-icon icon="hand-holding-usd" fixed-width />
          Дополнительно
        </td>
        <td>
          <span>Из биржи (нет рекорда) </span>
        </td>
      </tr>

      <tr v-if="request.comment">
        <td>
          <font-awesome-icon icon="comment" fixed-width />
          Комментарий
        </td>
        <td style="word-break: break-word">
          <span>{{ request.comment }}</span>
        </td>
      </tr>
      <!--          <tr>-->
      <!--            <td>-->
      <!--              Дата создания-->
      <!--            </td>-->
      <!--            <td>-->
      <!--              {{ getRequestCreateTime(request) }}-->
      <!--            </td>-->
      <!--          </tr>-->
    </tbody>
  </table>

  <b-modal
    id="modal-call-cause"
    ref="modal"
    title="Укажите причину звонка"
    cancel-title="Отмена"
    ok-title="Позвонить"
    title-tag="h3"
    @ok="makeCall"
  >
    <request-call-cause :call="call"></request-call-cause>
  </b-modal>
</div>
