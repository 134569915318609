
<div
  :data-active="active"
  draggable
  @click.prevent="clicked"
  @dragenter.prevent="setActive"
  @dragover.prevent="setActive"
  @dragleave.prevent="setInactive"
  @drop.prevent="onDrop"
>
  <!-- share state with the scoped slot -->
  <slot :drop-zone-active="active"></slot>
</div>
