<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Аккаунты</h1>
      <div class="mb-2">
        <router-link
          :to="{ name: 'direct-account-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          Добавить аккаунт
        </router-link>
      </div>

      <b-table
        v-if="accounts.length"
        responsive
        striped
        hover
        condensed
        :items="accounts"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> Данные подгружаются</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{
              name: 'direct-account-update',
              params: { id: data.item.id },
            }"
            class=""
          >
            {{ data.item.title ? data.item.title : "[Без названия]" }}
          </router-link>
        </template>

        <template #cell(currency)="data">
          {{ getAccountCurrencyTitle(data.item) }}
        </template>
        <template #cell(groupAccount)="data">
          {{ data.item.groupAccount ? "Да" : "Нет" }}
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getAccountCurrencyTitle } from "@/modules/direct/decorators/accounts";

export default {
  name: "DirectAccountList",
  components: {},
  data() {
    return {
      arrayLoadingAccountId: [],
      accounts: [{}],
      search: {
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Аккаунта",
      fields: [
        {
          key: "title",
          label: "Название",
          sortable: false,
        },
        {
          key: "currency",
          label: "Валюта",
          sortable: false,
        },
        {
          key: "comment",
          label: "Описание",
          sortable: false,
        },
        {
          key: "groupAccount",
          label: "Учет по группе",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getAccountCurrencyTitle,
    isAccountLoading(accountId) {
      return this.arrayLoadingAccountId.includes(accountId);
    },
    loadReport(accountId) {
      const index = this.arrayLoadingAccountId.indexOf(accountId);
      if (index !== -1) {
        return;
      }
      this.arrayLoadingAccountId.push(accountId);
      let reportUrl = "/direct/default/reports?id=" + accountId;
      this.$http
        .get(reportUrl)
        .then((response) => {
          if (response.data.status === "error") {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось получить отчет",
              type: "danger",
            });
          }
          this.arrayLoadingAccountId = this.arrayLoadingAccountId.filter(
            (n) => {
              return n !== accountId;
            }
          );
        })
        .catch((error) => {
          this.arrayLoadingAccountId = this.arrayLoadingAccountId.filter(
            (n) => {
              return n !== accountId;
            }
          );

          this.processRequestError(error);
        });
    },
    fetchData: function () {
      this.loading = true;

      let accountsUrl = "/direct/accounts";
      let params = {
        page: this.page,
      };
      this.$http
        .get(accountsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.accounts = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "direct-account-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
