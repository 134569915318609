//

import DocumentTypeList from "@/modules/papers/views/types/DocumentTypeList";
import DocumentTypeCreate from "@/modules/papers/views/types/DocumentTypeCreate";
import DocumentTypeUpdate from "@/modules/papers/views/types/DocumentTypeUpdate";
import DocumentTypeView from "@/modules/papers/views/types/DocumentTypeView";
import DocumentList from "@/modules/papers/views/documents/DocumentList";

export default [


//////////////////////////////////////////////////////

    {
        path: '/documents/:folderId?/:page?',
        name: 'document-list',
        components: {
            default: DocumentList,
        }
    },

//////////////////////////////////////////////////////

    {
        path: '/document/types/:page?',
        name: 'document-type-list',
        components: {
            default: DocumentTypeList,
        }
    },

    {
        path: '/document/types/new',
        name: 'document-type-create',
        components: {
            default: DocumentTypeCreate,
        }
    },
    {
        path: '/document/types/:id/update',
        name: 'document-type-update',
        components: {
            default: DocumentTypeUpdate,
        },
    },
    {
        path: '/document/types/:id',
        name: 'document-type-view',
        components: {
            default: DocumentTypeView,
        },
    },
//////////////////////////////////////////////////////


]
