
<b-form
  id="pest-penalty-form"
  ref="penalty"
  :model="penalty"
  class="pest-penalty-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Нарушение" prop="title">
          <b-form-textarea
            v-model="penalty.title"
            rows="3"
            placeholder="Нарушение"
            tabindex="1"
          />
        </b-form-group>
        <b-form-group label="Штраф" prop="sum">
          <b-form-input
            v-model="penalty.sum"
            placeholder=""
            tabindex="2"
          ></b-form-input>
        </b-form-group>
        <b-form-checkbox v-model="penalty.dismissal" value="1" tabindex="3"
          >Увольнение
        </b-form-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Комментарий" prop="title">
          <b-form-textarea
            v-model="penalty.comment"
            rows="3"
            placeholder="Описание"
            tabindex="3"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="penalty.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deletePenalty"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
