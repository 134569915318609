<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <div class="row">
          <div class="col-5">
            <b-form-group label="Дата от">
              <datepicker
                v-model="search.dateFrom"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="col-5">
            <b-form-group label="Дата до">
              <datepicker
                v-model="search.dateTo"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>
          <div class="col-2 align-self-center mt-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content pl-0 pr-0 pb-0">
      <div v-if="loading" class="pl-3 pr-3 pt-2 pb-4">Данные загружаются</div>

      <b-table
        v-else-if="deposits.length"
        responsive
        hover
        condensed
        bordered
        :items="deposits"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> Данные подгружаются</strong>
          </div>
        </template>

        <template #cell(time)="data">
          {{ getDepositDateTime(data.item) }}
        </template>

        <template #cell(totalSum)="data">
          {{ getDepositSumFormatted(data.item) }}
        </template>

        <template #cell(status)="data">
          {{ getDepositStatusTitle(data.item) }}
        </template>
      </b-table>

      <div v-else class="pl-3 pr-3 pt-2 pb-4">
        В указанный период инкассаций не было
      </div>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getDepositDateTime,
  getDepositStatusTitle,
  getDepositSum,
  getDepositSumFormatted,
} from "@/modules/pest/decorators/deposits";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";

export default {
  name: "DepositEmployeeList",
  components: {
    Datepicker: DatePicker,
  },
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      deposits: [],
      search: {
        dateFrom: "",
        dateTo: "",
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Места",
      fields: [
        {
          key: "time",
          label: "Дата",
          sortable: false,
        },
        {
          key: "totalSum",
          label: "Сумма",
          sortable: false,
        },
        {
          key: "status",
          label: "Статус",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {
    this.search.dateTo = dayjs().format("DD.MM.YYYY");
    this.search.dateFrom = dayjs().subtract(30, "d").format("DD.MM.YYYY");
  },
  methods: {
    getDepositDateTime,
    getDepositSum,
    getDepositSumFormatted,
    getDepositStatusTitle,
    fetchData: function () {
      this.loading = true;

      let depositsUrl = "/pest/deposits";
      let params = {
        page: this.page,
      };
      this.$http
        .get(depositsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.deposits = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-deposit-employee-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}
</style>
