<template>
  <div id="map" style=""></div>
</template>

<script>
import loadjs from "loadjs";

export default {
  name: "RequestFormMap",
  components: {},
  props: {
    coords: {},
    townId: {},
  },
  data() {
    return {
      directionsService: null,
      directionsRenderer: null,
      requestMarker: null,
      userMarker: null,
    };
  },
  computed: {
    gmapsKey() {
      return import.meta.env.VITE_GOOGLE_MAP_EMBED_KEY;
    },
    getMapUrl() {
      return (
        "https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=&v=weekly&key=" +
        this.gmapsKey
      );
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
    coords: {
      immediate: true,
      handler(obj) {
        if (this.requestMarker) {
          this.requestMarker.setPosition(this.getGoogleLatLng(obj));
        }
      },
    },
  },
  created: function () {
    window.initMap = this.mapCallback;
    loadjs.reset();
    loadjs([this.getMapUrl], "gmaps");
  },
  methods: {
    getPointCoords() {
      if (this.coords) {
        return this.getGoogleLatLng(this.coords);
      }
      return this.getTownCoords(this.townId);
    },
    getGoogleLatLng(str) {
      if (!str) {
        return false;
      }
      if (typeof str === "string") {
        let coords = JSON.parse(str);
        return new window.google.maps.LatLng(coords.lat, coords.lng);
      }
      if (typeof str === "object") {
        return new window.google.maps.LatLng(str.lat, str.lng);
      }
      return false;
    },
    mapCallback() {
      this.showMap();
      this.addPointMarker();
    },
    getTownCoords(townId) {
      if (townId === 1) {
        return { lat: 55.753237, lng: 37.62252 };
      } else if (townId === 2) {
        return { lat: 59.939095, lng: 30.315868 };
      } else {
        return { lat: 55.753237, lng: 37.62252 };
      }
    },

    showMap() {
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: this.getTownCoords(this.townId),
        zoom: 8,
      });
      this.geocoder = new window.google.maps.Geocoder();
      this.directionsService = new window.google.maps.DirectionsService();
      this.directionsRenderer = new window.google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
      let $this = this;
      window.google.maps.event.addListener(this.map, "click", function (event) {
        $this.placeUserMarker(event.latLng);
      });
    },

    showPath() {
      let $this = this;
      this.directionsService
        .route({
          origin: this.userMarker.position,
          destination: this.requestMarker.position,
          // waypoints: path,
          optimizeWaypoints: false,
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          $this.directionsRenderer.setDirections(response);
          $this.calculate(response);
        })
        .catch((e) =>
          console.log(["Directions request failed due to " + status, e])
        );
    },
    calculate(response) {
      let distance = response.routes[0].legs[0].distance.value;
      let time = response.routes[0].legs[0].duration.text;
      this.$emit("distance", {
        distance: distance,
        time: time,
      });
      console.log({
        distance: distance,
        time: time,
      });
    },
    placeUserMarker(location) {
      if (this.userMarker) {
        this.userMarker.setPosition(location);
      } else {
        let map = this.map;
        this.userMarker = new window.google.maps.Marker({
          position: location,
          map,
        });
      }
      this.showPath();
    },

    addPointMarker(user) {
      let map = this.map;
      const requestMarker = new window.google.maps.Marker({
        position: this.getPointCoords(),
        icon: "https://maps.google.com/mapfiles/ms/micons/blue.png",
        map,
      });
      // const infowindow = new window.google.maps.InfoWindow({
      //   content: getUserName(user),
      // });
      this.requestMarker = requestMarker;
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  position: relative;
  overflow: hidden;
  display: block;
  height: 300px;
  width: 100%;
}
</style>
