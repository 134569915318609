
<b-modal
  id="modal-assignee"
  ref="modal"
  :title="title"
  size="lg"
  cancel-title="Отмена"
  :ok-disabled="true"
  ok-title="Сохранить"
  title-tag="h3"
  @ok="handleTaskModalOk"
>
  <div class="row mb-2 btn-row">
    <div class="col-md-3">
      <button
        class="btn btn-block"
        :class="{
          'btn-primary': isActive(false),
          'btn-outline-info': !isActive(false),
        }"
        @click.prevent="selectAssignee(false)"
      >
        Не назначен
      </button>
    </div>

    <div class="col-md-3"></div>
    <div class="col-md-3"></div>
    <div class="col-md-3">
      <button
        v-if="!showOther"
        class="btn btn-block"
        :class="{ 'btn-primary': showOther, 'btn-outline-info': !showOther }"
        @click.prevent="openSearch"
      >
        Выбрать другого
      </button>
      <div :class="{ 'd-none': !showOther }">
        <user-autocomplete
          ref="assigneeSearch"
          default-value=""
          :type="TYPE_WORKER"
          :town-id="townId"
          :hide-reset="true"
          @selected="selectAssigneeFromAutocomplete"
        ></user-autocomplete>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div
      v-for="(group, gIndex) in processedAssignees"
      :key="'col' + gIndex"
      class="col-md-3"
    >
      <ul>
        <li
          v-for="(assignee, index) in group"
          :key="index"
          :class="{ active: isActive(assignee), busy: isBusy(assignee) }"
          @click.prevent="selectAssignee(assignee)"
        >
          {{ getAssigneeName(assignee) }}
        </li>
      </ul>
    </div>
  </div>
</b-modal>
