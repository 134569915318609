<template>
  <b-form
    id="pest-pest-form"
    ref="pest"
    :model="pest"
    class="pest-pest-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="pest.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="pest.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group label="Ставка" prop="percentType">
            <b-button-group>
              <button
                class="btn"
                :class="getPercentClass(PERCENT_TYPE_DEFAULT)"
                @click.prevent="selectPercent(PERCENT_TYPE_DEFAULT)"
              >
                По-умолчанию
              </button>
              <button
                class="btn"
                :class="getPercentClass(PERCENT_TYPE_MANUAL)"
                @click.prevent="selectPercent(PERCENT_TYPE_MANUAL)"
              >
                Вручную
              </button>
            </b-button-group>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <b-form-group label="Обычная ставка" prop="percentRegular">
            <b-form-input
              v-model="pest.percentRegular"
              :disabled="pest.percentType !== PERCENT_TYPE_MANUAL"
            />
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group label="Рекордная ставка" prop="percentRecord">
            <b-form-input
              v-model="pest.percentRecord"
              :disabled="pest.percentType !== PERCENT_TYPE_MANUAL"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Цвет" prop="title">
            <b-form-input
              v-model="pest.color"
              type="color"
              placeholder="Цвет"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Тип" prop="percentType">
            <b-button-group>
              <button
                class="btn"
                :class="getServiceClass(SERVICE_DEFAULT)"
                @click.prevent="selectService(SERVICE_DEFAULT)"
              >
                Обычный
              </button>
              <button
                class="btn"
                :class="getServiceClass(SERVICE_DOUBLE)"
                @click.prevent="selectService(SERVICE_DOUBLE)"
              >
                Сервисный (скрыть от всех)
              </button>
            </b-button-group>
          </b-form-group>

          <b-form-group
            v-if="pest.service === SERVICE_DOUBLE"
            label="Тип вредителя(несколько)"
            prop="title"
          >
            <multiply-autocomplete
              ref="pestAutocomplete"
              :get-data="fetchPestData"
              :get-title="getPestTitle"
              @selected="selectMultiplePest"
            ></multiply-autocomplete>
            <div v-if="pest.pestIds.length">
              Выбранные: {{ getPestsDoubleNames(pest) }}<br />
              <a @click.prevent="clearPests">Очистить</a>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Сортировка" prop="order">
            <b-form-input
              v-model="pest.order"
              placeholder="порядок сортировки"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>

          <a
            v-if="pest.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deletePest"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import {
  getPestsDoubleNames,
  getPestTitle,
  PERCENT_TYPE_DEFAULT,
  PERCENT_TYPE_MANUAL,
  SERVICE_DEFAULT,
  SERVICE_DOUBLE,
} from "../../decorators/pests.js";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete";

export default {
  name: "PestPestForm",
  directives: { mask: VueMaskDirective },
  components: { MultiplyAutocomplete },
  props: ["pest"],
  data() {
    return {
      PERCENT_TYPE_DEFAULT,
      PERCENT_TYPE_MANUAL,
      SERVICE_DEFAULT,
      SERVICE_DOUBLE,
      phoneMask: false,
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.pest.id;
    },
    name() {
      let name = this.pest.name ? this.pest.name : "Новый вредитель";
      return name ? name : "Вредитель";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    getPestTitle,
    getPestsDoubleNames,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/pests/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.pest)
        .then((response) => {
          this.pest = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-pest-info", this.pest);
          // this.$router.push({name: 'pest-pest-view', params: {id: this.pest.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Вредитель отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/pests";
      this.loading = true;
      this.$http
        .post(updateUrl, this.pest)
        .then((response) => {
          this.pest = response.data;
          this.$eventHub.$emit("update-pest-pest-info", this.pest);
          this.$router.push({
            name: "pest-pest-update",
            params: { id: this.pest.id },
          });
          this.$bvToast.toast("Вредитель добавлен", {
            title: "Вредитель добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить вредитель",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-pest-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deletePest() {
      if (confirm("Вы действительно хотите удалить вредитель?")) {
        let deleteUrl = "/pest/pests/" + this.pest.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-pest-list" });
            this.$bvToast.toast("Вредитель удален", {
              title: "Вредитель удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить вредитель",
                variant: "danger",
              });
            }
          });
      }
    },
    getPercentClass(type) {
      if (
        this.pest.percentType === type ||
        (type === PERCENT_TYPE_DEFAULT && !this.pest.percentType)
      ) {
        return "btn-primary";
      } else {
        return "btn-outline-info";
      }
    },
    selectPercent(type) {
      this.pest.percentType = type;
      if (type === PERCENT_TYPE_DEFAULT) {
        this.pest.percentRegular = "";
        this.pest.percentRecord = "";
      }
    },

    getServiceClass(type) {
      if (this.pest.service === type) {
        return "btn-primary";
      } else {
        return "btn-outline-info";
      }
    },
    selectService(type) {
      this.pest.service = type;
    },

    fetchPestData(search, resolve) {
      let pestUrl = "/pest/pests";
      let params = {
        search: search,
        service: false,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = [{ id: -1, title: "Не назначен" }, ...response.data];
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    clearPests() {
      this.campaign.pestIds = [];
    },
    selectMultiplePest(info) {
      this.campaign.pestIds = [];
      if (info) {
        for (let pest of info) {
          this.pest.pestIds.push(pest.id);
        }
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
