<template>
  <div class="contact-card">
    <contact-view
      v-if="!updating"
      :client="client"
      :is-last="isLast"
      @delete-contact="clientDeleted"
    />
    <contact-form
      v-else
      :client-model="client"
      :data-loaded="true"
      @cancel-updating="cancelUpdate"
      @update-client-info="clientUpdated"
    />
  </div>
</template>

<script>
import ContactView from "@/modules/pest/views/requests/contract/contacts/ContactView.vue";
import ContactForm from "@/modules/pest/views/requests/contract/contacts/ContactForm.vue";

export default {
  name: "ContactCard",
  components: { ContactForm, ContactView },
  props: ["client", "isLast"],
  data() {
    return {
      updating: false,
    };
  },
  computed: {},
  watch: {
    client: {
      immediate: true,
      handler(client) {
        if (client.isNew) {
          this.updating = true;
        }
      },
    },
  },
  created: function () {},
  methods: {
    clientUpdated(client) {
      // this.client = client;
      this.$emit("update-client-info", client);
      this.updating = false;
    },
    cancelUpdate(client) {
      this.$emit("cancel-updating", client);
    },
    clientDeleted(client) {
      this.$emit("client-deleted", client);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-card {
  overflow-x: hidden;
  width: 200px;
}
</style>
