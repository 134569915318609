<template>
  <div class="client-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-content pb-1">
            <h1>Добавление контактного лица</h1>
            <client-form
              :client-model="newClient"
              :data-loaded="!loading"
              :return-url="{ name: 'crm-client-list' }"
            ></client-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientForm from "./ClientForm";
import {
  NUMERATION_CLIENT,
  TYPE_EMPLOYEE,
} from "@/modules/crm/decorators/client";

export default {
  name: "Client",
  components: {
    "client-form": ClientForm,
  },
  data() {
    return {
      genders: [
        { text: "M", value: "male" },
        { text: "Ж", value: "female" },
      ],
      newClient: {},
      client: {
        id: "new",
        type: TYPE_EMPLOYEE,
        firstName: null,
        lastName: "",
        patronymic: "",
        numeration: NUMERATION_CLIENT,
        gender: "",
        phone: "",
        email: "",
        phoneSearch: "",
        birthday: "",
        clientBirthPlace: "",
        country: "",
        town: "",
        addressPassport: "",
        addressLiving: "",
        // 'addressPassport': '',
        // 'addressLiving': '',
        address: "",
        phones: [
          {
            phone: "",
            type: "mobile",
          },
        ],
        emails: [
          {
            email: "",
            type: "",
          },
        ],
        salary: "",
        creditSum: "",
        creditPurpose: "",
        creditDelayType: "none",
        creditDelayInfo: "",
        occupation: "none",
        workplace: "",
        passportSerial: "",
        passportNumber: "",
        passportAddress: "",
        passportIssuedDate: "",
        passportIssuedByTitle: "",
        passportIssuedByCode: "",
        hasOpenTasks: false,

        lastBankRequests: "",
        currentCredits: "",
        previousCredits: "",
        monthlyPayment: 0,
        organizationId: "",
        organization: {},
        // 'passportImagesIds': '',
      },

      loading: true,
    };
  },
  computed: {
    id() {
      return "new";
    },
    organizationId() {
      return this.$route.params.organizationId !== undefined
        ? this.$route.params.organizationId
        : "";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      this.client.organizationId = this.organizationId;
      if (this.client.organizationId) {
        this.fetchOrganization();
      } else {
        this.newClient = this.client;
        this.loading = false;
      }
      this.header = "Создать клиента";
      window.scrollTo(0, 0);
    },
    fetchOrganization() {
      let clientsUrl = "/crm/clients/" + this.client.organizationId;
      this.$http
        .get(clientsUrl)
        .then((response) => {
          this.client.organization = response.data;
          this.newClient = this.client;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
