

<b-form :model="documentType" id="documentType-form" ref="documentType" class="documentType-form "
        @submit="createOrUpdate" @reset="onReset"
        novalidate>
  <div class="div">

    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input v-model="documentType.title" placeholder="Название"
                        tabindex="1"></b-form-input>
        </b-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea v-model="documentType.comment" placeholder="Описание"
                           tabindex="1"></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check"/>
          {{ submitButton }}
        </b-button>


        <a href="" v-if="documentType.id!=='new'" class="btn btn-outline btn-danger ml-2"
           @click.prevent="deleteDocumentType">
          <font-awesome-icon icon="trash"/>
          Удалить
        </a>

        <router-link :to="returnUrl" class="btn btn-outline btn-default ml-2" v-if="returnUrl">
          <font-awesome-icon icon="arrow-left"/>
          Назад
        </router-link>
      </div>
    </div>

  </div>

</b-form>
