
<div class="pest-subway-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>Новое метро</h1>
        </div>
        <div class="ibox-content">
          <pest-subway-form :subway="subway"></pest-subway-form>
        </div>
      </div>
    </div>
  </div>
</div>
