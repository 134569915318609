
<div class="d-flex">
  <div
    v-if="
      request && request.callNotifications && request.callNotifications.length
    "
    class="d-flex mr-2"
  >
    <button class="btn btn-danger mr-2" @click.prevent>
      Позвонить клиенту
    </button>

    <div
      v-for="(call, cIndex) in request.callNotifications"
      :key="'c' + cIndex"
      class="btn-group mr-2"
    >
      <button class="btn btn-outline-danger btn-no-hover" @click.prevent>
        {{ callTime(call, cIndex) }} &nbsp;
      </button>
      <button
        class="btn btn-outline-danger"
        @click.prevent="removeCall(call, cIndex)"
      >
        <font-awesome-icon v-if="removing" fixed-width icon="rotate" spin />
        <font-awesome-icon v-else fixed-width icon="times" />
      </button>
    </div>
  </div>
  <button
    v-if="request.id !== 'new'"
    class="btn btn-outline btn-outline-primary"
    @click.prevent="openPopup"
  >
    <font-awesome-icon icon="plus" />
    Назначить звонок
  </button>

  <request-call-notification-popup />
</div>
