
<div>
  <div class="input-group">
    <div
      v-for="(param, pIndex) in searchParams"
      :key="'p' + pIndex"
      class="input-group-prepend"
    >
      <button
        class="btn btn-outline-secondary"
        type="button"
        @click="removeParam(param, pIndex)"
      >
        {{ param.label }}
        <font-awesome-icon icon="times" />
      </button>
    </div>

    <input
      v-model="text"
      type="text"
      class="form-control search-field"
      placeholder="Поиск"
      aria-label="Адрес, телефон, номер карточки"
      aria-describedby="basic-addon1"
      @focus="openPopup"
      @keydown.enter.prevent="startSearch"
    />

    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        type="button"
        @click="resetSearch"
      >
        <font-awesome-icon icon="times" />
      </button>
      <button v-if="loading" class="btn btn-outline-secondary" type="button">
        <font-awesome-icon icon="rotate" spin />
      </button>
      <button
        v-else
        class="btn btn-outline-secondary"
        type="button"
        @click="startSearch(true)"
      >
        <font-awesome-icon icon="search" />
      </button>
    </div>
  </div>

  <div class="additional-search p-3" :class="{ visible: showPopup }">
    <form @submit.prevent="startSearch">
      <div class="row">
        <div v-if="showDate" class="col-12">
          <b-form-group label="Дата работ" prop="date">
            <date-range-picker v-model="dateRange"></date-range-picker>
          </b-form-group>
        </div>

        <div v-if="showDate" class="col-12">
          <b-form-group label="Дата создания лида" prop="date">
            <date-range-picker v-model="createDateRange"></date-range-picker>
          </b-form-group>
        </div>

        <div v-if="showOrderDate" class="col-12">
          <b-form-group label="Оформление сделки" prop="orderDate">
            <date-range-picker v-model="orderDateRange"></date-range-picker>
          </b-form-group>
        </div>
        <div v-if="showProject" class="col-12">
          <b-form-group label="Проект" prop="projectIds">
            <multiply-autocomplete
              ref="projectAutocomplete"
              :get-data="fetchFilterProjects"
              :get-title="getProjectTitle"
              @selected="selectMultipleProjects"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showSource" class="col-12">
          <b-form-group label="Источник" prop="sourceIds">
            <multiply-autocomplete
              ref="sourceAutocomplete"
              :get-data="fetchFilterSources"
              :get-title="getSourceTitle"
              @selected="selectMultipleSources"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showStatus" class="col-12">
          <b-form-group label="Статус" prop="statusIds">
            <multiply-autocomplete
              ref="statusAutocomplete"
              :get-data="getFilterStatuses"
              :get-title="getFilterStatusTitle"
              @selected="selectStatuses"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showAddress" class="col-12">
          <b-form-group label="Адрес">
            <input
              v-model="address"
              type="text"
              prop="address"
              class="form-control"
              placeholder="город, поселок, улица"
            />
          </b-form-group>
        </div>
        <div v-if="showPhone" class="col-6">
          <b-form-group label="Телефон">
            <input
              v-model="phoneNumber"
              type="text"
              prop="phoneNumber"
              class="form-control"
              placeholder="номер телефона"
              aria-label=""
            />
          </b-form-group>
        </div>
        <div v-if="showCardId" class="col-6">
          <b-form-group label="Номер заявки/сделки">
            <input
              v-model="cardId"
              type="text"
              prop="cardId"
              class="form-control"
              placeholder="номер карточки"
              aria-label=""
            />
          </b-form-group>
        </div>
        <div v-if="showTown" class="col-12">
          <b-form-group label="Город" prop="townIds">
            <multiply-autocomplete
              ref="townAutocomplete"
              :get-data="fetchTownData"
              :get-title="getTownTitle"
              @selected="selectMultipleTowns"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showPest" class="col-12">
          <b-form-group label="Вредитель" prop="pestIds">
            <multiply-autocomplete
              ref="pestAutocomplete"
              :get-data="fetchPestData"
              :get-title="getPestTitle"
              @selected="selectMultiplePests"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showFacility" class="col-12">
          <b-form-group label="Место" prop="facilityIds">
            <multiply-autocomplete
              ref="facilityAutocomplete"
              :get-data="fetchFacilityData"
              :get-title="getFacilityTitle"
              @selected="selectMultipleFacilities"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showAssignee" class="col-12">
          <b-form-group label="Мастер" prop="assigneeId">
            <multiply-autocomplete
              ref="assigneeAutocomplete"
              :get-data="fetchWorkerUserData"
              :get-title="getUserName"
              @selected="selectMultipleAssignees"
            ></multiply-autocomplete>
          </b-form-group>

          <b-form-group
            v-if="showAssignee && false"
            label="Мастер"
            prop="assigneeId"
          >
            <user-autocomplete
              v-model="assigneeId"
              :type="TYPE_WORKER"
              :default-value="getUserName(assignee)"
              @selected="selectAssignee"
            ></user-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showManager" class="col-12">
          <b-form-group label="Ответственный" prop="managerId">
            <multiply-autocomplete
              ref="managerAutocomplete"
              :get-data="fetchManagerUserData"
              :get-title="getUserName"
              @selected="selectMultipleManagers"
            ></multiply-autocomplete>

            <user-autocomplete
              v-if="false"
              v-model="managerId"
              :type="(TYPE_MANAGER, TYPE_LOGIST)"
              :default-value="getUserName(manager)"
              @selected="selectManager"
            ></user-autocomplete>
          </b-form-group>
        </div>

        <div v-if="showFirstSecond" class="col-12">
          <b-form-group label="Первичное/вторичное" prop="firstSecond">
            <multiply-autocomplete
              ref="isFirstSecondAutocomplete"
              :get-data="getFirstSecondStatuses"
              :get-title="getFirstSecondStatusTitle"
              @selected="selectFirstSecondStatus"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showClient" class="col-12">
          <b-form-group label="Тип клиента" prop="clientType">
            <multiply-autocomplete
              ref="clientTypeAutocomplete"
              :get-data="getClientTypeTitles"
              :get-title="getClientTypeTitle"
              @selected="selectClientTypeTitle"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showAftercall" class="col-12">
          <b-form-group label="Исход прозвона" prop="aftercallStatus">
            <multiply-autocomplete
              ref="aftercallStatusAutocomplete"
              :get-data="getAfterCallStatuses"
              :get-title="getAftercallStatusTitle"
              @selected="selectAftercallStatus"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showAftercallFail" class="col-12">
          <b-form-group label="Причина провала прозвона" prop="aftercallFail">
            <multiply-autocomplete
              ref="aftercallFailAutocomplete"
              :get-data="getAfterCallFails"
              :get-title="getAftercallFailTitle"
              @selected="selectAftercallFail"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showpaymentTypes" class="col-12">
          <b-form-group label="Тип оплаты" prop="payments">
            <multiply-autocomplete
              ref="paymentsAutocomplete"
              :get-data="getPayments"
              :get-title="getPaymentTitle"
              @selected="selectMultiplePayments"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
        <div v-if="showServiceSum" class="col-12">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Сумма услуг, от" prop="serviceSumFrom">
                <b-form-input v-model="serviceSumFrom" />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Сумма услуг, до" prop="serviceSumTo">
                <b-form-input v-model="serviceSumTo" />
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
