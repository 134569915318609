
<div class="ibox">
  <div class="ibox-title">
    <div class="search">
      <div class="d-flex">
        <div class="mr-2">
          <b-form-group label="Дата от">
            <datepicker
              v-model="search.dateFrom"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              @change="fetchData"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Дата до">
            <datepicker
              v-model="search.dateTo"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              @change="fetchData"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Исполнитель">
            <user-autocomplete
              v-model="search.assigneeId"
              :default-value="
                search.assigneeId
                  ? getAssigneeName(search.assignee, true)
                  : ''
              "
              @selected="selectAssignee"
            />
          </b-form-group>
        </div>
        <div class="align-self-center mt-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;
          </b-button>
        </div>
      </div>
    </div>
  </div>
  <div class="ibox-content">
    <div v-if="loading" class="pl-3 pr-3 pt-2 pb-4">Данные загружаются</div>

    <b-table
      v-else-if="deposits.length"
      responsive
      hover
      condensed
      bordered
      :items="deposits"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(assigneeId)="data">
        {{ getDepositAssigneeName(data.item) }}
      </template>

      <template #cell(time)="data">
        {{ getDepositDateTime(data.item) }}
      </template>

      <template #cell(totalSum)="data">
        {{ getDepositSumFormatted(data.item) }}
      </template>

      <template #cell(status)="data">
        {{ getDepositStatusTitle(data.item) }}
      </template>
    </b-table>

    <div v-else class="pl-3 pr-3 pt-2 pb-4">
      В указанный период инкассаций не было
    </div>
  </div>
  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
