<template>
  <div class="documentType-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link :to="{ name: 'document-type-list' }" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left"/>
            </router-link>
          </div>
          <div>
            <h1>
              {{ documentType.title }}
            </h1>
          </div>

        </div>
      </div>
      <div class="ibox-content">
        <documentType-info :documentType="documentType" v-on:update-prop="updateProp"></documentType-info>
      </div>
    </div>

  </div>

</template>

<script>


import DocumentTypeInfo from "./DocumentTypeInfo";
import {getUserName} from "@/modules/crm/common";

export default {
  name: 'documentType',
  components: {
    'documentType-info': DocumentTypeInfo,
  },
  data() {
    return {
      tabIndex: 0,
      documentType: {
        'id': 'new',
      },
      loading: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    '$route'(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      this.documentType.phone = '';
      let documentTypesUrl = '/papers/types/' + this.id;
      this.loading = true;
      this.$http.get(documentTypesUrl)
          .then(response => {
            this.loading = false;
            this.documentType = response.data;
            this.documentType.managerName = this.documentType.manager.username
            this.header = this.documentType.email;
          })
          .catch(error => {
            this.loading = false;
            this.processRequestError(error)
          })
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = '/papers/types/' + this.id;
      this.loading = true;
      this.$http.put(updateUrl, this.documentType).then(response => {
        this.documentType = response.data;
        this.loading = false;
        this.$eventHub.$emit('update-documentType-info', this.documentType);
        this.$bvToast.toast('Данные сохранены', {
          title: 'Задача отредактирована',
          type: 'success'
        })
      }).catch(error => {
        this.loading = false;
        if (!this.processRequestError(error)) {
          this.$bvToast.toast('Данные не сохранены', {
            title: 'Не удалось сохранить данные',
            type: 'danger'
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>
