
<b-form
  id="pest-resource-form"
  ref="resource"
  :model="resource"
  class="pest-resource-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="resource.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Единица измерения" prop="unit">
          <b-form-input
            v-model="resource.unit"
            placeholder=""
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div v-if="!modal" class="row">
      <div class="col-12">
        <b-form-group label="Связанные материалы" prop="title">
          <div v-if="resource && resource.shopResources" class="mb-2">
            <div
              v-for="(assignedResource, apI) in resource.shopResources"
              :key="apI"
              class="mb-2"
            >
              <div class="btn btn-info btn-xs mr-2">
                {{ assignedResource.title }}
              </div>

              <router-link
                class="btn btn-outline-info btn-xs mr-2"
                target="_blank"
                :to="{
                  name: 'pest-shop-resource-update',
                  params: { id: assignedResource.id },
                }"
              >
                <font-awesome-icon icon="eye" />
              </router-link>
              <button
                class="btn btn-outline-danger btn-xs"
                :disabled="deleting"
                @click="unassignResource(assignedResource, apI)"
              >
                <font-awesome-icon
                  v-if="deleting === apI"
                  icon="rotate"
                  spin
                  fixed-width
                />
                <font-awesome-icon v-else icon="trash" fixed-width />
              </button>
            </div>
          </div>
          <div v-else>Пока нет связанных материалов</div>
        </b-form-group>
        <b-form-group label="Добавить связанный материал" prop="title">
          <multiply-autocomplete
            :get-data="fetchResourceData"
            :get-title="(p) => p.title"
            @selected="selectMultipleResource"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="resource.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div v-if="!fromResults" class="row">
      <div class="col-12">
        <b-form-group label="Видимость" prop="title">
          <b-form-checkbox
            v-model="resource.enabled"
            placeholder="Описание"
            tabindex="1"
            value="1"
          ></b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div v-if="!fromResults" class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="resource.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteResource"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
