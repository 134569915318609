<template>
  <div>
    Сообщение от администрации:
    {{ notification.text }}
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "NotificationUserMessage",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getUserName,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
