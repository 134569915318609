
<b-modal
  id="modal-schedule-record"
  ref="modal"
  :title="modalTitle"
  cancel-title="Отмена"
  ok-title="Сохранить"
  title-tag="h3"
  @ok="handleTaskModalOk"
>
  <b-form-group label="Статус" prop="statu">
    <b-button-group>
      <button
        class="btn"
        :class="getBtnClass(SCHEDULE_ACTIVE_NONE)"
        @click.prevent="setStatus(SCHEDULE_ACTIVE_NONE)"
      >
        Не&nbsp;работает
      </button>
      <button
        class="btn"
        :class="getBtnClass(SCHEDULE_ACTIVE_ACTIVE)"
        @click.prevent="setStatus(SCHEDULE_ACTIVE_ACTIVE)"
      >
        Работает
      </button>
      <button
        class="btn"
        :class="getBtnClass(SCHEDULE_ACTIVE_DUTY_1)"
        @click.prevent="setStatus(SCHEDULE_ACTIVE_DUTY_1)"
      >
        Деж.&nbsp;1
      </button>
      <button
        class="btn"
        :class="getBtnClass(SCHEDULE_ACTIVE_DUTY_2)"
        @click.prevent="setStatus(SCHEDULE_ACTIVE_DUTY_2)"
      >
        Деж.&nbsp;2
      </button>
      <button
        class="btn"
        :class="getBtnClass(SCHEDULE_ACTIVE_DAY_OFF)"
        @click.prevent="setStatus(SCHEDULE_ACTIVE_DAY_OFF)"
      >
        Выходной
      </button>
    </b-button-group>
  </b-form-group>

  <b-form-group label="Комментарий" prop="title">
    <b-form-textarea v-model="comment" tabindex="1"></b-form-textarea>
  </b-form-group>
</b-modal>
