
<div
  v-click-outside="cancelSearch"
  class="autocomplete-wrapper"
  @keydown.esc="cancelSearch"
>
  <div class="input-group">
    <b-form-input
      v-model="search"
      :state="state"
      autocomplete="off"
      @change="startSearch"
      @keyup="startSearch"
      @focus="startSearch"
    ></b-form-input>
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        type="button"
        @click="selectItem(false)"
      >
        <font-awesome-icon icon="times" />
      </button>
    </div>
  </div>

  <div v-if="items && items.length">
    <ul v-if="showResults" class="autocomplete-search-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="autocomplete-search-item"
        @click.prevent="selectItem(item)"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</div>
