
<datepicker
  ref="datepicker"
  v-model="dateRange"
  :input-class="inputClass"
  :editable="true"
  :lang="lang"
  :format="dateFormat"
  :placeholder="placeholder"
  :value-type="valueFormat"
  :formatter="dayjsFormat"
  range
  type="date"
  prefix-class="xmx"
  @change="onChange"
>
  <template #sidebar>
    <ul>
      <li @click.prevent="selectToday"><a href="#"> Сегодня </a></li>
      <li @click.prevent="selectYesterday"><a href="#"> Вчера </a></li>
      <li @click.prevent="selectLastWeek"><a href="#"> Неделя </a></li>
      <li @click.prevent="selectLastMonth"><a href="#"> Месяц </a></li>
      <li @click.prevent="selectLastYear"><a href="#"> Год </a></li>
      <li @click.prevent="selectTillToday"><a href="#"> До сегодня </a></li>
    </ul>
  </template>
</datepicker>
