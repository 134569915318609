
<div class="ibox">
  <div class="ibox-title">
    <div class="search">
      <div class="d-flex align-items-center">
        <h2 class="mr-2">Статистика</h2>
        <div class="mr-2">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>
        <div class="mr-2">
          <b-form-select
            v-model="search.townId"
            :options="townList"
            tabindex="1"
          ></b-form-select>
        </div>
        <!--          <div class="mr-2">-->
        <!--            <b-form-checkbox v-model="search.skipEmpty" tabindex="1">Скрыть пустые</b-form-checkbox>-->
        <!--          </div>-->
        <b-button class="btn btn-info ml-1" @click="fetchData">
          <font-awesome-icon icon="search" />&nbsp;Обновить
        </b-button>
      </div>
    </div>
  </div>
  <div class="ibox-content">
    <div v-if="loading">Данные загружаются</div>
    <div v-else-if="allStats" class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="title-cell"></th>
            <th
              v-for="(dayI, dIndex) in allStats.days"
              :key="'h' + dIndex"
              class="day-cell"
            >
              {{ dayI.day }}
            </th>
            <th>Итого</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(fieldI, fIndex) in fields" :key="'f' + fIndex">
            <th class="label-cell">{{ fieldI.label }}</th>

            <td v-for="(dayI, dIndex) in allStats.days" :key="'h' + dIndex">
              {{ formatNumber(dayI.total[fieldI.key]) }}
            </td>
            <th>
              {{ formatNumber(getTotalRowSum(fieldI.key)) }}
            </th>
          </tr>
        </tbody>

        <tbody
          v-for="(userI, uIndex) in allStats.total.users"
          :key="'u' + uIndex"
        >
          <tr class="delimeter-row">
            <td :colspan="allStats.days.length + 2"></td>
          </tr>

          <tr class="user-header-row">
            <th class="user-header">{{ userI.name }}</th>
            <th
              v-for="(dayI, dIndex) in allStats.days"
              :key="'h' + dIndex"
              :class="userDayClass(dayI.users[uIndex])"
            >
              {{ dayI.day }}
            </th>
            <th>Итого</th>
          </tr>

          <tr v-for="(fieldI, fIndex) in fields" :key="'f' + fIndex">
            <td>{{ fieldI.label }}</td>

            <td v-for="(dayI, dIndex) in allStats.days" :key="'h' + dIndex">
              <span v-if="dayI.users[uIndex][fieldI.key]">
                {{ formatNumber(dayI.users[uIndex][fieldI.key]) }}
              </span>
            </td>
            <th>
              <span v-if="fieldI.avg">
                {{ formatNumber(getUserAvgSum(uIndex, fieldI)) }}
              </span>
              <span v-else>
                {{ formatNumber(getUserRowSum(uIndex, fieldI.key)) }}
              </span>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>Данных нет</div>
  </div>
</div>
