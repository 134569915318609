<template>
  <div class="ibox">
    <div class="ibox-title d-flex pr-2">
      <h4>Синхронизация заказов из 1с</h4>
    </div>
    <div class="ibox-content">
      <b-form
        id="1с-form"
        ref="request"
        class="1с-form"
        novalidate
        @submit.prevent="createOrUpdate('roistat')"
        @reset="onReset"
      >
        <div class="div">
          <b-form-group label="Номер" prop="title">
            <template #label>Данные из 1с</template>
            <b-form-textarea
              v-model="text"
              tabindex="1"
              rows="5"
              required
            ></b-form-textarea>
          </b-form-group>

          <button
            class="btn btn-info btn-xs"
            @click.prevent="showExample = !showExample"
          >
            Пример
            <font-awesome-icon v-if="!showExample" icon="arrow-down" />
            <font-awesome-icon v-else icon="arrow-up" />
          </button>
          <div v-if="!!showExample">
            { "ДатаВыгрузки": "2023-12-21T19:56:57", "МассивОпераций": [ {
            "ВидОперации": "Продажа", "Номер": "00MC-003209", "Дата":
            "2023-12-21T12:45:29", "Клиент": "", "ДисконтнаяКарта": "8771 (Белан
            Алина Игоревна)", "Товары": [ { "Номенклатура": "Аттрактив Бэйт
            шприц 15г", "Упаковка": "шт", "Количество": 1, "Сумма": 449,
            "Артикул": "MC-00000006", "Вес": 15, "ОбъемДАЛ": 0,
            "ДополнительныеРеквизиты": "" } ] } ] }
          </div>
        </div>
      </b-form>
    </div>

    <div class="ibox-footer d-flex">
      <div class=" ">
        <b-button v-if="!saving" variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="paper-plane" />
          Отправить
        </b-button>
      </div>

      <b-button v-if="saving" variant="primary" disabled @click.prevent>
        <font-awesome-icon icon="sync" spin="" />
        Отправляется
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sync1CForm",
  data() {
    return {
      text: "",
      saving: false,
      showExample: false,
    };
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.refreshForm();
  },
  methods: {
    refreshForm() {
      this.text = "";
    },
    create: function () {
      let data = JSON.parse(this.text);
      let updateUrl;
      if (this.saving) {
        this.$bvToast.toast("Данные сохраняются", {
          title: "Подождите",
          variant: "info",
        });
        return;
      }
      this.saving = true;
      updateUrl = "/pest/exchange/orders";
      this.$http
        .post(updateUrl, data)
        .then((response) => {
          this.saving = false;
          this.$bvToast.toast("Запрос отправлен", {
            title: "Успешно",
            variant: "success",
          });
        })
        .catch((error) => {
          this.saving = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Не удалось отправить запрос", {
              title: "Ошибка",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      let form = document.getElementById("1с-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        this.create();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
