
<div class="status-page">
  <h1>{{ header }}</h1>

  <div class="row">
    <div class="col-md-5 col-sm-12">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h2>Параметры</h2>
        </div>

        <div class="ibox-content">
          <status-form
            :status-model="status"
            :data-loaded="!loading"
            :return-url="{ name: 'status-list' }"
            @update-status-info="afterStatusUpdate"
          ></status-form>
        </div>
      </div>
    </div>
  </div>
</div>
