import { apiSetToken } from "@/plugins/api/helpers";
import {
  TYPE_ADMIN,
  TYPE_FINE_MANAGER,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_SHADOW,
} from "../../modules/users/decorators/users.js";

const state = {
  token: null,
};

const getToken = (token) => {
  if (!token) {
    return null;
  }

  return `Bearer ${token}`;
};

const getters = {
  isLogged: (state) => {
    return !!state.token;
  },
  isGuest: (state) => {
    return !state.token;
  },
  isAdmin: (state, getters, rootState) => {
    return [TYPE_ADMIN, TYPE_SHADOW].includes(rootState.user.type);
  },
  isShadow: (state, getters, rootState) => {
    return rootState.user.type === TYPE_SHADOW;
  },
  getHeaders: (state) => {
    if (!state.token) {
      return null;
    }

    return {
      Authorization: `Bearer ${state.token}`,
    };
  },
  getToken: (state) => {
    return getToken(state.token);
  },

  //// Header

  /**
   * Может ли видеть доску распределения
   * @param state
   * @param getters
   * @param rootState
   * @returns {boolean}
   */
  canSeeRequestDispatchingBoard: (state, getters, rootState) => {
    const user = rootState.user;
    return (
      !user.isGuest &&
      [TYPE_SHADOW, TYPE_ADMIN, TYPE_LOGIST, TYPE_FINE_MANAGER].includes(
        user.profile.type
      )
    );
  },

  //// new rules

  canSetClosedSettings: (state, getters, rootState) => {
    return rootState.user.type === TYPE_SHADOW;
  },

  canSeeServiceSections: (state, getters, rootState) => {
    const user = rootState.user;
    return user.type === TYPE_SHADOW || user.access.canSeeTestSetting;
  },

  canSeeClosedReports: (state, getters, rootState) => {
    const user = rootState.user;
    return user.type === TYPE_SHADOW || user.access.canSeeClosedReports;
  },

  shouldAutoShowManagerDashboard: (state, getters, rootState) => {
    const user = rootState.user;
    return user.type === TYPE_MANAGER;
  },
};

const actions = {
  setToken: ({ commit }, token) => {
    commit("setToken", token);
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    apiSetToken(getToken(state.token));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
