
<div
  v-click-outside="cancelSearch"
  class="autocomplete-wrapper tags-input"
  @keydown.esc="cancelSearch"
>
  <div class="ti-input">
    <ul class="ti-tags">
      <li
        v-for="(param, pIndex) in selected"
        :key="'p' + pIndex"
        class="ti-tag"
      >
        <div class="ti-content">
          <div class="ti-tag-center">
            {{ getTitle(param) }}
          </div>
        </div>
        <div class="ti-actions">
          <button
            class="btn-remove"
            type="button"
            @click="removeParam(param, pIndex)"
          >
            <font-awesome-icon icon="times" />
          </button>
        </div>
      </li>
      <li class="ti-new-tag-input-wrapper">
        <input
          v-model="search"
          class="tag-search-field"
          autocomplete="off"
          @change="startSearch"
          @keyup="startSearch"
          @focus="startSearch"
        />
      </li>
      <li v-if="selected.length" class="ti-clear-all">
        <button class="btn-clear-all" type="button" @click="clear(false)">
          <font-awesome-icon icon="times" @click="clear(false)" />
        </button>
      </li>
    </ul>
  </div>
  <div v-if="items && items.length">
    <ul v-if="showResults" class="autocomplete-search-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="autocomplete-search-item"
        @click.prevent="selectItem(item)"
      >
        {{ getTitle(item) }}
      </li>
    </ul>
  </div>
</div>
