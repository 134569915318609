<template>
  <b-form
    v-if="dataLoaded"
    id="request-result-form"
    ref="request"
    :model="request"
    class="result-request-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div mb-2">
      <b-form-group label="Длительность, мин" prop="title">
        <b-form-input
          v-model="request.duration"
          placeholder=""
          tabindex="1"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group label="Стоимость проезда, р" prop="title">
            <b-form-input
              v-model="request.tripSum"
              placeholder="0"
              tabindex="1 required"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Длительность проезда, км" prop="title">
            <b-form-input
              v-model="request.tripDistance"
              placeholder=""
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group
            label="Стоимость проезда после последней заявки, р"
            prop="title"
          >
            <b-form-input
              v-model="request.tripBackSum"
              placeholder="0"
              tabindex="1 required"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            label="Длительность проезда после последней заявки, км"
            prop="title"
          >
            <b-form-input
              v-model="request.tripBackDistance"
              placeholder=""
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <hr />

      <b-form-group
        label="Сумма за оборудование, р"
        prop="equipmentUserSum"
        disabled
      >
        <b-form-input
          v-model="equipmentSum"
          placeholder=""
          tabindex="1"
        ></b-form-input>
        <div v-if="request.equipmentInfo" class="mt-1 font-bold">
          {{ request.equipmentInfo.title }}

          <span
            v-if="
              request.equipmentInfo.paymentType === EQUIPMENT_PAYMENT_PERCENT
            "
          >
            {{ request.equipmentInfo.percent }}% от стомости услуг
          </span>
          <span v-else> {{ request.equipmentInfo.price }}р фиксированно </span>
        </div>
      </b-form-group>

      <hr />

      <h4>Материалы</h4>

      <div v-if="request.resources.length > 0">
        <div
          v-for="(resource, rIndex) in request.resources"
          :key="'r' + rIndex"
          class="d-flex align-items-top"
        >
          <div class="mr-2">
            <b-form-group label="Материал">
              <b-form-select
                v-model="resource.resourceId"
                :options="resourcesList"
                placeholder=""
                class="resource-select"
                tabindex="1"
                required
                onfocus="this.size=5;"
                onblur="this.size=5;"
                onchange="this.size=5; this.blur();"
                onfocusout="this.size=null;"
                @change="setResourceTitle(resource, rIndex)"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Доз.на 1л, мл" prop="title">
              <b-form-input
                v-model="resource.dosage"
                placeholder=""
                type="number"
                tabindex="3"
                required
                step="0.01"
                min="0"
                @change="revertNegative('dosage', rIndex)"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="">
            <b-form-group label="Объем раствора, л">
              <b-form-input
                v-model="resource.solutionVolume"
                placeholder=""
                type="number"
                tabindex="2"
                step="0.01"
                min="0"
                required
                @change="revertNegative('solutionVolume', rIndex)"
              ></b-form-input>
            </b-form-group>

            <button
              v-if="rIndex > 0"
              class="btn btn-danger btn-outline mtfr"
              @click.prevent="removeResource(rIndex)"
            >
              <font-awesome-icon icon="trash" fixed-width />
            </button>
            <button
              v-else
              class="btn btn-primary btn-outline mtfr"
              @click.prevent="addResource"
            >
              <font-awesome-icon icon="plus" fixed-width />
            </button>
          </div>
        </div>
      </div>

      <h4>Услуги</h4>
      <div v-if="request.services.length > 0">
        <div
          v-for="(service, rIndex) in request.services"
          :key="'r' + rIndex"
          class="d-flex align-items-center"
        >
          <div class="">
            <b-form-group label="Услуга" prop="title">
              <b-form-select
                v-model="service.id"
                :options="servicesList"
                placeholder=""
                class="service-select"
                tabindex="1"
                required
                onfocus="this.size=5;"
                onblur="this.size=5;"
                onchange="this.size=5; this.blur();"
                onfocusout="this.size=null;"
                @change="setServiceTitle(service)"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="ml-2">
            <b-form-group label="Цена" prop="title">
              <b-form-input
                v-model="service.price"
                placeholder=""
                class="service-count"
                tabindex="1"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="ml-2 pt-2">
            <button
              v-if="rIndex > 0"
              class="btn btn-danger btn-outline"
              @click.prevent="removeService(rIndex)"
            >
              <font-awesome-icon icon="trash" fixed-width />
            </button>
            <button
              v-else
              class="btn btn-primary btn-outline"
              @click.prevent="addService"
            >
              <font-awesome-icon icon="plus" fixed-width />
            </button>
          </div>
        </div>
      </div>

      <h4>Товары</h4>
      <div v-if="request.products.length > 0">
        <div
          v-for="(product, rIndex) in request.products"
          :key="'r' + rIndex"
          class="d-flex align-items-top product-row"
        >
          <div class="">
            <b-form-group label="Товар" prop="title">
              <b-form-select
                v-model="product.id"
                :options="productList"
                placeholder=""
                class="service-select"
                tabindex="1"
                onfocus="this.size=5;"
                onblur="this.size=5;"
                onchange="this.size=5; this.blur();"
                onfocusout="this.size=null;"
                @change="setProductTitle(product)"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="ml-2">
            <b-form-group label="Цена" prop="title">
              <b-form-input
                v-model="product.price"
                placeholder=""
                class="service-count"
                tabindex="1"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Cебестоимость" prop="title">
              <b-form-input
                v-model="product.cost"
                placeholder=""
                class="service-count"
                tabindex="1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="ml-2 pt-2">
            <button
              v-if="rIndex > 0"
              class="btn btn-danger btn-outline"
              @click.prevent="removeProduct(rIndex)"
            >
              <font-awesome-icon icon="trash" fixed-width />
            </button>
            <button
              v-else
              class="btn btn-primary btn-outline"
              @click.prevent="addProduct"
            >
              <font-awesome-icon icon="plus" fixed-width />
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <b-form-group label="Повтор" prop="title">
          <div class="mr-2 mb-1">
            <b-button-group>
              <button
                class="btn"
                :class="
                  !request.repeatAppointed ? 'btn-danger' : 'btn-outline-info'
                "
                @click.prevent="setRepeat(0)"
              >
                <font-awesome-icon icon="times" />
                Не назначен
              </button>
              <button
                class="btn"
                :class="
                  request.repeatAppointed ? 'btn-primary' : 'btn-outline-info'
                "
                @click.prevent="setRepeat(1)"
              >
                <font-awesome-icon icon="check" />
                Назначен
              </button>
            </b-button-group>
          </div>
        </b-form-group>
        <div v-if="request.repeatAppointed" class="mr-2">
          <b-form-group label="Дата" prop="title">
            <datepicker
              v-model="request.repeatDate"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
            ></datepicker>
          </b-form-group>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="mr-2">
          <b-form-group label="Документы">
            <file-upload-field
              label="Документы"
              :upload-url="fileUploadUrl"
              @file-uploaded="fileUploaded"
            />
          </b-form-group>
        </div>
      </div>
      <ul class="files-block">
        <li v-for="(file, fIndex) in request.resultFiles" :key="'i' + fIndex">
          <div class="d-flex">
            <a
              v-b-tooltip.hover
              :href="file.url"
              :title="file.name"
              target="_blank"
            >
              {{ file.name + "." + file.extension }}
            </a>

            <div v-if="canDeleteResultFiles" class="ml-auto">
              <font-awesome-icon
                icon="trash"
                @click="deleteResultFile(fIndex, true)"
              />
            </div>
          </div>
        </li>
        <li v-for="(file, dIndex) in newDocuments" :key="'n' + dIndex">
          <div class="d-flex">
            <a
              v-b-tooltip.hover
              :href="file.url"
              :title="file.name"
              target="_blank"
            >
              {{ file.name + "." + file.extension }}
            </a>

            <div class="ml-auto">
              <font-awesome-icon icon="trash" @click="deleteDocument(dIndex)" />
            </div>
          </div>
        </li>
      </ul>
      <hr />
      <h3>
        Сумма: {{ totalSum(request) }} р | Доход: {{ earnTotalSum(request) }} |
        На руки: {{ calcTotalProfitSum(request) }} |

        <span v-if="request.fromExchange"
          >Заявка из биржи, рекорд не считается</span
        >
        <span v-else-if="recordDiffSum > 0"
          >До рекорда: {{ recordDiffSum }}</span
        >
        <span v-else>Рекорд<font-awesome-icon icon="star" /></span>
      </h3>
      <hr />

      <b-form-group label="Описание" prop="title">
        <b-form-textarea
          v-model="request.assigneeComment"
          placeholder="Комментарий"
          tabindex="1"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-button
        variant="success"
        :disabled="loading"
        @click="createOrUpdate('ok')"
      >
        <font-awesome-icon icon="check" />
        Закрыть заявку
      </b-button>

      <b-button
        v-if="canDecline"
        variant="danger"
        class="ml-2"
        @click="createOrUpdate('decline')"
      >
        <font-awesome-icon icon="exclamation-triangle" />
        Заявка отменилась
      </b-button>

      <b-button
        v-if="id !== 'new'"
        variant="outline-info"
        class="ml-2"
        @click="cancelUpdate"
      >
        <font-awesome-icon icon="times" />
        Отмена редактирования
      </b-button>

      <div v-if="false">
        <router-link
          :to="{ name: 'request-list' }"
          class="btn btn-default ml-1 mr-1"
        >
          <font-awesome-icon icon="arrow-left" />
          Назад к списку
        </router-link>

        <b-button v-if="!request.archived" variant="danger" @click="archive">
          <font-awesome-icon icon="trash" />
          В архив
        </b-button>
        <b-button v-else variant="danger" @click="unarchive">
          <!--                    <font-awesome-icon icon="archive"/>-->
          Убрать из архива
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { getUserName } from "../../../../crm/common.js";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import {
  calcEquipmentSum,
  calcProfitSum,
  calcTotalProfitSum,
  calcNetProfit,
  earnSum,
  earnTotalSum,
  recordDiff,
  recordTotalDiff,
  recordString,
  servicesSum,
  totalSum,
  STATUS_DECLINE,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests.js";
import { EQUIPMENT_PAYMENT_PERCENT } from "@/modules/pest/decorators/equipments.js";
import DatePicker from "vue2-datepicker";
import {
  canDeclineRequests,
  canDeleteResultFiles,
  canSaveResultWithoutDocuments,
} from "@/modules/users/access/common";
import FileUploadField from "@/components/shared/FileUploadField.vue";
import clone from "just-clone";

export default {
  name: "RequestResultForm",
  directives: { mask: VueMaskDirective },
  components: {
    Datepicker: DatePicker,
    FileUploadField,
  },
  props: ["requestModel", "dataLoaded"],
  data() {
    return {
      EQUIPMENT_PAYMENT_PERCENT,
      dateFormat: "DD.MM.YYYY",
      timeFormat: "HH:MM",
      labelWidth: "150px",
      loading: false,
      statusesList: [],
      resourcesList: [],
      servicesList: [],
      productList: [],
      resources: [],
      request: {},
      newDocuments: [],
    };
  },
  computed: {
    clientName() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    managerName() {
      return this.request && this.request.managerId && this.request.manager
        ? getUserName(this.request.manager)
        : "";
    },
    assigneeName() {
      return this.request && this.request.assigneeId && this.request.assignee
        ? getUserName(this.request.assignee)
        : "";
    },
    id() {
      return this.request.id;
    },
    equipmentSum() {
      return this.calcEquipmentSum(this.request);
    },
    name() {
      let name = this.request.name ? this.request.name : "Новая дело";
      return name ? name : "Дело";
    },
    recordDiffSum() {
      return recordTotalDiff(this.request, this.request.recordSum);
    },
    canDecline() {
      return canDeclineRequests(this.$store.state.user);
    },
    canDeleteResultFiles() {
      return canDeleteResultFiles(this.$store.state.user);
    },
    canSaveResultWithoutDocuments() {
      return canSaveResultWithoutDocuments(this.$store.state.user);
    },
    fileUploadUrl() {
      return "/pest/request/result-file?requestId=" + this.request.id;
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = clone(obj);
      },
    },
  },
  created() {
    this.fetchStatuses();
    this.fetchServices();
    this.fetchProducts();
    this.fetchResources();
  },
  methods: {
    earnSum,
    earnTotalSum,
    calcEquipmentSum,
    calcProfitSum,
    calcTotalProfitSum,
    calcNetProfit,
    servicesSum,
    totalSum,
    getClientName,
    recordString,
    recordDiff,
    getUserName,
    getStatusTitle,
    getClientNameWithContacts,
    fileUploaded({ file }) {
      this.newDocuments.push(file);
    },
    revertNegative(value, index) {
      if (this.request.resources[index][value] < 0)
        this.request.resources[index][value] =
          this.request.resources[index][value] * -1;
    },
    validateForm(form) {
      let documentsCount = this.request.resultFiles.concat(
        this.newDocuments
      ).length;
      if (!documentsCount && !this.canSaveResultWithoutDocuments) {
        this.$bvToast.toast("Загрузите фото договора", {
          variant: "danger",
        });
      }
      return (
        (documentsCount || this.canSaveResultWithoutDocuments) &&
        form.checkValidity()
      );
    },
    deleteResultFile(fileIndex) {
      this.request.resultFiles.splice(fileIndex, 1);
    },
    deleteDocument(index) {
      this.newDocuments.splice(index, 1);
    },
    clientNameF() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/request/result/" + this.id;
      if (this.loading) {
        return;
      }

      this.request.resultFilesIds = this.request.resultFiles
        .concat(this.newDocuments)
        .map((f) => f["id"]);

      this.loading = true;
      this.request.equipmentSum = this.equipmentSum;
      console.log(this.request);
      this.$http
        .put(updateUrl, this.request)
        .then((response) => {
          this.request = response.data;
          this.newDocuments = [];
          this.loading = false;
          this.$emit("update-request-info", this.request);
          // this.$router.push({name: 'request-view', params: {id: this.request.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Заявка закрыта",
            request: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось отредактировать дело",
              request: "danger",
            });
          }
        });
    },
    fetchResources: function () {
      this.resourcesList = [];
      let resourcesUrl = "/pest/resources";
      let params = {
        all: true,
        onlyEnabled: true,
      };
      this.$http
        .get(resourcesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.resourcesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
          this.resourcesList.unshift({
            value: "new",
            text: "Добавить новый препарат",
          });
          for (let item of this.request.resources) {
            this.resourcesList.push({
              value: item.resource.id,
              text: this.getStatusTitle(item.resource),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchServices: function () {
      this.servicesList = [];
      let servicesUrl = "/pest/services";
      let params = {
        all: true,
      };
      this.$http
        .get(servicesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.servicesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchProducts: function () {
      this.productList = [];
      let productUrl = "/pest/products";
      let params = {
        all: true,
      };
      this.$http
        .get(productUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.productList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchStatuses: function () {
      this.statusesList = [];
      // this.statusesList.push({value: '', text: 'Все'});
      let statusesUrl = "/crm/statuses";
      let params = {
        page: this.page,
      };
      this.$http
        .get(statusesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.statusesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },

    createOrUpdate: function (status) {
      if (status === "decline") {
        this.request.status = STATUS_DECLINE;
      } else {
        this.request.status = STATUS_DONE;
      }
      let form = document.getElementById("request-result-form");
      let valid = this.validateForm(form);
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        this.update();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },

    handleClientSubmit(result) {
      this.request.clientId = result.id;
    },
    getResultClientAutocompleteValue(client) {
      return this.getClientNameWithContacts(client);
    },
    searchClient(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/crm/clients/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    handleGroupSubmit(result) {
      this.request.groupId = result.id;
    },
    getResultGroupAutocompleteValue(group) {
      return this.getGroupTitle(group);
    },
    searchGroup(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let groupsUrl = "/law/group/search-groups";
        this.loading = true;
        this.$http
          .get(groupsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    handleManagerSubmit(result) {
      this.request.managerId = result.id;
    },
    getResultManagerAutocompleteValue(manager) {
      return this.getUserName(manager);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    handleAssigneeSubmit(result) {
      this.request.assigneeId = result.id;
    },
    getResultAssigneeAutocompleteValue(assignee) {
      return this.getUserName(assignee);
    },
    searchAssignee(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    setResourceTitle(resource, rIndex) {
      if (resource.resourceId === "new") {
        this.$emit("open-resource-popup-form", rIndex);
        return false;
      }
    },
    removeResource(index) {
      this.request.resources.splice(index, 1);
    },
    addResource() {
      let blankResource = {
        resourceId: "",
        title: "",
        count: "",
      };
      this.request.resources.push(blankResource);
    },
    setServiceTitle(service) {
      let i = this.servicesList
        .map(function (e) {
          return e.value;
        })
        .indexOf(service.id);
      service.title = this.servicesList[i].text;
    },
    setProductTitle(product) {
      let i = this.productList
        .map(function (e) {
          return e.value;
        })
        .indexOf(product.id);
      product.title = this.productList[i].text;
    },
    removeService(index) {
      this.request.services.splice(index, 1);
    },
    removeProduct(index) {
      this.request.products.splice(index, 1);
    },
    addProduct() {
      let blankProduct = {
        id: "",
        title: "",
        count: "",
      };
      this.request.products.push(blankProduct);
    },
    addService() {
      let blankService = {
        serviceId: "",
        title: "",
        count: "",
      };
      this.request.services.push(blankService);
    },
    setRepeat(status) {
      this.request.repeatAppointed = status;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.resource-select {
  min-width: 150px;
}

.resource-count {
  width: 50px;
}

.service-select {
  width: 180px !important;
}

.service-count {
  width: 50px;
}
.product-row button {
  margin-top: 19px;
}
.mtfr {
  margin-top: 28px;
  float: right;
}
</style>
