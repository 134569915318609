<template>

  <div class="div">
    <inline-field label="Название" type="input" attr="title" :value="documentType.title"
                  v-on:update-prop="updateProp"></inline-field>
    <inline-field label="Описание" type="input" attr="comment" :value="documentType.comment"
                  v-on:update-prop="updateProp"></inline-field>
  </div>

</template>

<script>

import {VueMaskDirective} from 'v-mask'
import InlineField from "@/components/shared/InlineField";

export default {
  directives: {mask: VueMaskDirective},
  name: 'documentType-form',
  components: {
    'inline-field': InlineField
  },
  props: [
    'documentType'
  ],
  data() {
    return {}
  },
  computed: {
    id() {
      return this.documentType.id;
    },
  },
  created: function () {
  },
  watch: {},
  methods: {
    updateProp(e) {
      this.documentType[e.attr] = e.value;
      this.$emit('update-prop', e);
    },
  }
}
</script>

<style lang="scss">
</style>
