
<a
  v-b-tooltip.hover="tooltip"
  class="title-link"
  :title="tooltip"
  @click.prevent="changeSort"
>
  <span class="title-link-text"
    ><slot>{{ title }}</slot></span
  >&nbsp;
  <span class="title-link-icon">
    <font-awesome-icon v-if="value === param" class="icon" icon="sort-up" />
    <font-awesome-icon
      v-else-if="value === '-' + param"
      class="icon"
      icon="sort-down"
    />
    <font-awesome-icon v-else class="icon inactive" icon="sort" />
  </span>
</a>
