
<b-form
  id="pest-pest-form"
  ref="pest"
  :model="pest"
  class="pest-pest-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="pest.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="title">
          <b-form-textarea
            v-model="pest.comment"
            placeholder="Описание"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group label="Ставка" prop="percentType">
          <b-button-group>
            <button
              class="btn"
              :class="getPercentClass(PERCENT_TYPE_DEFAULT)"
              @click.prevent="selectPercent(PERCENT_TYPE_DEFAULT)"
            >
              По-умолчанию
            </button>
            <button
              class="btn"
              :class="getPercentClass(PERCENT_TYPE_MANUAL)"
              @click.prevent="selectPercent(PERCENT_TYPE_MANUAL)"
            >
              Вручную
            </button>
          </b-button-group>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <b-form-group label="Обычная ставка" prop="percentRegular">
          <b-form-input
            v-model="pest.percentRegular"
            :disabled="pest.percentType !== PERCENT_TYPE_MANUAL"
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Рекордная ставка" prop="percentRecord">
          <b-form-input
            v-model="pest.percentRecord"
            :disabled="pest.percentType !== PERCENT_TYPE_MANUAL"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Цвет" prop="title">
          <b-form-input
            v-model="pest.color"
            type="color"
            placeholder="Цвет"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Тип" prop="percentType">
          <b-button-group>
            <button
              class="btn"
              :class="getServiceClass(SERVICE_DEFAULT)"
              @click.prevent="selectService(SERVICE_DEFAULT)"
            >
              Обычный
            </button>
            <button
              class="btn"
              :class="getServiceClass(SERVICE_DOUBLE)"
              @click.prevent="selectService(SERVICE_DOUBLE)"
            >
              Сервисный (скрыть от всех)
            </button>
          </b-button-group>
        </b-form-group>

        <b-form-group
          v-if="pest.service === SERVICE_DOUBLE"
          label="Тип вредителя(несколько)"
          prop="title"
        >
          <multiply-autocomplete
            ref="pestAutocomplete"
            :get-data="fetchPestData"
            :get-title="getPestTitle"
            @selected="selectMultiplePest"
          ></multiply-autocomplete>
          <div v-if="pest.pestIds.length">
            Выбранные: {{ getPestsDoubleNames(pest) }}<br />
            <a @click.prevent="clearPests">Очистить</a>
          </div>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Сортировка" prop="order">
          <b-form-input
            v-model="pest.order"
            placeholder="порядок сортировки"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>

        <a
          v-if="pest.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deletePest"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
