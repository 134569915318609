import { getTownTitle } from "@/modules/pest/decorators/towns";

const getPlaceTitle = function (place) {
  if (!place || !place.title) {
    return "[Не назначен]";
  }
  return place.title;
};
const getPlaceTownTitle = function (place) {
  if (!place || !place.town) {
    return "[Не назначен]";
  }
  return getTownTitle(place.town);
};

export { getPlaceTitle, getPlaceTownTitle };
