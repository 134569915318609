<template>
  <tr :class="rowClass">
    <slot name="title"></slot>
    <td
      v-for="(param, pIndex) in CPOParams"
      :key="getKey(pIndex)"
      :style="cellStyleComputed(param, pestInfo, pIndex)"
    >
      <div class="d-flex flex-nowrap">
        <div class="mr-2">
          <slot :name="[param.attr]">
            <font-awesome-icon
              v-if="param.attr === 'failedCount'"
              icon="circle"
              :style="{
                color: getCircleColor(
                  param.val(pestInfo, sourceInfo, assigneeInfo)
                ),
              }"
            />
            {{ formatNumber(param.val(pestInfo, sourceInfo, assigneeInfo)) }}
          </slot>
        </div>
        <div
          v-if="showChartIcon(param, pestInfo, sourceInfo, assigneeInfo)"
          class="ml-auto icon-block"
          :class="{ 'show-chart': chartShown(param) }"
        >
          <a href="#" @click.prevent="toggleChart(param)">
            <font-awesome-icon icon="chart-area" />
          </a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { formatNumber } from "@/modules/pest/decorators/common";
import {
  CPOParams,
  getConversionToSecondary,
} from "@/modules/pest/decorators/cpo";

export default {
  name: "CPOTableRow",
  components: {},
  data() {
    return { test: 1, CPOParams };
  },
  props: {
    pestInfo: { default: () => null, type: Object },
    sourceInfo: { default: () => null, type: Object },
    assigneeInfo: { default: () => null, type: Object },
    rowClass: { default: () => null, type: String },
    cellClass: { default: () => null, type: Object },
    cellStyle: { default: () => null, type: Function },
  },
  computed: {},
  created: function () {},
  methods: {
    getKey(pIndex) {
      if (this.assigneeInfo) {
        return "tdA" + pIndex;
      }
      if (this.sourceInfo) {
        return "tdS" + pIndex;
      }
      return "tdP" + pIndex;
    },
    getCircleColor(count) {
      if (count >= 2) {
        return "#ED5565";
      }
      if (count > 0) {
        return "#f8ac59";
      }
      return "#1ab394";
    },
    formatNumber,
    getConversionToSecondary,
    cellStyleComputed(param, info, pIndex) {
      if (this.cellStyle) {
        return this.cellStyle(param, info);
      }
      return null;
    },
    chartShown(param) {
      if (this.assigneeInfo) {
        return this.assigneeInfo.showChart === param.attr;
      } else if (this.sourceInfo) {
        return this.sourceInfo.showChart === param.attr;
      } else {
        return this.pestInfo.showChart === param.attr;
      }
    },
    toggleChart(param) {
      this.$eventHub.$emit("clear-chart");
      let block = false;
      let data = false;
      let off = false;
      if (this.assigneeInfo) {
        block = "assignee";
        data = this.assigneeInfo;
        if (!this.assigneeInfo.assigneeId) {
          this.assigneeInfo.assigneeId = 0;
        }
        if (this.assigneeInfo.showChart === param.attr) {
          this.assigneeInfo.showChart = false;
          off = true;
        } else {
          this.assigneeInfo.showChart = param.attr;
          this.sourceInfo.showChart = false;
          this.pestInfo.showChart = false;
        }
      } else if (this.sourceInfo) {
        block = "source";
        data = this.sourceInfo;
        if (this.sourceInfo.showChart === param.attr) {
          this.sourceInfo.showChart = false;
          off = true;
        } else {
          this.sourceInfo.showChart = param.attr;
          this.pestInfo.showChart = false;
        }
      } else {
        block = "pest";
        data = this.pestInfo;
        if (this.pestInfo.showChart === param.attr) {
          this.pestInfo.showChart = false;
          off = true;
        } else {
          this.pestInfo.showChart = param.attr;
        }
      }
      this.$eventHub.$emit("toggle-chart", {
        block: block,
        off: off,
        param: param,
        assigneeInfo: this.assigneeInfo,
        sourceInfo: this.sourceInfo,
        pestInfo: this.pestInfo,
      });
      this.$forceUpdate();
    },
    showChartIcon(param, pestInfo, sourceInfo, assigneeInfo) {
      if (!["cpoDirect", "cpl"].includes(param.attr)) return true;
      return !assigneeInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-block a {
  color: #e6e6e6;
  //opacity: 0.2;
}

td:hover a {
  //opacity: 1;
  color: $inspinia-primary-light !important;
}

.icon-block.show-chart a {
  //opacity: 1;
  color: $inspinia-primary !important;
}
</style>
