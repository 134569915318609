
<b-form
  id="pest-payment-form"
  ref="payment"
  :model="payment"
  class="pest-payment-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <div class="d-flex align-items-center">
              <h1>Новый платеж</h1>
              <b-button
                variant="primary"
                class="ml-5"
                @click="createOrUpdate"
              >
                <font-awesome-icon icon="check" />
                <span v-if="payment.id === 'new'">
                  Добавить новый платеж
                </span>
                <span v-else> Сохранить изменения </span>
              </b-button>

              <a
                v-if="payment.id !== 'new'"
                href=""
                class="btn btn-outline btn-danger ml-2"
                @click.prevent="deletePayment"
              >
                <font-awesome-icon icon="trash" />
                Удалить
              </a>
            </div>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-12">
                <b-form-group label="Сотрудник" prop="employeeId">
                  <user-autocomplete
                    v-model="payment.employeeId"
                    :default-value="getEmployeeName(payment.employee)"
                    @selected="selectManager"
                  />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Описание" prop="sum">
                  <b-form-textarea
                    v-model="payment.comment"
                    placeholder="Описание"
                    tabindex="1"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Сумма" prop="sum">
                  <b-form-input
                    v-model="payment.sum"
                    placeholder="Сумма"
                    tabindex="1"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="ibox-footer">
            <div class="row">
              <div class="col-12">
                <h3>
                  Заявки
                  <small>
                    <span v-if="requestLoading">
                      <font-awesome-icon icon="rotate" spin />
                    </span>
                    <span v-else>{{ requests.length }}</span>
                    |
                    <span
                      >Заработано:
                      <strong>{{ formatNumber(profit) }}</strong></span
                    >
                    |
                    <span
                      >Штрафы:
                      <strong>{{ formatNumber(fineSum) }}</strong></span
                    >
                    |
                    <span
                      >К оплате:
                      <strong>{{ formatNumber(toPayCounted) }}</strong></span
                    >
                  </small>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-8">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h2>Заявки</h2>
          </div>
          <div class="ibox-content">
            <div v-if="!requestLoading">
              <div class="">
                <!--            <h3> {{ daysRequest.date }}</h3>-->
                <table class="table table-condensed">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Дата</th>
                      <th>Номер</th>
                      <th>Клиент</th>
                      <th>Услуги и продажи</th>
                      <th>Заработано</th>
                      <th>Транспорт</th>
                      <th>Оборудование</th>
                      <th>Себестоимость</th>
                      <th>Депозит</th>
                      <th>К выплате</th>
                    </tr>
                  </thead>

                  <tbody
                    v-for="(daysRequest, index) in requestsByDays"
                    :key="index"
                  >
                    <tr class="">
                      <th>
                        <b-form-checkbox
                          v-if="daysRequest.requests.length"
                          v-model="daysRequest.checked"
                          @change="toggleAllDaysRequests(daysRequest)"
                        >
                        </b-form-checkbox>
                      </th>
                      <th colspan="3">{{ daysRequest.dateF }}</th>
                      <th>
                        {{
                          formatNumber(getDayTotalSum(daysRequest.requests))
                        }}
                        <!--                    Услуги-->
                      </th>
                      <th>
                        {{ formatNumber(getDayEarned(daysRequest.requests)) }}
                        <!--                    Заработано-->
                      </th>
                      <th>
                        {{ formatNumber(getTripSum(daysRequest.requests)) }}
                        <!--                    Транспорт-->
                      </th>
                      <th>
                        {{
                          formatNumber(
                            getDayEquipmentSum(daysRequest.requests)
                          )
                        }}
                        <!--                    Оборудование-->
                      </th>
                      <th>
                        {{
                          formatNumber(
                            getDayProductsNetCostSum(daysRequest.requests)
                          )
                        }}
                        <!-- Cебестоимость товаров -->
                      </th>
                      <th>
                        {{ formatNumber(getDayPledgeSum(daysRequest.date)) }}
                        <!--                    Депозит-->
                      </th>
                      <th>
                        {{ formatNumber(getToPaySum(daysRequest)) }}
                        <!--                    К выплате-->
                      </th>
                    </tr>

                    <tr
                      v-for="(request, rIndex) in daysRequest.requests"
                      :key="'r' + rIndex"
                    >
                      <td>
                        <b-form-checkbox
                          v-model="request.checked"
                          @change="toggleDaysRequest(daysRequest, request)"
                        >
                        </b-form-checkbox>
                      </td>
                      <td>
                        {{ getRequestTime(request) }}
                      </td>
                      <td>
                        <router-link
                          v-if="request.clientId"
                          :to="{
                            name: 'pest-request-view',
                            params: { id: request.id },
                          }"
                          class=""
                        >
                          {{ getRequestNumber(request) }}
                        </router-link>
                      </td>
                      <td>
                        <font-awesome-icon
                          v-if="request.isOrganization"
                          icon="building"
                          fixed-width
                          class="mr-1"
                        />

                        <font-awesome-icon
                          v-else
                          icon="user"
                          fixed-width
                          class="mr-1"
                        />
                        {{ getRequestClientName(request) }}
                      </td>
                      <td>
                        {{ formatNumber(totalSum(request)) }}
                      </td>
                      <td>
                        <span v-if="request.earned">
                          {{ formatNumber(request.earned) }}
                        </span>
                        <span v-if="request.hasRecord">
                          <font-awesome-icon
                            v-b-tooltip.hover
                            icon="star"
                            title="Рекорд"
                          />
                        </span>
                      </td>

                      <td>
                        {{
                          formatNumber(request.tripSum + request.tripBackSum)
                        }}
                      </td>
                      <td>
                        {{
                          formatNumber(
                            request.equipmentSum
                              ? request.equipmentSum
                              : request.equipmentUserSum
                          )
                        }}
                      </td>
                      <td>
                        {{ formatNumber(productNetCost(request)) }}
                      </td>
                      <td></td>
                      <td>
                        {{ formatNumber(paymentSum(request)) }}

                        <span v-if="request.isCashless"> (Безнал) </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h2>Штрафы</h2>
          </div>
          <div class="ibox-content">
            <div v-if="!requestLoading">
              <div class="">
                <!--            <h3> {{ daysFine.date }}</h3>-->
                <table class="table table-condensed">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Дата</th>
                      <th>Штраф</th>
                      <th>Сумма</th>
                      <th>
                        Кто назначил <br />
                        Комментарий
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    v-for="(daysFine, index) in finesByDays"
                    :key="index"
                  >
                    <tr
                      v-for="(fine, rIndex) in daysFine.fines"
                      :key="'r' + rIndex"
                    >
                      <td>
                        <b-form-checkbox
                          v-model="fine.checked"
                          @change="toggleDaysFine(daysFine, fine)"
                        >
                        </b-form-checkbox>
                      </td>
                      <td>
                        {{ getFineTime(fine) }}
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'pest-fine-update',
                            params: { id: fine.id },
                          }"
                          class=""
                        >
                          {{ fine.comment }}
                        </router-link>
                      </td>
                      <td>
                        {{ formatNumber(fine.sum) }}
                      </td>
                      <td>
                        {{ getUserName(fine.manager) }} <br />
                        {{ fine.comment }}
                        {{ fine.review }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</b-form>
