
<div>
  <div v-if="client">
    <router-link
      :to="{ name: 'crm-client-view', params: { id: client.id } }"
      class=""
    >
      <font-awesome-icon
        v-if="client.type == c_TYPE_ORGANIZATION"
        icon="building"
      />
      <font-awesome-icon v-else icon="user" />
      {{ getClientName(client) }}
    </router-link>

    <b-button
      v-if="showButtons"
      class=""
      size="xs"
      variant="primary"
      @click="clearClient()"
    >
      <font-awesome-icon fixed-width icon="trash" />
    </b-button>
  </div>

  <div v-else>
    <div v-if="editing">
      <b-form-group label="Клиент" prop="clientId">
        <autocomplete
          ref="searchClient"
          :search="searchClient"
          class=""
          :default-value="clientName"
          placeholder="Поиск по клиентам"
          aria-label="Поиск по клиентам"
          :get-result-value="getResultClientAutocompleteValue"
          @submit="handleClientSelect"
        ></autocomplete>
      </b-form-group>

      <b-button class="" variant="primary" @click="confirmEditing">
        <font-awesome-icon fixed-width icon="check" />
      </b-button>

      <b-button class="ml-2" variant="default" @click="cancelEditing">
        <font-awesome-icon fixed-width icon="times" />
      </b-button>
    </div>

    <div v-else>
      <b-button
        v-if="showButtons"
        class=""
        variant="primary"
        @click="startEditing()"
      >
        <font-awesome-icon fixed-width icon="plus" />
        Привязать клиента
      </b-button>
    </div>
  </div>
</div>
