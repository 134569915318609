
<div class="pest-shop-resource-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>Новый товар</h1>
        </div>
        <div class="ibox-content">
          <pest-shop-resource-form
            :shop-resource="shopResources"
          ></pest-shop-resource-form>
        </div>
      </div>
    </div>
  </div>
</div>
