
<b-navbar
  :fixed="getFixed"
  toggleable="lg"
  type="dark"
  variant="dark"
  class="top-menu mb-3 py-0"
>
  <b-navbar-brand v-if="!isWorker" href="#">CRM</b-navbar-brand>
  <div class="mobile-page-title d-sm-block d-md-none">{{ curPageTitle }}</div>
  <b-navbar-nav
    v-if="logged && canSeeOwnNotifications"
    class="ml-auto d-md-none"
  >
    <b-nav-item
      v-if="canSeeOwnNotifications"
      :active="
        isMenuItemActive('notifications-notification-user-list') ||
        unreadNotificationCount > 0
      "
      :to="{ name: 'notifications-notification-user-list' }"
      @click="setMenuItem('notifications-notification-user-list')"
    >
      <font-awesome-icon icon="bell" />
      <span
        v-if="unreadNotificationCount > 0"
        class="label label-primary ml-2"
        >{{ unreadNotificationCount }}</span
      >
    </b-nav-item>
  </b-navbar-nav>
  <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
  <hr class="d-lg-none w-100 my-0" />

  <b-collapse v-if="logged" id="nav-collapse" is-nav class="metismenu">
    <b-navbar-nav>
      <!--        <b-nav-item @click="setMenuItem('dashboard')"
                          :active="isMenuItemActive('dashboard')"
                          :to="{ name: 'crm-client-list' }">Главная</b-nav-item>-->
      <template v-if="canSeeKanban">
        <b-nav-item
          v-if="canSeeKanban"
          :active="isMenuItemActive('pest-request-board-global')"
          :to="{
            name: 'pest-request-board-global',
            params: { global: true },
          }"
          @click="setMenuItem('pest-request-board-global')"
          >КАНБАН
        </b-nav-item>
      </template>
      <template v-if="!canSeeAllKanbans && canSeeKanban && false">
        <b-nav-item
          v-if="canSeeKanban"
          :active="isMenuItemActive('pest-request-board')"
          :to="{
            name: 'pest-request-board',
            params: { project: getUserProject },
          }"
          @click="setMenuItem('pest-request-board')"
          >КАНБАН
        </b-nav-item>
      </template>
      <template v-if="canSeeAllKanbans && canSeeKanban && false">
        <b-nav-item-dropdown>
          <template #button-content>КАНБАН</template>
          <template v-for="(project, pIndex) in projects">
            <b-dropdown-item
              :key="pIndex"
              :to="{
                name: 'pest-request-board',
                params: { project: project.id },
              }"
              @click="setMenuItem('pest-request-board')"
            >
              {{ project.title }}
            </b-dropdown-item>
          </template>
        </b-nav-item-dropdown>
      </template>

      <b-nav-item-dropdown
        v-if="
          canSeeRequestDispatchingBoard ||
          canSeeRequestMap ||
          canSeeSchedule ||
          canSeeSchedule
        "
        right
        :active="
          isSubmenuItemActive([
            'request-dispatching-board',
            'pest-request-map',
            'pest-time-busyness',
            'pest-assignee-day-schedule',
          ])
        "
      >
        <template #button-content> ЛОГИСТИКА</template>
        <b-dropdown-item
          v-if="canSeeRequestDispatchingBoard"
          :to="{ name: 'request-dispatching-board' }"
          @click="setMenuItem('request-dispatching-board')"
          >Распределение
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeRequestMap && false"
          :to="{ name: 'pest-request-map' }"
          @click="setMenuItem('pest-request-map')"
          >Карта
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeSchedule"
          :to="{ name: 'pest-time-busyness' }"
          @click="setMenuItem('pest-time-busyness')"
          >Нагрузка
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeSchedule"
          :to="{ name: 'pest-assignee-day-schedule' }"
          @click="setMenuItem('pest-assignee-day-schedule')"
          >Расписание на день
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown v-if="canSeeSchedule" right>
        <!-- Using 'button-content' slot -->
        <template #button-content> ГРАФИК РАБОТ</template>
        <b-dropdown-item :to="{ name: 'pest-assignee-schedule' }"
          >График мастеров
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item
        v-if="canSeeRequestList && isWorker"
        :active="isMenuItemActive('pest-employee-current-request-list')"
        :to="{ name: 'pest-employee-current-request-list' }"
        @click="setMenuItem('pest-employee-current-request-list')"
        >Заявки в работе
      </b-nav-item>

      <b-nav-item-dropdown
        v-if="!isWorker && (canSeeFines || canSeePayments)"
        right
      >
        <template #button-content> ФИНАНСЫ</template>
        <b-dropdown-item v-if="canSeeFines" :to="{ name: 'pest-fine-list' }"
          >Штрафы
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeePayments"
          :to="{ name: 'pest-settlements' }"
          >Взаиморасчеты
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeFines"
          :to="{ name: 'pest-penalty-list' }"
          >Перечень штрафов
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeDeposits"
          :to="{ name: 'pest-deposit-list' }"
          >Инкассации
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown
        v-if="canSeePayments || canSeeSettings || canSeeManagerReport"
        right
      >
        <template #button-content> АНАЛИТИКА</template>
        <b-dropdown-item v-if="canSeeSettings" :to="{ name: 'pest-stats' }"
          >Статистика
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeClosedReports"
          :to="{ name: 'pest-report-cpo' }"
          >Отчет CPO
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeClosedReports"
          :to="{ name: 'direct-ad-report' }"
          >Отчет по рекламе
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeManagerReport"
          :to="{ name: 'pest-report-managers' }"
          >Отчет по менеджерам
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item
        v-if="showHeaderUserMenu"
        :active="isMenuItemActive('users-user-list')"
        :to="{ name: 'users-user-list' }"
        @click="setMenuItem('users-user-list')"
        >ПОЛЬЗОВАТЕЛИ
      </b-nav-item>

      <b-nav-item
        v-if="canSeeRequestList && isWorker"
        :active="isMenuItemActive('pest-own-schedule')"
        :to="{ name: 'pest-own-schedule' }"
        @click="setMenuItem('pest-own-schedule')"
        >Мое расписание
      </b-nav-item>
      <b-nav-item
        v-if="canSeeOwnStats"
        :active="isMenuItemActive('pest-employee-archive-request-list')"
        :to="{ name: 'pest-employee-archive-request-list' }"
        @click="setMenuItem('pest-employee-archive-request-list')"
        >Закрытые заявки
      </b-nav-item>
      <b-nav-item
        v-if="canSeeOwnStats"
        :active="isMenuItemActive('pest-own-stats')"
        :to="{ name: 'pest-own-stats' }"
        @click="setMenuItem('pest-own-stats')"
        >Статистика
      </b-nav-item>

      <b-nav-item
        v-if="canSeeFines && isWorker"
        :active="isMenuItemActive('pest-fine-list')"
        :to="{ name: 'pest-fine-list' }"
        @click="setMenuItem('pest-fine-list')"
        >Мои штрафы
      </b-nav-item>

      <b-nav-item
        v-if="canSeeFines && isWorker"
        :active="isMenuItemActive('pest-penalties-list')"
        :to="{ name: 'pest-penalties-list' }"
        @click="setMenuItem('pest-penalties-list')"
        >Перечень штрафов
      </b-nav-item>

      <b-nav-item-dropdown
        v-if="isWorker"
        right
        :class="{ active: isMenuItemActive('pest-deposit-employee') }"
        :active="isMenuItemActive('pest-deposit-employee')"
      >
        <template #button-content> Инкассация</template>
        <b-dropdown-item
          :active="isMenuItemActive('pest-deposit-employee-create')"
          :to="{ name: 'pest-deposit-employee-create' }"
          @click="setMenuItem('pest-deposit-employee')"
          >Сдать кассу
        </b-dropdown-item>
        <b-dropdown-item
          :active="isMenuItemActive('pest-deposit-employee-list')"
          :to="{ name: 'pest-deposit-employee-list' }"
          @click="setMenuItem('pest-deposit-employee')"
          >История
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item
        v-if="isWorker"
        :active="isMenuItemActive('pest-employee-pledge')"
        :to="{ name: 'pest-employee-pledge' }"
        @click="setMenuItem('pest-employee-pledge')"
        >Мой депозит
      </b-nav-item>

      <b-nav-item
        v-if="canSeeOwnNotifications"
        :active="isMenuItemActive('notifications-notification-user-list')"
        :to="{ name: 'notifications-notification-user-list' }"
        @click="setMenuItem('notifications-notification-user-list')"
        >Уведомления
        <span
          v-if="unreadNotificationCount"
          class="label label-primary ml-2"
          >{{ unreadNotificationCount }}</span
        >
      </b-nav-item>

      <b-nav-item-dropdown v-if="!isWorker && canSeeAllNotifications" right>
        <template #button-content> СООБЩЕНИЯ</template>
        <b-dropdown-item
          v-if="canSeeFines"
          :to="{ name: 'notifications-notification-all-list' }"
          >Уведомления
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeePayments"
          :to="{ name: 'notifications-message-list' }"
          >Сообщения
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item
        v-if="canSeeSettings"
        :active="isMenuItemActive('settings')"
        :to="{ name: 'settings' }"
        @click="setMenuItem('settings')"
        >НАСТРОЙКИ
      </b-nav-item>

      <b-nav-item-dropdown v-if="canSeeServiceSections" right>
        <template #button-content> СЛУЖЕБНОЕ</template>
        <b-dropdown-item :to="{ name: 'service-dashboard' }"
          >Заявки
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <!--                <b-nav-item :to="{ name: 'request-list' }" v-if="canViewRequests">Заявки</b-nav-item>-->
    </b-navbar-nav>
    <!-- Right aligned nav items -->
    <b-navbar-nav class="ml-lg-auto align-items-lg-center">
      <b-nav-item-dropdown right class="left-border">
        <!-- Using 'button-content' slot -->
        <template #button-content>
          {{ username }}
        </template>

        <b-dropdown-item @click="logout">Выйти</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-collapse>
  <b-collapse v-else id="nav-collapse" is-nav>
    <b-navbar-nav>
      <b-nav-item to="/login">Вход</b-nav-item>
    </b-navbar-nav>
  </b-collapse>
</b-navbar>
