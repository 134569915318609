<template>
  <div>
    Сумма услуг: <strong>{{ log.serviceSum }}</strong
    ><br />
    Новый рекорд: <strong>{{ log.newSum }}</strong
    ><br />
    Предыдущий рекорд: <strong>{{ log.recordCurSum }}</strong
    ><br />

    <a v-if="show" href="" @click.prevent="toggle">Скрыть</a>
    <a v-else href="" @click.prevent="toggle">Показать подробности</a>

    <div v-if="show">
      Город: <strong>{{ log.town }}</strong
      ><br />
      <span v-if="log.newSum1">
        <strong>Рекорд был сброшен </strong><br
      /></span>
      <!--      <span v-if="log.newSum2"> <strong >Рекорд округлен </strong><br></span>-->
      Сумма, которую надо превысить: <strong>{{ log.compareRecordSum }}</strong
      ><br />
      Минимальная планка для рекорда: <strong>{{ log.recordSumMin }}</strong
      ><br />
      Максимальная планка для рекорда: <strong>{{ log.recordSumMax }}</strong
      ><br />
      <!--      Интервал повышения: <strong>{{ log.recordSumInc }}</strong> <br>-->
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogNewRecord",
  components: {},
  props: ["log"],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
