<template>
  <div class="task-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link :to="{ name: 'task-list' }" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <div>
            <h1>Задача: {{ task.title }}</h1>
            <h4 class="font-bold no-margins">
              Автор: {{ getUserName(task.author) }}

              <span v-if="task.completed">
                | <font-awesome-icon icon="check-circle" /> Выполнена в
                {{ getTaskCompleteTime(task) }}
              </span>
            </h4>
          </div>
          <div class="ml-auto">
            <div>
              <b-button
                v-if="!task.completed"
                class=""
                variant="primary"
                @click="completeTask"
              >
                <font-awesome-icon v-if="!task.loading" icon="check" />
                <font-awesome-icon v-else icon="rotate" spin />
                Выполнить
              </b-button>
              <b-button v-else class="" variant="info" @click="restoreTask">
                <font-awesome-icon v-if="!task.loading" icon="box" />
                <font-awesome-icon v-else icon="rotate" spin />
                Вернуть в работу
              </b-button>
              <router-link
                :to="{ name: 'task-update', id: task.id }"
                class="btn btn-primary ml-1"
              >
                <font-awesome-icon icon="edit" />
                Изменить
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <task-info :task="task" @update-prop="updateProp"></task-info>
      </div>
    </div>
  </div>
</template>

<script>
import TaskInfo from "./TaskInfo";
import { getUserName } from "@/modules/crm/common";
import { getTaskCompleteTime } from "@/modules/crm/decorators/task";

export default {
  name: "Task",
  components: {
    "task-info": TaskInfo,
  },
  data() {
    return {
      tabIndex: 0,
      task: {
        id: "new",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    getTaskCompleteTime,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      this.task.phone = "";
      let tasksUrl = "/crm/tasks/" + this.id;
      this.loading = true;
      this.$http
        .get(tasksUrl)
        .then((response) => {
          this.loading = false;
          this.task = response.data;
          this.task.managerName = this.task.manager.username;
          this.header = this.task.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = "/crm/tasks/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.task)
        .then((response) => {
          this.task = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-task-info", this.task);
          this.$bvToast.toast("Данные сохранены", {
            title: "Задача отредактирована",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    completeTask() {
      this.task.loading = true;
      let completeUrl = "/crm/tasks/complete/" + this.task.id;
      this.$http
        .post(completeUrl, {})
        .then((response) => {
          this.task.completed = true;
          this.task.loading = false;
          this.task.completedTime = Math.floor(Date.now() / 1000);
          this.$bvToast.toast("Задача закрыта", {
            title: "Задача отмечена как завершенная",
            variant: "success",
          });
        })
        .catch((error) => {
          this.task.loading = false;
        });
    },
    restoreTask() {
      this.task.loading = true;
      let restoreUrl = "/crm/tasks/restore/" + this.task.id;
      this.$http
        .post(restoreUrl, {})
        .then((response) => {
          this.task.completed = 0;
          this.task.loading = false;
          this.$bvToast.toast("Задача открыта", {
            title: "Задача открыта заново",
            variant: "success",
          });
        })
        .catch((error) => {
          this.task.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>
