
<div class="pest-reject-cause-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>Новая причина</h1>
        </div>
        <div class="ibox-content">
          <pest-reject-cause-form
            :reject-cause="rejectCause"
          ></pest-reject-cause-form>
        </div>
      </div>
    </div>
  </div>
</div>
