<template>
  <b-form
    id="task-form"
    ref="task"
    :model="task"
    class="task-form"
    novalidate
    @submit.prevent="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Тип" prop="type">
            <b-form-radio-group
              id="task-type"
              v-model="task.typeId"
              name="task-type"
              :options="taskTypesList"
              tabindex="-1"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="task.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Исполнитель" prop="assigneeId">
            <autocomplete
              ref="searchAssignee"
              :search="searchAssignee"
              class=""
              :default-value="assigneeName"
              placeholder="Поиск по сотрудникам"
              aria-label="Поиск по сотрудникам"
              :get-result-value="getResultAssigneeAutocompleteValue"
              @submit="handleAssigneeSubmit"
            ></autocomplete>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group label="Крайний срок" prop="deadline">
            <datepicker
              v-model="task.deadline"
              v-mask="'##.##.####'"
              input-class="form-control"
              :editable="true"
              lang="ru"
              :format="dateFormat"
              type="date"
              value-type="format"
              tabindex="6"
              required
            >
            </datepicker>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="task.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr />
          <h4>Привязки</h4>
          <client-link
            :task="task"
            :show-buttons="true"
            @update-client-link="updateClientLink"
          ></client-link>
          <hr />
        </div>
      </div>

      <div v-if="ownWindow" class="row mt-2">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { getUserName } from "../../../crm/common";
import { VueMaskDirective } from "v-mask";
import {
  TYPE_CALL,
  TYPE_LETTER,
  TYPE_TASK,
} from "@/modules/crm/decorators/task";
import TaskClientLink from "@/modules/crm/views/tasks/links/TaskClientLink";

export default {
  name: "TaskForm",
  directives: { mask: VueMaskDirective },
  components: {
    Datepicker: DatePicker,
    "client-link": TaskClientLink,
  },
  props: {
    task: {},
    returnUrl: {},
    ownWindow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      phoneMask: false,
      taskTypesList: [],
      types: [
        { text: "Задача", value: TYPE_TASK },
        { text: "Звонок", value: TYPE_CALL },
        { text: "Письмо", value: TYPE_LETTER },
      ],
      dateFormat: "DD.MM.YYYY",
      labelWidth: "150px",
      loading: false,
      header: "Задача",
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.task.id;
    },
    assigneeName() {
      return this.task.assigneeId && this.task.assignee
        ? getUserName(this.task.assignee)
        : "";
    },
    name() {
      let name = this.task.name ? this.task.name : "Новая задача";
      return name ? name : "Задача";
    },
  },
  watch: {},
  created: function () {
    this.fetchTaskTypes();
  },
  methods: {
    getUserName,
    fetchTaskTypes: function () {
      this.taskTypesList = [];
      let usersUrl = "/crm/task-types";
      let params = {
        page: this.page,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.taskTypesList.push({ value: item.id, text: item.title });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    update: function () {
      let updateUrl;
      updateUrl = "/crm/tasks/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.task)
        .then((response) => {
          this.task = response.data;
          this.loading = false;
          this.$router.push({
            name: "task-view",
            params: { id: this.task.id },
          });
          this.$bvToast.toast("Данные сохранены", {
            title: "Задача отредактирована",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function (successCallback, errorCallback) {
      let updateUrl;
      updateUrl = "/crm/tasks";
      this.loading = true;
      this.$http
        .post(updateUrl, this.task)
        .then((response) => {
          this.task = response.data;
          if (successCallback) {
            successCallback();
          } else {
            if (!this.returnUrl) {
              this.returnUrl = {
                name: "task-view",
                params: { id: this.task.id },
              };
            }
            this.$router.push(this.returnUrl);
          }

          this.$bvToast.toast("Задача добавлена", {
            title: "Задача добавлена",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить задачу",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event, successCallback, errorCallback) {
      event.preventDefault();
      let form = document.getElementById("task-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create(successCallback, errorCallback);
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    handleAssigneeSubmit(result) {
      this.task.assigneeId = result.id;
    },
    getResultAssigneeAutocompleteValue(user) {
      return this.getUserName(user);
    },
    searchAssignee(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let tasksUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(tasksUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.task = response.data;
            // this.header = this.task.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    updateClientLink(e) {
      this.task.links.clientId = e.clientId;
      this.task.links.client = e.client;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
