<template>
  <b-modal
    id="modal-search-contract-popup"
    ref="modal"
    title="Поиск контракта"
    size="lg"
    class="contract-popup"
    cancel-title="Отмена"
    ok-title="Сохранить"
    @hide="handleHide"
    @shown="onShown"
    @ok.prevent="handleModalOk"
  >
    <template #modal-title> <h3>Поиск контракта или лида</h3></template>
    <template #default>
      <div class="d-flex">
        <div class="mr-2">
          <b-form-group label="Адрес" prop="address">
            <b-form-input v-model="address" @change="fetchData"></b-form-input>
          </b-form-group>
        </div>
        <div v-if="false" class="mr-2">
          <b-form-group label="Имя" prop="name">
            <b-form-input v-model="name" @change="fetchData"></b-form-input>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Телефон" prop="phone">
            <b-form-input v-model="phone" @change="fetchData"></b-form-input>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Источник" prop="source">
            <source-autocomplete
              :default-value="source"
              @selected="selectSource"
            ></source-autocomplete>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Номер" prop="id">
            <b-form-input v-model="id" @change="fetchData"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <b-overlay :show="loading" rounded="sm">
        <table class="table table-condensed table-striped table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Тип</th>
              <th>Имя</th>
              <th>Телефон</th>
              <th>Источник</th>
            </tr>
          </thead>
          <tbody v-if="requests.length">
            <tr
              v-for="(request, rIndex) in requests"
              :key="'r' + rIndex"
              :class="{ 'table-info': request.id === selectedId }"
              @click.prevent="selectContract(request)"
            >
              <td>
                {{
                  request.contractId
                    ? request.contractId
                    : getRequestNumber(request)
                }}
              </td>
              <td>
                {{ request.contractId ? "Контракт" : "Лид" }}
              </td>
              <td>
                <div
                  v-if="request.communications && request.communications.length"
                >
                  <ul
                    v-for="(link, lIndex) in request.communications"
                    :key="'li' + rIndex + '+' + lIndex"
                    class="pl-0"
                  >
                    {{
                      link.clientName
                    }}
                  </ul>
                </div>
                <div v-else>
                  {{ getRequestClientName(request) }}
                </div>
                <div>
                  {{ getRequestAddressFormatted(request) }}
                </div>
              </td>
              <td>
                <div
                  v-if="request.communications && request.communications.length"
                >
                  <ul
                    v-for="(link, lIndex) in request.communications"
                    :key="'li' + rIndex + '+' + lIndex"
                    class="pl-0"
                  >
                    <li
                      v-for="(aPhone, phIndex) in link.phones"
                      :key="'li' + rIndex + '+' + lIndex + '+' + phIndex"
                    >
                      {{ aPhone }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  {{ request.phone }}
                </div>
              </td>
              <td>{{ getRequestSourceName(request) }}</td>
            </tr>
          </tbody>
          <tbody v-if="!requests.length">
            <tr>
              <td colspan="5">Данных нет</td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </template>

    <template #modal-footer>
      <div class="mt-2">
        <!--        <button class="btn btn-outline-info " @click.prevent="fetchData">-->
        <!--          <font-awesome-icon fixedWidth icon="arrow-left"/>-->
        <!--          search-->
        <!--        </button>-->
        <button class="btn btn-outline-info mr-2" @click.prevent="handleHide">
          Отмена
        </button>
        <button class="btn btn-outline-primary" @click.prevent="handleModalOk">
          Прикрепить
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import SourceAutocomplete from "@/modules/pest/views/sources/SourceAutocomplete";
import {
  getRequestAddressFormatted,
  getRequestClientName,
  getRequestNumber,
  getRequestSourceName,
} from "@/modules/pest/decorators/requests";

export default {
  name: "RequestSearchPopup",
  directives: { mask: VueMaskDirective },
  components: { SourceAutocomplete },
  props: {},
  data() {
    return {
      id: "",
      requestId: null,
      phone: "",
      name: "",
      address: "",
      sourceId: null,
      source: "Выберите",
      selectedId: null,
      contractId: null,

      loading: false,
      clientId: false,
      successHandler: false,
      cancelHandler: false,
      requests: [],
    };
  },
  computed: {},
  watch: {},
  created: function () {
    this.$eventHub.$on("modal-search-contract-popup", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub(modal-search-contract-popup);
  },
  methods: {
    getRequestNumber,
    getRequestClientName,
    getRequestSourceName,
    getRequestAddressFormatted,

    handleSuccess() {
      this.$bvModal.hide("modal-search-contract-popup");
      if (this.successHandler) {
        this.successHandler({
          requestId: this.selectedId,
          contractId: this.contractId,
        });
      }
      this.successHandler = false;
      this.request = {};
    },
    handleHide() {
      this.loading = false;
      this.$bvModal.hide("modal-search-contract-popup");
      if (this.hideHandler) {
        this.hideHandler();
      }
    },

    fetchData: function () {
      this.loading = true;
      let requestsUrl = "/pest/contract/search";
      let params = {
        id: this.id,
        page: this.page,
        name: this.title,
        phone: this.phone,
        address: this.address,
        sourceId: this.sourceId,
        projectId: this.projectId,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.requests = response.data.filter((r) => r.id !== this.requestId);
          // this.pagination.size = parseInt(response.headers['x-pagination-per-page']);
          // this.pagination.total = parseInt(response.headers['x-pagination-total-count']);
          // this.pagination.count = parseInt(response.headers['x-pagination-page-count']);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },

    selectSource(info) {
      if (info.value) {
        this.source = info.value.title;
        this.sourceId = info.value.id;
      } else {
        this.source = "";
        this.sourceId = null;
      }
      this.fetchData();
    },
    handleModalOk() {
      this.handleSuccess();
    },
    openPopup(info) {
      // this.fetchData();
      this.selectedId = null;
      this.contractId = null;
      this.requestId = info.requestId;
      this.projectId = info.projectId;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.hideHandler = info.hide;
      this.$bvModal.show("modal-search-contract-popup");
    },
    onShown() {},
    selectContract(request) {
      this.selectedId = request.id;
      this.contractId = request.contractId;
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}

ul {
  margin: 0;
}
</style>
