
<div class="table-responsive">
  <table class="table table-bordered table-condensed">
    <thead>
      <tr>
        <th>Имя</th>
        <th>Лиды</th>
        <th>Успешные первички</th>
        <th>Успешные вторички</th>
        <th @click="sortData">
          Конверсия, %
          <span class="ml-2">
            <font-awesome-icon v-if="sort" icon="arrow-down" />
            <font-awesome-icon v-else icon="arrow-up" />
          </span>
        </th>
      </tr>
    </thead>
    <template v-for="(info, ind) in sourceData.results">
      <tbody :key="'ind' + ind">
        <tr v-if="hasRowData(info)">
          <td>
            {{ getUserName(info.user) }}
          </td>
          <td>
            {{ info.totalCount }}
          </td>
          <td>
            {{ info.firstSuccessCount }}
          </td>
          <td>
            {{ info.nextSuccessCount }}
          </td>
          <td>
            {{ info.conversion }}
          </td>
        </tr>
      </tbody>
    </template>
  </table>
</div>
