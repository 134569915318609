
<div class="ibox">
  <div class="ibox-title pr-3">
    <div class="d-flex align-items-center">
      <h3>Выезды по цепочке</h3>
      <div class="ml-auto"></div>
    </div>
  </div>
  <div class="ibox-content">
    <div v-if="sequence && sequence.length > 1">
      <div
        v-for="(aRequest, srIndex) in sequence"
        :key="'sr' + srIndex"
        class="mb-4 pb-3 previous-request"
      >
        <h4>
          <!--                  @click.prevent="showRequestPopup(request)"-->
          <!--                  :class="getRequestTabClass(request.id)"-->
          <span class="label mr-2"> {{ aRequest.indexNumber }}</span>
          {{ getRequestNumber(aRequest) }}
        </h4>

        <div class="mb-1 d-flex">
          <div>
            <font-awesome-icon icon="user-cog" fixed-width class="mr-1" />
            <!-- Исполнитель-->
            {{ getRequestAssigneeName(aRequest) }}
          </div>
        </div>

        <div class="mb-1 d-flex">
          <div>
            <!-- Время -->
            <font-awesome-icon icon="clock" fixed-width class="mr-1" />
            {{ getRequestDatetime(aRequest) }}
          </div>
        </div>

        <div class="mb-1">
          <font-awesome-icon icon="ruble-sign" fixed-width class="mr-1" />
          <!--              Сумма -->
          {{
            getRequestDisplayableSum(aRequest)
              ? getRequestDisplayableSum(aRequest)
              : " – "
          }}

          <span v-if="aRequest.earned"> / {{ aRequest.earned }} </span>
          <span v-if="aRequest.hasRecord">
            <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
          </span>

          <span
            v-if="
              aRequest.isOrganization && aRequest.paymentType == PT_CASHLESS
            "
          >
            (Безнал)
          </span>
        </div>
        <div v-if="hasAnyResources(aRequest)" class="mb-1 d-flex">
          <font-awesome-icon icon="vial" fixed-width class="mr-1 mt-1" />
          <ul class="inline mb-0 pl-3">
            <li v-for="(resource, index) in aRequest.resources" :key="index">
              {{
                resource.resource
                  ? resource.resource.title
                  : "[Без материала]"
              }}
              <span v-if="resource.count">
                раб.р.-{{ resource.solutionVolume }}л, доз.-
                {{ resource.dosage }}мл/л, расход - {{ resource.count }}мл
              </span>
            </li>
          </ul>
        </div>
        <div v-if="hasAnyServices(aRequest)" class="mb-1 d-flex">
          <font-awesome-icon icon="gears" fixed-width class="mr-1 mt-1" />
          <ul class="inline mb-0 pl-3">
            <li v-for="(service, index) in aRequest.services" :key="index">
              {{ service.title }} - {{ service.price }}
            </li>
          </ul>
        </div>
        <div v-if="hasAnyProducts(aRequest)" class="mb-1 d-flex align-middle">
          <font-awesome-icon icon="box" fixed-width class="mr-1 mt-1" />
          <ul class="inline mb-0 pl-3">
            <li v-for="(product, index) in aRequest.products" :key="index">
              {{ product.product.title }} - {{ product.price }} ({{
                product.cost
              }})
            </li>
          </ul>
        </div>

        <div class="mb-1">
          <!-- Комментарий менеджера -->
          <font-awesome-icon icon="comment" fixed-width class="mr-1" />
          {{ aRequest.comment ? aRequest.comment : " – " }}
        </div>

        <div class="mb-1">
          <!-- Комментарий мастера -->
          <font-awesome-icon icon="comments" fixed-width class="mr-1" />
          {{ aRequest.assigneeComment ? aRequest.assigneeComment : " – " }}
        </div>
        <div v-if="aRequest.warning" class="mb-1 text-warning">
          <!--              Особое внимание -->
          <font-awesome-icon
            icon="exclamation-triangle"
            fixed-width
            class="mr-1"
          />
          {{ aRequest.warning }}
        </div>
      </div>
    </div>
  </div>
</div>
