
<b-form
  id="pest-fine-form"
  ref="fine"
  :model="fine"
  class="pest-fine-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Сотрудник" prop="employeeId">
          <user-autocomplete
            v-model="fine.employeeId"
            :default-value="getEmployeeName(fine.employee)"
            @selected="selectEmployee"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Описание" prop="sum">
          <b-form-textarea
            v-model="fine.comment"
            placeholder="Описание"
            :disabled="!canBeEdited"
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Сумма" prop="sum">
          <b-form-input
            v-model="fine.sum"
            placeholder="Сумма"
            tabindex="1"
            :disabled="!canBeEdited"
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div v-if="fine.id !== 'new'" class="row">
      <div class="col-12">
        <b-form-group label="Кто назначил" prop="sum">
          {{ getUserName(fine.manager) }}
        </b-form-group>
      </div>
    </div>

    <div v-if="canBeEdited" class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          <span v-if="fine.id === 'new'">Создать</span>
          <span v-else> Изменить</span>
        </b-button>

        <a
          v-if="fine.id !== 'new'"
          href=""
          class="btn btn-outline btn-danger ml-2"
          @click.prevent="deleteFine"
        >
          <font-awesome-icon icon="trash" />
          Удалить
        </a>
      </div>
    </div>
  </div>
</b-form>
