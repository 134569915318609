<template>
  <div>
    Попытка: {{ log.recurringCallAttempt }} <br />
    Время звонка: {{ getFormattedTime(log.recurringCallTime) }}
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import RequestResultLogInfo from "@/modules/pest/views/logs/details/RequestResultLogInfo";
import dayjs from "dayjs";

export default {
  name: "RequestLogRecurringQueued",
  components: {},
  props: ["log"],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    getFormattedTime(time) {
      return dayjs.unix(time).utcOffset("+03:00").format("DD.MM.YYYY HH:mm");
    },
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
