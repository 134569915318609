<template>
  <b-form
    id="pest-project-form"
    ref="project"
    :model="project"
    class="pest-project-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="project.title"
              placeholder="Название"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Номер проекта ройстат" prop="projectNumber">
            <b-form-input
              v-model="project.projectNumber"
              number
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Включено" prop="enabled">
            <b-form-checkbox v-model="project.enabled" value="1">
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  name: "ProjectForm",
  components: {},
  props: ["project"],
  data() {
    return {
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.project.id;
    },
    name() {
      let name = this.project.title ? this.project.title : "Новый проект";
      return name ? name : "Проект";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    update: function () {
      let updateUrl;
      updateUrl = "/pest/projects/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.project)
        .then((response) => {
          this.project = response.data;
          this.loading = false;
          // this.$router.push({name: 'pest-town-view', params: {id: this.town.id}});
          this.$bvToast.toast("Данные сохранены", {
            title: "Проект отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/projects";
      this.loading = true;
      this.$http
        .post(updateUrl, this.project)
        .then((response) => {
          this.project = response.data;
          this.$router.push({
            name: "pest-project-update",
            params: { id: this.project.id },
          });
          this.$bvToast.toast("Проект добавлен", {
            title: "Проект добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить проект",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-project-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}
</style>
