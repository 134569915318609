<template>
  <div v-if="showModal" id="modal-template">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="hideModal">
          <div ref="content" class="modal-tab-container gray-bg">
            <span class="d-block d-sm-none closeicon" @click="hideByButton"
              ><font-awesome-icon icon="close" size="2x"
            /></span>
            <div class="tabs-container">
              <div class="similar-block pb-3">
                <slot name="tabs"></slot>
              </div>

              <div class="modal-container">
                <div class="modal-header d-flex align-items-center pb-0">
                  <div>
                    <slot name="header"> Buttons</slot>
                  </div>
                  <div class="ml-auto pb-3 d-flex">
                    <slot name="header-buttons"> Default header</slot>
                  </div>
                </div>
                <div>
                  <slot name="toptabs"></slot>
                </div>
                <div class="modal-body gray-bg">
                  <slot name="default"> Default body</slot>
                </div>

                <div v-if="false" class="modal-footer">
                  <slot name="footer">
                    Default footer
                    <button
                      class="modal-default-button"
                      @click="$emit('close')"
                    >
                      OK
                    </button>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { contains } from "@/utils/contains";

export default {
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {
    hideModal(event) {
      // Do nothing if element
      // that generated click event is no longer in document body
      if (!contains(document.body, event.target)) {
        return;
      }
      // If backdrop clicked, hide modal
      if (!contains(this.$refs.content, event.target)) {
        this.$emit("hide");
      }
    },
    hideByButton() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.closeicon {
  position: absolute;
  top: 0;
  right: 5px;
}
.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  z-index: 2010;
  padding: 10px;
}

.modal-tab-container {
  max-width: 1140px;
  margin: 0px auto;
  position: relative;
  padding-top: 20px;
  //background: gray;
}

.modal-container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  padding-left: 30px;
  padding-right: 30px;
  //min-height: 66px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  height: 80vh !important;
  max-height: 80vh !important;
  overflow: auto;
}

.tabs-container .nav-tabs {
  margin-left: 30px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

//.nav-tabs .nav-link,{
//  color: #495057;
//  background-color: #fff;
//  border-color: #dee2e6 #dee2e6 #fff;
//  border-bottom: 0;
//}
.similar-block {
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 640px) {
  .similar-block {
    overflow: hidden;
    overflow-x: scroll;
  }
  .modal-body {
    height: 100% !important;
  }
}
</style>
