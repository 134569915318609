<template>
  <b-form
    id="folder-form"
    ref="folder"
    :model="folder"
    class="folder-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input
              v-model="folder.title"
              placeholder="Название"
              required
              required
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="folder.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div v-if="ownWindow" class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ submitButton }}
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "../../../crm/common";
import { VueMaskDirective } from "v-mask";

export default {
  name: "FolderForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: {
    model: {},
    ownWindow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      folder: {},
      phoneMask: false,
      loading: false,
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.folder.id;
    },
    name() {
      let name = this.folder.name ? this.folder.name : "Новая задача";
      return name ? name : "Задача";
    },
  },
  watch: {
    model: {
      immediate: true,
      handler(newVal, oldVal) {
        this.folder = newVal;
      },
    },
  },
  created: function () {},
  methods: {
    getUserName,
    update: function (successCallback, errorCallback) {
      let updateUrl;
      updateUrl = "/papers/folders/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.folder)
        .then((response) => {
          this.folder = response.data;
          this.loading = false;
          if (this.ownWindow) {
            this.$router.push({
              name: "document-type-view",
              params: { id: this.folder.id },
            });
          }
          this.$bvToast.toast("Данные сохранены", {
            title: "Папка отредактирована",
            type: "success",
          });
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось сохранить данные",
              type: "danger",
            });
          }
          if (errorCallback) {
            errorCallback();
          }
        });
    },
    create: function (successCallback, errorCallback) {
      let updateUrl;
      updateUrl = "/papers/folders";
      this.loading = true;
      this.$http
        .post(updateUrl, this.folder)
        .then((response) => {
          this.folder = response.data;
          if (this.ownWindow) {
            this.$router.push({
              name: "document-type-view",
              params: { id: this.folder.id },
            });
          }
          this.$bvToast.toast("Папка добавлена", {
            title: "Папка добавлена",
            variant: "success",
          });
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить папку",
              variant: "danger",
            });
          }

          if (errorCallback) {
            errorCallback();
          }
        });
    },
    createOrUpdate: function (event, successCallback, errorCallback) {
      event.preventDefault();
      let form = document.getElementById("folder-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create(successCallback, errorCallback);
        } else {
          this.update(successCallback, errorCallback);
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
