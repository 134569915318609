<template>
  <div>
    <div v-if="client">
      <router-link
        :to="{ name: 'crm-client-view', params: { id: client.id } }"
        class=""
      >
        <font-awesome-icon
          v-if="client.type == c_TYPE_ORGANIZATION"
          icon="building"
        />
        <font-awesome-icon v-else icon="user" />
        {{ getClientName(client) }}
      </router-link>

      <b-button
        v-if="showButtons"
        class=""
        size="xs"
        variant="primary"
        @click="clearClient()"
      >
        <font-awesome-icon fixed-width icon="trash" />
      </b-button>
    </div>

    <div v-else>
      <div v-if="editing">
        <b-form-group label="Клиент" prop="clientId">
          <autocomplete
            ref="searchClient"
            :search="searchClient"
            class=""
            :default-value="clientName"
            placeholder="Поиск по клиентам"
            aria-label="Поиск по клиентам"
            :get-result-value="getResultClientAutocompleteValue"
            @submit="handleClientSelect"
          ></autocomplete>
        </b-form-group>

        <b-button class="" variant="primary" @click="confirmEditing">
          <font-awesome-icon fixed-width icon="check" />
        </b-button>

        <b-button class="ml-2" variant="default" @click="cancelEditing">
          <font-awesome-icon fixed-width icon="times" />
        </b-button>
      </div>

      <div v-else>
        <b-button
          v-if="showButtons"
          class=""
          variant="primary"
          @click="startEditing()"
        >
          <font-awesome-icon fixed-width icon="plus" />
          Привязать клиента
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getClientName,
  TYPE_ORGANIZATION,
} from "@/modules/crm/decorators/client";

export default {
  name: "ClientAssign",
  components: {},
  props: {
    showButtons: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      c_TYPE_ORGANIZATION: TYPE_ORGANIZATION,
      clientId: false,
      client: false,
      editing: false,
    };
  },
  computed: {
    clientName(client) {
      if (!client) {
        return "";
      }
      return this.getClientName(client);
    },
  },
  watch: {
    task: {
      immediate: true,
      handler(obj) {
        this.lead = Object.assign({}, obj);
        this.saveLocalVal();
      },
    },
  },
  created: function () {},
  methods: {
    getClientName,
    saveLocalVal() {
      this.client = this.task.links.client;
      this.clientId = this.task.links.clientId;
    },
    handleClientSelect(result) {
      this.clientId = result.id;
      this.client = result;
      this.editing = false;
      this.$emit("update-client-link", {
        client: this.client,
        clientId: this.clientId,
      });
    },
    getResultClientAutocompleteValue(client) {
      return this.getClientName(client);
    },
    searchClient(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/crm/client/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    startEditing() {
      this.editing = true;
      this.client = this.task.links.client;
      this.clientId = this.task.links.clientId;
    },
    confirmEditing() {
      this.editing = false;
      this.$emit("update-client-link", {
        client: this.client,
        clientId: this.clientId,
      });
    },
    clearClient() {
      this.client = false;
      this.clientId = false;
      this.$emit("update-client-link", {
        client: this.client,
        clientId: this.clientId,
      });
    },
    cancelEditing() {
      this.editing = false;
      this.client = this.task.links.client;
      this.clientId = this.task.links.clientId;
    },
  },
};
</script>

<style lang="scss" scoped></style>
