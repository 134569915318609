
<div class="pest-town-page">
  <div class="ibox">
    <div class="ibox-title pb-3">
      <div class="d-flex">
        <div class="ml-1 mr-2 mt-2">
          <router-link :to="{ name: 'pest-town-list' }" class="h4 text-muted">
            <font-awesome-icon icon="arrow-left" />
          </router-link>
        </div>
        <div>
          <h1>
            {{ town.title }}
          </h1>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <pest-town-info :town="town" @update-prop="updateProp"></pest-town-info>
    </div>
  </div>
</div>
