<template>
  <b-modal
    id="modal-contract-phone-popup"
    ref="modal"
    title="Новый телефон"
    size="sm"
    class="contract-popup"
    cancel-title="Отмена"
    ok-title="Сохранить"
    title-tag="h3"
    @hide="handleHide"
    @shown="onShown"
    @ok.prevent="handleTaskModalOk"
  >
    <template #default>
      <b-overlay :show="loading" rounded="sm">
        <b-form
          id="phone-form"
          ref="phone"
          class="phone-form"
          novalidate
          @submit.prevent="createOrUpdate"
        >
          <b-form-group label="Телефон" prop="phone">
            <b-form-input
              id="phone-field"
              v-model="phone"
              required
            ></b-form-input>
          </b-form-group>
        </b-form>
      </b-overlay>
    </template>

    <template #footer>
      <div class="mt-2">
        <button
          v-if="!loading"
          class="btn btn-primary mr-2"
          @click.prevent="createOrUpdate"
        >
          <font-awesome-icon fixed-width icon="plus" />
          Добавить
        </button>
        <button v-else class="btn btn-primary mr-2" disabled>
          <font-awesome-icon fixed-width icon="rotate" spin />
          Добавляется
        </button>
        <button class="btn btn-outline-info" @click.prevent="handleHide">
          <font-awesome-icon fixed-width icon="arrow-left" />
          Отмена
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { VueMaskDirective } from "v-mask";

export default {
  name: "NewContactPhonePopup",
  directives: { mask: VueMaskDirective },
  components: {},
  props: {},
  data() {
    return {
      phone: "",
      loading: false,
      clientId: false,
      successHandler: false,
      cancelHandler: false,
    };
  },
  computed: {},
  watch: {},
  created: function () {
    this.$eventHub.$on("modal-contract-phone-popup", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub(modal-contract-phone-popup);
  },
  methods: {
    handleSuccess() {
      this.$bvModal.hide("modal-contract-phone-popup");
      if (this.successHandler) {
        this.successHandler({ phone: this.phone });
      }
      this.successHandler = false;
      this.request = {};
    },
    handleHide() {
      this.loading = false;
      this.$bvModal.hide("modal-contract-phone-popup");
      if (this.hideHandler) {
        this.hideHandler();
      }
    },

    create: function () {
      let updateUrl = "/crm/clients/add-phone/" + this.clientId;
      this.loading = true;
      this.$http
        .post(updateUrl, { phone: this.phone })
        .then((response) => {
          this.loading = false;
          this.handleSuccess();
          this.$bvToast.toast("Телефон добавлен", {
            title: "Успешно",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Ошибка", {
              title: "Не удалось добавить телефон",
              variant: "danger",
            });
          }
        });
    },

    createOrUpdate: function () {
      let form = document.getElementById("phone-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast("Данные сохраняются", {
            title: "Подождите",
            variant: "info",
          });
          return;
        }
        this.create();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast("В форме содержатся ошибки", {
          variant: "danger",
        });
        return false;
      }
    },

    handleTaskModalOk() {
      this.createOrUpdate(this.handleSuccess);
    },
    openPopup(info) {
      this.phone = "";
      this.clientId = info.clientId;

      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.hideHandler = info.hide;
      this.$bvModal.show("modal-contract-phone-popup", { size: "sm" });
    },
    onShown() {
      document.getElementById("phone-field").focus();
    },
  },
};
</script>

<style lang="scss" scoped></style>
