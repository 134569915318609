import SourceCreate from "@/modules/pest/views/sources/SourceCreate";
import SourceList from "@/modules/pest/views/sources/SourceList";
import SourceUpdate from "@/modules/pest/views/sources/SourceUpdate";
import SourceView from "@/modules/pest/views/sources/SourceView";

export default [
  {
    path: "/sources/list/:page?",
    name: "pest-source-list",
    components: {
      default: SourceList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/sources/new/:userId?",
    name: "pest-source-create",
    components: {
      default: SourceCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/sources/:id/update",
    name: "pest-source-update",
    components: {
      default: SourceUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/sources/:id",
    name: "pest-source-view",
    components: {
      default: SourceView,
    },
    meta: { requiresAuth: true },
  },
];
