
<b-form
  id="pest-project-form"
  ref="project"
  :model="project"
  class="pest-project-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Название" prop="title">
          <b-form-input
            v-model="project.title"
            placeholder="Название"
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="Номер проекта ройстат" prop="projectNumber">
          <b-form-input
            v-model="project.projectNumber"
            number
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="Включено" prop="enabled">
          <b-form-checkbox v-model="project.enabled" value="1">
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ submitButton }}
        </b-button>
      </div>
    </div>
  </div>
</b-form>
