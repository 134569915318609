
<div class="ibox">
  <div class="ibox-content">
    <request-logs
      ref="request-logs"
      :show-search="true"
      :show-requests="true"
      :request-number-list="requestNumberList"
      :contract-id="contractId"
    ></request-logs>
  </div>
</div>
