<template>
  <div class="pest-subway-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>Новое метро</h1>
          </div>
          <div class="ibox-content">
            <pest-subway-form :subway="subway"></pest-subway-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubwayForm from "./SubwayForm";

export default {
  name: "Subway",
  components: {
    "pest-subway-form": SubwayForm,
  },
  data() {
    return {
      subway: {
        id: "new",
        title: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
