<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Яндекс Директ</h1>
      <div class="mb-2">
        <div class="mb-2">
          <button class="btn btn-primary" @click.prevent="downloadCampaigns">
            <font-awesome-icon
              v-if="downloadingCampaigns"
              icon="rotate"
              spin
              fixed-width
            />
            <font-awesome-icon v-else icon="list-ol" fixed-width />
            Обновить перечень кампаний
          </button>
        </div>

        <div class="mb-2">
          <button class="btn btn-primary" @click.prevent="downloadData">
            <font-awesome-icon
              v-if="downloadingData"
              icon="rotate"
              spin
              fixed-width
            />
            <font-awesome-icon v-else icon="download" fixed-width />
            Загрузить данные по кампаниям
          </button>
        </div>

        <div class="mb-2">
          <button class="btn btn-primary" @click.prevent="recountMoney">
            <font-awesome-icon
              v-if="recountingMoney"
              icon="rotate"
              spin
              fixed-width
            />
            <font-awesome-icon v-else icon="money-bill" fixed-width />
            Пересчитать суммы затрат по рекламе
          </button>
        </div>

        <div class="mb-2">
          <button class="btn btn-primary" @click.prevent="recountResults">
            <font-awesome-icon
              v-if="recountingResults"
              icon="rotate"
              spin
              fixed-width
            />
            <font-awesome-icon v-else icon="file" fixed-width />
            Пересчитать итоговый отчет
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DirectAccountList",
  components: {},
  data() {
    return {
      loading: true,
      downloadingData: false,
      downloadingCampaigns: false,
      recountingMoney: false,
      recountingResults: false,
    };
  },
  computed: {},
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {},
  created() {},
  methods: {
    downloadData() {
      if (this.downloadingData) {
        return;
      }
      let updateUrl = "/direct/manage/download-data";
      this.downloadingData = true;
      this.$http
        .post(updateUrl)
        .then((response) => {
          this.downloadingData = false;
          this.$bvToast.toast("Данные поставлены в очередь на загрузку", {
            title: "Успешно",
            variant: "success",
          });
        })
        .catch((error) => {
          this.downloadingData = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(
              "Не удалось поставить данные в очередь на загрузку",
              {
                title: "Ошибка",
                variant: "danger",
              }
            );
          }
        });
    },
    downloadCampaigns() {
      if (this.downloadingCampaigns) {
        return;
      }
      let updateUrl = "/direct/manage/download-campaigns";
      this.downloadingCampaigns = true;
      this.$http
        .post(updateUrl)
        .then((response) => {
          this.downloadingCampaigns = false;
          this.$bvToast.toast("Кампании поставлены в очередь на загрузку", {
            title: "Успешно",
            variant: "success",
          });
        })
        .catch((error) => {
          this.downloadingCampaigns = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(
              "Не удалось поставить кампании в очередь на загрузку",
              {
                title: "Ошибка",
                variant: "danger",
              }
            );
          }
        });
    },
    recountMoney() {
      if (this.recountingMoney) {
        return;
      }
      let updateUrl = "/direct/manage/recount-money";
      this.recountingMoney = true;
      this.$http
        .post(updateUrl)
        .then((response) => {
          this.recountingMoney = false;
          this.$bvToast.toast("Данные поставлены в очередь на загрузку", {
            title: "Успешно",
            variant: "success",
          });
        })
        .catch((error) => {
          this.recountingMoney = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(
              "Не удалось поставить данные в очередь на загрузку",
              {
                title: "Ошибка",
                variant: "danger",
              }
            );
          }
        });
    },
    recountResults() {
      if (this.recountingResults) {
        return;
      }
      let updateUrl = "/direct/manage/recount-results";
      this.recountingResults = true;
      this.$http
        .post(updateUrl)
        .then((response) => {
          this.recountingResults = false;
          this.$bvToast.toast("Данные поставлены в очередь на загрузку", {
            title: "Успешно",
            variant: "success",
          });
        })
        .catch((error) => {
          this.recountingResults = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(
              "Не удалось поставить данные в очередь на загрузку",
              {
                title: "Ошибка",
                variant: "danger",
              }
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
