
<div class="ibox">
  <div class="">
    <div class="ibox-title">
      <div class="d-flex align-items-center">
        <h1>Пользователи</h1>
        <div v-if="canUpdateUser" class="mr-2 ml-2">
          <router-link
            :to="{ name: 'users-user-view', params: { id: 'new' } }"
            class="btn btn-primary"
          >
            <font-awesome-icon icon="plus" />
            Добавить
          </router-link>
        </div>
        <users-cards-import v-if="isAdmin" />
      </div>
    </div>

    <div class="ibox-content">
      <div class="search">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-form-group label="Роль">
              <b-form-select
                v-model="search.type"
                :options="userTypesOptions"
                tabindex="1"
                @change="fetchData"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="mr-2">
            <b-form-group label="Город">
              <b-form-select
                v-model="search.townId"
                :options="townOptions"
                tabindex="2"
                @change="fetchData"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="mr-2">
            <b-form-group label="Имя, Номер, Телефон, Email">
              <b-form-input
                v-model="search.term"
                tabindex="3"
                @change="fetchData"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-info ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            Найдено: {{ pagination.totalCount }}
          </div>
        </div>
      </div>

      <hr />
      <b-overlay :show="loading" rounded="sm">
        <table class="table table-condensed table-striped table-bordered">
          <thead>
            <tr>
              <th v-if="false" class="check-column">
                <b-form-checkbox
                  v-model="checkAll"
                  :value="true"
                  @change="checkAllCheckboxes"
                ></b-form-checkbox>
              </th>

              <th class="name-column">
                <sort-column-title
                  v-model="search.sort"
                  title="ФИО"
                  param="surname"
                  @change="fetchData"
                />
              </th>
              <th class="phone-column">
                <sort-column-title
                  v-model="search.sort"
                  title="Телефон"
                  param="phone"
                  @change="fetchData"
                />
              </th>
              <th class="email-column">
                <sort-column-title
                  v-model="search.sort"
                  title="E-mail"
                  param="email"
                  @change="fetchData"
                />
              </th>
              <th class="telegram-column">
                <sort-column-title
                  v-model="search.sort"
                  title="Telegram"
                  param="telegramChatId"
                  @change="fetchData"
                />
              </th>
              <th class="town-column">
                <sort-column-title
                  v-model="search.sort"
                  title="Город"
                  param="townId"
                  @change="fetchData"
                />
              </th>
              <th class="type-column">
                <sort-column-title
                  v-model="search.sort"
                  title="Роль"
                  param="type"
                  @change="fetchData"
                />
              </th>
              <th v-if="canUpdateUser" class="check-column">
                <div>
                  <b-dropdown
                    text=""
                    variant="default btn-xs"
                    :disabled="!hasCheckedItems"
                  >
                    <b-dropdown-item
                      href="#"
                      @click.prevent="deleteSelectedUsers"
                      >Удалить выбранное
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </th>
              <th v-if="false" class="comment-column"></th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr v-for="(user, index) in users" :key="'p' + index" class="">
              <td v-if="false" class="check-column">
                <b-form-checkbox
                  v-model="user.checked"
                  :value="true"
                  @change="checkCheckboxStatus"
                ></b-form-checkbox>
              </td>
              <td>
                <router-link
                  :to="{ name: 'users-user-view', params: { id: user.id } }"
                >
                  {{ getUserName(user) }}
                </router-link>
              </td>

              <td>
                <router-link
                  v-if="canUpdateUser"
                  :to="{ name: 'users-user-view', params: { id: user.id } }"
                  class=""
                >
                  {{ user.phone ? user.phone : "[Нет телефона]" }}
                </router-link>
                <span v-else>
                  {{ user.phone ? user.phone : "[Нет телефона]" }}
                </span>
              </td>
              <td>
                <router-link
                  v-if="canUpdateUser"
                  :to="{ name: 'users-user-view', params: { id: user.id } }"
                  class=""
                >
                  {{ user.email ? user.email : "[Нет email]" }}
                </router-link>
                <span v-else>
                  {{ user.email ? user.email : "[Нет email]" }}
                </span>
              </td>
              <td>
                {{ user.telegramChatId ? "Подключен" : "" }}
              </td>
              <td>
                {{ getUserTown(user) }}
              </td>
              <td>
                {{ getTypeTitle(user) }}
              </td>
              <td v-if="canUpdateUser" class="check-column">
                <b-dropdown text="" variant="default btn-xs" right>
                  <b-dropdown-item
                    :to="{ name: 'users-user-view', params: { id: user.id } }"
                  >
                    Редактировать
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click.prevent="deleteUser(user)"
                    >Удалить
                  </b-dropdown-item>
                </b-dropdown>
              </td>

              <td v-if="false" class="comment-column">
                <span v-if="user.isNovice">
                  Стажер с {{ user.noviceDateFrom }} <br />
                  Смен: {{ user.noviceShiftCount }}
                </span>

                <span v-if="user.teamHead">
                  В команде у {{ getUserTitle(user.teamHead) }} C:
                  {{ user.teamSince }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">Загружается</td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
      <b-pagination-nav
        v-if="pagination.pageCount > 1"
        :link-gen="linkGen"
        :value="pagination.pageNumber"
        :number-of-pages="pagination.pageCount"
        limit="10"
        use-router
        class="mt-2"
      >
      </b-pagination-nav>
    </div>
  </div>
</div>
