
<div class="task-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link :to="returnRoute" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Создание задачи
          </h1>
        </div>
        <div class="ibox-content">
          <task-form
            :task="task"
            :return-url="returnRoute"
            :own-window="true"
          ></task-form>
        </div>
      </div>
    </div>
  </div>
</div>
