<template>
  <div v-if="dataLoaded">
    <!--Сделка-->
    <div class="ibox">
      <div class="ibox-title d-flex pr-4 toptitle">
        <div>
          <div :data-id="request.id" class="d-flex align-items-baseline">
            <h3>Сделка: {{ getRequestNumber(request) }}</h3>
            <div v-if="request.id !== 'new'" class="ml-2 small">
              [Лид создан {{ getRequestCreateDatetime(request) }}]
            </div>
          </div>
          <!--          <div v-if="request.source" class="ml-auto">-->
          <!--            <a :href="getRequestSourceUrl(request)" target="_blank">{{-->
          <!--              request.source.title-->
          <!--            }}</a>-->
          <!--          </div>-->
        </div>
        <div class="ml-auto">
          <request-call-notifications :request="request" />
        </div>
        <div class="ml-2">
          <button
            v-if="
              request.id !== 'new' && request.contractId && !request.hasNext
            "
            class="btn btn-primary"
            @click.prevent="createReturnVisit(request)"
          >
            <font-awesome-icon icon="reply" />
            Назначить повтор
          </button>
        </div>
        <div v-if="request.status === STATUS_DECLINE" class="ml-2">
          <button class="btn btn-warning" @click.prevent="confirmReanimation">
            <span v-if="reanimationLoading">
              <font-awesome-icon icon="rotate-right" spin />
            </span>
            <span v-else>
              <font-awesome-icon icon="rotate-right" />
            </span>
            Реанимировать
          </button>
        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <b-form-group label="Менеджер" prop="managerId">
              {{ getRequestManagerName(request) }}
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-4">
            <b-form-group label="Статус" prop="leadStatusId">
              {{ getRequestStatus(request) }}
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-4">
            <b-form-group label="Исполнитель" prop="assigneeId">
              {{ getRequestAssigneeName(request) }}
            </b-form-group>
            <b-form-group
              v-if="request.assigneeCancelCause"
              label="Причина отмены мастера"
              prop="assigneeId"
            >
              {{ request.assigneeCancelCause }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <b-form-group label="Проект" prop="projectId">
              {{ getRequestProjectTitle(request) }}
            </b-form-group>
          </div>
          <div class="col-12 col-md-4">
            <b-form-group label="Имя" prop="title">
              {{ request.clientName }}
            </b-form-group>
          </div>
          <div v-if="request.status === STATUS_DECLINE" class="col-12 col-md-4">
            <b-form-group label="Причина провала" prop="title">
              {{ request.rejectionComment }}
            </b-form-group>
          </div>
        </div>

        <!--  новые контакты -->
        <fieldset>
          <legend
            id="__BVID__420__BV_label_"
            class="bv-no-focus-ring col-form-label pt-0"
          >
            Контакты
          </legend>
          <div
            v-if="request.communications && request.communications.length"
            class="d-flex flex-wrap"
          >
            <div
              v-for="(communication, cIndex) in request.communications"
              :key="'communication' + cIndex"
              class="contact-card px-3 pt-2 pb-2 mr-2 mb-2"
            >
              <div class=" ">
                <h4>{{ communication.clientName }}</h4>
              </div>
              <div>
                <ul class="pl-3">
                  <li
                    v-for="(phone, pIndex) in communication.phones"
                    :key="'phone' + cIndex + '-' + pIndex"
                    class="mb-2"
                  >
                    <div class="d-flex align-items-center">
                      <div class="ml-auto">{{ phone }}</div>
                      <div class="ml-2">
                        <button
                          v-b-tooltip.hover
                          class="btn btn-success btn-sm ml-auto"
                          title="Добавить телефон"
                          @click.prevent="
                            makeCallInternal(communication, phone)
                          "
                        >
                          <font-awesome-icon icon="phone" />
                          Позвонить
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <!--Расчет стоимости-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>Расчет стоимости</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Город" prop="townIds">
              {{ getTownTitle(request.town) }}
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Тип вредителя" prop="title">
              {{ getRequestPestName(request) }}
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Объект" prop="title">
              {{ getRequestFacilityName(request) }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Тип" prop="title">
              <b-button-group>
                <div v-if="request.clientType === CLIENT_TYPE_IND">
                  Физ. лицо
                </div>
                <div v-else>Организация</div>
              </b-button-group>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              v-if="request.clientType === CLIENT_TYPE_ORG"
              label="Тип оплаты"
              prop="paymentType"
            >
              <div v-if="request.paymentType === PT_CASH">Нал</div>
              <div v-else>Безнал</div>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              v-if="request.clientType === CLIENT_TYPE_ORG"
              label="Оплачено"
              prop="clientPaymentReceived"
            >
              <div v-if="request.clientPaymentReceived">Да</div>
              <div v-else>Нет</div>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Тип расчета" prop="fromExchange">
              <div v-if="request.fromExchange">Из биржи (нет рекорда)</div>
              <div v-else>Стандарт</div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <b-form-group label="Стоимость" prop="title">
              <div class="input-group">
                {{ request.knownSum }}
              </div>

              <!--              <b-form-input v-model="request.sum" placeholder="" number></b-form-input>-->
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group label="Дорога, км" prop="title">
              {{ request.tripDistance }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Дорога, р" prop="title">
              {{ request.tripSum }}
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <!--Результаты просмотра-->
    <div v-if="!correctingResult" class="ibox">
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>Результаты</h3>
          <div class="ml-auto">
            <button
              class="btn btn-outline-info"
              @click.prevent="toggleCorrectResult(true)"
            >
              Скорректировать
            </button>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-4">
            <b-form-group v-if="hasAnyServices(request)" label="Услуги">
              <ul class="mb-0 pl-3">
                <li
                  v-for="(service, rIndex) in request.services"
                  :key="'r' + rIndex"
                >
                  {{ service.title }} – {{ formatNumber(service.price) }}
                </li>
              </ul>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group v-if="hasAnyResources(request)" label="Материалы">
              <ul class="mb-0 pl-3">
                <li
                  v-for="(resource, rIndex) in request.resources"
                  :key="'r' + rIndex"
                >
                  {{
                    resource.resource
                      ? resource.resource.title
                      : "[Без материала]"
                  }}
                  <span v-if="resource.count">
                    – раб.р.-{{ resource.solutionVolume }}л, доз.-{{
                      resource.dosage
                    }}мл/л, расход - {{ resource.count }}мл</span
                  >
                </li>
              </ul>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label="Исполнитель" prop="assigneeId">
              {{ getRequestAssigneeName(request) }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <b-form-group label="Транспорт" prop="title">
              <span v-if="request.tripSum || request.tripDistance">
                {{ formatNumber(request.tripSum) }}р –
                {{ request.tripDistance }}км
              </span>
              <span v-else>Не задано</span>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              v-if="request.tripBackSum || request.tripBackDistance"
              label="Транспорт после последней заявки "
              prop="title"
            >
              <span v-if="request.tripBackSum || request.tripBackDistance">
                {{ formatNumber(request.tripBackSum) }}р –
                {{ request.tripBackDistance }}км
              </span>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <b-form-group
              v-if="hasAnyServices(request)"
              label="Стоимость услуг"
            >
              {{ formatNumber(servicesSum(request)) }}р
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              v-if="hasAnyProducts(request)"
              label="Стоимость товаров (себестоимость)"
            >
              {{ formatNumber(productSum(request)) }}р ({{
                formatNumber(productNetCost(request))
              }}
              р)
            </b-form-group>
          </div>

          <div class="col-2">
            <b-form-group v-if="equipmentSum(request) > 0" label="Оборудование">
              {{ formatNumber(equipmentSum(request)) }}р
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group v-if="hasAnyServices(request) > 0" label="Заработано">
              <strong> {{ request.earned }}р</strong>
              <span v-if="request.earnPercent">
                ({{ request.earnPercent }} %)
              </span>
              <br />
              <span v-if="request.hasRecord">
                <font-awesome-icon
                  v-b-tooltip.hover
                  icon="star"
                  title="Рекорд"
                />
                Есть рекорд
              </span>
              <span v-else> Нет рекорда </span>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group v-if="hasAnyServices(request)" label="На руки">
              {{ formatNumber(calcTotalProfitSum(request)) }}р
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group v-if="hasAnyServices(request)" label="К выплате">
              {{ formatNumber(request.paymentSum) }}р
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group v-if="hasAnyServices(request)" label="Выплачено">
              <div v-if="request.isPaid">Да</div>
              <div v-else>Нет</div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <b-form-group label="Повтор">
              <div v-if="request.repeatAppointed">
                {{ request.repeatDate }}
              </div>
              <div v-else>Не назначен</div>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="Длительность, мин" prop="title">
              {{ request.duration }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group v-if="request.assigneeComment" label="Комментарий">
              <span>{{ request.assigneeComment }}</span>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Документы">
              <div class="files-block mb-2">
                <div
                  v-for="(file, fIndex) in request.resultFiles"
                  :key="'i' + fIndex"
                  class="d-flex"
                >
                  <a
                    v-b-tooltip.hover
                    class="mr-2"
                    :href="file.url"
                    :title="file.name"
                    target="_blank"
                  >
                    {{ file.name + "." + file.extension }}
                  </a>
                  <div class="ml-2 mr-2">{{ getDate(file.createTime) }}</div>
                  <div v-if="canDeleteResultFiles" class="ml-auto mr-2">
                    <font-awesome-icon
                      icon="trash"
                      @click="deleteResultFile(fIndex)"
                    />
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <!--Редактирование результатов -->
    <div v-if="!loading && correctingResult" class="ibox">
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>Корректировка результатов</h3>
        </div>
      </div>

      <div class="ibox-content">
        <request-correct-result-form
          :request-model="request"
          :data-loaded="!loading"
          @request-corrected="afterRequestCorrected"
          @cancel-updating="toggleCorrectResult(false)"
        ></request-correct-result-form>
      </div>
    </div>

    <!--Блок исхода прозвона -->
    <div v-if="showAfterCallInfo && !enteringAftercallResults" class="ibox">
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>Исход прозвона</h3>
          <div class="ml-auto">
            <button
              class="btn btn-outline-info"
              @click.prevent="toggleAftercallResult(true)"
            >
              Внести результаты
            </button>
          </div>
        </div>
      </div>

      <div class="ibox-content">
        <div class="row">
          <div class="col-4">
            <b-form-group label="Статус" prop="status">
              <span v-if="request.aftercall">
                {{ getAftercallStatusTitle(request.aftercall) }}
              </span>
              <span v-else>Без прозвона</span>
            </b-form-group>
          </div>

          <div
            v-if="
              request.aftercall &&
              request.aftercall.status === AFTERCALL_STATUS_NOT_REGISTERED
            "
            class="col-4"
          >
            <b-form-group label="Причина" prop="status">
              {{ getAftercallFailStatusTitle(request.aftercall) }}
            </b-form-group>
          </div>
        </div>
        <div v-if="request.aftercall" class="row">
          <div class="col-4">
            <b-form-group label="Менеджер" prop="managerId">
              {{ getAftercallManagerTitle(request.aftercall) }}
            </b-form-group>
          </div>
        </div>

        <b-form-group v-if="request.aftercall" label="Комментарий" prop="title">
          {{ request.aftercall.comment }}
        </b-form-group>
      </div>
    </div>

    <!--Внесение результатов прозвона -->
    <div
      v-if="!loading && showAfterCallInfo && enteringAftercallResults"
      class="ibox"
    >
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>Исход прозвона</h3>
        </div>
      </div>

      <div class="ibox-content">
        <request-enter-aftercall-form
          :request-model="request"
          :data-loaded="!loading"
          @request-corrected="afterAftercallCorrected"
          @cancel-updating="toggleAftercallResult(false)"
        ></request-enter-aftercall-form>
      </div>
    </div>

    <!--Адрес-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>Адрес</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-3">
            <b-form-group label="Индекс" prop="">
              {{ request.addressIndex ? request.addressIndex : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Населенный пункт" prop="">
              {{ request.addressTown ? request.addressTown : " – " }}
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group label="Улица" prop="">
              {{ request.address }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group v-if="showSubway" label="Метро" prop="subwayId">
              {{ getSubwayTitle(request.subway) }}
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <b-form-group label="Дом" prop="">
              {{ request.addressHouse ? request.addressHouse : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Корпус" prop="">
              {{ request.addressBlock ? request.addressBlock : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Подъезд" prop="">
              {{ request.addressEntrance ? request.addressEntrance : " – " }}
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group label="Этаж" prop="appartment">
              {{ request.addressFloor ? request.addressFloor : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Квартира" prop="appartment">
              <template #label>
                Квартира
                <request-appartment-visibility
                  class="ml-auto"
                  :request="request"
                  :assignee-can-see-address="request.assigneeCanSeeAddress"
                />
              </template>
              {{ request.appartment ? request.appartment : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Домофон" prop="appartment">
              {{ request.addressIntercom ? request.addressIntercom : " – " }}
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <b-form-group label="Доп информация" prop="addressInfo">
              {{ request.addressInfo ? request.addressInfo : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Координаты" prop="addressInfo">
              {{ request.addressCoords ? request.addressCoords : " – " }}
            </b-form-group>
          </div>
        </div>

        <request-form-map
          v-if="false"
          ref="map"
          :town-id="request.townId"
          :coords="request.addressCoords"
          @distance="distanceCalculated"
        />
      </div>
    </div>

    <!-- Выезды по цепочке -->
    <requests-sequence-history
      v-if="sequence && sequence.length > 1"
      :sequence="sequence"
      :request:="request"
    />

    <!--Время-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>Время</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Дата" prop="duration">
              {{ getRequestDatetime(request) }}
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group label="Описание" prop="comment">
              {{ request.comment }}
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group label="Особое внимание" prop="warning">
              {{ request.warning }}
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { getUserName } from "@/modules/crm/common";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import {
  calcProfitSum,
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  equipmentSum,
  getDate,
  getRequestAddressFormatted,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateDatetime,
  getRequestDatetime,
  getRequestFacilityName,
  getRequestManagerName,
  getRequestNumber,
  getRequestPestName,
  getRequestSourceUrl,
  getRequestStatus,
  hasAnyResources,
  hasAnyServices,
  PT_CASH,
  PT_CASHLESS,
  servicesSum,
  STAGE_DEAL,
  STATUS_DECLINE,
  STATUS_DONE,
  STATUS_PAID,
  calcTotalProfitSum,
  hasAnyProducts,
  productSum,
  productNetCost,
} from "@/modules/pest/decorators/requests.js";
import { TYPE_MANAGER } from "@/modules/users/decorators/users.js";
import {
  getTownTitle,
  MSK,
  MSK_C,
  SPB,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import clone from "just-clone";
import { getSubwayTitle } from "@/modules/pest/decorators/subways.js";
import RequestFormMap from "@/modules/pest/views/requests/card/RequestFormMap.vue";
import { formatNumber } from "../../../../decorators/common.js";
import RequestCorrectResultForm from "@/modules/pest/views/requests/view/RequestCorrectResultForm";
import RequestAppartmentVisibility from "@/modules/pest/views/requests/dispatching/_request/view/RequestAppartmentVisibility";
import RequestEnterAftercallForm from "@/modules/pest/views/requests/view/RequestEnterAftercallForm";
import {
  AFTERCALL_STATUS_NOT_REGISTERED,
  getAftercallFailStatusTitle,
  getAftercallManagerTitle,
  getAftercallStatusTitle,
} from "@/modules/pest/decorators/aftercall";
import RequestCallNotifications from "@/modules/pest/views/requests/kanban/popup/RequestCallNotifications.vue";
import RequestsSequenceHistory from "@/modules/pest/views/requests/kanban/popup/RequestSequenceHistory.vue";
import * as Sentry from "@sentry/vue";
import { canDeleteResultFiles } from "@/modules/users/access/common";
import { getRequestProjectTitle } from "@/modules/pest/decorators/requests.js";

export default {
  name: "RequestPopupInfo",
  directives: { mask: VueMaskDirective },
  components: {
    RequestsSequenceHistory,
    RequestCallNotifications,
    RequestEnterAftercallForm,
    RequestAppartmentVisibility,
    RequestCorrectResultForm,
    RequestFormMap,
  },
  props: ["requestModel", "dataLoaded", "embedded", "sequence"],
  data() {
    return {
      STATUS_DECLINE,
      AFTERCALL_STATUS_NOT_REGISTERED,
      MSK,
      SPB,
      MSK_C,
      SPB_C,
      PT_CASHLESS,
      PT_CASH,
      CLIENT_TYPE_ORG,
      CLIENT_TYPE_IND,
      TYPE_MANAGER,
      loading: false,
      reanimationLoading: false,
      correctingResult: false,
      enteringAftercallResults: false,
      request: {
        clientType: CLIENT_TYPE_IND,
        stage: STAGE_DEAL,
        dateDay: "",
        sum: "",
        dateTime: 10,
        duration: 120,
        pestId: null,
        pest: null,
        address: null,
        addressInfo: null,
        addressCoords: null,
        appartment: null,
        townId: this.$store.state.user.profile.townId,
        managerId: this.$store.state.user.profile.id,
        manager: this.$store.state.user.profile,
      },
    };
  },
  computed: {
    showAfterCallInfo() {
      return [STATUS_DONE, STATUS_PAID].includes(this.request.status);
    },
    showSubway() {
      return [SPB, MSK].includes(this.request.townId);
    },
    addressRequired() {
      return [SPB_C, MSK_C].includes(this.request.townId);
    },
    showContactPhone1() {
      return this.request.contactPhone || this.showContactPhone1Field;
    },
    showContactPhone2() {
      return this.request.contactPhone2 || this.showContactPhone2Field;
    },
    clientName() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    managerName() {
      return this.request && this.request.managerId && this.request.manager
        ? getUserName(this.request.manager)
        : "";
    },
    assigneeName() {
      return this.request && this.request.assigneeId && this.request.assignee
        ? getUserName(this.request.assignee)
        : "";
    },
    id() {
      return this.request.id;
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = clone(obj);
      },
    },
  },
  created() {},
  methods: {
    getRequestProjectTitle,
    productNetCost,
    productSum,
    hasAnyProducts,
    canDeleteResultFiles,
    getDate,
    getRequestAssigneeName,
    getAftercallStatusTitle,
    getAftercallFailStatusTitle,
    getAftercallManagerTitle,
    formatNumber,
    equipmentSum,
    hasAnyResources,
    hasAnyServices,
    servicesSum,
    getRequestAddressFormatted,
    getRequestNumber,
    getRequestCreateDatetime,
    getTownTitle,
    calcProfitSum,
    calcTotalProfitSum,
    getRequestPestName,
    getRequestManagerName,
    getRequestClientName,
    getRequestFacilityName,
    getClientName,
    getUserName,
    getStatusTitle,
    getSubwayTitle,
    getClientNameWithContacts,
    getRequestDatetime,
    getRequestStatus,
    getRequestSourceUrl,
    deleteResultFile(fileIndex) {
      let delUrl = "/pest/request/delete-result-file/";
      this.$http
        .post(delUrl, {
          requestId: this.request.id,
          fileId: this.request.resultFiles[fileIndex]["id"],
        })
        .then(() => {
          this.request.resultFiles.splice(fileIndex, 1);
          this.$bvToast.toast("Файл удален", {
            variant: "success",
          });
        })
        .catch(() => {
          this.$bvToast.toast("Ошибка удаления файла", {
            variant: "danger",
          });
        });
    },
    clientNameF() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    confirmReanimation() {
      if (confirm("Подтвердите возврат сделки")) {
        this.$emit("reanimate-request", this.request);
      }
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },
    toggleCorrectResult(updating) {
      this.correctingResult = updating;
    },
    afterRequestCorrected(request) {
      console.log(request);
      this.$emit("request-changed", request);
      this.toggleCorrectResult(false);
    },
    toggleAftercallResult(updating) {
      this.enteringAftercallResults = updating;
    },
    afterAftercallCorrected(request) {
      this.$emit("request-changed", request);
      this.toggleAftercallResult(false);
    },
    createReturnVisit(request) {
      this.$emit("add-new-request", request);
    },
    makeCallInternal(communication, phone) {
      console.log(communication);
      if (this.calling) {
        return;
      }
      let callUrl = "/pest/request/manager-call/" + this.request.id;
      this.calling = true;
      this.$http
        .get(callUrl, {
          params: {
            phone: phone,
            clientId: communication.clientId,
          },
        })
        .then((response) => {
          this.$bvToast.toast("Осуществляется звонок", {
            title: "Ожидайте",
            variant: "success",
          });
          this.calling = false;
          // if (successCallback) {
          //   successCallback();
          // }
        })
        .catch((error) => {
          let message = "Не удалось совершить звонок";
          if (error.response.data.error) {
            message = error.response.data.error;
          }
          this.$bvToast.toast(message, {
            title: "Ошибка",
            variant: "danger",
          });
          this.calling = false;
          Sentry.captureException(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-card {
  border: 1px solid #e5e6e7;
  width: 250px;
  min-width: 250px;
}

.contact-card ul {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

.contact-card ul .ml-auto:first-child {
  margin: 0px !important;
}
@media (max-width: 640px) {
  .toptitle {
    display: block !important;
  }
  .toptitle > div {
    margin: 5px auto !important;
  }
}
</style>
