
<div class="pest-call-cause-page">
  <div class="ibox">
    <div class="ibox-title pb-3">
      <div class="d-flex">
        <div class="ml-1 mr-2 mt-2">
          <router-link
            :to="{ name: 'pest-call-cause-list' }"
            class="h4 text-muted"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>
        </div>
        <div>
          <h1>
            {{ callCause.title }}
          </h1>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <pest-call-cause-info
        :call-cause="callCause"
        @update-prop="updateProp"
      ></pest-call-cause-info>
    </div>
  </div>
</div>
