<template>

  <b-form :model="documentType" id="documentType-form" ref="documentType" class="documentType-form "
          @submit="createOrUpdate" @reset="onReset"
          novalidate>
    <div class="div">

      <div class="row">
        <div class="col-12">
          <b-form-group label="Название" prop="title">
            <b-form-input v-model="documentType.title" placeholder="Название"
                          tabindex="1"></b-form-input>
          </b-form-group>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea v-model="documentType.comment" placeholder="Описание"
                             tabindex="1"></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check"/>
            {{ submitButton }}
          </b-button>


          <a href="" v-if="documentType.id!=='new'" class="btn btn-outline btn-danger ml-2"
             @click.prevent="deleteDocumentType">
            <font-awesome-icon icon="trash"/>
            Удалить
          </a>

          <router-link :to="returnUrl" class="btn btn-outline btn-default ml-2" v-if="returnUrl">
            <font-awesome-icon icon="arrow-left"/>
            Назад
          </router-link>
        </div>
      </div>

    </div>

  </b-form>
</template>

<script>

import {getUserName} from "../../../crm/common";
import {VueMaskDirective} from 'v-mask'

export default {
  directives: {mask: VueMaskDirective},
  name: 'documentType-form',
  components: {},
  props: [
    'documentType',
    'returnUrl',
  ],
  data() {
    return {
      phoneMask: false,
      loading: false,
      submitButton: 'Сохранить',
    }
  },
  computed: {
    id() {
      return this.documentType.id;
    },
    name() {
      let name = this.documentType.name ? this.documentType.name : 'Новая задача';
      return name ? name : 'Задача';
    }
  },
  created: function () {
  },
  watch: {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = '/papers/types/' + this.id;
      this.loading = true;
      this.$http.put(updateUrl, this.documentType).then(response => {
        this.documentType = response.data;
        this.loading = false;
        this.$eventHub.$emit('update-documentType-info', this.documentType);
        this.$router.push({name: 'document-type-list'});
        this.$bvToast.toast('Данные сохранены', {
          title: 'Тип отредактирован',
          type: 'success'
        })
      }).catch(error => {
        this.loading = false;
        if (!this.processRequestError(error)) {
          this.$bvToast.toast('Данные не сохранены', {
            title: 'Не удалось сохранить данные',
            type: 'danger'
          })
        }
      })
    },
    create: function () {
      let updateUrl;
      updateUrl = '/papers/types';
      this.loading = true;
      this.$http.post(updateUrl, this.documentType).then(response => {
        this.documentType = response.data;
        this.$eventHub.$emit('update-documentType-info', this.documentType);
        this.$router.push({name: 'document-type-list'});
        this.$bvToast.toast('Тип документа добавлен', {
          title: 'Тип документа добавлен',
          variant: 'success'
        });
      }).catch(error => {
        this.loading = false;
        if (!this.processRequestError(error)) {
          this.$bvToast.toast('Ошибка', {
            title: 'Не удалось добавить тип документа',
            variant: 'danger'
          })
        }
      })
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById('documentType-form');
      let valid = form.checkValidity();
      form.classList.add('was-validated');
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast('Данные сохраняются', {
            title: 'Подождите',
            variant: 'info'
          });
          return;
        }
        if (this.id === 'new') {
          this.create()
        } else {
          this.update()
        }
        form.classList.remove('was-validated');
      } else {
        this.$bvToast.toast('В форме содержатся ошибки', {
          variant: 'danger'
        });
        return false
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteDocumentType() {
      if (confirm('Вы действительно хотите удалить тип?')) {
        let deleteUrl = '/papers/types/' + this.documentType.id;
        this.$http.delete(deleteUrl).then(response => {
          this.loading = false;
          this.$router.push({name: 'document-type-list'});
          this.$bvToast.toast('Тип удален', {
            title: 'Тип удален',
            variant: 'success'
          });
        }).catch(error => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast('Ошибка', {
              title: 'Не удалось удалить тип',
              variant: 'danger'
            })
          }
        })

      }
    }


  }
}
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
