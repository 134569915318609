
<div>
  <h2>
    Мои дела
    <small>
      <router-link
        v-if="canAddIssue()"
        :to="{ name: 'issue-create' }"
        class="btn btn-primary btn-sm"
      >
        <font-awesome-icon icon="plus" />
        Добавить дело
      </router-link>
    </small>
  </h2>

  <b-table
    v-if="issues.length"
    responsive
    striped
    hover
    condensed
    :items="issues"
    :fields="fields"
    :busy="loading"
  >
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle table-spinner"></b-spinner>
        <strong> Данные подгружаются</strong>
      </div>
    </template>

    <template #cell(title)="data">
      <router-link
        :to="{ name: 'issue-view', params: { id: data.item.id } }"
        class=""
      >
        {{ getIssueTitle(data.item) }}
      </router-link>
      <br />
      <small>
        {{ getIssueGroupTitle(data.item) }}
      </small>
    </template>

    <template #cell(status)="data">
      {{ getIssueStatus(data.item) }}
    </template>

    <template #cell(client)="data">
      <router-link
        v-if="data.item.clientId"
        :to="{ name: 'crm-client-view', params: { id: data.item.clientId } }"
        class=""
      >
        {{ getIssueClientName(data.item) }}
      </router-link>
      <div v-else>
        {{ getIssueClientName(data.item) }}
      </div>
    </template>

    <template #cell(manager)="data">
      <router-link
        v-if="data.item.managerId"
        :to="{ name: 'users-user-view', params: { id: data.item.managerId } }"
        class=""
      >
        {{ getIssueManagerName(data.item) }}
      </router-link>
      <div v-else>
        {{ getIssueManagerName(data.item) }}
      </div>
    </template>

    <template #cell(timeCreated)="data">
      {{ getIssueCreateTime(data.item) }}
    </template>

    <template #cell(deadline)="data">
      {{ getIssueDeadline(data.item) }}
    </template>

    <template #cell(timeUpdated)="data">
      {{ getIssueUpdateTime(data.item) }}
    </template>
  </b-table>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
