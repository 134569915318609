
<div class="ibox mb-1">
  <div class="ibox-content">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h2>Системное</h2>
        <ul>
          <li>
            <a class="" @click.prevent="testErrorRequest">
              Test Error Sentry Call
              <span class="hint">/app/site/e500</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
