<template>
  <div>
    <a v-if="show" href="" @click.prevent="toggle">Скрыть</a>
    <a v-else href="" @click.prevent="toggle">Показать подробности</a>
    <div v-if="show">
      <table class="table table-striped request-info">
        <tbody>
          <tr
            v-for="(info, ind) in log.fields"
            :key="ind"
            :class="getClass(info)"
          >
            <td class="title">
              <font-awesome-icon :icon="getIcon(info)" fixed-width />
              {{ getTitle(info) }}
            </td>
            <td class="val">
              <div v-if="info.attr === 'time'">
                {{ getDatetime(info.oldVal) }}
              </div>
              <div v-else-if="info.attr === 'project'">
                {{ getProjectTitle(info.oldVal) }}
              </div>
              <div v-else-if="info.attr === 'clientType'">
                {{ info.oldVal === CLIENT_TYPE_ORG ? "Юр. лицо" : "Физ. лицо" }}
              </div>
              <div v-else-if="info.attr === 'paymentType'">
                {{ info.oldVal === PT_CASHLESS ? "Безнал" : "Нал" }}
              </div>
              <div v-else-if="info.attr === 'resultFiles'">
                <span v-for="(file, fIndex) in info.oldVal" :key="'i' + fIndex">
                  <a
                    v-b-tooltip.hover
                    :href="file.url"
                    :title="file.name"
                    target="_blank"
                  >
                    {{ file.name + "." + file.extension }}
                  </a>
                </span>
              </div>
              <div v-else-if="isBoolAttr(info)">
                {{ info.oldVal ? "Да" : "Нет" }}
              </div>
              <div v-else>{{ getOldVal(info) }}</div>
            </td>
            <td class="arrow">
              <font-awesome-icon icon="arrow-right" />
            </td>
            <td class="val">
              <div v-if="info.attr === 'time'">
                {{ getDatetime(info.newVal) }}
              </div>
              <div v-else-if="info.attr === 'project'">
                {{ getProjectTitle(info.newVal) }}
              </div>
              <div v-else-if="info.attr === 'clientType'">
                {{ info.newVal === CLIENT_TYPE_ORG ? "Юр. лицо" : "Физ. лицо" }}
              </div>
              <div v-else-if="info.attr === 'paymentType'">
                {{ info.newVal === PT_CASHLESS ? "Безнал" : "Нал" }}
              </div>
              <div v-else-if="info.attr === 'resultFiles'">
                <div v-if="info.newVal.length" class="files-block">
                  <span
                    v-for="(file, fIndex) in info.newVal"
                    :key="'i' + fIndex"
                  >
                    <a
                      v-b-tooltip.hover
                      :href="file.url"
                      :title="file.name"
                      target="_blank"
                    >
                      {{ file.name + "." + file.extension }}
                    </a>
                  </span>
                </div>
                <div v-else>Нет файлов</div>
              </div>
              <div v-else-if="isBoolAttr(info)">
                {{ info.newVal ? "Да" : "Нет" }}
              </div>
              <div v-else>{{ getNewVal(info) }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  getDatetime,
  PT_CASHLESS,
} from "@/modules/pest/decorators/requests";
import { getProjectTitle } from "@/modules/pest/decorators/project";
export default {
  name: "RequestLogChangedFields",
  components: {},
  props: ["log"],
  data() {
    return {
      show: true,
      request: {},
      fields: {
        address: {
          icon: "map",
          title: "Улица",
        },
        time: {
          icon: "clock",
          title: "Время",
        },
        clientType: {
          icon: "user",
          title: "Тип клиента",
        },
        townId: {
          icon: "map-marker",
          title: "Город",
        },
        facilityId: {
          icon: "building",
          title: "Объект",
        },
        paymentType: {
          icon: "money-bill",
          title: "Тип оплаты",
          // val:(info)=>{getPaymentTypeTitle}
        },
        subwayId: {
          icon: "map",
          title: "Метро (старое)",
        },
        pestId: {
          icon: "bug",
          title: "Вредитель",
        },
        // clientId: {
        //   icon: "client",
        //   title: "Имя клиента",
        // },
        // leadStatusId: {
        //   icon: "map",
        //   title: "Адрес",
        // },
        clientPaymentReceived: {
          icon: "money-bill",
          title: "Оплачено",
        },
        sourceId: {
          icon: "map",
          title: "Источник",
        },
        managerId: {
          icon: "user",
          title: "Менеджер",
        },
        assigneeId: {
          icon: "user",
          title: "Мастер",
        },
        sum: {
          icon: "money-bill",
          title: "Сумма",
        },
        addressCoords: {
          icon: "map",
          title: "Координаты",
        },
        addressInfo: {
          icon: "map",
          title: "Доп информация",
        },
        duration: {
          icon: "clock",
          title: "Длительность",
        },
        comment: {
          icon: "comment",
          title: "Комментарий",
        },
        warning: {
          icon: "exclamation-triangle",
          title: "Особое внимание",
        },
        // phone: {
        //   icon: "map",
        //   title: "Адрес",
        // },
        fromExchange: {
          icon: "map",
          title: "Биржа",
        },
        resultFiles: {
          icon: "file",
          title: "Файлы",
        },
        // contactPhone: {
        //   icon: "map",
        //   title: "Адрес",
        // },
        // contactPhone2: {
        //   icon: "map",
        //   title: "Адрес",
        // },
        // clientName: {
        //   icon: "map",
        //   title: "Адрес",
        // },

        addressIndex: {
          icon: "map",
          title: "Индекс",
        },
        addressTown: {
          icon: "map",
          title: "Город",
        },
        addressHouse: {
          icon: "map",
          title: "Дом",
        },
        addressBlock: {
          icon: "map",
          title: "Корпус",
        },
        addressEntrance: {
          icon: "map",
          title: "Подъезд",
        },
        addressFloor: {
          icon: "map",
          title: "Этаж",
        },
        addressIntercom: {
          icon: "map",
          title: "Домофон",
        },
        appartment: {
          icon: "door-open",
          title: "Квартира",
        },
        tripDistance: {
          icon: "car",
          title: "Дорога, км",
        },
        tripSum: {
          icon: "car",
          title: "Дорога, р",
        },
        rejectionComment: {
          icon: "exclamation-triangle",
          title: "Причина провала",
        },
        project: {
          icon: "paperclip",
          title: "Проект",
        },
      },
      CLIENT_TYPE_ORG,
      PT_CASHLESS,
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        // this.field = obj.record;
      },
    },
  },
  methods: {
    getProjectTitle,
    getUserName,
    getDatetime,
    toggle() {
      this.show = !this.show;
    },
    isBoolAttr(info) {
      return ["clientPaymentReceived", "fromExchange"].includes(info.attr);
    },
    getClass(info) {
      if (info.attr in this.fields) {
        if ("class" in this.fields[info.attr]) {
          return this.fields[info.attr].class;
        }
      }
      return "";
    },
    getTitle(info) {
      if (info.attr in this.fields) {
        return this.fields[info.attr].title;
      }
      return "";
    },
    getOldVal(info) {
      if (info.attr in this.fields) {
        if ("val" in this.fields[info.attr]) {
          return this.fields[info.attr].val(info);
        }
      }
      return info.oldVal ? info.oldVal : "[Не задано]";
    },
    getNewVal(info) {
      if (info.attr in this.fields) {
        if ("val" in this.fields[info.attr]) {
          return this.fields[info.attr].val(info);
        }
      }
      return info.newVal ? info.newVal : "[Не задано]";
    },
    getIcon(info) {
      if (info.attr in this.fields) {
        return this.fields[info.attr].icon;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100px;
}

.arrow {
  width: 30px;
}
.val {
  width: 40%;
}
</style>
