<template>
  <datepicker
    ref="datepicker"
    v-model="date"
    :editable="true"
    :format="dateFormat"
    :input-class="inputClass"
    :lang="lang"
    :placeholder="placeholder"
    :value-type="valueFormat"
    prefix-class="xmx"
    type="date"
    @change="onChange"
  >
    <template #sidebar>
      <ul>
        <li @click.prevent="selectToday"><a href="#"> Сегодня </a></li>
        <li @click.prevent="selectYesterday"><a href="#"> Вчера </a></li>
        <li @click.prevent="selectTomorrow"><a href="#"> Завтра </a></li>
      </ul>
    </template>
  </datepicker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";

export default {
  name: "DatePicker",
  components: {
    Datepicker: DatePicker,
  },
  data() {
    return {
      date: this.value,
      dayjsFormat: {
        // переопределяем, поскольку локализация даты в dayjs реализована с падежами
        stringify: (date, format) => {
          return date ? dayjs(date).format(format) : "";
        },
        parse: (value, format) => {
          return value ? dayjs(value, format).toDate() : null;
        },
      },
    };
  },
  props: {
    value: {
      default: new Date(),
    },
    dateFormat: {
      type: String,
      default: "DD.MM.YYYY",
    },
    valueFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
    placeholder: {
      type: String,
      default: "Выберите дату",
    },
    inputClass: {
      type: String,
      default: "form-control",
    },
    lang: {
      type: String,
      default: "ru",
    },
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler(obj) {
        this.date = this.value;
      },
    },
  },
  created: function () {},
  methods: {
    onChange() {
      this.$emit("input", this.date);
      this.$emit("change", this.date);
      this.$refs.datepicker.closePopup();
    },
    selectToday() {
      this.date = dayjs().format(this.valueFormat);
      this.onChange();
    },
    selectYesterday() {
      this.date = dayjs().subtract(1, "day").format(this.valueFormat);
      this.onChange();
    },
    selectTomorrow() {
      this.date = dayjs().add(1, "day").format(this.valueFormat);
      this.onChange();
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
}

ul li {
  list-style: none;
  padding: 0;
}

ul li a {
  display: block;
  padding: 5px;
  color: inherit !important;
}

ul li:hover {
  background: #f5f4f4;
  border-radius: 5px;
  color: $inspinia-primary;
}
</style>
