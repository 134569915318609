
<b-form
  v-if="dataLoaded"
  id="client-form"
  ref="client"
  :model="client"
  class="client-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Фамилия" prop="lastName">
          <b-form-input v-model="client.lastName" placeholder="Фамилия" />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Имя" prop="firstName">
          <b-form-input v-model="client.firstName" placeholder="Имя" />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Отчество" prop="patronymic">
          <b-form-input v-model="client.patronymic" placeholder="Отчество" />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Организация" prop="firm">
          <b-form-input v-model="client.firm" placeholder="Организация" />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Должность" prop="post">
          <b-form-input v-model="client.post" placeholder="Должность" />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Ответственный" prop="responsiblePerson">
          <b-form-input v-model="client.responsiblePerson"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div
      v-for="(phone, phoneIndex) in client.phones"
      :key="'p' + phoneIndex"
      class="row"
    >
      <div class="col-12">
        <b-form-group label="Телефон" prop="phone">
          <b-form-input
            v-model="phone.phone"
            v-mask="'+7 (###) ###-####'"
          ></b-form-input>
          <small class="form-text text-muted">
            <a
              v-if="phoneIndex === 0"
              href="#"
              @click.prevent="addPhone(phoneIndex)"
              >Добавить</a
            >
            <a
              v-if="phoneIndex > 0 && client.phones.length > 0"
              href="#"
              @click.prevent="removePhone(phoneIndex)"
              >Удалить</a
            >
          </small>
        </b-form-group>
      </div>
    </div>
  </div>

  <div class="">
    <b-form-group label="Адрес" prop="address">
      <b-form-input v-model="client.address"></b-form-input>
    </b-form-group>
  </div>

  <b-button variant="primary" @click="createOrUpdate">
    <font-awesome-icon icon="check" />
    Добавить
  </b-button>

  <b-button variant="outline-default" class="ml-2" @click="cancelUpdate">
    <font-awesome-icon icon="times" />
    Отмена
  </b-button>

  <b-button
    v-if="client.id > 0"
    variant="danger"
    class="ml-2"
    @click="deleteClient"
  >
    <font-awesome-icon icon="trash" />
    Удалить
  </b-button>
</b-form>
