
<div>
  <input
    id="file-input"
    ref="file"
    class="d-none"
    type="file"
    @input="inputChanged"
  />
  <button
    v-if="isAdmin"
    class="btn btn-info"
    :disabled="loading"
    @click="openFileInput"
  >
    Загрузить из файла, csv
  </button>

  <font-awesome-icon v-if="loading" icon="rotate" class="ml-2" spin />
</div>
