<template>
  <datepicker
    ref="datepicker"
    v-model="dateRange"
    :input-class="inputClass"
    :editable="true"
    :lang="lang"
    :format="dateFormat"
    :placeholder="placeholder"
    :value-type="valueFormat"
    :formatter="dayjsFormat"
    range
    type="date"
    prefix-class="xmx"
    @change="onChange"
  >
    <template #sidebar>
      <ul>
        <li @click.prevent="selectToday"><a href="#"> Сегодня </a></li>
        <li @click.prevent="selectYesterday"><a href="#"> Вчера </a></li>
        <li @click.prevent="selectLastWeek"><a href="#"> Неделя </a></li>
        <li @click.prevent="selectLastMonth"><a href="#"> Месяц </a></li>
        <li @click.prevent="selectLastYear"><a href="#"> Год </a></li>
        <li @click.prevent="selectTillToday"><a href="#"> До сегодня </a></li>
      </ul>
    </template>
  </datepicker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import "dayjs/locale/ru";

const { updateCalendars } = DatePicker.CalendarRange.methods;

DatePicker.CalendarRange.methods.updateCalendars = function (
  calendars,
  adjustIndex = 0
) {
  updateCalendars.call(this, calendars, adjustIndex);
};

dayjs.locale("ru");

export default {
  name: "DateRangePicker",
  components: {
    Datepicker: DatePicker,
  },
  props: {
    value: {
      type: Array,
      default: () => [new Date(), new Date()],
    },
    dateFormat: {
      type: String,
      default: "DD MMM YYYY",
    },
    valueFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
    placeholder: {
      type: String,
      default: "Выберите период",
    },
    inputClass: {
      type: String,
      default: "form-control",
    },
    lang: {
      type: String,
      default: "ru",
    },
  },
  data() {
    return {
      dateRange: this.value,
      dayjsFormat: {
        // переопределяем, поскольку локализация даты в dayjs реализована с падежами
        stringify: (date, format) => {
          return date ? dayjs(date).format(format) : "";
        },
        parse: (value, format) => {
          return value ? dayjs(value, format).toDate() : null;
        },
      },
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {
    onChange() {
      this.$emit("input", this.dateRange);
      this.$emit("change", this.dateRange);
      this.$refs.datepicker.closePopup();
    },
    selectToday() {
      let today = dayjs().format(this.valueFormat);
      this.dateRange = [today, today];
      this.onChange();
    },
    selectYesterday() {
      let yesterday = dayjs().subtract(1, "day").format(this.valueFormat);
      this.dateRange = [yesterday, yesterday];
      this.onChange();
    },
    selectLastWeek() {
      let today = dayjs().format(this.valueFormat);
      let lastWeek = dayjs().subtract(7, "day").format(this.valueFormat);
      this.dateRange = [lastWeek, today];
      this.onChange();
    },
    selectLastMonth() {
      let today = dayjs().format(this.valueFormat);
      let lastMonth = dayjs().subtract(1, "month").format(this.valueFormat);
      this.dateRange = [lastMonth, today];
      this.onChange();
    },
    selectLastYear() {
      let today = dayjs().format(this.valueFormat);
      let lastYear = dayjs().subtract(1, "year").format(this.valueFormat);
      this.dateRange = [lastYear, today];
      this.onChange();
    },
    selectTillToday() {
      let today = dayjs().format(this.valueFormat);
      let start = "01.01.2020";
      this.dateRange = [start, today];
      this.onChange();
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
}

ul li {
  list-style: none;
  padding: 0;
}

ul li a {
  display: block;
  padding: 5px;
  color: inherit !important;
}

ul li:hover {
  background: #f5f4f4;
  border-radius: 5px;
  color: $inspinia-primary;
}
</style>
