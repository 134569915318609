
<div class="ibox">
  <div class="ibox-content">
    <h1>Яндекс Директ</h1>
    <div class="mb-2">
      <div class="mb-2">
        <button class="btn btn-primary" @click.prevent="downloadCampaigns">
          <font-awesome-icon
            v-if="downloadingCampaigns"
            icon="rotate"
            spin
            fixed-width
          />
          <font-awesome-icon v-else icon="list-ol" fixed-width />
          Обновить перечень кампаний
        </button>
      </div>

      <div class="mb-2">
        <button class="btn btn-primary" @click.prevent="downloadData">
          <font-awesome-icon
            v-if="downloadingData"
            icon="rotate"
            spin
            fixed-width
          />
          <font-awesome-icon v-else icon="download" fixed-width />
          Загрузить данные по кампаниям
        </button>
      </div>

      <div class="mb-2">
        <button class="btn btn-primary" @click.prevent="recountMoney">
          <font-awesome-icon
            v-if="recountingMoney"
            icon="rotate"
            spin
            fixed-width
          />
          <font-awesome-icon v-else icon="money-bill" fixed-width />
          Пересчитать суммы затрат по рекламе
        </button>
      </div>

      <div class="mb-2">
        <button class="btn btn-primary" @click.prevent="recountResults">
          <font-awesome-icon
            v-if="recountingResults"
            icon="rotate"
            spin
            fixed-width
          />
          <font-awesome-icon v-else icon="file" fixed-width />
          Пересчитать итоговый отчет
        </button>
      </div>
    </div>
  </div>
</div>
