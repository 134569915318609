
<div class="mt-2">
  <div v-if="stats && stats.length" class="d-flex pest-stats-list flex-wrap">
    <div
      v-for="(stat, sIndex) in stats"
      :key="sIndex"
      class="pest-stat mr-2"
      :style="{ border: '3px solid  ', 'border-color': getBgColor(stat) }"
    >
      {{ stat.pest.title }}:
      <strong>{{ stat.countFirst }} | {{ stat.countOther }}</strong>
    </div>
  </div>
  <div v-else-if="stats && !stats.length"></div>
  <div v-else>Загружается</div>
</div>
