<template>
  <div>
    <a v-if="show" href="" @click.prevent="toggle">Скрыть</a>
    <a v-else href="" @click.prevent="toggle">Показать подробности</a>
    <request-log-created-info
      v-if="request && show"
      :request="request"
      class="mt-2"
    />
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import RequestLogCreatedInfo from "@/modules/pest/views/logs/details/RequestLogCreatedInfo";

export default {
  name: "LeadLogCreatedManually",
  components: { RequestLogCreatedInfo },
  props: ["log"],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
