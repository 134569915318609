
<div>
  <div v-if="isGeoForbidden" class="panel panel-danger mb-4">
    <div class="panel-heading">
      <font-awesome-icon icon="exclamation-triangle" />
      У вас заблокирована геолокация
    </div>
    <div class="panel-body">
      Вы не сможете сдать кассу, пока не разрешите определение
      месторасположения для сайта в вашем браузере и не обновите страницу.
    </div>
  </div>

  <div v-if="hasGeoError" id="geoError" class="panel panel-danger mb-4">
    <div class="panel-heading">
      <font-awesome-icon icon="exclamation-triangle" />
      Вы не находитесь в точке инкассации
    </div>
    <div class="panel-body">
      По вашим координатам не найден магазин. Если вы прибыли в нужную точку,
      попробуйте перезагрузить устройство для более точного определения
      местоположения или свяжитесь с администратором.
    </div>
  </div>

  <div class="ibox">
    <div class="ibox-content">
      <b-form
        id="pest-deposit-form"
        ref="deposit"
        :model="deposit"
        :coords="coords"
        class="pest-deposit-form"
        novalidate
        @submit="createOrUpdate"
        @reset="onReset"
      >
        <div class="div">
          <div v-if="loadingStats">Данные загружаются</div>
          <div v-else-if="!loadingStats && !count">
            <div class="panel panel-primary mb-0">
              <div class="panel-heading">
                <!--                  <font-awesome-icon icon="warning"/>-->
                У вас нет незакрытых смен!
              </div>
            </div>
          </div>

          <div v-else>
            <div>
              <h2>Выберите даты</h2>
              <button
                v-if="false"
                class="btn btn-info"
                @click.prevent="fetchDepositData"
              >
                Refresh
              </button>

              <div v-if="loadingStats">Данные загружаются</div>
              <div v-else-if="days">
                <table class="table table-bordered table-condensed">
                  <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Сдать</th>
                      <th>Штраф</th>
                      <th>Депозит</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(day, dIndex) in days"
                      :key="dIndex"
                      @click.prevent="toggleDay(day)"
                    >
                      <td class="">
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            v-model="day.checked"
                            type="checkbox"
                            class="mr-1"
                            @click.prevent
                          />
                          <span>
                            {{ day.dayF2 }}
                          </span>
                        </div>
                      </td>
                      <td>{{ formatNumber(day.requestSum) }}</td>
                      <td>{{ formatNumber(day.fineSum) }}</td>
                      <td>{{ formatNumber(day.pledgeSum) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>У вас нет долга</div>
            </div>
            <div>
              <h2>Сдать кассу за</h2>

              <div class="d-flex align-items-center">
                <div class="w150"><strong> Заявки:</strong></div>
                <div class="h5 pt-0 mt-0 mr-1 ml-auto">
                  {{ formatNumber(totalRequestSum) }}
                </div>
                руб.
              </div>
              <div class="d-flex align-items-center">
                <div class="w150"><strong> Штрафы:</strong></div>
                <div class="h5 pt-0 mt-0 mr-1 ml-auto">
                  {{ formatNumber(totalFineSum) }}
                </div>
                руб.
              </div>
              <div class="d-flex align-items-center">
                <div class="w150"><strong> Депозит:</strong></div>
                <div class="h5 pt-0 mt-0 mr-1 ml-auto">
                  {{ formatNumber(totalPledgeSum) }}
                </div>
                руб.
              </div>
              <div class="d-flex align-items-center">
                <div class="w150"><strong> Сумма к сдаче:</strong></div>
                <div class="h3 pt-0 mt-0 mr-1 ml-auto">
                  {{ formatNumber(totalSum) }}
                </div>
                руб.
              </div>
            </div>
            <div v-if="false">
              <b-form-group label="Заявки" prop="townId">
                <b-form-input v-model="totalRequestSum"></b-form-input>
              </b-form-group>
              <b-form-group label="Штрафы" prop="townId">
                <b-form-input v-model="totalFineSum"></b-form-input>
              </b-form-group>
              <b-form-group label="Депозит" prop="townId">
                <b-form-input v-model="totalPledgeSum"></b-form-input>
              </b-form-group>
              <b-form-group label="Сумма к сдаче" prop="townId">
                <b-form-input v-model="totalSum" disabled></b-form-input>
              </b-form-group>

              <b-form-group label="Координаты" prop="color">
                <b-form-input v-model="deposit.coords"></b-form-input>
              </b-form-group>
            </div>

            <div class="row">
              <div class="col">
                <b-button
                  v-if="!isGeoForbidden"
                  variant="primary"
                  @click="createOrUpdate"
                >
                  <font-awesome-icon icon="piggy-bank" />
                  Сдать кассу
                </b-button>
                <b-button v-else variant="default" @click.prevent>
                  <font-awesome-icon icon="piggy-bank" />
                  Сдать кассу
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</div>
