<template>
  <div
    class="p-2 mb-2"
    :class="{
      closed: isClosed(request),
      'request-card': !forEmployee,
      'employee-request-card': forEmployee,
    }"
    :style="{
      border:
        '3px  ' +
        getRequestBorderType(request) +
        ' ' +
        getRequestColor(request),
    }"
    @dblclick="openRequest"
  >
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="list-ol" fixed-width class="mr-1" />
        <!--              Описание-->
        <router-link
          v-if="request.clientId"
          :to="{ name: 'pest-request-view', params: { id: request.id } }"
          class=""
        >
          {{ request.externalId }}
        </router-link>
        <span v-else>[Не назначен]</span>
      </div>
      <div class="ml-auto">
        <span v-if="request.isFirstTime">Первичное</span>
        <span v-else>Вторичное</span>
      </div>
    </div>
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon
          v-if="request.isOrganization"
          icon="building"
          fixed-width
          class="mr-1"
        />
        <font-awesome-icon v-else icon="user" fixed-width class="mr-1" />
        <span
          v-if="request.clientId"
          :to="{ name: 'pest-request-view', params: { id: request.id } }"
          class=""
        >
          {{ getRequestClientName(request) }}
        </span>
        <span v-else>[Не назначен]</span>
      </div>
      <div v-if="!forEmployee" class="ml-auto">
        <a class="edit-assignee-link" @click.prevent="openAssigneePopup">
          <font-awesome-icon icon="edit" fixed-width />
          <font-awesome-icon icon="user-cog" fixed-width />
        </a>
      </div>
    </div>

    <div
      v-if="request.isOrganization && request.organizationTitle"
      class="mb-1"
    >
      <font-awesome-icon icon="building" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ request.organizationTitle }}
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="map" fixed-width class="mr-1" />
      <!--              координаты-->
      <span v-if="request.addressCoords">
        {{ request.addressCoords }}
      </span>
      <span v-else>Нет</span>
    </div>

    <div
      v-if="request.isOrganization && request.paymentType && false"
      class="mb-1"
    >
      <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ getPaymentTypeTitle(request) }}
    </div>

    <div
      v-if="!forEmployee"
      class="mb-1"
      :style="'color: ' + getRequestStatusColor(request)"
    >
      <font-awesome-icon icon="tag" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ getRequestStatus(request) }}
    </div>
    <div v-if="!forEmployee && false" class="mb-1">
      <font-awesome-icon icon="phone" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ request.phone }}
    </div>
    <div v-if="!forEmployee && request.contactPhone && false" class="mb-1">
      <font-awesome-icon icon="phone-volume" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ request.contactPhone }}
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="ruble-sign" fixed-width class="mr-1" />
      <!--              Сумма -->
      {{ request.sum }}

      <span v-if="request.earned"> / {{ request.earned }} </span>
      <span v-if="request.hasRecord">
        <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
      </span>

      <span v-if="request.isOrganization"> (Безнал) </span>
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="home" fixed-width class="mr-1" />
      <!--              Сумма -->
      {{ address(request.address) }}
    </div>

    <div v-if="request.subway" class="mb-1">
      <font-awesome-icon icon="map-marked-alt" fixed-width class="mr-1" />
      <!--              Метро -->
      {{ request.subway }}
    </div>
    <div
      v-if="request.pest && false"
      class="mb-1"
      :style="'color: ' + getRequestColor(request)"
    >
      <font-awesome-icon icon="bug" fixed-width class="mr-1" />
      <!--              Вредитель -->
      {{ getPestTitle(request.pest) }}
    </div>
    <div v-if="request.facility && false" class="mb-1">
      <font-awesome-icon icon="building" fixed-width class="mr-1" />
      <!--              Тип объекта -->
      {{ getFacilityTitle(request.facility) }}
    </div>
    <div v-if="request.town && false" class="mb-1">
      <font-awesome-icon icon="map-marker-alt" fixed-width class="mr-1" />
      <!--              Город -->
      {{ getRequestTownTitle(request) }}
    </div>
    <div class="mb-1 d-flex">
      <div>
        <!--              Время -->
        <font-awesome-icon icon="clock" fixed-width class="mr-1" />
        {{ getRequestDatetime(request) }}
      </div>
      <div class="ml-auto">
        <font-awesome-icon icon="stopwatch" fixed-width class="" />
        <a href="#" class="" @click.prevent="openTimePopup">
          {{ getRequestDuration(request) }}
        </a>
      </div>
    </div>

    <div v-if="request.warning && false" class="mb-1 text-warning">
      <font-awesome-icon icon="exclamation-triangle" fixed-width class="mr-1" />
      {{ request.warning }}
      <!--              Особое внимание -->
    </div>

    <div v-if="false" class="mb-1">
      <font-awesome-icon icon="comment" fixed-width class="mr-1" />
      {{ request.comment }}
      <!--              Время -->
    </div>
  </div>
</template>

<script>
import {
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAssigneeName,
  getRequestBorderType,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDuration,
  getRequestManagerName,
  getRequestStatus,
  getRequestStatusColor,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  servicesSum,
  STATUS_DECLINE,
  STATUS_DONE,
  STATUS_PAID,
} from "@/modules/pest/decorators/requests.js";
import { VueMaskDirective } from "v-mask";
import { getUserName } from "@/modules/crm/common.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";

export default {
  name: "RequestMapCard",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["request", "forEmployee"],
  data() {
    return {
      STATUS_DONE: STATUS_DONE,
      loading: false,
      closeRequest: false,
      decisionForm: false,
      header: "Клиент",
      submitButton: "Сохранить",
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    name() {
      let name = this.request.name ? this.request.name : "Новый клиент";
      return name ? name : "Клиент";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getFacilityTitle,
    getRequestTitle,
    getPaymentTypeTitle,
    getRequestStatusColor,
    getRequestColor,
    getRequestBorderType,
    getPestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    servicesSum,
    getRequestTownTitle,
    isClosed(request) {
      if (request.status === STATUS_DONE) {
        return true;
      }
      if (request.status === STATUS_PAID) {
        return true;
      }
      if (request.status === STATUS_DECLINE) {
        return true;
      }
      return false;
    },
    address(addr) {
      return addr ? addr.split("|")[0] : "";
    },
    openRequest() {
      this.$router.push({
        name: "pest-request-view",
        params: { id: this.request.id },
      });
    },
    updateTime(duration) {
      let updateUrl;
      updateUrl = "/pest/request/duration/" + this.id;
      // this.loading = true;
      this.$http
        .put(updateUrl, {
          duration: duration,
        })
        .then((response) => {
          this.$bvToast.toast("Данные сохранены", {
            title: "Время у заявки отредактировано",
            request: "success",
          });
        })
        .catch((error) => {
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Данные не сохранены", {
              title: "Не удалось отредактировать время",
              request: "danger",
            });
          }
        });
    },

    openTimePopup() {
      let $this = this;
      this.$eventHub.$emit("time-popup-show", {
        request: this.request,
        success: function (duration) {
          $this.request.duration = duration;
          $this.updateTime(duration);
        },
        cancel: function () {},
      });
    },
    openAssigneePopup() {
      let $this = this;
      let request = this.request;
      this.$eventHub.$emit("assignee-popup-show", {
        request: this.request,
        success: function (assignee) {
          let changed = false;
          let oldAssigneeId = request.assigneeId;
          if (assignee) {
            if (assignee.id !== request.assigneeId) {
              changed = true;
              request.assigneeId = assignee.id;
              request.assignee = assignee;
            }
          } else {
            if (request.assigneeId) {
              request.assigneeId = 0;
              request.assignee = null;
              changed = true;
            }
          }
          if (changed) {
            $this.$eventHub.$emit("assignee-changed", {
              request: request,
              assignee: assignee,
              oldAssigneeId: oldAssigneeId,
            });
          }
        },
        cancel: function () {},
      });
    },
  },
};
</script>

<style lang="scss">
.request-card {
  background: #fafafb;
  border: 1px solid #e7eaec;
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 2px;
  //border-left: 3px solid #f8ac59;
  //border-left: 3px solid #1ab394;
  //border-left: 3px solid #1c84c6;
  //border-left: 3px solid #ed5565;
  overflow-x: hidden;
  width: 240px;
}

.closed {
  opacity: 0.5;
}

.closed:hover {
  opacity: 1;
}

.request-card:hover {
  cursor: pointer;
  background: #fff;
}

.comment-link {
  border-bottom: 1px dashed;
}

.edit-assignee-link {
  display: none;
}

.request-card:hover .edit-assignee-link {
  display: block;
}

.edit-assignee-link:hover {
  margin-bottom: -1px;
  border-bottom: 1px dashed;
}
</style>
