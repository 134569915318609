
<div>
  <div class="ibox">
    <div class="ibox-title d-flex align-items-center pr-2">
      <h3>Отчет CPO по дате создания</h3>

      <div class="ml-2">
        <date-range-picker
          v-model="dateRange"
          @change="fetchData"
        ></date-range-picker>
      </div>

      <common-filter
        class="ml-2"
        data-key="cpo-report"
        :show-date="false"
        :show-status="false"
        :show-town="true"
        :show-pest="true"
        :show-facility="false"
        :show-assignee="false"
        :show-manager="false"
        :show-first-second="false"
        :show-client="false"
        :show-aftercall="false"
        :show-aftercall-fail="false"
        @search="startSearch"
      />
      <div class="ml-4">
        <button class="btn btn-primary" @click.prevent="showEmptyRows">
          <span v-if="showEmpty"
            >Скрыть источники с нулевыми значениями
          </span>
          <span v-else>Показать источники с нулевыми значениями </span>
          <font-awesome-icon icon="eye" />
        </button>
      </div>

      <div v-if="showChart" class="ml-auto">
        <button class="btn btn-outline-info" @click.prevent="hideChart">
          Скрыть
          <font-awesome-icon icon="chart-area" />
        </button>
      </div>
    </div>

    <div class="ibox-content">
      <b-overlay :show="loadingData" rounded="sm">
        <line-chart
          ref="chart"
          :source-data="data"
          :has-data="hasData"
          :date-to="dateTo"
          :date-from="dateFrom"
          :selected-towns="selectedTowns"
        ></line-chart>
        <div :class="{ 'd-none': hasData }">Отчет еще не построен</div>
        <div class="table-responsive" :class="{ 'd-none': !hasData }">
          <c-p-o-data-table
            ref="reportTable"
            :selected-pests="selectedPests"
            :has-data="hasData"
            :source-data="data"
            :pests="true"
            :show-empty="showEmpty"
          />
        </div>
      </b-overlay>
    </div>
  </div>
</div>
