
<div class="ibox mb-2">
  <div class="ibox-content pb-2">
    <notification-user-changed-assignee
      v-if="notification.type === REQUEST_CHANGED_ASSIGNEE"
      :notification="notification"
    />
    <notification-user-fine-approved
      v-else-if="notification.type === FINE_APPROVED"
      :notification="notification"
    />
    <notification-user-new-record
      v-else-if="notification.type === REQUEST_NEW_RECORD"
      :notification="notification"
    />
    <notification-user-message
      v-else-if="notification.type === MESSAGE"
      :notification="notification"
    />
    <div v-else>Что-то непонятное. Позовите программиста.</div>

    <div class="time text-right small">
      {{ getNotificationCreateTime(notification) }}
    </div>
  </div>
</div>
