import {getTypeTitle} from "@/modules/papers/decorators/types";

const getDocumentUrl = function (document) {
    if (document && document.file) {
        return document.file.url;
    }
    return '';
}
const getDocumentTitle = function (document) {
    if (document && document.name) {
        return document.name;
    }
    if (document && document.sourceName) {
        return document.sourceName;
    }
    return '[Без названия]';
};

const getDocumentTypeTitle = function (document) {
    if (document && document.type) {
        return getTypeTitle(document.type)
    }
    return '';
};

export {
    getDocumentUrl,
    getDocumentTitle,
    getDocumentTypeTitle
}
