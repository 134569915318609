
<div class="pest-service-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link
              :to="{ name: 'pest-service-list', id: service.id }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Изменение услуги
          </h1>
        </div>
        <div class="ibox-content">
          <pest-service-form :service="service"></pest-service-form>
        </div>
      </div>
    </div>
  </div>
</div>
