const AUTO_DISPATCH_OFF = 'off';
const AUTO_DISPATCH_BY_QUEUE = 'queue';
const AUTO_DISPATCH_BY_LOAD = 'load';


const getGroupTitle = function (group) {
    if (!group) {
        return '[Не назначено]';
    }
    if (group && group.title) {
        return group.title;
    }
    return '[Без названия]';
};

const getIssueTitle = function (issue) {
    if (issue && issue.title) {
        return issue.title;
    }
    return '[Без названия]';
};

const getGroupParent = function (group) {
    if (!group.parent) {
        return ''
    }
    return getGroupTitle(group.parent);
}


export {
    getGroupTitle, getGroupParent,
    AUTO_DISPATCH_OFF, AUTO_DISPATCH_BY_QUEUE, AUTO_DISPATCH_BY_LOAD,
};
