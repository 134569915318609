import ServiceDashboard from "@/modules/roistat/views/dashboard/ServiceDashboard";
import ProjectList from "@/modules/pest/views/project/ProjectList.vue";
import ProjectCreate from "@/modules/pest/views/project/ProjectCreate.vue";
import ProjectUpdate from "@/modules/pest/views/project/ProjectUpdate.vue";

export default [
  {
    path: "/service/dashboard",
    name: "service-dashboard",
    components: {
      default: ServiceDashboard,
    },
  },
  {
    path: "/project/page:page?",
    name: "pest-project-list",
    components: {
      default: ProjectList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/project/new",
    name: "pest-project-create",
    components: {
      default: ProjectCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/project/:id/update",
    name: "pest-project-update",
    components: {
      default: ProjectUpdate,
    },
    meta: { requiresAuth: true },
  },
];
