<template>
  <tr>
    <th>Итого</th>
    <th>{{ formatNumber(getROITotal()) }}</th>
    <th></th>
    <th></th>
    <th>{{ formatNumber(getTotalCount()) }}</th>
    <th>{{ formatNumber(data.total.newCount) }}</th>
    <th>
      <font-awesome-icon icon="circle" />
      {{ formatNumber(getTotalFailedCount()) }}
    </th>
    <th>{{ formatNumber(data.total.successAverageSum) }}</th>
    <th>{{ formatNumber(getTotalNetCostSum()) }}</th>
    <th>{{ formatNumber(getTotalProfitSum()) }}</th>
    <th>{{ formatNumber(data.total.secondaryCount) }}</th>
    <th>{{ formatNumber(data.total.secondaryNewCount) }}</th>
    <th>{{ formatNumber(data.total.secondaryFailedCount) }}</th>
    <th>{{ formatNumber(data.total.secondarySuccessAverageSum) }}</th>
    <th>{{ formatNumber(data.total.secondaryNetCostSum) }}</th>
    <th>{{ formatNumber(data.total.secondaryProfitSum) }}</th>
    <th>
      {{ formatNumber(getTotalProfitSum() + data.total.secondaryProfitSum) }}
    </th>
    <th>
      {{
        formatNumber(
          getConversionToSecondary(data.total.secondaryCount, getTotalCount())
        )
      }}
    </th>
  </tr>
</template>

<script>
import { formatNumber } from "@/modules/pest/decorators/common";
import {
  getConversionToSecondary,
  getNetCostSum,
  getProfitSum,
} from "@/modules/pest/decorators/cpo";

export default {
  name: "CPOTableTotalRow",
  components: {},
  data() {
    return {};
  },
  props: {
    data: { default: () => null, type: Object },
  },
  computed: {},
  created: function () {},
  methods: {
    formatNumber,
    getProfitSum,
    getNetCostSum,
    getConversionToSecondary,
    getTotalProfitSum() {
      if (!this.data || !this.data.results || !this.data.results.length) {
        return 0;
      }
      let sum = 0;
      for (let record of this.data.results) {
        sum += this.getProfitSum(record);
      }
      return sum;
    },

    getTotalNetCostSum() {
      if (!this.data || !this.data.results.length) {
        return 0;
      }
      let sum = 0;
      for (let record of this.data.results) {
        sum += this.getNetCostSum(record);
      }
      return sum;
    },
    getTotalFailedCount() {
      if (!this.data || !this.data.results.length) {
        return 0;
      }
      let sum = 0;
      for (let record of this.data.results) {
        sum += record.failedCount;
      }
      return sum;
    },

    getTotalCount() {
      if (!this.data || !this.data.results.length) {
        return 0;
      }
      let sum = 0;
      for (let record of this.data.results) {
        sum += record.count;
      }
      return sum;
    },
    getROITotal() {
      let totalFirstPlusSecondSuccessSum =
        this.data.total.successTotalSum +
        this.data.total.secondarySuccessTotalSum;
      let totalFirstPlusSecondNetCost =
        this.getTotalNetCostSum() + this.data.total.secondaryNetCostSum;
      if (totalFirstPlusSecondNetCost > 0) {
        let roi =
          ((totalFirstPlusSecondSuccessSum - totalFirstPlusSecondNetCost) /
            totalFirstPlusSecondNetCost) *
          100;
        return roi;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
