<template>
  <div>
    <strong>Старый статус: </strong> {{ getStatus(log.oldStatus) }} <br />
    <strong>Новый статус: </strong> {{ getStatus(log.newStatus) }}<br />
  </div>
</template>

<script>
import { statusTitles } from "@/modules/pest/decorators/requests";

export default {
  name: "RequestLogChangedStatus",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getStatus(status) {
      if (!statusTitles[status]) {
        return "[Не проставлен]";
      }
      return statusTitles[status];
    },
  },
};
</script>

<style lang="scss" scoped></style>
