
<div class="ibox mb-1">
  <div class="ibox-content pb-1">
    <table v-if="contacts.length > 0" class="table table-striped">
      <tbody>
        <tr v-for="(contact, index) in contacts" :key="index">
          <td>
            <router-link
              :to="{ name: 'crm-client-view', params: { id: contact.id } }"
              class=""
            >
              {{ getClientName(contact) }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="no-employees">У организации нет сотрудников</div>

    <router-link
      :to="{
        name: 'crm-client-create',
        params: { organizationId: client.id },
      }"
      class="btn btn-primary mt-2 mb-2"
    >
      <font-awesome-icon icon="plus" />
      Добавить контакт
    </router-link>
  </div>
</div>
