const getFolderTitle = function (folder) {
    if (folder && folder.title) {
        return folder.title;
    }
    return '[Без названия]';
};

export {
    getFolderTitle
}
