
<div v-if="showModal" id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="hideModal">
        <div ref="content" class="modal-tab-container gray-bg">
          <span class="d-block d-sm-none closeicon" @click="hideByButton"
            ><font-awesome-icon icon="close" size="2x"
          /></span>
          <div class="tabs-container">
            <div class="similar-block pb-3">
              <slot name="tabs"></slot>
            </div>

            <div class="modal-container">
              <div class="modal-header d-flex align-items-center pb-0">
                <div>
                  <slot name="header"> Buttons</slot>
                </div>
                <div class="ml-auto pb-3 d-flex">
                  <slot name="header-buttons"> Default header</slot>
                </div>
              </div>
              <div>
                <slot name="toptabs"></slot>
              </div>
              <div class="modal-body gray-bg">
                <slot name="default"> Default body</slot>
              </div>

              <div v-if="false" class="modal-footer">
                <slot name="footer">
                  Default footer
                  <button
                    class="modal-default-button"
                    @click="$emit('close')"
                  >
                    OK
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
