
<div>
  <drop-zone
    v-slot="{ dropZoneActive }"
    class="drop-area"
    @click="openFileInput"
    @files-dropped="addFiles"
  >
    <div>
      <div v-if="dropZoneActive">Перенесите файлы сюда для загрузки</div>
      <div v-else>
        Перенесите файл или <strong><em>нажмите сюда</em></strong
        >, чтобы загрузить файл
      </div>
    </div>
  </drop-zone>

  <input
    id="file-input"
    ref="file"
    class="d-none"
    multiple
    type="file"
    @input="inputChanged"
  />
  <progress
    v-if="uploadPercentage > 0"
    max="100"
    :value.prop="uploadPercentage"
  ></progress>
</div>
