
<div class="pest-penalty-page">
  <div class="ibox">
    <div class="ibox-title pb-3">
      <div class="d-flex">
        <div class="ml-1 mr-2 mt-2">
          <router-link
            :to="{ name: 'pest-penalty-list' }"
            class="h4 text-muted"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>
        </div>
        <div>
          <h1>
            {{ penalty.title }}
          </h1>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <pest-penalty-info
        :penalty="penalty"
        @update-prop="updateProp"
      ></pest-penalty-info>
    </div>
  </div>
</div>
