
<div class="pest-fine-page">
  <div class="row">
    <div class="col-md-6">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h2>
            <router-link
              :to="{ name: 'pest-fine-list', id: fine.id }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Штраф от {{ getFineTime(fine) }} – {{ getFineStatusTitle(fine) }}
          </h2>
        </div>
        <div class="ibox-content">
          <pest-fine-form :fine="fine"></pest-fine-form>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h2>Проверка</h2>
        </div>
        <div class="ibox-content">
          <pest-fine-review-form
            :fine="fine"
            @update-pest-fine-info="updateFine"
          ></pest-fine-review-form>
        </div>
      </div>
    </div>
  </div>
</div>
