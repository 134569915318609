
<div class="pest-call-cause-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>
            <router-link
              :to="{ name: 'pest-call-cause-list', id: callCause.id }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            Изменение причины
          </h1>
        </div>
        <div class="ibox-content">
          <pest-call-cause-form
            :call-cause="callCause"
          ></pest-call-cause-form>
        </div>
      </div>
    </div>
  </div>
</div>
