
<div class="ibox">
  <div class="ibox-title d-flex pr-2">
    <h4>Имитация входящего звонка</h4>
  </div>
  <div class="ibox-content">
    <p>
      У менеджера появится уведомление со звонком, и связанной с ним карточкой
    </p>
    <b-form
      id="test-call"
      ref="request"
      class="test-call"
      novalidate
      @submit.prevent="createOrUpdate('roistat')"
      @reset="onReset"
    >
      <div class="div">
        <b-form-group label="Номер телефона" prop="title">
          <b-form-input
            v-model="number"
            tabindex="1"
            placeholder=""
          ></b-form-input>
        </b-form-group>
        <!--          <b-form-group label="Номер заявки" prop="title">-->
        <!--            <b-form-input v-model="number" tabindex="1" required></b-form-input>-->
        <!--          </b-form-group>-->
        <b-form-group label="Менеджер" prop="title">
          <user-autocomplete
            v-model="userId"
            :default-value="getEmployeeName(user)"
            :type="userTypes"
            @selected="selectManager"
          />
        </b-form-group>
      </div>
    </b-form>
  </div>

  <div class="ibox-footer d-flex">
    <div class=" ">
      <b-button v-if="!saving" variant="primary" @click="createOrUpdate">
        <font-awesome-icon icon="number" />
        Инициировать звонок
      </b-button>

      <b-button v-if="!saving" variant="primary" @click="endCall">
        <font-awesome-icon icon="number" />
        Закончить звонок
      </b-button>
    </div>

    <b-button v-if="saving" variant="primary" disabled @click.prevent>
      <font-awesome-icon icon="sync" spin="" />
      Отправляется
    </b-button>
  </div>
</div>
