
<b-form
  v-if="dataLoaded"
  id="request-correct-form"
  ref="request"
  :model="request"
  class="request-correct-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div mb-2">
    <h3>
      Сумма: {{ totalSum(request) }} р | Доход:
      {{ earnTotalSumWithPresetRecord(request) }} | После вычетов:
      {{ recalculateTotalProfitSum(request) }} |
    </h3>
    <hr />

    <b-button-group class="mb-2 mr-2">
      <button
        class="btn"
        :class="request.hasRecord ? 'btn-outline-info' : 'btn-success'"
        @click.prevent="toggleRecord(false)"
      >
        Нет рекорда
      </button>
      <button
        class="btn"
        :disabled="!!request.fromExchange"
        :class="request.hasRecord ? 'btn-success' : 'btn-outline-info'"
        @click.prevent="toggleRecord(true)"
      >
        <font-awesome-icon icon="star" />
        Есть рекорд
      </button>
    </b-button-group>

    <b-button-group class="mb-2 mr-2">
      <button
        class="btn"
        :class="request.wereNovice ? 'btn-outline-info' : 'btn-success'"
        @click.prevent="toggleNovice(false)"
      >
        Обычный
      </button>
      <button
        class="btn"
        :class="request.wereNovice ? 'btn-success' : 'btn-outline-info'"
        @click.prevent="toggleNovice(true)"
      >
        Стажер
      </button>
    </b-button-group>

    <b-button-group class="mb-2">
      <button
        v-for="(aPercent, pIndex) in availablePercents"
        :key="pIndex"
        class="btn"
        :class="isPercent(aPercent) ? 'btn-success' : 'btn-outline-info'"
        disabled
        @click.prevent="setPercent(aPercent)"
      >
        {{ aPercent }}%
      </button>
    </b-button-group>
    <div v-if="!!request.fromExchange" class="mb-2">
      <span class="text-danger">
        * Нельзя назначить рекорд - заявка из биржи
      </span>
    </div>

    <b-form-group label="" prop="title">
      <b-form-textarea
        v-model="managerComment"
        placeholder="Комментарий"
        tabindex="1"
        required
      ></b-form-textarea>
    </b-form-group>

    <b-button variant="success" @click="createOrUpdate('ok')">
      <font-awesome-icon icon="check" />
      Скорректировать расчет
    </b-button>

    <b-button
      v-if="id !== 'new'"
      variant="outline-info"
      class="ml-2"
      @click="cancelUpdate"
    >
      <font-awesome-icon icon="times" />
      Отмена редактирования
    </b-button>
  </div>
</b-form>
