<template>
  <div>
    <b-button variant="primary" @click.prevent="addNewContact">
      <font-awesome-icon icon="plus" />
      Добавить контакт
    </b-button>
  </div>
</template>

<script>
export default {
  name: "PopupContactButtons",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    addNewContact() {
      this.$emit("add-new-contact");
    },
  },
};
</script>

<style lang="scss" scoped></style>
