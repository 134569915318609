
<div
  v-click-outside="cancelSearch"
  class="autocomplete-wrapper"
  @keydown.esc="cancelSearch"
>
  <b-form-input
    v-model="search"
    :disabled="disabled"
    :required="required"
    autocomplete="off"
    @change="startSearch"
    @keyup="startSearch"
    @focus="startSearch"
  ></b-form-input>
  <div v-if="(items && items.length) || addNew">
    <ul v-if="showResults" class="autocomplete-search-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="autocomplete-search-item"
        @click.prevent="selectItem(item)"
      >
        {{ getTitle(item) }}
      </li>
      <li v-if="addNew" class="autocomplete-search-item">Добавить клиента</li>
    </ul>
  </div>
</div>
