
<div class="notifications-message-page">
  <div class="row">
    <div class="col-md-6" style="margin: auto">
      <div class="ibox mb-1">
        <div class="ibox-title">
          <h1>Отправка сообщения</h1>
        </div>
        <div class="ibox-content">
          <notifications-message-form
            :message="message"
          ></notifications-message-form>
        </div>
      </div>
    </div>
  </div>
</div>
