<template>
  <div class="data-record-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>Новый вредитель</h1>
          </div>
          <div class="ibox-content">
            <data-record-form :record="record"></data-record-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";

export default {
  name: "Record",
  components: {
    "data-record-form": RecordForm,
  },
  data() {
    return {
      record: {
        id: "new",
        title: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
