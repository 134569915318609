
<b-form
  v-if="dataLoaded"
  id="request-result-form"
  ref="request"
  :model="request"
  class="result-request-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div mb-2">
    <b-form-group label="Длительность, мин" prop="title">
      <b-form-input
        v-model="request.duration"
        placeholder=""
        tabindex="1"
      ></b-form-input>
    </b-form-group>

    <div class="row">
      <div class="col-6">
        <b-form-group label="Стоимость проезда, р" prop="title">
          <b-form-input
            v-model="request.tripSum"
            placeholder="0"
            tabindex="1 required"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Длительность проезда, км" prop="title">
          <b-form-input
            v-model="request.tripDistance"
            placeholder=""
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group
          label="Стоимость проезда после последней заявки, р"
          prop="title"
        >
          <b-form-input
            v-model="request.tripBackSum"
            placeholder="0"
            tabindex="1 required"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group
          label="Длительность проезда после последней заявки, км"
          prop="title"
        >
          <b-form-input
            v-model="request.tripBackDistance"
            placeholder=""
            tabindex="1"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <hr />

    <b-form-group
      label="Сумма за оборудование, р"
      prop="equipmentUserSum"
      disabled
    >
      <b-form-input
        v-model="equipmentSum"
        placeholder=""
        tabindex="1"
      ></b-form-input>
      <div v-if="request.equipmentInfo" class="mt-1 font-bold">
        {{ request.equipmentInfo.title }}

        <span
          v-if="
            request.equipmentInfo.paymentType === EQUIPMENT_PAYMENT_PERCENT
          "
        >
          {{ request.equipmentInfo.percent }}% от стомости услуг
        </span>
        <span v-else> {{ request.equipmentInfo.price }}р фиксированно </span>
      </div>
    </b-form-group>

    <hr />

    <h4>Материалы</h4>

    <div v-if="request.resources.length > 0">
      <div
        v-for="(resource, rIndex) in request.resources"
        :key="'r' + rIndex"
        class="d-flex align-items-top"
      >
        <div class="mr-2">
          <b-form-group label="Материал">
            <b-form-select
              v-model="resource.resourceId"
              :options="resourcesList"
              placeholder=""
              class="resource-select"
              tabindex="1"
              required
              onfocus="this.size=5;"
              onblur="this.size=5;"
              onchange="this.size=5; this.blur();"
              onfocusout="this.size=null;"
              @change="setResourceTitle(resource, rIndex)"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Доз.на 1л, мл" prop="title">
            <b-form-input
              v-model="resource.dosage"
              placeholder=""
              type="number"
              tabindex="3"
              required
              step="0.01"
              min="0"
              @change="revertNegative('dosage', rIndex)"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="">
          <b-form-group label="Объем раствора, л">
            <b-form-input
              v-model="resource.solutionVolume"
              placeholder=""
              type="number"
              tabindex="2"
              step="0.01"
              min="0"
              required
              @change="revertNegative('solutionVolume', rIndex)"
            ></b-form-input>
          </b-form-group>

          <button
            v-if="rIndex > 0"
            class="btn btn-danger btn-outline mtfr"
            @click.prevent="removeResource(rIndex)"
          >
            <font-awesome-icon icon="trash" fixed-width />
          </button>
          <button
            v-else
            class="btn btn-primary btn-outline mtfr"
            @click.prevent="addResource"
          >
            <font-awesome-icon icon="plus" fixed-width />
          </button>
        </div>
      </div>
    </div>

    <h4>Услуги</h4>
    <div v-if="request.services.length > 0">
      <div
        v-for="(service, rIndex) in request.services"
        :key="'r' + rIndex"
        class="d-flex align-items-center"
      >
        <div class="">
          <b-form-group label="Услуга" prop="title">
            <b-form-select
              v-model="service.id"
              :options="servicesList"
              placeholder=""
              class="service-select"
              tabindex="1"
              required
              onfocus="this.size=5;"
              onblur="this.size=5;"
              onchange="this.size=5; this.blur();"
              onfocusout="this.size=null;"
              @change="setServiceTitle(service)"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="ml-2">
          <b-form-group label="Цена" prop="title">
            <b-form-input
              v-model="service.price"
              placeholder=""
              class="service-count"
              tabindex="1"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="ml-2 pt-2">
          <button
            v-if="rIndex > 0"
            class="btn btn-danger btn-outline"
            @click.prevent="removeService(rIndex)"
          >
            <font-awesome-icon icon="trash" fixed-width />
          </button>
          <button
            v-else
            class="btn btn-primary btn-outline"
            @click.prevent="addService"
          >
            <font-awesome-icon icon="plus" fixed-width />
          </button>
        </div>
      </div>
    </div>

    <h4>Товары</h4>
    <div v-if="request.products.length > 0">
      <div
        v-for="(product, rIndex) in request.products"
        :key="'r' + rIndex"
        class="d-flex align-items-top product-row"
      >
        <div class="">
          <b-form-group label="Товар" prop="title">
            <b-form-select
              v-model="product.id"
              :options="productList"
              placeholder=""
              class="service-select"
              tabindex="1"
              onfocus="this.size=5;"
              onblur="this.size=5;"
              onchange="this.size=5; this.blur();"
              onfocusout="this.size=null;"
              @change="setProductTitle(product)"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="ml-2">
          <b-form-group label="Цена" prop="title">
            <b-form-input
              v-model="product.price"
              placeholder=""
              class="service-count"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Cебестоимость" prop="title">
            <b-form-input
              v-model="product.cost"
              placeholder=""
              class="service-count"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="ml-2 pt-2">
          <button
            v-if="rIndex > 0"
            class="btn btn-danger btn-outline"
            @click.prevent="removeProduct(rIndex)"
          >
            <font-awesome-icon icon="trash" fixed-width />
          </button>
          <button
            v-else
            class="btn btn-primary btn-outline"
            @click.prevent="addProduct"
          >
            <font-awesome-icon icon="plus" fixed-width />
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex mt-2">
      <b-form-group label="Повтор" prop="title">
        <div class="mr-2 mb-1">
          <b-button-group>
            <button
              class="btn"
              :class="
                !request.repeatAppointed ? 'btn-danger' : 'btn-outline-info'
              "
              @click.prevent="setRepeat(0)"
            >
              <font-awesome-icon icon="times" />
              Не назначен
            </button>
            <button
              class="btn"
              :class="
                request.repeatAppointed ? 'btn-primary' : 'btn-outline-info'
              "
              @click.prevent="setRepeat(1)"
            >
              <font-awesome-icon icon="check" />
              Назначен
            </button>
          </b-button-group>
        </div>
      </b-form-group>
      <div v-if="request.repeatAppointed" class="mr-2">
        <b-form-group label="Дата" prop="title">
          <datepicker
            v-model="request.repeatDate"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
          ></datepicker>
        </b-form-group>
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="mr-2">
        <b-form-group label="Документы">
          <file-upload-field
            label="Документы"
            :upload-url="fileUploadUrl"
            @file-uploaded="fileUploaded"
          />
        </b-form-group>
      </div>
    </div>
    <ul class="files-block">
      <li v-for="(file, fIndex) in request.resultFiles" :key="'i' + fIndex">
        <div class="d-flex">
          <a
            v-b-tooltip.hover
            :href="file.url"
            :title="file.name"
            target="_blank"
          >
            {{ file.name + "." + file.extension }}
          </a>

          <div v-if="canDeleteResultFiles" class="ml-auto">
            <font-awesome-icon
              icon="trash"
              @click="deleteResultFile(fIndex, true)"
            />
          </div>
        </div>
      </li>
      <li v-for="(file, dIndex) in newDocuments" :key="'n' + dIndex">
        <div class="d-flex">
          <a
            v-b-tooltip.hover
            :href="file.url"
            :title="file.name"
            target="_blank"
          >
            {{ file.name + "." + file.extension }}
          </a>

          <div class="ml-auto">
            <font-awesome-icon icon="trash" @click="deleteDocument(dIndex)" />
          </div>
        </div>
      </li>
    </ul>
    <hr />
    <h3>
      Сумма: {{ totalSum(request) }} р | Доход: {{ earnTotalSum(request) }} |
      На руки: {{ calcTotalProfitSum(request) }} |

      <span v-if="request.fromExchange"
        >Заявка из биржи, рекорд не считается</span
      >
      <span v-else-if="recordDiffSum > 0"
        >До рекорда: {{ recordDiffSum }}</span
      >
      <span v-else>Рекорд<font-awesome-icon icon="star" /></span>
    </h3>
    <hr />

    <b-form-group label="Описание" prop="title">
      <b-form-textarea
        v-model="request.assigneeComment"
        placeholder="Комментарий"
        tabindex="1"
        required
      ></b-form-textarea>
    </b-form-group>

    <b-button
      variant="success"
      :disabled="loading"
      @click="createOrUpdate('ok')"
    >
      <font-awesome-icon icon="check" />
      Закрыть заявку
    </b-button>

    <b-button
      v-if="canDecline"
      variant="danger"
      class="ml-2"
      @click="createOrUpdate('decline')"
    >
      <font-awesome-icon icon="exclamation-triangle" />
      Заявка отменилась
    </b-button>

    <b-button
      v-if="id !== 'new'"
      variant="outline-info"
      class="ml-2"
      @click="cancelUpdate"
    >
      <font-awesome-icon icon="times" />
      Отмена редактирования
    </b-button>

    <div v-if="false">
      <router-link
        :to="{ name: 'request-list' }"
        class="btn btn-default ml-1 mr-1"
      >
        <font-awesome-icon icon="arrow-left" />
        Назад к списку
      </router-link>

      <b-button v-if="!request.archived" variant="danger" @click="archive">
        <font-awesome-icon icon="trash" />
        В архив
      </b-button>
      <b-button v-else variant="danger" @click="unarchive">
        <!--                    <font-awesome-icon icon="archive"/>-->
        Убрать из архива
      </b-button>
    </div>
  </div>
</b-form>
