
<datepicker
  ref="datepicker"
  v-model="date"
  :editable="true"
  :format="dateFormat"
  :input-class="inputClass"
  :lang="lang"
  :placeholder="placeholder"
  :value-type="valueFormat"
  prefix-class="xmx"
  type="date"
  @change="onChange"
>
  <template #sidebar>
    <ul>
      <li @click.prevent="selectToday"><a href="#"> Сегодня </a></li>
      <li @click.prevent="selectYesterday"><a href="#"> Вчера </a></li>
      <li @click.prevent="selectTomorrow"><a href="#"> Завтра </a></li>
    </ul>
  </template>
</datepicker>
