
<div>
  Звонок клиенту от мастера:
  <div v-if="!emptyFirstInfo && !firstAnswered">
    <div>Не дозвон</div>
  </div>
  <div v-if="!emptySecondInfo && !secondAnswered">
    <div>Не дозвон</div>
  </div>
  <div v-else>Идет в настоящее время</div>

  <div v-if="firstAnswered">
    Длительность звонка: {{ firstDuration }}
    <div><audio :src="firstFile" controls></audio></div>
  </div>
  <div v-if="secondAnswered">
    Длительность звонка: {{ secondDuration }}
    <div><audio :src="secondFile" controls></audio></div>
  </div>
</div>
