
<b-form
  v-if="dataLoaded"
  id="request-correct-form"
  ref="request"
  :model="request"
  class="request-correct-form"
  novalidate
  @submit="createOrUpdate"
  @reset="onReset"
>
  <div class="div mb-2">
    <div class="row">
      <div class="col-4">
        <b-form-group label="Статус" prop="status">
          <b-form-select
            v-model="status"
            :options="aftercallStatusOptions"
            tabindex="1"
            required
          />
        </b-form-group>
      </div>

      <div v-if="status === AFTERCALL_STATUS_NOT_REGISTERED" class="col-4">
        <b-form-group label="Причина" prop="status">
          <universal-autocomplete
            :default-value="fail ? fail.title : 'Выберите причину'"
            :add-new="false"
            :get-title="getAftercallFailTitle"
            :get-data="getClientsForRequest"
            @selected="selectFail"
          ></universal-autocomplete>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <b-form-group label="Менеджер" prop="managerId">
          <user-autocomplete
            v-model="managerId"
            :type="TYPE_MANAGER"
            :default-value="getUserName(manager)"
            @selected="selectManager"
          ></user-autocomplete>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="Комментарий" prop="title">
      <b-form-textarea
        v-model="comment"
        placeholder="Комментарий"
        tabindex="1"
        required
      ></b-form-textarea>
    </b-form-group>

    <b-button variant="success" @click="createOrUpdate('ok')">
      <font-awesome-icon icon="check" />
      Сохранить
    </b-button>

    <b-button
      v-if="id !== 'new'"
      variant="outline-info"
      class="ml-2"
      @click="cancelUpdate"
    >
      <font-awesome-icon icon="times" />
      Отмена редактирования
    </b-button>
  </div>
</b-form>
