
<div>
  <div v-if="loading" class="">
    <font-awesome-icon icon="rotate" spin />
  </div>
  <div v-if="showSearch" class="search mb-2">
    <div class="d-flex flex-wrap align-items-center">
      <div class="mr-2">
        <b-form-group label="Дата от">
          <datepicker
            v-model="dateFrom"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
            @change="fetchData"
          ></datepicker>
        </b-form-group>
      </div>

      <div class="mr-2">
        <b-form-group label="Дата до">
          <datepicker
            v-model="dateTo"
            input-class="form-control"
            :editable="true"
            lang="ru"
            :format="dateFormat"
            type="date"
            value-type="format"
            @change="fetchData"
          ></datepicker>
        </b-form-group>
      </div>

      <div v-if="requestNumberList" class="mr-2">
        <b-form-group label="Номер заявки">
          <b-form-select
            v-model="itemId"
            :options="numberList"
            tabindex="1"
            @change="fetchData"
          />
        </b-form-group>
      </div>
      <div v-else class="mr-2">
        <b-form-group label="Номер заявки">
          <b-form-input
            v-model="requestNumber"
            tabindex="1"
            @change="fetchData"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="mr-2 type-list">
        <b-form-group label="Событие">
          <b-form-select
            v-model="searchType"
            :options="typeOptions"
            tabindex="1"
          ></b-form-select>
        </b-form-group>
      </div>

      <div class="mr-2">
        <b-form-group label="Пользователь">
          <user-autocomplete
            v-model="userId"
            :default-value="userId ? getAssigneeName(user, true) : ''"
            @selected="selectUser"
          />
        </b-form-group>
      </div>
    </div>
    <hr />
  </div>

  <div v-if="!logs.length">Еще нет записей</div>
  <div v-else class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col-1">Время</th>
          <th v-if="showRequests" class="col-1">Заявка</th>
          <th class="col-1">Пользователь</th>
          <th class="col-1">Событие</th>
          <th class="col-10">Описание</th>
        </tr>
      </thead>
      <tbody>
        <request-log
          v-for="(log, index) in logs"
          :key="index"
          :log="log"
          :show-request="showRequests"
        />
      </tbody>
    </table>

    <div v-if="hasMoreLogs" class="">
      <button
        v-if="!loading"
        class="btn btn-outline-info"
        @click.prevent="loadMore"
      >
        Загрузить еще
      </button>
      <button v-else class="btn btn-info">
        <font-awesome-icon icon="rotate" spin />
        Загрузить еще
      </button>
    </div>
  </div>
</div>
