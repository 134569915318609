
<div>
  <a v-if="show" href="" @click.prevent="toggle">Скрыть</a>
  <a v-else href="" @click.prevent="toggle">Показать подробности</a>
  <request-log-created-info
    v-if="
      [REQUEST_CREATED_MANUALLY, REQUEST_CREATED_DUE_IMPORT].includes(
        log.type
      ) &&
      request &&
      show
    "
    :request="request"
    class="mt-2"
  />
  <request-log-roistat-imported-info
    v-if="log.type === REQUEST_CREATED_FROM_ROISTAT && request && show"
    :request="request"
    class="mt-2"
  />
</div>
