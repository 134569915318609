<template>
  <b-form
    id="notifications-message-form"
    ref="message"
    :model="message"
    class="notifications-message-form"
    novalidate
    @submit.prevent="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Текст" prop="title">
            <b-form-textarea
              v-model="message.text"
              placeholder="Сообщение"
              required
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Отправить всем мастерам в городе"
            prop="allManagers"
          >
            <b-button-group>
              <button
                :class="getWorkersMSKClass"
                @click.prevent="clickSelectMSKMasters"
              >
                Москва
              </button>
              <button
                :class="getWorkersSPBClass"
                @click.prevent="clickSelectSPBMasters"
              >
                Санкт-Петербург
              </button>
            </b-button-group>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Отправить индивидуальным получателям"
            prop="managerId"
          >
            <multiply-autocomplete
              ref="userAutocomplete"
              :get-data="fetchUserData"
              :get-title="getUserName"
              required
              @selected="selectMultipleUsers"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div v-if="message.allMskSelected || message.allSpbSelected">
            <b-button
              variant="primary"
              @click.prevent="showConfirmation = true"
            >
              <font-awesome-icon icon="paper-plane" />
              Отправить всем мастерам
            </b-button>
            <b-button
              v-if="showConfirmation"
              :disabled="loading"
              variant="danger"
              class="ml-2"
              @click.prevent="createOrUpdate"
            >
              <font-awesome-icon icon="paper-plane" />
              Да, я хочу отправить сообщение всем мастерам
            </b-button>
          </div>

          <b-button
            v-else
            :disabled="loading"
            variant="primary"
            @click.prevent="createOrUpdate"
          >
            <font-awesome-icon icon="paper-plane" />
            Отправить
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete.vue";
import { MSK, SPB } from "@/modules/pest/decorators/towns.js";

export default {
  name: "NotificationsMessageForm",
  directives: { mask: VueMaskDirective },
  components: { MultiplyAutocomplete },
  props: [],
  data() {
    return {
      MSK,
      SPB,
      message: {
        text: "",
        recipientsIds: [],
        allSpbSelected: false,
        allMskSelected: false,
      },
      recipients: [],
      phoneMask: false,
      loading: false,
      showConfirmation: false,
    };
  },
  computed: {
    id() {
      return this.message.id;
    },
    name() {
      let name = this.message.name ? this.message.name : "Новый город";
      return name ? name : "Город";
    },
    managerName() {
      return this.message.managerId && this.message.manager
        ? getUserName(this.client.manager)
        : "";
    },
    getWorkersMSKClass() {
      return this.message.allMskSelected
        ? "btn btn-primary"
        : "btn btn-outline-info";
    },
    getWorkersSPBClass() {
      return this.message.allSpbSelected
        ? "btn btn-primary"
        : "btn btn-outline-info";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    send: function () {
      let updateUrl;
      updateUrl = "/notifications/messages";
      this.loading = true;
      this.$http
        .post(updateUrl, this.message)
        .then((response) => {
          this.message = response.data;
          this.$eventHub.$emit(
            "update-notifications-message-info",
            this.message
          );
          this.$toast.open({
            message: "Сообщение отправлено",
            type: "success",
          });
          this.$router.push({ name: "notifications-message-list" });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$toast.open({
              message: "Не удалось отправить сообщений",
              type: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      let form = document.getElementById("notifications-message-form");
      let valid = form.checkValidity();
      if (!this.message.text) {
        valid = false;
        this.$toast.open({
          message: "Напишите текст",
          type: "danger",
        });
      }
      if (
        !this.message.allMskSelected &&
        !this.message.allSpbSelected &&
        (!this.message.recipientsIds || this.message.recipientsIds.length === 0)
      ) {
        valid = false;
        this.$toast.open({
          message: "Выберите получателей",
          type: "danger",
        });
      }
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$toast.open({
            message: "Подождите",
            type: "info",
          });
          return;
        }
        this.send();
        form.classList.remove("was-validated");
      } else {
        this.$toast.open({
          message: "В форме содержатся ошибки",
          type: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteMessage() {
      if (confirm("Вы действительно хотите удалить город?")) {
        let deleteUrl = "/notifications/messages/" + this.message.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "notifications-message-list" });
            this.$bvToast.toast("Город удален", {
              title: "Город удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast("Ошибка", {
                title: "Не удалось удалить город",
                variant: "danger",
              });
            }
          });
      }
    },

    getManagerName(user) {
      return getUserName(user);
    },
    handleManagerSubmit(result) {
      this.message.managerId = result.id;
    },
    getResultManagerAutocompleteValue(user) {
      return this.getManagerName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    selectMultipleUsers(info) {
      this.message.recipientsIds = info ? info.map((i) => i.id) : [];
      this.recipients = info;
    },

    fetchUserData(search, resolve) {
      let usersUrl = "/users/admin/search";
      let params = {
        term: search,
      };
      // if (this.type) {
      //   params.type = this.type;
      // }

      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },

    clickSelectMSKMasters() {
      this.message.allMskSelected = !this.message.allMskSelected;
      this.showConfirmation = false;
    },
    clickSelectSPBMasters() {
      this.message.allSpbSelected = !this.message.allSpbSelected;
      this.showConfirmation = false;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
