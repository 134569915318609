
<div class="ibox">
  <div class="ibox-title">
    <div class="search">
      <div class="d-flex align-items-center">
        <h2 class="mr-2">Нагрузка</h2>
        <div class="mr-2">
          <b-button-group>
            <button
              class="btn"
              :class="getTownClass(MSK)"
              @click.prevent="selectTown(MSK)"
            >
              Москва
            </button>
            <button
              class="btn"
              :class="getTownClass(SPB)"
              @click.prevent="selectTown(SPB)"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
          <!--            <b-form-select v-model="search.townId" :options="townList" tabindex="1"></b-form-select>-->
        </div>
        <div class="mr-2">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox-content pl-0 pr-0">
    <div v-if="!loading" class="  ">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Время</th>
            <th
              v-for="(date, ddIndex) in dates"
              :key="'dd' + ddIndex"
              class="statusCell"
            >
              {{ formattedDateHeader(date) }} <br />
              <font-awesome-icon icon="file" />&nbsp;{{
                getRequestCount(date)
              }}&nbsp;&nbsp; <font-awesome-icon icon="user" />&nbsp;{{
                getAssigneesCount(date)
              }}
              <div :class="getRatioClass(date)">
                <font-awesome-icon icon="chart-pie" />&nbsp;{{
                  getRatio(date)
                }}
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(timeSlot, tIndex) in slots" :key="'t' + tIndex">
            <th class="time-cell statusCell">{{ timeSlot }}:00</th>
            <td
              v-for="(info, dIndex) in schedule.dates"
              :key="'h' + dIndex"
              :class="getCellClass(info.slots[timeSlot])"
            >
              <div class="d-flex">
                <div v-if="info.slots[timeSlot]" class="count">
                  {{ info.slots[timeSlot] }}
                </div>
                <div class="time ml-auto">{{ timeSlot }}:00</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="p-2">Данные загружаются</div>
  </div>
</div>
