
<div class="feed-activity-list">
  <div v-if="comments.length">
    <comment :comment="comment" v-for="(comment, index) in comments" :key="index"></comment>
  </div>
  <div v-else>
    <p>
      Еще нет комментариев
    </p>
  </div>

</div>
