
<div>
  <board-modal :show-modal="showModal" @hide="hideModal">
    <template #tabs>
      <div class="reqtabs">
        <button
          v-if="loadingSimilar || !similarRequests.length"
          class="btn mr-2"
          :class="getRequestTabClass(firstRequestId)"
          @click.prevent="switchRequest(firstRequestId)"
        >
          <span v-if="request">
            {{ getRequestPopupTitle(request) }}
          </span>
          <span v-else> Загружается </span>
        </button>

        <button
          v-if="loadingSimilar"
          class="btn btn-outline-danger"
          @click.prevent
        >
          <font-awesome-icon icon="rotate" spin />
          Ищем двойников
        </button>
        <template v-else>
          <button
            v-for="(sRequest, srIndex) in similarRequests"
            :key="'sr' + srIndex"
            class="btn mr-2"
            :class="getRequestTabClass(sRequest.id)"
            @click.prevent="switchRequest(sRequest.id)"
          >
            {{ getRequestPopupTitle(sRequest) }}
          </button>
        </template>
      </div>
    </template>

    <template #header>
      <div v-if="!loading" class="d-flex align-items-center contract-title">
        <div class="pl-3">
          <strong v-if="isContract && !requestOpened">{{
            getRequestPopupTitle(request)
          }}</strong>
          <strong v-else>{{ getRequestStageTitle(request) }}</strong>
        </div>

        <ul
          v-if="isContract"
          class="nav nav-tabs ml-5 d-none d-md-block"
          role="tablist"
        >
          <li>
            <a
              :class="tabClass('contacts')"
              class="nav-link"
              data-toggle="tab"
              @click="openTab('contacts')"
            >
              Контактные данные
            </a>
          </li>
          <li>
            <a
              :class="tabClass('requests')"
              class="nav-link"
              data-toggle="tab"
              @click="openTab('requests')"
            >
              Заявки
            </a>
          </li>
          <li>
            <a
              :class="tabClass('logs')"
              class="nav-link"
              data-toggle="tab"
              @click="openTab('logs')"
            >
              История
            </a>
          </li>
        </ul>
      </div>
      <div v-else class="pl-3">
        <strong>Загружается</strong>
      </div>
    </template>

    <template #toptabs>
      <ul
        v-if="isContract"
        class="d-block d-sm-none m-0 p-0 text-center"
        role="tablist"
      >
        <li class="inline">
          <a
            :class="tabClass('contacts')"
            class="nav-link"
            data-toggle="tab"
            @click="openTab('contacts')"
          >
            Контактные данные
          </a>
        </li>
        <li class="inline">
          <a
            :class="tabClass('requests')"
            class="nav-link"
            data-toggle="tab"
            @click="openTab('requests')"
          >
            Заявки
          </a>
        </li>
        <li class="inline">
          <a
            :class="tabClass('logs')"
            class="nav-link"
            data-toggle="tab"
            @click="openTab('logs')"
          >
            История
          </a>
        </li>
      </ul>
    </template>
    <template #header-buttons>
      <button
        v-if="shouldSearchContractButton"
        class="btn btn-outline-primary mr-2"
        @click="openSearchContractPopup"
      >
        Повторное обращение
      </button>

      <popup-contact-buttons
        v-if="contractTab === 'contacts'"
        class="mr-3"
        @add-new-contact="addNewContact"
      ></popup-contact-buttons>

      <popup-request-buttons
        v-if="contractTab === 'requests'"
        class="mr-3"
        :request-saving="requestSaving"
        :is-contract="isContract"
        :request-updating="!isClosed(request) && requestOpened"
        :request-opened="requestOpened"
        :request-opening="loading"
        @close-request-card="closeRequestCard"
        @save-request="saveRequest"
        @add-new-request="addNewRequest"
      ></popup-request-buttons>
    </template>

    <template #default>
      <b-overlay :show="loading" rounded="sm" style="height: 100%">
        <div v-if="request">
          <div v-if="requestOpened">
            <requests-sequence-list
              ref="sequence"
              :request-id="request.id"
              :adding-request="addingSimilarRequest"
              @open-request="openSelectedRequest"
              @sequence-loaded="onSequenceLoaded"
            />

            <request-popup-info
              v-if="isClosed(request)"
              :request-model="request"
              :sequence="sequence"
              :data-loaded="true"
              :embedded="true"
              @reanimate-request="reanimateRequest"
              @request-changed="requestChanged"
              @add-new-request="addNewRequest"
            ></request-popup-info>

            <request-form
              v-else
              ref="request-form"
              :request-model="request"
              :sequence="sequence"
              :data-loaded="true"
              :embedded="true"
              @add-new-request="addNewRequest"
              @request-created="requestCreated"
              @start-creating="creationStarted"
              @end-creating="creationStopped"
            ></request-form>
          </div>

          <div v-if="isContract" :class="{ 'd-none': requestOpened }">
            <contract-contacts
              ref="contactList"
              :class="{ 'd-none': contractTab !== 'contacts' }"
              :contract-id="request.contractId"
            ></contract-contacts>

            <contract-requests
              ref="requestList"
              :class="{ 'd-none': contractTab !== 'requests' }"
              :contract-id="request.contractId"
              @open-request="openSelectedRequest"
              @duplicate-request="duplicateRequest"
              @number-list-updated="requestListUpdated"
            ></contract-requests>
            <!--                  v-on:start-updating="requestUpdating=true"-->
            <!--                  v-on:end-updating="requestUpdating=false"-->
            <!--                  v-on:start-saving="requestSaving=true"-->
            <!--                  v-on:end-saving="requestSaving=false"-->

            <request-logs-wrapper
              ref="requestLogWrap"
              :class="{ 'd-none': contractTab !== 'logs' }"
              :request-number-list="requestNumberList"
              :contract-id="request.contractId"
            ></request-logs-wrapper>
          </div>

          <div v-else-if="!loading" class="ibox">
            <div class="ibox-title d-flex">
              <h3>История событий</h3>
            </div>
            <div class="ibox-content">
              <request-logs
                ref="request-logs"
                :show-search="true"
                :show-requests="
                  isContract || (request.linkIds && request.linkIds.length)
                "
                :request-number-list="[request.id, ...request.linkIds]"
                :item-id="[request.id, ...request.linkIds]"
              ></request-logs>
            </div>
          </div>
        </div>
        <div v-else></div>
      </b-overlay>
    </template>
  </board-modal>

  <contact-popup />
  <new-contact-phone-popup />
  <request-search-popup />
</div>
