
<div class="ibox">
  <div class="ibox-content">
    <h1>Материалы в CRM</h1>
    <div class="mb-2">
      <router-link
        :to="{ name: 'pest-resource-create' }"
        class="btn btn-primary"
      >
        <font-awesome-icon icon="plus" />
        Добавить материал
      </router-link>
    </div>
    <div class="search">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <b-form-group label="Название">
            <b-form-input
              v-model="search.title"
              tabindex="3"
              @change="fetchData"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group label="Видимость">
            <b-form-select
              v-model="search.enabled"
              tabindex="3"
              @change="fetchData"
            >
              <b-form-select-option value="null">Все</b-form-select-option>
              <b-form-select-option value="1">Да</b-form-select-option>
              <b-form-select-option value="0">Нет</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="align-self-center mt-2 mr-2">
          <b-button class="btn btn-info ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;Искать
          </b-button>
        </div>

        <div v-if="!loading" class="mt-2 mr-2">
          Найдено: {{ pagination.totalCount }}
        </div>
      </div>
    </div>
    <b-table
      v-if="resources.length"
      responsive
      striped
      hover
      condensed
      :items="resources"
      :fields="fields"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle table-spinner"></b-spinner>
          <strong> Данные подгружаются</strong>
        </div>
      </template>

      <template #cell(title)="data">
        <router-link
          :to="{ name: 'pest-resource-update', params: { id: data.item.id } }"
          class=""
        >
          {{ data.item.title ? data.item.title : "[Без названия]" }}
        </router-link>
      </template>

      <template #cell(shopResources)="data">
        <div v-if="data.item && data.item.shopResources" class="mb-2">
          <router-link
            v-for="(assignedResource, apI) in data.item.shopResources"
            :key="apI"
            :to="{
              name: 'pest-shop-resource-update',
              params: { id: assignedResource.id },
            }"
            class="badge badge-info mr-2"
          >
            {{ assignedResource.title }}
          </router-link>
        </div>
      </template>

      <template #cell(enabled)="data">
        <span v-if="data.item.enabled">
          Да <font-awesome-icon icon="eye" />
        </span>
        <span v-else> Нет <font-awesome-icon icon="eye-slash" /></span>
      </template>
    </b-table>
  </div>

  <b-pagination-nav
    v-if="pagination.count > 1"
    :link-gen="linkGen"
    :value="pagination.page"
    :number-of-pages="pagination.count"
    limit="10"
    use-router
    class="mt-2"
  >
  </b-pagination-nav>
</div>
