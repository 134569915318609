
<tr :class="rowClass">
  <slot name="title"></slot>
  <td
    v-for="(param, pIndex) in CPOParams"
    :key="getKey(pIndex)"
    :style="cellStyleComputed(param, pestInfo, pIndex)"
  >
    <div class="d-flex flex-nowrap">
      <div class="mr-2">
        <slot :name="[param.attr]">
          <font-awesome-icon
            v-if="param.attr === 'failedCount'"
            icon="circle"
            :style="{
              color: getCircleColor(
                param.val(pestInfo, sourceInfo, assigneeInfo)
              ),
            }"
          />
          {{ formatNumber(param.val(pestInfo, sourceInfo, assigneeInfo)) }}
        </slot>
      </div>
      <div
        v-if="showChartIcon(param, pestInfo, sourceInfo, assigneeInfo)"
        class="ml-auto icon-block"
        :class="{ 'show-chart': chartShown(param) }"
      >
        <a href="#" @click.prevent="toggleChart(param)">
          <font-awesome-icon icon="chart-area" />
        </a>
      </div>
    </div>
  </td>
</tr>
