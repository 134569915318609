
<b-overlay v-if="loadingStats" :show="true" rounded="sm" class="pt-4 pb-4" />

<div v-else>
  <div class="ibox mt-2">
    <div class="ibox-title pr-3 pt-2 pb-1">
      <div class="d-flex">
        <div>Сдать в кассу</div>
        <div class="ml-auto">{{ formatNumber(totalDepositSum) }} руб.</div>
      </div>
      <div class="d-flex mt-2">
        <div>Заявок в работе</div>
        <div class="ml-auto">{{ requestCount }}</div>
      </div>

      <div v-if="false && !canAddClient()" class="h6">
        <div v-if="false" class="d-flex">
          <div class="">Оборот:</div>
          <div class="ml-auto">
            <strong> {{ formatNumber(totalSum) }} </strong>
          </div>
        </div>
        <div class="d-flex">
          <div class="">Заработано:</div>
          <div class="ml-auto">
            <strong> {{ formatNumber(profitSum) }} </strong>
          </div>
        </div>
        <div class="d-flex">
          <div class="">Штрафы:</div>
          <div class="ml-auto">
            <strong> {{ formatNumber(finesSum) }} </strong>
          </div>
        </div>
        <div class="d-flex">
          <div class="">К выплате:</div>
          <div class="ml-auto">
            <strong> {{ formatNumber(sumToPay) }} </strong>
          </div>
        </div>
        <div class="d-flex">
          <div class="">Средний чек</div>
          <div class="ml-auto" :class="getAverageSumClass">
            <strong> {{ formatNumber(average) }} </strong>
          </div>
        </div>
      </div>

      <div class="mb-2"></div>
    </div>

    <div
      v-if="showTelegramSingupWindow"
      class="panel panel-warning mt-4 mb-4"
    >
      <div class="panel-heading">
        <font-awesome-icon icon="exclamation-triangle" />
        Подпишитесь на нас в Telegram для получения уведомлений
      </div>
      <div class="panel-body">
        <p>
          Перейдите по этой ссылке:
          <strong
            ><a :href="telegramUrl" target="_blank">@DezCrmBot</a></strong
          >
          и, когда откроется Telegram, нажмите <strong>start</strong>. <br />
          Или самостоятельно найдите контакт <strong>@DezCrmBot</strong> и
          отправьте ваш номер телефона.
        </p>
      </div>
    </div>
  </div>

  <div v-if="initialPledgeRequired" class="panel panel-warning mt-4 mb-4">
    <div class="panel-heading">
      <font-awesome-icon icon="exclamation-triangle" />
      Депозит недостаточен
    </div>
    <div class="panel-body">
      <p>
        До накопления минимальной суммы необходимо сдавать кассу каждый день.
        Несдача кассы влечёт за собой штраф в размере 1000₽. Своевременность
        сдачи кассы влияет на приоритет распределения заявок.
      </p>
    </div>
  </div>

  <div v-if="blocked === BLOCKED" class="panel panel-danger mt-4 mb-4">
    <div class="panel-heading">
      <font-awesome-icon icon="exclamation-triangle" />
      Ваш личный кабинет заблокирован.
    </div>
    <div class="panel-body">
      <p>
        Для продолжения работы необходимо сдать кассу. Своевременность сдачи
        кассы влияет на приоритет распределения заявок.
      </p>
    </div>
  </div>
  <div v-else-if="blocked === UNBLOCKED" class="panel panel-danger mt-4 mb-4">
    <div class="panel-heading">
      <font-awesome-icon icon="lock-open" />
      Ваш личный кабинет временно разблокирован.
    </div>
    <div class="panel-body">
      <p>
        Для продолжения работы необходимо сдать кассу. Своевременность сдачи
        кассы влияет на приоритет распределения заявок.
      </p>
    </div>
  </div>

  <div
    v-else-if="isHalfPledgeUsed && !initialPledgeRequired"
    class="panel panel-warning mt-4 mb-4"
  >
    <div class="panel-heading">
      <font-awesome-icon icon="exclamation-triangle" />
      Использовано 50% депозита
    </div>
    <div class="panel-body">
      <p>
        Во избежание блокировки личного кабинета сдайте кассу в ближайшее
        время. Своевременность сдачи кассы влияет на приоритет распределения
        заявок.
      </p>
    </div>
  </div>
  <request-list
    :blocked="blocked"
    tab="currentEmployeeList"
    @loaded="loaded"
  ></request-list>
</div>
