import { getTownTitle } from "@/modules/pest/decorators/towns";

const getRegionTitle = function (region) {
  if (!region || !region.title) {
    return "[Не назначен]";
  }
  return region.title;
};
const getRegionTownTitle = function (region) {
  if (!region || !region.town) {
    return "[Не назначен]";
  }
  return getTownTitle(region.town);
};

export { getRegionTitle, getRegionTownTitle };
