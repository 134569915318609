
<b-form
  id="notifications-message-form"
  ref="message"
  :model="message"
  class="notifications-message-form"
  novalidate
  @submit.prevent="createOrUpdate"
  @reset="onReset"
>
  <div class="div">
    <div class="row">
      <div class="col-12">
        <b-form-group label="Текст" prop="title">
          <b-form-textarea
            v-model="message.text"
            placeholder="Сообщение"
            required
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group
          label="Отправить всем мастерам в городе"
          prop="allManagers"
        >
          <b-button-group>
            <button
              :class="getWorkersMSKClass"
              @click.prevent="clickSelectMSKMasters"
            >
              Москва
            </button>
            <button
              :class="getWorkersSPBClass"
              @click.prevent="clickSelectSPBMasters"
            >
              Санкт-Петербург
            </button>
          </b-button-group>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group
          label="Отправить индивидуальным получателям"
          prop="managerId"
        >
          <multiply-autocomplete
            ref="userAutocomplete"
            :get-data="fetchUserData"
            :get-title="getUserName"
            required
            @selected="selectMultipleUsers"
          ></multiply-autocomplete>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div v-if="message.allMskSelected || message.allSpbSelected">
          <b-button
            variant="primary"
            @click.prevent="showConfirmation = true"
          >
            <font-awesome-icon icon="paper-plane" />
            Отправить всем мастерам
          </b-button>
          <b-button
            v-if="showConfirmation"
            :disabled="loading"
            variant="danger"
            class="ml-2"
            @click.prevent="createOrUpdate"
          >
            <font-awesome-icon icon="paper-plane" />
            Да, я хочу отправить сообщение всем мастерам
          </b-button>
        </div>

        <b-button
          v-else
          :disabled="loading"
          variant="primary"
          @click.prevent="createOrUpdate"
        >
          <font-awesome-icon icon="paper-plane" />
          Отправить
        </b-button>
      </div>
    </div>
  </div>
</b-form>
