<template>
  <div>
    <b-form ref="user" :model="user" class="user-form">
      <div class="ibox">
        <div class="ibox-title d-flex align-items-center">
          <h1 class="">
            <router-link :to="returnUrlC" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            {{ name }}
            <span v-if="isWorker" class=""> ({{ user.percentString }}) </span>
          </h1>

          <div v-if="isWorker && user.teamHead" class="ml-4">
            Команда: {{ getUserTitle(user.teamHead) }}
          </div>

          <div v-if="canUpdateUser" class="ml-4">
            <div class="row">
              <div class="col-md-12">
                <b-button variant="primary" @click="createOrUpdate">
                  <font-awesome-icon icon="check" />
                  {{ submitButton }}
                </b-button>

                <a
                  v-if="id !== 'new'"
                  href=""
                  class="btn btn-outline btn-danger ml-2"
                  @click.prevent="remove"
                >
                  <font-awesome-icon icon="trash" />
                  Удалить
                </a>

                <router-link
                  v-if="false"
                  :to="{ name: 'users-user-list' }"
                  class="btn btn-default ml-2"
                >
                  <font-awesome-icon icon="arrow-left" />
                  Назад к списку
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-overlay :show="loading" rounded="sm">
        <b-tabs content-class="mt-3">
          <b-tab title="Личные данные" active>
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Учетная запись</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group label="Тип" prop="type">
                      <b-form-select
                        v-model="user.type"
                        :options="typeSelectOptions"
                        required
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group label="Новый пароль" prop="password">
                      <b-form-input
                        v-model="user.password"
                        type="password"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-checkbox v-model="user.enabled" class="mb-2"
                      >Активен
                    </b-form-checkbox>

                    <div v-if="canSetClosedSettings">
                      <b-form-checkbox
                        v-model="user.canSeeClosedReports"
                        class="mb-2"
                      >
                        Может видеть закрытые отчеты
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="user.canSeeServiceSections"
                        class="mb-2"
                      >
                        Доступ к служебным разделам
                      </b-form-checkbox>
                    </div>

                    <b-form-group label="Id в 1c" prop="externalId">
                      <b-form-input
                        v-model="user.externalId"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="Id в telegram" prop="telegramChatId">
                      <b-form-input
                        v-model="user.telegramChatId"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Дисконтная карта" prop="discountCard">
                      <b-form-input v-model="user.discountCard"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Проект" prop="projectId">
                      <multiply-autocomplete
                        ref="projectAutocomplete"
                        :get-data="getProjects"
                        :get-title="getProjectTitle"
                        @selected="selectMultiplyProjects"
                      ></multiply-autocomplete>
                    </b-form-group>
                  </div>
                </div>

                <div class="ibox">
                  <div class="ibox-content">
                    <b-form-group label="Фото профиля">
                      <file-upload-field
                        label="Фотография"
                        @file-uploaded="fileUploaded"
                      />
                      <div v-if="user.image">
                        <img
                          :src="user.image.url"
                          alt=""
                          class="profileImg mt-2"
                        />
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Личные данные</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group label="Фамилия" prop="family">
                      <b-form-input v-model="user.surname"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Имя" prop="name">
                      <b-form-input v-model="user.name"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Отчество" prop="patronymic">
                      <b-form-input v-model="user.patronymic"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Электронная почта" prop="email">
                      <b-form-input v-model="user.email"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Телефон" prop="phone">
                      <b-form-input v-model="user.phone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div v-if="user.type === TYPE_WORKER" class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Город</h5>
                  </div>

                  <div class="ibox-content">
                    <town-autocomplete
                      ref="townAutocomplete"
                      :default-value="
                        user.townId ? getTownTitle(user.town) : ''
                      "
                      @selected="selectTown"
                    ></town-autocomplete>
                  </div>
                </div>

                <div v-if="user.type === TYPE_WORKER" class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Рекорд</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      label="Текущая планка рекорда"
                      prop="externalId"
                    >
                      <b-form-input v-model="user.record"></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div v-if="[TYPE_WORKER].includes(user.type)" class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Депозит</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group label="Текущий депозит" prop="externalId">
                      <b-form-input v-model="user.pledgeGoal"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Накопленный депозит" prop="externalId">
                      <b-form-input v-model="user.pledgeSum"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Сдача кассы" prop="externalId">
                      <b-button-group>
                        <b-button
                          :variant="
                            !user.disabledDepositGeo ? 'primary' : 'default'
                          "
                          @click.prevent="user.disabledDepositGeo = 0"
                          >Только в магазине
                        </b-button>
                        <b-button
                          :variant="
                            user.disabledDepositGeo ? 'primary' : 'default'
                          "
                          @click.prevent="user.disabledDepositGeo = 1"
                          >Где угодно
                        </b-button>
                      </b-button-group>
                    </b-form-group>
                  </div>
                </div>

                <div
                  v-if="
                    [
                      TYPE_MANAGER,
                      TYPE_ADMIN,
                      TYPE_SHADOW,
                      TYPE_LOGIST,
                    ].includes(user.type)
                  "
                  class="ibox"
                >
                  <div class="ibox-title">
                    <h5 class="h5">Настройки телефонии</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      label="Внутренний номер в Mango"
                      prop="mangoNumber"
                    >
                      <b-form-input v-model="user.mangoNumber"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      label="Внутренний номер в Zadarma"
                      prop="zadarmaNumber"
                    >
                      <b-form-input v-model="user.zadarmaNumber">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab v-if="false" title="Сменить пароль"></b-tab>
          <b-tab v-if="isWorker" title="Оборудование">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Оборудование</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group label="Настройки" prop="equipmentSum">
                      Оборудование с фиксированной суммой (ГХТ и другие)
                      <b-form-radio-group
                        v-model="user.ignoreCommonFixedEquipment"
                        :options="[
                          { value: 1, text: 'Только свое' },
                          { value: 0, text: 'Общее' },
                        ]"
                      />
                      Оборудование с процентной суммой (Штиль и др.)
                      <b-form-radio-group
                        v-model="user.ignoreCommonPercentEquipment"
                        :options="[
                          { value: 1, text: 'Только свое' },
                          { value: 0, text: 'Общее' },
                        ]"
                      />
                    </b-form-group>

                    <hr />
                    <div
                      v-for="(equipment, eqIndex) in user.equipments"
                      :key="'eq' + eqIndex"
                      class="d-flex align-items-center"
                    >
                      <!--                <b-form-group label="Стоимость " prop="equipmentSum">-->
                      <!--                  <b-form-input v-model="equipment.sum"></b-form-input>-->
                      <!--                </b-form-group>-->
                      <div class="">
                        <b-form-group label="Оборудование" prop="equipmentSum">
                          <b-form-select
                            v-model="equipment.equipmentId"
                            :options="equipmentOptions"
                          ></b-form-select>
                        </b-form-group>
                      </div>

                      <div class="ml-2 pt-2">
                        <button
                          v-if="eqIndex > 0"
                          class="btn btn-danger btn-outline"
                          @click.prevent="removeEquipment(eqIndex)"
                        >
                          <font-awesome-icon icon="trash" fixed-width />
                        </button>
                        <button
                          v-else
                          class="btn btn-primary btn-outline"
                          @click.prevent="addEquipment"
                        >
                          <font-awesome-icon icon="plus" fixed-width />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="isWorker" title="Типы вредителей">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Типы вредителей</h5>
                  </div>
                  <div class="ibox-content">
                    <div v-if="user && user.pests.length > 0">
                      <div
                        v-for="(pest, rIndex) in user.pests"
                        :key="'r' + rIndex"
                        class="d-flex align-items-center"
                      >
                        <div class="">
                          <b-form-group label="Вредитель" prop="title">
                            <b-form-select
                              v-model="pest.id"
                              :options="pestsList"
                              placeholder=""
                              class="pest-select"
                              tabindex="1"
                              @change="setPestTitle(pest)"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                        <div class="ml-2 pt-2">
                          <button
                            v-if="rIndex > 0"
                            class="btn btn-danger btn-outline"
                            @click.prevent="removePest(rIndex)"
                          >
                            <font-awesome-icon icon="trash" fixed-width />
                          </button>
                          <button
                            v-else
                            class="btn btn-primary btn-outline"
                            @click.prevent="addPest"
                          >
                            <font-awesome-icon icon="plus" fixed-width />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="isWorker" title="Материалы">
            <user-resources-list :user="user" />
          </b-tab>
          <b-tab v-if="false" title="Настройки в crm"></b-tab>
          <b-tab v-if="isWorker" title="Стажировка">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Стажировка</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group v-if="false" label="Цвет" prop="color">
                      <b-form-input
                        v-model="user.color"
                        type="color"
                      ></b-form-input>
                    </b-form-group>

                    <hr />
                    <b-form-group label="Стажер" prop="isNovice">
                      <b-form-select
                        v-model="user.isNovice"
                        :options="noviceOptions"
                      />
                    </b-form-group>

                    <b-form-group label="Дата начала отсчета" prop="color">
                      <b-form-datepicker
                        v-model="user.noviceDateFrom"
                        placeholder="Выберите дату"
                      ></b-form-datepicker>
                    </b-form-group>

                    <b-form-group label="Количество отработанных смен">
                      <b-form-input
                        v-model="user.noviceShiftCount"
                        placeholder=""
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="isWorker" title="Команда">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">Команда</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group
                      label="Руководитель команды"
                      prop="teamHeadId"
                    >
                      <user-autocomplete
                        v-model="user.teamHeadId"
                        :type="TYPE_WORKER"
                        :default-value="getUserTitle(user.teamHead)"
                        @selected="selectTeamHead"
                      ></user-autocomplete>
                    </b-form-group>
                    <b-form-group label="В команде с " prop="color">
                      <date-picker v-model="user.teamSince"></date-picker>
                    </b-form-group>
                    <b-form-checkbox v-model="user.commonPercent" class="mb-2"
                      >Обычный процент оплаты (30/50)
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import {
  TYPE_ADMIN,
  TYPE_CLIENT,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_SHADOW,
  TYPE_WORKER,
  typeSelectOptions,
} from "@/modules/users/decorators/users";
import { getClientName } from "@/modules/crm/decorators/client";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { getEquipmentTitle } from "@/modules/pest/decorators/equipments";
import { getUserTitle } from "@/modules/users/decorators/userDecorator.js";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import { canUpdateUser } from "@/modules/users/access/common.js";
import { mapGetters } from "vuex";
import DatePicker from "@/components/shared/DatePicker";
import TownAutocomplete from "@/modules/pest/views/towns/TownAutocomplete.vue";
import { getTownTitle } from "@/modules/pest/decorators/towns";
import FileUploadField from "@/components/shared/FileUploadField.vue";
import UserResourcesList from "@/modules/users/views/user/UserResourcesList.vue";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete.vue";
export default {
  name: "User",
  components: {
    UserResourcesList,
    FileUploadField,
    TownAutocomplete,
    UserAutocomplete,
    DatePicker,
    MultiplyAutocomplete,
  },
  data() {
    return {
      TYPE_WORKER,
      TYPE_MANAGER,
      TYPE_CLIENT,
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_LOGIST,
      typeSelectOptions,
      labelWidth: "150px",
      equipmentOptions: [],
      user: {
        id: null,
        name: "",
        surname: "",
        patronymic: "",
        username: "",
        email: "",
        phone: "",
        isNovice: false,
        enabled: true,
        commonPercent: false,
        clientId: "",
        client: "",
        externalId: "",
        telegramChatId: "",
        discountCard: "",
        pledgeGoal: "",
        pledgeSum: "",
        disabledDepositGeo: 0,
        townId: null,
        town: {},
        pests: [],
        projectIds: [],
      },
      loading: false,
      header: "Пользователь",
      submitButton: "Сохранить",
      pestsList: [],
      noviceOptions: [
        { value: true, text: "Да" },
        { value: false, text: "Нет" },
      ],
      sampleGroup: "",
      sampleGroups: [
        {
          label: "Администраторы",
          value: "admin",
        },
        {
          label: "Менеджеры",
          value: "manager",
        },
      ],
      // rules: {
      //   name: [
      //     {required: true, message: 'Введите имя', trigger: 'blur'}
      //   ]
      // }
    };
  },
  computed: {
    ...mapGetters({
      canSetClosedSettings: "auth/canSetClosedSettings",
      canSeeTestSetting: "auth/canSeeTestSetting",
    }),

    isWorker() {
      return (
        this.user && this.user.id !== "new" && this.user.type === TYPE_WORKER
      );
    },
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
    name() {
      let name = this.user.name ? this.user.name : this.user.username;
      return name ? name : "Пользователь";
    },
    clientName() {
      return this.user.clientId && this.user.client
        ? getClientName(this.user.client)
        : "";
    },
    returnUrlC() {
      if (this.$route.params.returnUrl) {
        return this.$route.params.returnUrl;
      }
      return { name: "users-user-list" };
    },
    canUpdateUser() {
      return canUpdateUser(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.fetchPests();
    this.fetchEquipmentOptions();

    this.checkUrl();
  },
  methods: {
    getProjects(search, resolve) {
      let pestUrl = "/pest/projects?enabled=1";
      let params = {
        search: search,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    getProjectTitle(item) {
      if (item.title) {
        return item.title;
      }
    },
    selectMultiplyProjects(info) {
      this.user.projectIds = [];
      if (info) {
        for (let project of info) {
          this.user.projectIds.push(project.id);
        }
      }
    },
    getClientName,
    getUserTitle,
    getPestTitle,
    getTownTitle,
    getEquipmentTitle,
    checkUrl: function () {
      if (this.id === "new") {
        this.header = "Создать пользователя";
      } else {
        this.fetchData();
      }
    },
    setUserData(data) {
      this.user = data;
      this.user.enabled = !!data.enabled;
      this.user.projectIds = this.user.userProjects;
      this.ensureBlankData();
      this.$refs.projectAutocomplete.selected = this.user.projects;
    },
    fetchEquipmentOptions: function () {
      this.equipmentOptions = [];
      let pestsUrl = "/pest/equipments";
      let params = {
        page: this.page,
      };
      this.equipmentOptions.push({ value: "", text: "Нет" });
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.equipmentOptions.push({
              value: item.id,
              text: this.getEquipmentTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchPests: function () {
      this.pestsList = [];
      let pestsUrl = "/pest/pests";
      let params = {
        page: this.page,
      };
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.pestsList.push({
              value: item.id,
              text: this.getPestTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchData: function () {
      this.loading = true;
      let usersUrl = "users/admin/" + this.id;
      this.$http
        .get(usersUrl)
        .then((response) => {
          this.loading = false;
          this.setUserData(response.data);
          this.header = this.user.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
          this.$bvToast.toast("Данные не загрузились", {
            title: "Не удалось загрузить пользователя",
            type: "danger",
          });
        });
    },
    ensureBlankData() {
      if (!this.user.pests || !this.user.pests.length) {
        this.user.pests = [];
        let blankPest = {
          pestId: "",
        };
        this.user.pests.push(blankPest);
      }
      if (!this.user.equipments || !this.user.equipments.length) {
        this.user.equipments = [];
        let blankEquipment = {
          equipmentId: "",
        };
        this.user.equipments.push(blankEquipment);
      }

      if (!this.user.ignoreCommonFixedEquipment) {
        this.user.ignoreCommonFixedEquipment = 0;
      }
      if (!this.user.ignoreCommonPercentEquipment) {
        this.user.ignoreCommonPercentEquipment = 0;
      }
    },
    update: function () {
      let updateUrl;
      updateUrl = "users/admin/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.user)
        .then((response) => {
          this.setUserData(response.data);
          this.loading = false;
          this.$bvToast.toast("Данные сохранены", {
            title: "Пользователь отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            let message = this.getErrorMessage(error);
            this.$bvToast.toast(message, {
              title: "Не удалось отредактировать пользователя",
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "users/admin";
      this.loading = true;
      this.$http
        .post(updateUrl, this.user)
        .then((response) => {
          this.user = response.data;
          this.$bvToast.toast("Пользователь добавлен", {
            title: "Пользователь добавлен",
            variant: "success",
          });
          this.$router.push({
            name: "users-user-view",
            params: { id: this.user.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            let message = this.getErrorMessage(error);
            this.$bvToast.toast(message, {
              title: "Не удалось добавить пользователя",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      // this.$refs['user'].validate((valid) => {
      //   if (valid) {
      if (this.id === "new") {
        this.create();
      } else {
        this.update();
      }
      // } else {
      //   this.$bvToast.toast('Произошла неизвестная ошибка', {
      //     variant: 'danger'
      //   });
      //   console.log('error submit!!');
      //   return false
      // }
      // })
    },
    remove: function () {
      if (!confirm("Вы действительно хотите удалить группу?")) {
        return false;
      }

      let deleteUrl = "users/admin/" + this.id;
      this.true = false;
      this.$http
        .delete(deleteUrl)
        .then((response) => {
          this.loading = false;
          this.$bvToast.toast("Пользователь удален", {
            variant: "success",
          });
          this.$router.push({ name: "users-user-list" });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Не удалось удалить пользователя", {
              variant: "danger",
            });
          }
        });
    },
    // image upload
    handleImageSuccess: function (response, file, fileList) {
      this.user.image = response;
      this.user.imageId = response.id;
      this.loading = false;
    },
    handleImageError: function (response, file, fileList) {
      this.loading = false;
      this.user.imageId = null;
      this.$message({
        message: "Не удалось загрузить изображение",
        type: "danger",
      });
    },
    beforeImageUpload: function () {
      this.loading = true;
    },

    handleClientSubmit(result) {
      this.user.clientId = result.id;
      this.user.surname = result.lastName;
      this.user.name = result.firstName;
      this.user.patronymic = result.patronymic;
    },
    getResultClientAutocompleteValue(client) {
      return this.getClientName(client);
    },
    searchClient(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/crm/client/search-employees";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    removeClientLink() {
      this.user.client = false;
      this.user.clientId = false;
    },
    getErrorMessage(data) {
      if ("errors" in data.response.data) {
        console.log(data.response.data.errors);
        return Object.values(data.response.data.errors).join("\n");
      } else {
        return "Неизвестная ошибка";
      }
    },
    removePest(index) {
      this.user.pests.splice(index, 1);
    },
    addPest() {
      let blankPest = {
        pestId: "",
      };
      this.user.pests.push(blankPest);
    },
    removeEquipment(index) {
      this.user.equipments.splice(index, 1);
    },
    addEquipment() {
      let blankEquipment = {
        equipmentId: "",
      };
      this.user.equipments.push(blankEquipment);
    },
    setPestTitle(pest) {
      let i = this.pestsList
        .map(function (e) {
          return e.value;
        })
        .indexOf(pest.id);
      pest.title = this.pestsList[i].text;
    },
    selectTeamHead(data) {
      if (data && data.value) {
        this.user.teamHead = data.value;
        this.user.teamHeadId = data.value.id;
      } else {
        this.user.teamHead = {};
        this.user.teamHeadId = null;
      }
      this.$forceUpdate();
    },
    selectTown(data) {
      if (data && data.value) {
        this.user.town = data.value;
        this.user.townId = data.value.id;
      } else {
        this.user.town = {};
        this.user.townId = null;
      }
      this.$forceUpdate();
    },
    fileUploaded({ file }) {
      this.user.imageId = file.id;
      this.user.image = file;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-view {
  &__statistics {
    margin-bottom: 0;

    &__value {
      margin-bottom: 0;
    }
  }
}
.profileImg {
  max-height: 100px;
  max-width: 100px;
}
</style>
