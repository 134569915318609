<template>
  <div class="pest-equipment-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>
              <router-link
                :to="{ name: 'pest-equipment-list', id: equipment.id }"
                class="h4 text-muted"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>
              Изменение оборудования
            </h1>
          </div>
          <div class="ibox-content">
            <pest-equipment-form :equipment="equipment"></pest-equipment-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EquipmentForm from "./EquipmentForm";
import { EQUIPMENT_PAYMENT_FIXED } from "@/modules/pest/decorators/equipments";

export default {
  name: "Equipment",
  components: {
    "pest-equipment-form": EquipmentForm,
  },
  data() {
    return {
      equipment: {
        id: "new",
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl() {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      let equipmentsUrl = "/pest/equipments/" + this.id;
      this.loading = true;
      this.$http
        .get(equipmentsUrl)
        .then((response) => {
          this.loading = false;
          this.equipment = response.data;
          if (!this.equipment.paymentType) {
            this.equipment.paymentType = EQUIPMENT_PAYMENT_FIXED;
          }
          if (!("pests" in this.equipment) || !this.equipment.pests) {
            this.equipment.pests = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
