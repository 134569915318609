
<div class="pest-fine-page">
  <div class="ibox">
    <div class="ibox-title pb-3">
      <div class="d-flex">
        <div class="ml-1 mr-2 mt-2">
          <router-link :to="{ name: 'pest-fine-list' }" class="h4 text-muted">
            <font-awesome-icon icon="arrow-left" />
          </router-link>
        </div>
        <div>
          <h1>{{ fine.title }}</h1>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <pest-fine-info :fine="fine" @update-prop="updateProp"></pest-fine-info>
    </div>
  </div>
</div>
