
<b-form
  id="pest-fine-review-form"
  ref="fine"
  :model="fine"
  class="pest-fine-review-form"
  novalidate
  @submit="createOrUpdate"
>
  <div class="div">
    <div class="row">
      <div class="col-12"></div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group label="Результат проверки" prop="sum">
          <b-form-textarea
            v-model="fine.review"
            placeholder="Результат"
            :disabled="!canBeEdited"
            required
            tabindex="1"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div v-if="canReviewFines" class="row">
      <div v-if="canBeEdited" class="col">
        <b-button
          v-if="fine.status === FINE_STATUS_NEW"
          variant="info"
          @click="setProcessingStatus"
        >
          <font-awesome-icon icon="rotate" :spin="updatingProcessStatus" />
          Перевести в статус проверки
        </b-button>

        <b-button
          v-else-if="fine.status === FINE_STATUS_PROCESSING"
          variant="info"
          disabled
        >
          <font-awesome-icon icon="rotate" />
          В обработке
        </b-button>

        <b-button
          variant="primary"
          class="ml-2"
          @click="createOrUpdate($event, FINE_STATUS_VERIFIED)"
        >
          <font-awesome-icon icon="check" />
          Подтвердить
        </b-button>

        <b-button
          variant="danger"
          class="ml-2"
          @click="createOrUpdate($event, FINE_STATUS_DECLINED)"
        >
          <font-awesome-icon icon="times" />
          Отклонить
        </b-button>
      </div>
      <div v-else class="col">
        <b-button
          v-if="fine.status === FINE_STATUS_VERIFIED && canReviewFines"
          variant="danger"
          class="ml-2"
          @click="createOrUpdate($event, FINE_STATUS_NEW)"
        >
          <font-awesome-icon icon="times" />
          Отменить подтверждение
        </b-button>
      </div>
    </div>
  </div>
</b-form>
