// processRequestError
import * as Sentry from "@sentry/vue";

export default function (error) {
  Sentry.captureException(error);
  if (error.response) {
    if (error.response.status === 404) {
      // this.$router.push({name: 'not-found'})
    } else if (error.response.status === 403) {
      if (this.$store.state.user.isGuest === true) {
        this.$bvToast.toast("Для выполнения действия войдите в систему.", {
          title: "Ошибка доступа",
          variant: "danger",
        });
        this.$router.push({ name: "users-login" });
        return true;
      } else {
        this.$bvToast.toast(
          "У вас нет прав на совершение действия. Обратитесь к администратору.",
          {
            title: "Ошибка",
            variant: "danger",
          }
        );
        return true;
      }
    }
  }
  return false;
}
