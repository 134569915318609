<template>
  <div class="ibox">
    <div class="ibox-content">
      <request-logs
        ref="request-logs"
        :show-search="true"
        :show-requests="true"
        :request-number-list="requestNumberList"
        :contract-id="contractId"
      ></request-logs>
    </div>
  </div>
</template>

<script>
import RequestLogs from "@/modules/pest/views/logs/RequestLogs";

export default {
  name: "RequestLogsWrapper",
  components: { RequestLogs },
  props: {
    requestNumberList: {
      type: Array,
      default: () => [],
    },
    contractId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
