<template>
  <div v-if="hasData && data && data.results" class="table-responsive">
    <table class="table table-bordered table-condensed">
      <thead>
        <tr>
          <th class="titleCol">Тип вредителя / Источник / Исполнитель</th>
          <th v-for="(param, pIndex) in CPOParams" :key="'t' + pIndex">
            {{ param.title }}
          </th>
        </tr>
      </thead>
      <template v-for="(pestInfo, pestInd) in data.results">
        <tbody :key="'pestInd' + pestInd" class="pestBlock">
          <c-p-o-table-row
            :pest-info="pestInfo"
            :class="{
              sourceOpened: !!pestInfo.toggled,
            }"
            :cell-style="gradientStyle"
          >
            <template #title>
              <td @click.prevent="togglePest(pestInfo)">
                <div class="d-flex">
                  <div>
                    {{ pestInfo.pestTitle }}
                  </div>
                  <div class="ml-auto">
                    <font-awesome-icon
                      v-if="!pestInfo.toggled"
                      icon="arrow-down"
                    />
                    <font-awesome-icon v-else icon="arrow-up" />
                  </div>
                </div>
              </td>
            </template>
          </c-p-o-table-row>
        </tbody>

        <tbody
          v-if="pests && pestInfo.toggled"
          :key="'toggled-pestInd' + pestInd"
          class="sourceBlock"
        >
          <template v-for="(sourceInfo, sourceInd) in pestInfo.sources">
            <c-p-o-table-row
              v-if="sourceInfo.count || showEmpty"
              :key="'sourceInd' + pestInd + '-' + sourceInd"
              row-class="sourceBlock"
              :pest-info="pestInfo"
              :source-info="sourceInfo"
            >
              <template #title>
                <td @click.prevent="togglePest(sourceInfo)">
                  <div class="d-flex">
                    <div>{{ getSourceTitle(sourceInfo) }}</div>
                    <div v-if="sourceInfo.count" class="ml-auto">
                      <font-awesome-icon
                        v-if="!sourceInfo.toggled"
                        icon="arrow-down"
                      />
                      <font-awesome-icon v-else icon="arrow-up" />
                    </div>
                  </div>
                </td>
              </template>
            </c-p-o-table-row>

            <template
              v-for="(assigneeInfo, assigneeInd) in sourceInfo.assignees"
            >
              <c-p-o-table-row
                v-if="sourceInfo.toggled"
                :key="
                  'assigneeInd' + pestInd + '-' + sourceInd + '-' + assigneeInd
                "
                row-class="assigneeBlock"
                :pest-info="pestInfo"
                :source-info="sourceInfo"
                :assignee-info="assigneeInfo"
              >
                <template #title>
                  <td>
                    <div class="d-flex">
                      {{ getUserName(assigneeInfo.assignee) }}
                    </div>
                  </td>
                </template>
              </c-p-o-table-row>
            </template>
          </template>
        </tbody>
      </template>
      <tbody>
        <c-p-o-table-total-row :data="data" />
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../../../decorators/common.js";
import { getPestTitle } from "../../../decorators/pests.js";
import clone from "just-clone";
import { mapActions, mapGetters } from "vuex";
import { getUserName } from "@/modules/crm/common";
import {
  CPOParams,
  getNetCostSum,
  getProfitSum,
  getTotalProfitSum,
  getSourceTitle,
} from "@/modules/pest/decorators/cpo";
import CPOTableTotalRow from "@/modules/pest/views/reports/cpo/CPOTableTotalRow.vue";
import CPOTableRow from "@/modules/pest/views/reports/cpo/CPOTableRow.vue";
import Gradient from "javascript-color-gradient";
export default {
  name: "CPODataTable",
  components: { CPOTableRow, CPOTableTotalRow },
  props: {
    selectedPests: { default: [] },
    sourceData: { default: null },
    hasData: { default: false },
    pests: { default: false },
    showEmpty: { default: false },
  },
  data() {
    return {
      defaultCPO: 0,
      maxSuccessAverageSum: 0,
      maxNetCostSum: 0,
      gradientPlus: [],
      gradientMinus: [],
      storedData: {},
      data: null,
      CPOParams,
    };
  },
  computed: {},
  watch: {
    selectedPests(val) {
      this.filterData();
      this.refreshTable();
    },
    sourceData(val) {
      this.filterData();
      this.refreshTable();
    },
  },
  mounted: function () {
    this.loadFieldsData();
  },
  created() {
    this.$eventHub.$on("clear-chart", this.chartCleared);
  },
  beforeDestroy() {
    this.$eventHub.$off("toggle-chart");
  },

  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    getUserName,
    getSourceTitle,
    formatNumber,
    chartCleared() {
      if (!this.data || !this.data.results) return;
      for (let pestInfo of this.data.results) {
        pestInfo.showChart = false;
        for (let sourceInfo of pestInfo.sources) {
          sourceInfo.showChart = false;
          for (let assigneeInfo of sourceInfo.assignees) {
            assigneeInfo.showChart = false;
          }
        }
      }
      this.$forceUpdate();
    },
    gradientStyle(param, info, pIndex) {
      if (param.attr === "successAverageSum") {
        return this.getLinearGradient(
          "#f8ac59",
          info.successAverageSum,
          this.maxSuccessAverageSum
        );
      }
      if (param.attr === "netCostSum") {
        return this.getLinearGradient(
          "#1c84c6",
          info.netCostSum,
          this.maxNetCostSum
        );
      }
      if (param.attr === "totalProfitSum") {
        return this.getTotalGradient(info.totalProfitSum);
      }
      return null;
    },

    refreshTable() {
      this.sortData();
      this.setColors();
      this.$forceUpdate();
    },
    setColors() {
      this.createGradient();
      this.maxSuccessAverageSum = this.countMax("successAverageSum");
      this.maxNetCostSum = this.countMax("netCostSum");
    },

    filterData() {
      let $this = this;
      this.data = clone(this.sourceData);
      if (
        this.data &&
        this.selectedPests &&
        this.selectedPests.length &&
        this.sourceData.results &&
        this.sourceData.results.length
      ) {
        this.data.results = this.sourceData.results.filter((info) =>
          $this.selectedPests.includes(info.pestId)
        );
      }
      if (this.data && this.data.results && this.storedData) {
        for (let record of this.data.results) {
          if (record.pestId in this.storedData) {
            record.cpo = this.storedData[record.pestId];
          }
        }
      }
    },
    sortData() {
      if (this.data && this.data.results) {
        this.data.results.sort((a, b) => {
          let profitA = this.getTotalProfitSum(a);
          let profitB = this.getTotalProfitSum(b);
          if (profitA > profitB) {
            return -1;
          }
          if (profitA < profitB) {
            return 1;
          }
          return 0;
        });
      }
    },
    countMax(prop) {
      if (!this.data || !this.data.results) {
        return 0;
      }
      let sum = 0;
      for (let info of this.data.results) {
        if (info[prop] > sum) {
          sum = info[prop];
        }
      }
      return sum;
    },
    getLinearGradient(color, value, maxValue) {
      if (!maxValue) {
        return {};
      }
      let percent = (value / maxValue) * 100;
      return {
        background:
          "linear-gradient(90deg, " +
          color +
          " 0%, rgba(255,255,255,1) " +
          percent +
          "%)",
      };
    },
    getCircleColor(count) {
      if (count >= 2) {
        return "#ED5565";
      }
      if (count > 0) {
        return "#f8ac59";
      }
      return "#1ab394";
    },
    getPestTitle(id) {
      let pest = this.pests.find((p) => p.id === id);
      return pest ? getPestTitle(pest) : "Не определен";
    },
    getTotalGradient(val) {
      if (!val) {
        return {
          background: "#f8ac59",
        };
      }
      if (val > 0) {
        if (!this.gradientPlus) {
          return {};
        }
        let i = 0;
        for (let info of this.data.results) {
          if (info.totalProfitSum > 0) {
            if (info.totalProfitSum === val) {
              return {
                background:
                  "linear-gradient(180deg, " +
                  this.gradientPlus[i] +
                  " 100%, " +
                  this.gradientPlus[i + 1] +
                  "  100%)",
              };
            }
            i++;
          }
        }
      }
      if (val < 0) {
        if (!this.gradientMinus) {
          return {};
        }
        let i = 0;
        for (let info of this.data.results) {
          if (info.totalProfitSum < 0) {
            if (info.totalProfitSum === val) {
              return {
                background:
                  "linear-gradient(180deg, " +
                  this.gradientMinus[i] +
                  " 100%, " +
                  this.gradientMinus[i + 1] +
                  "  100%)",
              };
            }
            i++;
          }
        }
      }
      return {};
    },
    createGradient() {
      let plus = 0;
      let minus = 0;
      if (!this.sourceData || !this.sourceData.results) {
        return;
      }
      for (let info of this.data.results) {
        if (info.totalProfitSum > 0) {
          plus++;
        }
        if (info.totalProfitSum < 0) {
          minus++;
        }
      }
      this.gradientPlus = new Gradient()
        .setColorGradient("#1ab394", "#f8ac59")
        .setMidpoint(plus)
        .getColors();
      this.gradientPlus.unshift("#1ab394");
      this.gradientMinus = new Gradient()
        .setColorGradient("#f8ac59", "#ED5565")
        .setMidpoint(minus)
        .getColors();
      this.gradientMinus.unshift("#f8ac59");
      this.$forceUpdate();
    },
    getNetCostSum,
    getProfitSum,
    getTotalProfitSum,
    loadFieldsData() {
      // this.storedData = this.getProp()("cpo-pests-values");
    },
    saveFieldsData() {
      let info = {};
      for (let record of this.data.results) {
        info[record.pestId] = record.cpo;
      }
      this.storedData = info;
      this.setProp({ key: "cpo-pests-values", value: info });
    },
    togglePest(info) {
      info.toggled = !info.toggled;
      this.$forceUpdate();
    },

    isChartSelected(info) {
      return true;
    },
    toggleChart(info) {
      // this
    },
  },
};
</script>

<style lang="scss" scoped>
.setInput {
  padding-top: 0;
  padding-bottom: 0;
  height: 27px;
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
}

td,
input {
  transition: background-color 500ms linear;
}

.cpoColumn {
  width: 150px;
}

.titleCol {
  width: 310px;
}

.pestBlock {
  border-top: 0 !important;
}

.assigneeBlock {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}

tr.sourceOpened td:first-child {
  background: #d9eeee !important;
}

input:focus::placeholder {
  color: transparent;
}

.sourceBlock tr td:first-child {
  padding-left: 20px;
}

tr.assigneeBlock td:first-child {
  padding-left: 40px;
}
</style>
