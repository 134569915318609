import TaskList from "@/modules/crm/views/tasks/TaskList.vue";
import TaskCreate from "@/modules/crm/views/tasks/TaskCreate.vue";
import TaskUpdate from "@/modules/crm/views/tasks/TaskUpdate.vue";
import TaskView from "@/modules/crm/views/tasks/TaskView.vue";

export default [
  {
    path: "/tasks/page:page?",
    name: "task-list",
    components: {
      default: TaskList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/tasks/new:returnUrl?",
    name: "task-create",
    components: {
      default: TaskCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/tasks/:id/update",
    name: "task-update",
    components: {
      default: TaskUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/tasks/:id",
    name: "task-view",
    components: {
      default: TaskView,
    },
    meta: { requiresAuth: true },
  },
];
