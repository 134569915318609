
<div class="documentType-page">
  <div class="ibox">
    <div class="ibox-title pb-3">
      <div class="d-flex">
        <div class="ml-1 mr-2 mt-2">
          <router-link :to="{ name: 'document-type-list' }" class="h4 text-muted">
            <font-awesome-icon icon="arrow-left"/>
          </router-link>
        </div>
        <div>
          <h1>
            {{ documentType.title }}
          </h1>
        </div>

      </div>
    </div>
    <div class="ibox-content">
      <documentType-info :documentType="documentType" v-on:update-prop="updateProp"></documentType-info>
    </div>
  </div>

</div>

