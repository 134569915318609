
<b-modal
  id="modal-time"
  ref="modal"
  title="Длительность выполнения"
  cancel-title="Отмена"
  :ok-disabled="true"
  ok-title="Сохранить"
  title-tag="h3"
  @ok="handleTaskModalOk"
>
  <ul>
    <li @click.prevent="selectTime(30)"><a href="">0:30</a></li>
    <li @click.prevent="selectTime(60)"><a href="">1:00</a></li>
    <li @click.prevent="selectTime(90)"><a href="">1:30</a></li>
    <li @click.prevent="selectTime(120)"><a href="">2:00</a></li>
    <li @click.prevent="selectTime(150)"><a href="">2:30</a></li>
    <li @click.prevent="selectTime(180)"><a href="">3:30</a></li>
    <li @click.prevent="selectTime(210)"><a href="">3:30</a></li>
    <li @click.prevent="selectTime(240)"><a href="">4:00</a></li>
  </ul>
</b-modal>
