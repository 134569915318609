import dayjs from "dayjs";

const TYPE_TASK = "task";
const TYPE_CALL = "call";
const TYPE_LETTER = "letter";

const getTaskCreateTime = function (task) {
  return dayjs.unix(task.timeCreated).format("DD.MM.YYYY HH:mm:ss");
};
const getTaskCompleteTime = function (task) {
  return dayjs.unix(task.completedTime).format("DD.MM.YYYY HH:mm:ss");
};
const getTaskDeadline = function (task) {
  return dayjs(task.deadlineD).format("DD.MM.YYYY");
};

export {
  TYPE_TASK,
  TYPE_CALL,
  TYPE_LETTER,
  getTaskDeadline,
  getTaskCreateTime,
  getTaskCompleteTime,
};
